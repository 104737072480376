import React, {Component} from 'react'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {numberWithCommas} from '../../../util/util-formaters'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {DocumentIcon, Squares2X2Icon} from '@heroicons/react/20/solid'
import AccountReportDialog from '../dialogs/account-report-dialog'
import {getSecondResource} from '../../../data/actions/secondResource'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {DEFAULT_DATABASE_DATETIME_FORMAT, READ_PERM} from '../../../util/util-constants'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import {
    ArrowPathIcon,
    Bars4Icon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    MinusCircleIcon,
    XCircleIcon
} from '@heroicons/react/24/outline'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import FunnelIcon from "@heroicons/react/20/solid/FunnelIcon";
import InfoParagraph from "../../../common/components/info-paragraph";
import FieldDate from "../../../common/components/fields/field-date";
import {fieldsToHtml, getFieldLabelClass} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import {LoaderSmall} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";

const MAIN_ACCOUNT_TYPE_EXPENSE = 1
const MAIN_ACCOUNT_TYPE_INCOME = 2

export default class IncomeStatementTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            queryFilterFields: this.getQueryFilterFields(),
            selectedItem: null,
            accountDetailsDialog: false,
            comparisonDataView: false,
            comparisonDates: [],
            columnsInData: 2, // Split View
            zeroValuesShown: true,
            datesFilterDialogOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingIncomeStatement,
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingIncomeStatement,
            query: Object.assign({
                format: 'EXCEL',
                name: 'accounting_income_statement' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    downloadPdf = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingIncomeStatement,
            query: Object.assign({
                format: 'PDF',
                name: 'accounting_income_statement' + currentDate() + '.pdf'
            }, this.getQuery())
        }))
    }

    downloadExcelReports = (query) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingAccountReport,
            query: Object.assign({
                format: 'EXCEL',
                name: 'income_statement_' + currentDate() + '.xlsx'
            }, query)
        }))
    }
    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value)}, this.fetchData)
    }

    handleComparisonDatesInputChange = (name, value, index) => {
        let fields = this.state.comparisonDates
        fields[index][name].value = value
        this.setState({comparisonDates: fields})
    }

    handleToggleCompareDatesModal = () => {
        if (this.state.comparisonDates.length > 0) {
            this.setState({
                comparisonDates: [],
                comparisonDataView: false
            }, this.fetchData)
        } else {
            this.setState({
                comparisonDates: [this.getQueryFilterFields()],
                comparisonDataModal: true
            })
        }
    }

    handleToggleAccountDetails = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            accountDetailsDialog: !this.state.accountDetailsDialog
        })
    }

    handleToggleFilterDialog = () => {
        this.setState({
            datesFilterDialogOpen: !this.state.datesFilterDialogOpen
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        const StartDate = this.state.comparisonDates.map(it => {
            return it.StartDate.value.split(' ')[0]
        })
        const EndDate = this.state.comparisonDates.map(it => {
            return it.EndDate.value.split(' ')[0]
        })

        return this.state.comparisonDates.length > 0 ?
            {StartDate: StartDate, EndDate: EndDate} :
            FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
    }

    getQueryFilterFields = () => {
        return {
            StartDate: new Field('StartDate', moment().startOf('year').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['date'], false, 'date', {
                addContainerClass: 'col-span-4'
            }),
            EndDate: new Field('EndDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['date'], false, 'date', {
                addContainerClass: 'col-span-4'
            })
        }
    }

    groupBy = (arr, property) => {
        return arr?.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = []
            }
            memo[x[property]].push(x)
            return memo
        }, {})
    }

    getIncomes = (data) => {
        const Accounts = getProp(this.props, 'resource.data.Accounts', data ?? {})
        const groupedItems = this.groupBy(Object.values(Accounts), 'MainAccountTypeID')
        const res = Object.keys(groupedItems).length ? this.groupBy(groupedItems[MAIN_ACCOUNT_TYPE_INCOME], 'AccountType') : {}
        return res ? res : {}
    }

    getExpenses = (data) => {
        const Accounts = getProp(this.props, 'resource.data.Accounts', data ?? {})
        const groupedItems = this.groupBy(Object.values(Accounts), 'MainAccountTypeID')
        return Object.keys(groupedItems).length ? this.groupBy(groupedItems[MAIN_ACCOUNT_TYPE_EXPENSE], 'AccountType') : {}
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.queryFilterFields)), translate, this.handleInputChange)

        const incomes = this.getIncomes()
        const expenses = this.getExpenses()

        const Income = getProp(this.props, 'resource.data.Income', 0)
        const Expense = getProp(this.props, 'resource.data.Expense', 0)
        const NetIncome = getProp(this.props, 'resource.data.NetIncome', 0)

        const comparisonFormattedObject = Array.isArray(getProp(this.props, 'resource.data', [])) &&
            getProp(this.props, 'resource.data', []).reduce((memo, it, index) => {
                memo[index] = Object.assign({}, this.getIncomes(it.Accounts), this.getExpenses(it.Accounts))
                return memo
            }, {})

        const comparisonList = Object.keys(comparisonFormattedObject).length && Object.keys(comparisonFormattedObject[0]).map((item, index) => {
            const dropdownKey = item
            let totals = this.state.comparisonDates.reduce((memo, it, index) => {
                memo[index] = 0
                return memo
            }, {})

            const children = getProp(comparisonFormattedObject[0], item, [])
                .filter((elem) => !(!this.state.zeroValuesShown && !elem.Value))
                .map((elem) => {
                    const values = this.state.comparisonDates.map((it, dateIndex) => {
                        const arr = comparisonFormattedObject[dateIndex][elem.AccountType]
                        const account = arr?.find(account => account.AccountID === elem.AccountID)
                        totals[dateIndex] += getProp(account, 'Value', 0)

                        return (
                            <span>{genericMoneyFormatter(account?.Value ?? 0)}</span>
                        )
                    })
                    return !this.state.zeroValuesShown && !elem.Value ? '' : (
                        <div
                            onClick={() => this.handleToggleAccountDetails(elem)}
                            className="hover:bg-tm-gray-100 py-2 pr-0 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">

                            <div
                                className={`ml-3 w-full gap-5 grid grid-cols-${this.state.comparisonDates.length + 1}`}>
                                <span>{elem.AccountNumber} {elem.AccountName}</span>
                                {values}
                            </div>
                        </div>
                    )
                })
            const totalsList = Object.values(totals).map(it => {
                return (
                    <span>{genericMoneyFormatter(it)}</span>
                )
            })
            return !!children.length && (
                <div key={index}>
                    <div className={'border-gray-300 border-t hover:bg-tm-gray-100'}>
                        <div
                            className={'whitespace-nowrap py-4 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer'}
                            onClick={() => {
                                this.setState({[dropdownKey]: !this.state[dropdownKey]})
                            }}
                        >
                            {!this.state[dropdownKey] ?
                                (<ChevronDownIcon
                                    className={`h-4 w-4`}/>)
                                :
                                (<ChevronRightIcon
                                    className={`h-4 w-4`}/>)
                            }
                            <span className="ml-3">{item}</span>
                        </div>
                    </div>
                    {!this.state[dropdownKey] && <div className={'ml-4'}>{children}</div>}
                    <div
                        className={`grid gap-5 grid-cols-${this.state.comparisonDates.length + 1} pl-5 font-bold text-lg`}>
                        <div>Total {item}</div>
                        {totalsList}
                    </div>
                </div>
            )
        })

        const incomesList = Object.keys(incomes).map((item, index) => {
            const dropdownKey = item
            let total = 0
            const children = getProp(incomes, item, [])
                .filter((elem) => !(!this.state.zeroValuesShown && !elem.Value))
                .map((elem) => {
                    total += (elem.Value || 0)
                    return !this.state.zeroValuesShown && !elem.Value ? '' : (
                        <div
                            onClick={() => this.handleToggleAccountDetails(elem)}
                            className="hover:bg-tm-gray-100 py-2 pr-0 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">

                            <div className={'ml-3 w-full flex items-center justify-between'}>
                                <span>{elem.AccountNumber} {elem.AccountName}</span>
                                <span>{genericMoneyFormatter(elem.Value)}</span>
                            </div>
                        </div>
                    )
                })

            return !!children.length && (
                <div key={index}>
                    <div className={'border-gray-300 border-t hover:bg-tm-gray-100'}>
                        <div
                            className={'whitespace-nowrap py-4 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer'}
                            onClick={() => {
                                this.setState({[dropdownKey]: !this.state[dropdownKey]})
                            }}
                        >
                            {!this.state[dropdownKey] ?
                                (<ChevronDownIcon
                                    className={`h-4 w-4`}/>)
                                :
                                (<ChevronRightIcon
                                    className={`h-4 w-4`}/>)
                            }
                            <span className="ml-3">{item}</span>
                        </div>
                    </div>
                    {!this.state[dropdownKey] && <div className={'ml-4'}>{children}</div>}
                    <div className="flex items-center justify-between pl-5 font-bold text-lg">
                        <div>Total {item}</div>
                        <div>{genericMoneyFormatter(total)}</div>
                    </div>
                </div>
            )
        })

        const expensesList = Object.keys(expenses ?? {})
            .sort((a, b) => {
                const arr = {'Expenses': 1, 'Operating expenses': 2, 'Other expenses': 3};
                return arr[a] > arr[b] ? 1 : -1
            })
            .map((item, index) => {
                const dropdownKey = item
                let total = 0
                const children = getProp(expenses, item, [])
                    .filter((elem) => !(!this.state.zeroValuesShown && !elem.Value))
                    .map((elem) => {
                        total += (-elem.Value || 0)
                        return !this.state.zeroValuesShown && !elem.Value ? '' : (
                            <div
                                onClick={() => this.handleToggleAccountDetails(elem)}
                                className="hover:bg-tm-gray-100 py-2 pr-0 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">

                                <div className={'ml-3 w-full flex items-center justify-between'}>
                                    <span>{elem.AccountNumber} {elem.AccountName}</span>
                                    <span>{genericMoneyFormatter(-elem.Value)}</span>
                                </div>
                            </div>
                        )
                    })

                return !!children.length && (
                    <div key={index}>
                        <div className={'border-gray-300 border-t hover:bg-tm-gray-100'}>
                            <div
                                className={'whitespace-nowrap py-4 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer'}
                                onClick={() => {
                                    this.setState({[dropdownKey]: !this.state[dropdownKey]})
                                }}
                            >
                                {!this.state[dropdownKey] ?
                                    (<ChevronDownIcon
                                        className={`h-4 w-4`}/>)
                                    :
                                    (<ChevronRightIcon
                                        className={`h-4 w-4`}/>)
                                }
                                <span className="ml-3">{item}</span>
                            </div>
                        </div>
                        {!this.state[dropdownKey] && <div className={'ml-4'}>{children}</div>}
                        <div className="flex items-center justify-between pl-5 font-bold text-lg">
                            <div>Total {item}</div>
                            <div>{genericMoneyFormatter(total)}</div>
                        </div>
                    </div>
                )
            })

        return (
            <Card>
                <div className="mb-5">
                    <PageHeader
                        title={translate('page.heading.IncomeStatement')}
                    >
                        <div className="">
                            <Tippy
                                content={translate(`text.${this.state.zeroValuesShown ? 'HideZeroValues' : 'ShowZeroValues'}`)}>
                                <button
                                    className={classNames('btn-icon cursor-pointer relative z-10 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2', this.state.zeroValuesShown ? 'text-secondary-500 bg-tm-gray-100 ' : 'text-red-500 bg-red-50')}
                                    onClick={() => this.setState({zeroValuesShown: !this.state.zeroValuesShown})}
                                >
                                    <CurrencyDollarIcon className="w-5 h-5"/>

                                    {!this.state.zeroValuesShown && (
                                        <span
                                            className="top-1.5 left-1.5 animate-ping absolute inline-flex h-6 w-6 rounded-full bg-red-400 opacity-75"
                                        />
                                    )}
                                </button>
                            </Tippy>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>
                            <Tippy content={translate('text.download_pdf')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadPdf}
                                >
                                    <DocumentIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={translate(`text.${this.state.comparisonDates.length > 0 ? 'ClearFilter' : 'CompareDates'}`)}>
                                <button
                                    className="btn-icon"
                                    onClick={this.handleToggleCompareDatesModal}
                                >
                                    <DocumentDuplicateIcon
                                        className={`w-5 h-5 ${this.state.comparisonDates.length > 0 ? 'text-red-600' : ''}`}/>
                                </button>
                            </Tippy>

                            {!this.state.comparisonDataView && (
                                <button
                                    className="btn-icon"
                                    title={translate('text.Filters')}
                                    onClick={this.handleToggleFilterDialog}
                                >
                                    <FunnelIcon className="w-5 h-5"/>
                                </button>
                            )}

                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10 ml-8"
                                    )
                                }
                                onClick={this.fetchData}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        this.props.resource?.isLoading || this.props.secondResource?.isLoading ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>
                    </PageHeader>
                </div>

                {!!this.state.comparisonDataView && (
                    <div>
                        <div className={`grid gap-5 grid-cols-${this.state.comparisonDates.length + 1}`}>
                            <div/>
                            {this.state.comparisonDates.map((it, index) => (
                                <div
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ">
                                    <div className="flex">
                                        <div>{moment(it.StartDate.value).format('MMMM Do YYYY')} - {moment(it.EndDate.value).format('MMMM Do YYYY')}</div>
                                        <Tippy content={
                                            <span>{translate('text.RemoveDateFromComparison')}</span>}>
                                            <MinusCircleIcon
                                                onClick={() => {
                                                    let comparisonDates = this.state.comparisonDates
                                                    comparisonDates.splice(index, 1)
                                                    this.setState({
                                                        comparisonDates: comparisonDates,
                                                        comparisonDataView: comparisonDates.length !== 0 // Changing view if last removed
                                                    }, () => {
                                                        this.fetchData()
                                                    })
                                                }}
                                                className={'-rotate-45 h-4 w-4 text-red-600 ml-3 cursor-pointer'}/>
                                        </Tippy>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={``}>
                            {comparisonList}
                        </div>

                        {Array.isArray(getProp(this.props, 'resource.data', [])) && (
                            <>
                                <hr className="my-2"/>
                                <div
                                    className={`grid gap-5 grid-cols-${this.state.comparisonDates.length + 1} pl-5 font-bold text-lg`}>
                                    <div>Total Income</div>
                                    {getProp(this.props, 'resource.data', []).map(it => (
                                        <div>{genericMoneyFormatter(it.Income)}</div>
                                    ))}
                                </div>
                                <hr className="my-2"/>
                                <div
                                    className={`grid gap-5 grid-cols-${this.state.comparisonDates.length + 1} pl-5 font-bold text-lg`}>
                                    <div>Total Expense</div>
                                    {getProp(this.props, 'resource.data', []).map(it => (
                                        <div>{genericMoneyFormatter(it.Expense)}</div>
                                    ))}
                                </div>
                                <hr className="my-2"/>
                                <div
                                    className={`grid gap-5 grid-cols-${this.state.comparisonDates.length + 1} pl-5 font-bold text-lg`}>
                                    <div>Net Income</div>
                                    {getProp(this.props, 'resource.data', []).map(it => (
                                        <div>{genericMoneyFormatter(it.NetIncome)}</div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}

                {!this.state.comparisonDataView && (
                    <>
                        <div className="row mb-4">
                            <div className="col p-5 grid grid-cols-12 gap-4">
                                {fieldsHtml}
                            </div>

                            <div className="col flex justify-end items-end">
                                <div className="flex justify-end items-end">
                                    <Tippy content={translate('text.StackedView')}>
                                        <button
                                            onClick={() => this.setState({columnsInData: 1})}
                                            className={`${this.state.columnsInData === 1 && 'bg-tm-gray-200-500'} h-full relative inline-flex items-center px-4 py-2 rounded-l-md border border-tm-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 text-tm-gray-700`}
                                        >
                                            <Bars4Icon
                                                className={`${this.state.columnsInData === 1 && 'text-primary'} w-5 h-5`}
                                                aria-hidden="true"/>
                                        </button>
                                    </Tippy>
                                    <Tippy content={translate('text.SplitView')}>
                                        <button
                                            onClick={() => this.setState({columnsInData: 2})}
                                            className={`${this.state.columnsInData === 2 && 'bg-tm-gray-200-500'} h-full -ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-tm-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 text-tm-gray-700`}
                                        >
                                            <Squares2X2Icon
                                                className={`${this.state.columnsInData === 2 && 'text-primary'} w-5 h-5`}
                                                aria-hidden="true"/>
                                        </button>
                                    </Tippy>
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <h1 className={'font-bold'}>{translate('tab.income_statement')}</h1>
                            <h2>From {moment(this.state.queryFilterFields.StartDate.value, 'YYYY-MM-DD 00:00:00').format('MMMM Do YYYY')} to {moment(this.state.queryFilterFields.EndDate.value, 'YYYY-MM-DD 00:00:00').format('MMMM Do YYYY')}</h2>
                        </div>

                        <div className="flex items-center justify-between border-b border-gray-300">
                            <div className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500'}/>
                        </div>

                        <div className={`grid grid-cols-${this.state.columnsInData} gap-5`}>
                            <div className="col-span-1">
                                <div className="mt-2">
                                    <h1 className="text-2xl flex items-center">Revenue</h1>
                                    {incomesList}
                                </div>
                            </div>
                            <div
                                className={`col-span-1 ${this.state.columnsInData === 2 ? 'border-l-2' : 'border-t-2'} border-gray-200`}>
                                <div className={`mt-2 ${this.state.columnsInData === 2 ? 'ml-5' : ''}`}>
                                    <h1 className="text-2xl flex items-center">Expenses</h1>
                                    {expensesList}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-4">
                            <div className="flex items-center justify-between pl-5 font-bold text-lg">
                                <div>Total Income</div>
                                <div>{genericMoneyFormatter(Income)}</div>
                            </div>
                            <div className="flex items-center justify-between pl-5 font-bold text-lg">
                                <div>Total Expense</div>
                                <div>{genericMoneyFormatter(Expense)}</div>
                            </div>
                        </div>

                        <hr className="my-2"/>
                        <h2 className="mr-5 text-xl"><strong>Net income</strong>: {numberWithCommas(NetIncome)}</h2>
                    </>
                )}


                {this.props.resource.isLoading && (
                    <LoaderSmall/>
                )}

                <ModalDefault
                    show={!!this.state.comparisonDataModal}
                    widthClass={'max-w-xl'}
                    title={translate('text.SelectDatesForComparison')}

                    onButtonClick={() => {
                        // Removing duplicates
                        const dates = this.state.comparisonDates.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    (t.StartDate.value + t.StartDate.value) === (value.StartDate.value + value.StartDate.value)
                                ))
                        )
                        this.setState({
                            comparisonDates: dates,
                            comparisonDataView: true,
                            comparisonDataModal: false
                        }, this.fetchData)
                    }}
                    buttonLabel={translate('btn.save')}

                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={() => this.setState({comparisonDataModal: false, comparisonDates: []})}
                >
                    <div className="p-5">
                        {this.state.comparisonDates.map((item, i) => (
                            <div className={'mb-5'} key={i}>
                                <span className={classNames(getFieldLabelClass(), 'flex justify-between items-center')}>
                                    <span>{translate('field.Date')} {i + 1}</span>
                                    {i !== 0 && (
                                        <XCircleIcon
                                            onClick={() => {
                                                let comparisonDates = this.state.comparisonDates
                                                comparisonDates.splice(i, 1)
                                                this.setState({comparisonDates})
                                            }}
                                            className={'h-4 w-4 cursor-pointer'}/>
                                    )}
                                </span>
                                <div className="grid grid-cols-2 gap-3">
                                    <FieldDate
                                        addClass={'form-control'}
                                        onChange={(name, value) => this.handleComparisonDatesInputChange(name, value, i)}
                                        showTimeSelect={false}
                                        {...item.StartDate}
                                    />
                                    <FieldDate
                                        addClass={'form-control'}
                                        onChange={(name, value) => this.handleComparisonDatesInputChange(name, value, i)}
                                        showTimeSelect={false}
                                        {...item.EndDate}
                                    />
                                </div>
                            </div>
                        ))}

                        {this.state.comparisonDates.length < 5 ?
                            <div className="flex justify-center items-center mt-5">
                                <Tippy
                                    content={translate('btn.AddAnotherDate')}
                                >
                                    <button
                                        className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                        onClick={() => {
                                            this.setState({
                                                comparisonDates: this.state.comparisonDates.concat(this.getQueryFilterFields())
                                            })
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4"/>
                                    </button>
                                </Tippy>
                            </div>
                            :
                            <InfoParagraph message={'Maximum comparison dates reached.'}/>
                        }
                    </div>
                </ModalDefault>

                <AccountReportDialog
                    AccountID={this.state.selectedItem?.AccountID}
                    AccountName={this.state.selectedItem?.AccountName}
                    StartDate={this.state.queryFilterFields.StartDate?.value}
                    EndDate={this.state.queryFilterFields.EndDate?.value}
                    onClose={this.handleToggleAccountDetails}
                    show={checkPerm(Resources.AccountingAccountReport, READ_PERM) && this.state.accountDetailsDialog}
                    translate={this.props.translate}
                    getTransactions={(query) => {
                        this.props.dispatch(getSecondResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.AccountingAccountReport,
                            query: query
                        }))
                    }}
                    downloadExcelReports={(query) => this.downloadExcelReports(query)}
                    isLoading={getProp(this.props, 'secondResource.isLoading', false)}
                    transactions={getProp(this.props, 'secondResource.data', [])}
                    thirdResource={this.props.thirdResource}
                    dialogResource={this.props.dialogResource}
                    dispatch={this.props.dispatch}
                />

                <ModalDefault
                    show={!!this.state.datesFilterDialogOpen}
                    widthClass={'max-w-3xl'}
                    title={"Dates filter"}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleFilterDialog}

                    translate={translate}
                >
                    <PayDateFilter
                        queryFields={this.state.queryFilterFields}
                        translate={translate}
                        updateQueryFields={(queryFieldsUpdate) => {
                            this.setState({
                                queryFilterFields: queryFieldsUpdate
                            }, () => {
                                this.fetchData()
                            })
                        }}
                        hasDateType={false}
                        isDateTypeClearable={false}
                        isClearDatesButtonVisible={false}
                        onQueryChange={this.handleInputChange}
                    />
                </ModalDefault>
            </Card>
        )
    }
}
