import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Resources from '../../../data/services/resources'
import AccountingSettingsTab from './accounting-settings-tab'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import DocumentTypesTab from "../../../common/components/tabs/document-types-tab";
import Layout from "../../../common/components/layout";

export default function AccountingSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    const [tabs, setTabs] = useState(getTabs())
    const [breakpoint, setBreakpoint] = useState({})

    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource
                return it
            })
        )
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }
    const currentTab = tabs.find(tab => tab.current)

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            hasFooter={currentTab.name === 'AccountingSettings'}
            isAccessible={!(resource.errorStatus === 2)}
            onBreakpoinChange={handleBreakpointChange}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.AccountingSettings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'AccountingSettings' && (
                    <AccountingSettingsTab
                        translate={translate}
                        dispatch={dispatch}
                        resource={resource}
                    />
                )}

                {currentTab.name === 'DocumentTypes' && (
                    <DocumentTypesTab
                        translate={translate}
                        primaryType={'IsAccountingDocument'}
                        breakpoint={breakpoint}
                    />
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    return [
        {
            name: 'AccountingSettings',
            resource: Resources.AccountingSettings,
            current: true,
            visible: true
        },
        {
            name: 'DocumentTypes',
            resource: Resources.DocumentTypes,
            current: false,
            visible: true
        }
    ]
}
