import React from "react";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import {MapIcon} from "@heroicons/react/24/outline";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY,
    STOP_TYPE_STOP_BY
} from "../../../../../util/util-constants";
import {classNames, getHighlightedFullAddressName} from "../../../../../common/util/util-helpers";
import moment from "moment-timezone";
import {getUserDateTimeFormat, toFrontDateTime} from "../../../../../common/util/util-dates";
import {getStopIcon} from "../../load-utils";
import ArrowSmallUpIcon from "@heroicons/react/20/solid/ArrowSmallUpIcon";
import ArrowSmallDownIcon from "@heroicons/react/20/solid/ArrowSmallDownIcon";
import ArrowsUpDownIcon from "@heroicons/react/20/solid/ArrowsUpDownIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import CopyToClipboardButton from "../../../../../common/components/buttons/copy-to-clipboard-button";


export default function StopsShortDisplay({
                                              stopsCombined,
                                              selectedStopID,
                                              isLoading,
                                              postUpdatePingLocation,
                                              translate,
                                              pingZone
                                          }) {


    return (
        <React.Fragment>
            {!isLoading && postUpdatePingLocation.address && (
                <>
                    <InfoBar className="flex gap-2 py-2 px-8" Icon={MapPinIcon}>
                        <div className="grow leading-7 relative">
                            <div className="font-bold">Pinged location</div>
                            {postUpdatePingLocation.address}
                            <CopyToClipboardButton
                                className="w-9 h-9 rounded-lg absolute -top-1.5 focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"
                                clipboardText={postUpdatePingLocation?.address}
                                translate={translate}
                            />
                        </div>
                        {!!pingZone && (
                            <div className="grow pl-4 leading-7">
                                <div className="font-bold flex gap-2">
                                    <ClockIcon className="w-5 h-5 shrink-0 text-sky-600" /> {translate("text.ping_local_time")}:
                                </div>
                                <div className="pl-7">{toFrontDateTime(moment.tz(pingZone))}</div>
                            </div>
                        )}
                    </InfoBar>
                </>
            )}

            {postUpdatePingLocation?.eta && (
                <InfoBar className="flex gap-2 py-2 px-8" Icon={MapIcon}>
                    <div>
                        <div className="font-bold">ETA from a pinged location to this stop</div>
                        <div className="leading-7 relative">
                            {postUpdatePingLocation?.eta}
                            <CopyToClipboardButton
                                className="w-9 h-9 rounded-lg absolute -top-1.5 focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"
                                clipboardText={postUpdatePingLocation?.eta}
                                translate={translate}
                            />
                        </div>
                    </div>
                </InfoBar>
            )}

            <div className="p-4 space-y-4">
                {stopsCombined.map((stopCombined, index) => {
                    const isStopBy = stopCombined.StopType === STOP_TYPE_STOP_BY;
                    const StopIcon = isStopBy && getStopIcon(stopCombined.StopType);

                    let startDateTime = isStopBy
                        ? getStartEndDateTime(stopCombined.metadata.StopDate, stopCombined.metadata.StopTime)
                        : getStartEndDateTime(stopCombined.metadata.StopDate, stopCombined.metadata.StopTime);
                    let startEndDateTime = isStopBy
                        ? getStartEndDateTime(stopCombined.metadata.StopEndDate, stopCombined.metadata.StopEndTime)
                        : getStartEndDateTime(stopCombined.metadata.StopEndDate, stopCombined.metadata.StopEndTime);

                    const isSelectedStop = selectedStopID === stopCombined?.metadata?.LoadStopID;

                    return (
                        <div
                            key={stopCombined?.metadata?.LoadStopByID}
                            className={
                                classNames(
                                    "px-2.5 flex items-center rounded-card md:border border-tm-gray-300 md:shadow-card relative",
                                    isSelectedStop ? "bg-primary-transparent" : undefined
                                )
                            }
                        >
                            <div className="w-6 h-6 text-base font-bold text-center text-tm-gray-500 mr-2">
                                {index + 1}.
                            </div>

                            <div className="w-full flex">
                                <div
                                    className="relative justify-center rounded-lg flex items-center h-auto grow py-2"
                                >
                                    <div className={
                                        classNames(
                                            "text-sm text-left text-ellipsis",
                                            isSelectedStop ? "text-primary font-bold" : undefined
                                        )
                                    }>
                                        <div className="flex items-center mr-2">
                                            {!!StopIcon && (
                                                <StopIcon
                                                    className={classNames(
                                                    "w-6 h-6 p-1 rounded-md  mr-2",
                                                        stopCombined?.metadata?.IsStopCompleted ? "bg-green-600 text-white" : "text-white bg-red-600"
                                                    )}
                                                />
                                            )}

                                            {Number(stopCombined.StopType) === STOP_TYPE_PICKUP && (
                                                <ArrowSmallUpIcon
                                                    className={classNames(
                                                        "w-6 h-6 p-0.5 rounded-md  mr-2",
                                                        stopCombined?.metadata?.IsStopCompleted ? "bg-green-600 text-white" : "bg-tm-gray-200 text-tm-gray-700"
                                                    )}/>
                                            )}

                                            {Number(stopCombined.StopType) === STOP_TYPE_DELIVERY && (
                                                <ArrowSmallDownIcon
                                                    className={classNames(
                                                        "w-6 h-6 p-0.5 rounded-md mr-2",
                                                        stopCombined?.metadata?.IsStopCompleted ? "bg-green-600 text-white" : "bg-tm-gray-200 text-tm-gray-700"
                                                    )}/>
                                            )}

                                            {Number(stopCombined.StopType) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                                                <ArrowsUpDownIcon
                                                    className={classNames(
                                                        "w-6 h-6 p-0.5 rounded-md mr-2",
                                                        stopCombined?.metadata?.IsStopCompleted ? "bg-green-600 text-white" : "bg-tm-gray-200 text-tm-gray-700"
                                                    )}/>
                                            )}

                                        </div>
                                    </div>

                                    <div
                                        className="leading-4 text-xs text-left w-full text-tm-gray-900"
                                    >
                                        {getHighlightedFullAddressName(stopCombined.metadata)}
                                    </div>
                                </div>

                                {(!!startDateTime || !!startEndDateTime) && (
                                    <div className="flex flex-col justify-center">
                                        <div className="py-1 text-center flex text-right justify-end">
                                            From:
                                            <div className="max-w-[8rem] w-[8rem] justify-end text-left pl-4">
                                                {startDateTime ?? "Not set"}
                                            </div>
                                        </div>

                                        <div className="py-1 text-center flex text-right justify-end">
                                            To:
                                            <div className="max-w-[8rem] w-[8rem] justify-end text-left pl-4">
                                                {startEndDateTime ?? "Not set"}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

function getStartEndDateTime(date, time) {
    if (!!date && !!time) {
        return moment(date + " " + time, DEFAULT_DATABASE_DATETIME_FORMAT).format(getUserDateTimeFormat());
    }

    if (!!date && !time) {
        return moment(date, "YYYY-MM-DD").format(getUserDateTimeFormat().split(" ")[0]);
    }

    if (!date && !!time) {
        return moment(time, "YYYY-MM-DD").format(getUserDateTimeFormat().split(" ")[1]);
    }

    return undefined;
}
