import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, resetResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    focusOnAddedPhone,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getPreFillFromQuery,
    getProp,
    getVacationTemplateLabel
} from '../../../common/util/util-helpers'
import {showGlobalModal} from '../../../data/actions/ui'
import EmployeeInfoTab from './employeeInfoTab'
import EmployeeVacationsTab from './employeeVacationTab'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {
    CREATE_PERM,
    DEFAULT_CONTACT_INFO_FIELDS,
    DEFAULT_PHONES_FIELDS,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import EmployeePayrollTab from "./employeePayrollTab";
import EmployeeSettlementTab from "./employeeSettlementTab";
import {getContactResource} from "../../../data/actions/contactResource";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import EmploymentTab from "../../../common/components/tabs/employment-tab";
import {getPhoneFieldsKeyValues} from "../../../common/components/misc/phone-fields";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import NavResponsive from "../../../common/components/nav-responsive";
import Card from "../../../common/components/card";
import {DEFAULT_COMPANY_ROLE_FIELDS} from "../../../common/components/contact/contact-fields/company-role-fields";
import {
    DEFAULT_EMERGENCY_CONTACT_FIELDS
} from "../../../common/components/contact/contact-fields/emergency-contact-fields";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import Modal from "../../../common/components/modal";

class EmployeeSingleView extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'EmployeesInfo',
                resource: Resources.EmployeesInfo,
                current: true,
                visible: checkPerm(Resources.EmployeesInfo, READ_PERM)
            },
            {
                name: 'EmployeePayroll',
                resource: Resources.EmployeesPayroll,
                current: false,
                visible: (checkPerm(Resources.EmployeesPayroll, READ_PERM) && !!this.getID())
            },
            {
                name: 'EmployeesEmployments',
                resource: Resources.EmployeesEmployments,
                current: false,
                visible: (checkPerm(Resources.EmployeesEmployments, READ_PERM) && !!this.getID())
            },
            {
                name: 'EmployeeSettlements',
                resource: Resources.EmployeesSettlements,
                current: false,
                visible: (checkPerm(Resources.EmployeesSettlements, READ_PERM) && !!this.getID())
            },
            {
                name: 'EmployeesVacation',
                resource: Resources.EmployeesVacation,
                current: false,
                visible: checkPerm(Resources.EmployeesDocuments, READ_PERM)
            },
            {
                name: 'EmployeesDocuments',
                resource: Resources.EmployeesDocuments,
                current: false,
                visible: (checkPerm(Resources.EmployeesDocuments, READ_PERM) && !!this.getID()) || (checkPerm(Resources.EmployeesDocuments, CREATE_PERM))
            },
        ]

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.EmployeesInfo,
            canSwapTabs: true,
            employeeName: '',

            // Fields
            fields: this.getFields(),
            phonesFields: this.getPhones(),
            fields_documents: [],
            files: [],
            linkedContactData: {},
            contactInfoData: getProp(this.props.contactResource, 'data.list', []),

            footerIsSticky: true,

            createNewData: {
                [Resources.EmployeesInfo]: {},
                [Resources.ContactImage]: null,
                [Resources.EmployeesVacation]: []
            },

            //IMAGE CROP
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            isCropVisible: false,

            createFromExistingContact: false,
            isResourceTaskModalVisible: false
        }

        this.fileImageObject = null;
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        !this.isCreate() && this.fetchData()
        !this.isCreate() && this.fetchTaskResourceCount()
        this.fetchContacts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        if (this.props.contactResource !== prevProps.contactResource) {
            this.setState({contactInfoData: getProp(this.props.contactResource, 'data.list', [])})
        }
        const name = '- ' + getProp(this.props, 'resource.data.FirstName', '') + ' ' + getProp(this.props, 'resource.data.LastName', '')
        if (name !== '-  ' && this.state.employeeName !== name) {
            this.setState({
                employeeName: '- ' +
                    getProp(this.props, 'resource.data.FirstName', '') +
                    ' ' +
                    getProp(this.props, 'resource.data.LastName', '')
            })
        }
        if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === Resources.EmployeesInfo && !this.isCreate()) this.fetchData()
        if ((prevProps.resource != this.props.resource) && this.props.match.params.id) {
            this.setState({
                phonesFields: this.getPhones(),
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
            if (this.state.selectedTab === Resources.EmployeesInfo) {
                this.setState({
                    EmployeeName: getProp(this.props.resource, 'data.FirstName', '') + ' ' + getProp(this.props.resource, 'data.LastName', ''),
                    ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
                })
            }
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.EmployeesInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchContacts = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactsQuick,
            query: {}
        }))
    }

    onSubmit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            phonesFields: this.state.phonesFields.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && this.state.phonesFields.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)))) {
                const phones = getPhoneFieldsKeyValues(this.state.phonesFields)
                const fields = this.formatMailingAddressValues(this.state.fields)
                const params = Object.assign({},
                    fields,
                    {
                        Phones: phones,
                        Vacations: this.state.createNewData[Resources.EmployeesVacation],
                        Active: true
                    }
                )

                if (this.getID()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, params, {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID()
                        },
                        errorMessage: true,
                        successMessage: `Employee ${this.state.fields.FirstName.value} ${this.state.fields.LastName.value} updated`,
                        resource: Resources.EmployeesInfo,
                        piggyResource: Resources.EmployeesInfo,
                        fileParams: {
                            id: getProp(this.props.resource, 'data.ContactID', 0)
                        },
                        file: [this.fileImageObject],
                        fileResource: Resources.ContactImage
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.Employees,
                        showRedirectDialog: true,
                        errorMessage: true,
                        file: [this.fileImageObject],
                        document: this.state.fields_documents,
                        documentResource: Resources.EmployeesDocuments,
                        fileResource: Resources.ContactImage,
                    }))
                }
                this.setState({canSubmit: false})
            } else {
                if (this.state.selectedTab !== Resources.EmployeesInfo) this.handleTabChange(Resources.EmployeesInfo)
            }
        })
    }

    /** UI events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!!this.getID()) {
            if (!!this.state.canSubmit) {
                this.setState({
                    confirmModal: true,
                    reserveTab: resource
                })
            } else {
                this.tabChange(resource)
            }
            return false
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleToggleRedirectDialog = () => {
        // and reset state
        this.fileImageObject = null // reset image

        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.EmployeeID,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,

            // Reset state
            fields: this.getFields(),
            phonesFields: this.getPhones(),
            src: null,
            croppedImageUrl: null,
            files_info: [],
            canSubmit: false
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    handleSetLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields)

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        });
    }

    handleSetMailingLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields);

        updatedFields.MailingAddressName.value = fields.AddressName;
        updatedFields.MailingAddressName2.value = fields.AddressName2;
        updatedFields.MailingCityName.value = fields.CityName;
        updatedFields.MailingStateID.value = fields.StateID;
        updatedFields.MailingPostalCode.value = fields.PostalCode;
        //updatedFields.MailingListAddressAutocomplete.value = fields.query

        this.setState({
            fields_info: updatedFields,
            canSubmit: true
        })
    }

    canSwapTabsHandler = (canSwapTabs = false) => {
        this.setState({canSwapTabs: canSwapTabs, canSubmit: !canSwapTabs})
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            isResourceTaskModalVisible: !this.state.isResourceTaskModalVisible
        })
    }

    handleCreateNewVacation = (key, item) => {
        const createNewData = this.state.createNewData
        createNewData[Resources.EmployeesVacation].push(item)
        this.setState({
            createNewData: createNewData
        })
    }

    handleRemoveVacation = (item) => {
        const createNewData = this.state.createNewData
        createNewData[Resources.EmployeesVacation] = createNewData[Resources.EmployeesVacation].filter((it, index) => index !== item.id)
        this.setState({
            createNewData: createNewData
        }, () => console.log(this.state.createNewData))
    }

    handleEditVacation = (item, id) => {
        const createNewData = this.state.createNewData
        createNewData[Resources.EmployeesVacation][id] = item
        this.setState({
            createNewData: createNewData
        })
    }

    handleAddPhoneField = () => {
        const newPhones = this.state.phonesFields

        newPhones.push(DEFAULT_PHONES_FIELDS())

        this.setState({
            phonesFields: newPhones
        }, () => focusOnAddedPhone())
    }

    handleRemovePhoneField = (i) => {
        let newPhones = this.state.phonesFields
        newPhones.splice(i, 1)
        this.setState({
            phonesFields: Array.from(newPhones),
            canSubmit: true
        })
    }

    handleItemInputChange = (i, name, value) => {
        let fields = this.state.phonesFields[i]
        const newItems = this.state.phonesFields
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({canSubmit: true, phonesFields: newItems})
    }

    handleInputChange = (name, value) => {
        let canSubmit = true
        let fieldsInfo = Object.assign({}, this.state.fields)
        let disableStateID = this.state.disableStateID
        let phonesFields = this.state.phonesFields
        let defaultCountryCode = this.state.defaultCountryCode

        if (name === 'Email' && this.state.emailExist) {
            this.setState({emailExist: false})
        } else if (name === 'Organization') {
            this.getOrganizationOptions('', value)
            fieldsInfo[name].value = value
        }

        if (name === 'OfficeID' && this.state.officesCache && this.state.officesCache[value.value] && phonesFields.length > 0) {
            defaultCountryCode = this.state.officesCache[value.value]
            phonesFields[0] = FieldsManager.updateField(phonesFields[0], 'AreaCode', defaultCountryCode)
        }

        if (name === 'Email') {
            let data = this.state.contactInfoData;
            let emailExist = data.filter((it) => it.Email === value)
            if (emailExist.length != 0 && getProp(this.props.resource, 'data.Email', null) != value ){
                fieldsInfo[name].errorMessage = [this.props.translate("text.contact_email_in_system")]
            } else
            {
                fieldsInfo[name].errorMessage = null
            }
        }
        if (name === 'AddressName' && !!this.state.sameAsFirstAddress) {
            this.setState({fields: FieldsManager.updateField(fieldsInfo, 'SecondAddress', value)})
        }
        if (name === 'CountryID' && value == 1) {
            disableStateID = true
        } else if (name === 'CountryID' && value != 1) {
            disableStateID = false
            fieldsInfo = FieldsManager.updateField(fieldsInfo, 'StateID', '')
        }
        if (name === 'ContactID') {
            this.setState({linkedContactData:value.metadata})
        }

        if (fieldsInfo['Email'].errorMessage){
            canSubmit = false
        } else
        {
            canSubmit = true
        }

        this.setState({
            phonesFields,
            defaultCountryCode,
            canSubmit: canSubmit,
            fields: FieldsManager.updateField(fieldsInfo, name, value),
            disableStateID: disableStateID
        })
    }

    handleToggleCreateFromExistingContact = () => {
        let fields = this.state.fields
        if (!this.state.createFromExistingContact) {
            fields.FirstName.validate = []
            fields.LastName.validate = []
            fields.Email.validate = []
            fields.ValidationType.validate = []
            fields.Roles.validate = []
            fields.ContactID.validate = ['empty']
            this.setState({createFromExistingContact: !this.state.createFromExistingContact, fields: fields})
        } else {
            fields.FirstName.validate = ['empty']
            fields.LastName.validate = ['empty']
            fields.Email.validate = ['empty']
            fields.ValidationType.validate = ['empty']
            fields.Roles.validate = ['empty']
            fields.ContactID.validate = []
            fields.ContactID.value = '-1'
            this.setState({createFromExistingContact: !this.state.createFromExistingContact, fields: fields})
        }
    }

    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(getProp(this.props.resource, 'data', {})),
            phonesFields: this.getPhones(),
            canSubmit: false
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getPhones = () => {
        const phones = getProp(this.props.resource, 'data.ContactPhone', [])
        if (this.getID()) {
            return phones?.map(item => {
                return this.getDefaultPhoneFields(item)
            })
        } else {
            return [this.getDefaultPhoneFields()]
        }
    }

    getDefaultPhoneFields = (item = {}) => {
        return fillFieldsFromData(DEFAULT_PHONES_FIELDS(), item)
    }

    onBlurFetchContacts = () => {
        return new Promise((resolve) => {
            this.fetchContacts();
            setTimeout(() => {
                resolve();
            }, 200);
        });
    }

    getFields = (item = {}) => {
        const name = getPreFillFromQuery()

        let fieldTemplates = Object.assign({},
            DEFAULT_CONTACT_INFO_FIELDS(),
            DEFAULT_EMERGENCY_CONTACT_FIELDS(),
            DEFAULT_COMPANY_ROLE_FIELDS(),
            {
                ContactID: new Field('ContactID', -1, [''], false, 'select-search'),

                // EMPLOYEE FIELDS
                CountryID: new Field('CountryID', 1, [''], false, 'select'),
                AddressName: new Field('AddressName', '', ['']),
                AddressName2: new Field('AddressName2', '', ['']),
                CityName: new Field('CityName', '', ['']),
                StateID: new Field('StateID', '', [''], false, 'select'),
                SocialSecurityNumber: new Field('SocialSecurityNumber', '', ['']),
                OfficeID: new Field('OfficeID', !this.getID() ? getDefaultUserOfficeValues() : '', [''], false, 'select-search'),
                EmployeeIDNumber: new Field('EmployeeIDNumber', '', ['']),
                PostalCode: new Field('PostalCode', '', ['']),

                // Mailing Address Info
                MailingSameAsFirstAddress: new Field('MailingSameAsFirstAddress', 1, [], false, 'checkbox', {
                    addContainerClass: "col-span-3"
                }),
                MailingListAddressAutocomplete: new Field('MailingListAddressAutocomplete', '', [], false, 'google-locations', {
                    setLocations: this.handleSetMailingLocations,
                    addContainerClass: "col-span-full"
                }),
                MailingAddressName: new Field('MailingAddressName', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingAddressName2: new Field('MailingAddressName2', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingCityName: new Field('MailingCityName', '', ['']),
                MailingStateID: new Field('MailingStateID', '', [''], false, 'select'),
                MailingPostalCode: new Field('MailingPostalCode', '', ['']),

                UserDefinedField1: new Field('UserDefinedField1', '', ['']),
                UserDefinedField2: new Field('UserDefinedField2', '', ['']),
                VacationTemplateID: new Field('VacationTemplateID', '', [''], false, 'select-search'),
            })

        fieldTemplates['FirstName'].value = name ?? ''
        fieldTemplates['IsSystemUser'].type = 'hidden'
        fieldTemplates['Roles'].validate = ['']
        fieldTemplates['Roles'].type = 'hidden'
        fieldTemplates['ValidationType'].validate = ['']
        fieldTemplates['ValidationType'].type = 'hidden'
        fieldTemplates['Title'].metadata.label = 'JobTitle'

        fieldTemplates.Email =  new Field('Email', '', ['empty', 'email'], false, 'text', {
            addContainerClass: "md:col-span-full",
            label: "BusinessEmail",
            htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                <CopyToClipboardButton
                    clipboardText={it.value}
                    addClass="relative -top-2"
                    translate={this.props.translate}
                />
            </div>
        },{
            onBlur: (e) => {
                e.persist();
                if (e.target.value) {
                    if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(e.target.value)) {
                        this.onBlurFetchContacts().then(() => {
                            this.handleInputChange('Email', e.target?.value);
                        });
                    }
                }
            }
        })

        fieldTemplates.PrivateEmail = new Field('PrivateEmail', '', [], false, "email", {addContainerClass: "md:col-span-6",
            htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                <CopyToClipboardButton
                    clipboardText={it.value}
                    addClass="relative -top-2"
                    translate={this.props.translate}
                />
            </div>
        })

        if (Object.keys(item).length > 0) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, item)
            fieldTemplates['Groups'].value = getProp(item, 'Groups', []).map(it => ({
                value: it.ContactGroupID,
                label: it.ContactGroupName
            }))

            if (item.VacationTemplateID) {
                fieldTemplates['VacationTemplateID'].value = {
                    value: item.VacationTemplateID,
                    label: getVacationTemplateLabel({
                        Months: item.VacationTemplate.split(' ')[1],
                        Days: item.VacationTemplate.split(' ')[0],
                    })
                }
            }
        }


        return fieldTemplates
    }


    /** Helpers
     ================================================================= */
    isCreate = () => {
        return !this.getID()
    }

    formatMailingAddressValues = (fields) => {
        if (!!fields.MailingSameAsFirstAddress.value) {
            fields.MailingAddressName.value = fields.AddressName.value
            fields.MailingAddressName2.value = fields.AddressName2.value
            fields.MailingStateID.value = fields.StateID.value
            fields.MailingPostalCode.value = fields.PostalCode.value
            fields.MailingCityName.value = fields.CityName.value
        }

        return FieldsManager.getFieldKeyValues(fields)
    }

    getID = () => {
        return this.props.match.params.id
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        return (
            <Layout
                {...this.props}
                hasFooter={(this.state.selectedTab === Resources.EmployeesInfo || this.state.selectedTab === Resources.EmployeesSettlements)}
                isDirty={(this.getID() && this.state.canSubmit)}
                customPageTitle={this.getID() ? this.state.EmployeeName : 'Employee'}
            >
                <Page>
                                <div className="mb-2 flex justify-between items-center">
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center justify-center">
                                            <GoBackButton
                                                canSubmit={!this.state.canSwapTabs}
                                                translate={this.props.translate}
                                                history={this.props.history}
                                                path={'/employees-page'}
                                            />
                                            <h1 className={'mr-5 text-3xl ml-2'}>
                                                {translate('text.employee')}
                                                <span
                                                    className="text-tm-gray-500"> - {this.props.match.params.id ? this.state.EmployeeName : 'Create new'}
                                                    {this.state.ArchivedDate && (
                                                        <Tippy content={<span>Archived</span>}>
                                                            <i className="iconsminds-delete-file"/>
                                                        </Tippy>
                                                    )}
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                    <div>
                                        {checkPerm(Resources.Tasks, CREATE_PERM) && !this.isCreate() && (
                                            <div className="btn btn-outline mr-3"
                                                 onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_employee_task')}
                                            </div>
                                        )}
                                        {checkPerm(Resources.Tasks, READ_PERM) && !this.isCreate() && (
                                            <div className="btn btn-outline"
                                                 onClick={() => this.handleToggleResourceTasks()}>{translate('btn.show_tasks')}
                                                {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                                {!this.props.taskResource.isCountLoading && `(${TaskCount})`}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>


                                            {this.state.selectedTab === Resources.EmployeesInfo && (
                                                <EmployeeInfoTab
                                                    formatMailingAddressValues={this.formatMailingAddressValues}
                                                    canSwapTabsHandler={this.canSwapTabsHandler}
                                                    setLocations={this.handleSetLocations}
                                                    isCreate={this.isCreate()}
                                                    linkedContactInfo={this.state.linkedContactData}
                                                    canSubmit={this.state.canSubmit}
                                                    fields={this.state.fields}
                                                    phonesFields={this.state.phonesFields}
                                                    handleInputChange={this.handleInputChange}
                                                    handleItemInputChange={this.handleItemInputChange}
                                                    removePhoneField={this.handleRemovePhoneField}
                                                    addPhoneField={this.handleAddPhoneField}
                                                    setActiveTab={this.handleTabChange}
                                                    cancel={this.handleFormCancel}
                                                    onSubmit={this.onSubmit}
                                                    createFromExistingContact={this.state.createFromExistingContact}
                                                    handleToggleCreateFromExistingContact={this.handleToggleCreateFromExistingContact}
                                                    ContactID={getProp(this.props.resource, 'data.ContactID', null)}
                                                    files={this.state.files}
                                                    setImage={(file) => {
                                                        this.fileImageObject = file
                                                        this.setState({canSubmit: true})
                                                    }}
                                                    mainID={this.props.match.params.id}
                                                    fileImageObject={this.fileImageObject}
                                                    {...this.props}/>
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesPayroll && (
                                                <EmployeeSettlementTab
                                                    dispatch={this.props.dispatch}
                                                    EmployeeID={this.props.match.params.id}
                                                    match={this.props.match}
                                                    resource={this.props.resource}
                                                    translate={translate}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesEmployments && (
                                                <EmploymentTab
                                                    resourceName={Resources.EmployeesEmployments}
                                                    id={this.getID()}
                                                    primaryKey={'EmployeeEmploymentID'}
                                                    translate={translate}
                                                    dispatch={this.props.dispatch}
                                                    resource={this.props.resource}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesSettlements && (
                                                <EmployeePayrollTab
                                                    translate={translate}
                                                    dispatch={this.props.dispatch}
                                                    id={this.getID()}
                                                    resource={this.props.resource}
                                                    secondResource={this.props.secondResource}
                                                    thirdResource={this.props.thirdResource}
                                                    setIsStateDirty={(isDirty) => {
                                                        this.setState({canSubmit: isDirty})
                                                    }}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesVacation && (
                                                <EmployeeVacationsTab
                                                    canSwapTabsHandler={this.canSwapTabsHandler}
                                                    isCreate={this.isCreate()}
                                                    onCreateNewData={this.handleCreateNewVacation}
                                                    onRemoveVacation={this.handleRemoveVacation}
                                                    onEditVacation={this.handleEditVacation}
                                                    id={this.getID()}
                                                    resource={this.isCreate() ? {
                                                        data: {
                                                            list: this.state.createNewData[Resources.EmployeesVacation]
                                                        }
                                                    } : this.props.resource}
                                                    translate={translate}
                                                    dispatch={this.props.dispatch}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesDocuments && this.props.match.params.id && (
                                                <DocumentsUpdateTab
                                                    id={this.props.match.params.id}
                                                    dispatch={this.props.dispatch}
                                                    translate={this.props.translate}
                                                    resource={this.props.resource}
                                                    thirdResource={this.props.thirdResource}

                                                    dialogTitle={'Employee Documents'}

                                                    sortBy={'Description'}
                                                    primaryKey={'EmployeeDocumentID'}

                                                    documentUpdateFields={checkPerm(Resources.EmployeesDocuments, UPDATE_PERM) && {
                                                        DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                                            values: getDocumentTypesFor("IsPersonnelDocument")
                                                        }),
                                                        ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'},{isClearable: true}),
                                                        Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                                    }}

                                                    download={this.props.download}
                                                    resourceName={Resources.EmployeesDocuments}

                                                    documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                                                    searchFields={{
                                                        query: new Field('query', '', [], false, 'text', {
                                                            containerClass: 'col-md-4'
                                                        }),
                                                        DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                                            containerClass: 'col-md-4'
                                                        }, {
                                                            all: true,
                                                            values: getDocumentTypesFor("IsPersonnelDocument")
                                                        }),
                                                        DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                                            containerClass: 'col-md-4'
                                                        }, {
                                                            all: true
                                                        }),
                                                    }}
                                                    readOnly={!checkPerm(Resources.EmployeesDocuments, CREATE_PERM)}
                                                    disableDelete={!checkPerm(Resources.EmployeesDocuments, DELETE_PERM)}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.EmployeesDocuments && !this.props.match.params.id && (
                                                <Card>
                                                    <DocumentsCreateTab
                                                        fields={this.state.fields_documents}
                                                        translate={this.props.translate}

                                                        dialogTitle={'Employee Documents'}

                                                        documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                                                        handleUploadDocument={this.handleUploadDocument}
                                                        handleDeleteDocument={this.handleDeleteDocument}
                                                    />
                                                </Card>
                                            )}

                    <RedirectDialog
                        show={this.state.isRedirectDialogVisible}
                        title={translate('card_header.employee_created')}
                        onClose={() => {
                            this.props.dispatch(resetResource())
                            this.setState({
                                createdResourceID: null,
                                isRedirectDialogVisible: false
                            })
                        }}

                        createdLabel={translate('btn.employee')}
                        createdLink={`/employees/info/${this.state.createdResourceID}`}

                        newLabel={translate('btn.create_new')}

                        listLabel={translate('btn.list')}
                        listLink="/employees-page"

                        dashboardLabel={translate('btn.dashboard')}

                        {...this.props}
                    >
                        <p>{translate('message.employee_created')}</p>
                    </RedirectDialog>

                    {!this.props.match.params.id && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={checkPerm(Resources.Employees, CREATE_PERM) && this.onSubmit}
                        />
                    )}
                </Page>

                <ModalConfirm
                    type="default"
                    title={this.props.translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate('btn.proceed')}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        canSubmit: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <Modal
                    show={!!this.state.isResourceTaskModalVisible}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.employee_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </Modal>
            </Layout>
        )
    }
}

export default connect(state => state)(EmployeeSingleView)
