import {getDialogResource, updateDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoaderLarge} from "../../../../common/components/loader";
import {checkPerm, resourceIsUpdated} from "../../../../common/util/util-helpers";
import {CREATE_PERM} from "../../../../common/util/util-consts";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {Field} from "../../../../data/services/fields";
import Button from "../../../../common/components/button";
import {TrashIcon} from "@heroicons/react/24/outline";
import {CloudArrowDownIcon} from "@heroicons/react/20/solid";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import packageJson from '../../../../../package.json';

export default function UserSettingsProfiles({onClose, onReLogin, translate}) {
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const dialogResource = useSelector((state) => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const profiles = dialogResource?.data?.list?.[getSystemProfileKey()];

    const numberOfProfiles = Object.keys(profiles ?? {})?.length ?? 0;

    const [isNewProfileFormOpen, setIsNewProfileFormOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isConfirmApplyModalOpen, setIsConfirmApplyModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const getFields = () => {
        return {
            ProfileName: new Field('ProfileName', "", ['empty'], false, 'text'),
            Comment: new Field('Comment', "", [''], false, 'textarea')
        }
    }

    function handleDeleteProfile() {
        if (!profiles[selectedItem]) {
            return null;
        }

        delete profiles[selectedItem];


        dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {
                ProfileMapKey: 'ProfileMapMain',
                ProfileMapValue: JSON.stringify(profiles)
            },
            resource: Resources.UserTableProfiles,
            piggyResource: Resources.UserTableProfiles,
            errorMessage: true, successMessage: `Table profile saved`
        }));
        setSelectedItem("");
    }

    function handleApplyProfile() {
        if (profiles[selectedItem].metadata) {
            delete profiles[selectedItem].metadata;
        }
        setIsConfirmApplyModalOpen(false);
        setSelectedItem("");

        Object.keys(profiles[selectedItem]).forEach(key => {
            LocalStorage.set(key, profiles[selectedItem][key]);
        })
        onReLogin();
        onClose();
    }

    function fetchData() {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.UserTableProfiles,
        }));
    }

    function handleDeleteProfileClick(key) {
        setIsConfirmDeleteModalOpen(true);
        setSelectedItem(key);
    }

    function handleApplyProfileClick(key) {
        setIsConfirmApplyModalOpen(true);
        setSelectedItem(key);
    }

    function getSystemProfileKey() {
        switch (packageJson?.name ?? "") {
            case 'truck-wo-frontend':
                return 'ProfileMapWO'
            case 'truck-rental-frontend':
                return 'ProfileMapRental'
            case 'truck-tracking-frontend':
                return 'ProfileMapTracking'
            default:
                return "ProfileMapMain"
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (resourceIsUpdated(dialogResource)) {
            setIsNewProfileFormOpen(false);
        }
    }, [dialogResource]);

    return <div className="p-6">
        {isLoading && (
            <LoaderLarge/>
        )}

        <NoRecords
            show={(profiles === null || Object.keys(profiles ?? {})?.length === 0) && !isLoading}
            canCreate={checkPerm(Resources.UserTableProfiles, CREATE_PERM)}
            title={translate('text.no_profiles_found')}
            btnLabel={translate('btn.create_new_profile')}
            onBtnClick={() => setIsNewProfileFormOpen(true)}
        />

        {!!profiles && (
            <>
                <div className="space-y-4">
                    {Object.keys(profiles).map(key => {
                        const comment = (profiles?.[key] ?? {})?.metadata?.Comment;

                        return <div
                            className="pr-4 col-span-full flex justify-between items-center rounded-xl bg-inverse shadow border border-tm-gray-300"
                            key={key}
                        >
                            <div className="font-medium text-tm-gray-900 select-none py-3 px-4">
                                {key}

                                {comment && (
                                    <div className="text-tm-gray-700">
                                        {comment}
                                    </div>
                                )}
                            </div>

                            <div>
                                <Button
                                    onClick={() => handleApplyProfileClick(key)}
                                    className="p-2 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint"
                                >
                                    <CloudArrowDownIcon className="w-5 h-5 text-primary"/>
                                </Button>

                                <Button
                                    onClick={() => handleDeleteProfileClick(key)}
                                    className="p-2 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint"
                                >
                                    <TrashIcon className="w-5 h-5 text-tm-gray-700"/>
                                </Button>
                            </div>
                        </div>
                    })}
                </div>

                {!!numberOfProfiles && numberOfProfiles < 3 && (
                    <div className="text-right mt-4">
                        <Button
                            appearance="primary"
                            onClick={() => setIsNewProfileFormOpen(true)}
                        >
                            {translate("btn.create_new")}
                        </Button>
                    </div>
                )}

                {numberOfProfiles > 2 && (
                    <div className="mt-4">
                        <InfoBar>
                            <div>
                                <p className="font-bold">Maximum number of profiles reached.</p>
                                <p className="text-tm-gray-700">You must delete the existing one if you wish to create a
                                    new one.</p>
                            </div>
                        </InfoBar>
                    </div>
                )}
            </>
        )}

        <ModalSaveResource
            show={isNewProfileFormOpen}
            widthClass="max-w-lg"
            gridColsClass="grid-cols-1"
            title={translate('modal_heading.create_new_profile')}
            onClose={() => setIsNewProfileFormOpen(false)}
            closeButtonLabel={translate('btn.close')}
            buttonDisabled={isLoading}
            initialFocusRef={inputRef}
            translate={translate}
            fields={getFields()}
            onSubmit={(params) => {
                if (params) {
                    let relevantStorageItems = {};

                    const excludedKeys = ['iconify-version', 'iconify-count', 'user', 'lookup', 'notifications']

                    for (let i = 0; i < localStorage.length; i++) {
                        let key = localStorage.key(i);
                        if (!excludedKeys.includes(key)) {
                            relevantStorageItems[key] = LocalStorage.get(key);
                        }
                    }

                    if (params.Comment) {
                        relevantStorageItems = Object.assign(relevantStorageItems, {metadata: {Comment: params.Comment}})
                    }

                    console.log('relevantStorageItems', relevantStorageItems)

                    dispatch(updateDialogResource({
                        user: LocalStorage.get('user'),
                        params: {
                            ProfileMapKey: getSystemProfileKey(),
                            ProfileMapValue: JSON.stringify(Object.assign(
                                {},
                                profiles, // Existing profiles
                                {[params.ProfileName]: relevantStorageItems})
                            )
                        },
                        resource: Resources.UserTableProfiles,
                        piggyResource: Resources.UserTableProfiles,
                        errorMessage: true, successMessage: `Table profile saved`
                    }));
                }
            }}
            htmlAfter={
                isLoading && <LoaderLarge/>
            }
        />

        <ModalConfirm
            title={"Delete profile"}
            show={isConfirmDeleteModalOpen}
            onClose={() => {
                setIsConfirmDeleteModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Delete'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmDeleteModalOpen(false);
                handleDeleteProfile();
            }}
        >
            Are you sure you want to delete profile named `{selectedItem}`?
        </ModalConfirm>

        <ModalConfirm
            type="warning"
            title={"Apply profile"}
            show={isConfirmApplyModalOpen}
            onClose={() => {
                setIsConfirmApplyModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Apply profile'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmDeleteModalOpen(false);
                handleApplyProfile();
            }}
        >
            <p>Are you sure you want to apply profile named <span className="font-bold">`{selectedItem}`</span>?</p>
            <p>This will overwrite your current settings!</p>
            <p className="mt-4">Existing table settings that are not loaded in this profile will be unaffected.</p>
        </ModalConfirm>
    </div>
}