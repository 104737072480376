import React, {Component} from 'react'
import moment from 'moment-timezone'
import LocalStorage from '../../../../util/localStorage'
import BellIcon from '@heroicons/react/24/outline/BellIcon'
import {classNames, getProp} from '../../../../common/util/util-helpers'
import {Field, FieldsManager} from "../../../../data/services/fields";
import {LoadExternalSendCustomerMessages, sendCustomerMessage} from "../../../../package/realtime/actions/realtime";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import FieldTextarea from "../../../../common/components/fields/field-textarea";
import ImageAvatarLoader from "../../../../common/components/image/image-avatar-loader";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
export default class LoadInternalChatTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                Message: new Field('Message', '', ['']),
            },
            notifyMe: false,
            dirty: false,
            messageUpdateKey: 0
        }

        this.myID = LocalStorage.get('user')?.ContactID;
        this.scrollRef = React.createRef();
        this.chatBoxRef = React.createRef();
        this.isExternalUser = !!this.props.token;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.chatBoxRef.current) {
                this.chatBoxRef.current.focus();
            }
        }, 200);

        this.scrollToEnd();
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.loadPresence && this.props.loadPresence !== prevProps.loadPresence) {
            this.scrollToEnd();
        }
    }

    /** Data Events
     ================================================================= */
    onMessageSend = () => {
        if (this.isExternalUser) {
            this.props.dispatch(LoadExternalSendCustomerMessages({
                LoadID: this.props.loadID,
                ExternalAccessToken: this.props.token,
                Message: this.state.fields.Message.value,
                Name: this.props.customerChatName
            }));
        } else {
            this.props.dispatch(sendCustomerMessage({
                LoadID: this.props.loadID,
                Message: this.state.fields.Message.value
            }));
        }

        this.handleInputChange('Message', '');
    }

    onToggleNotify = () => {
        this.setState({notifyMe: !this.state.notifyMe})
    }


    /** UI Events
     ================================================================= */

    handleInputChange = (name, value) => {
        let dirty = true
        if ('Message' === name && !value) {
            dirty = false
        }

        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value), dirty,
            chatBoxHeight: value === '' ? 48 : this.chatBoxRef.current.scrollHeight
        })
    }

    /** Helpers
     ================================================================= */

    formatChatTime = (messageDate) => {
        const dateFormat = 'HH:mm'

        return moment(messageDate, 'YYYY-MM-DD HH:mm:ss').format(dateFormat)
    }

    scrollToEnd = () => {
        const scrollEl = this.scrollRef.current
        if (scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    /** Render
     ================================================================= */

    render() {
        const {isExternalPage} = this.props;
        let prevMessageTime = ''
        let prevMessageFromID = ''
        let isSameDay = false

        const CustomerMessages = getProp(this.props.loadPresence, 'messages.CustomerMessages', []);

        const messages = CustomerMessages.map(message => {
            const isExternalCustomer = !message.Contact;

            let samePersonMessage = message.ContactID === prevMessageFromID
            prevMessageFromID = message.ContactID

            const myMessage = message.ContactID === this.myID
            let duration = '';
            let areMessagesCloseTime = false
            const messageDate = moment(message.DateTime)

            isSameDay = false
            if (prevMessageTime) {
                if (prevMessageTime.isSame(messageDate, 'day')) {
                    isSameDay = true
                }
            }

            if (prevMessageTime) {
                duration = moment.duration(messageDate.diff(prevMessageTime))
                const diff = duration.asMinutes()
                if (diff < 1) {
                    areMessagesCloseTime = true
                }
                prevMessageTime = moment(message.DateTime)
            } else {
                prevMessageTime = moment(message.DateTime)
            }

            return (
                <div>
                    {!isSameDay && (
                        <div
                            className="text-center mb-2 text-tm-gray-500">{messageDate.isSame(new Date(), 'day') ? 'Today' : moment(messageDate).format('YYYY-MM-DD')}</div>
                    )}

                    {(!areMessagesCloseTime || !samePersonMessage) && (
                        <div className="text-sm text-tm-gray-600 mb-1">
                            {!isExternalCustomer && (
                                <span className="font-bold">{message.Contact}</span>
                            )}

                            {isExternalCustomer && (
                                <span className="font-bold">
                                    {message.Name + (!this.props.isExternalPage ? " - Customer" : "")}
                                </span>
                            )}

                            {" "}

                            <span className="text-xs">{this.formatChatTime(messageDate)}</span></div>
                    )}

                    <div
                        className={classNames(myMessage ? 'chat-message flex' : 'flex items-end justify-end')}>
                        <div className="flex items-end">
                            <div
                                className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                <div><span
                                    className={classNames('px-4 py-2 whitespace-pre-wrap rounded-lg inline-block', myMessage ? 'bg-tm-gray-300 text-tm-gray-900 rounded-bl-none' : 'bg-primary text-white rounded-br-none')}>
                                    {isExternalCustomer && !isExternalPage && (
                                        <ArrowTopRightOnSquareIcon className="flex-shrink-0 w-5 h-5 mr-1 text-primary-contrast float-left relative right-1"/>
                                    )}

                                    {message.Message}
                                </span>
                                </div>
                            </div>
                        </div>

                        {!myMessage && (
                            <ImageAvatarLoader
                                addClass="bg-tm-gray-100 rounded-full"
                                ContactID={message.ContactID}
                                size="sm"
                                hasImagePath={true}
                            />
                        )}
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <div className="flex-1 justify-between flex flex-col max-w-lg">
                    <div
                        className="flex sm:items-center justify-between py-3 border-b-2 border-tm-gray-200">
                        <div className="flex items-center space-x-4 px-6">
                            <div className="flex flex-col leading-tight">
                                {!this.props.customerChatName && (
                                    <React.Fragment>
                                        <div className="text-2xl mt-1 flex items-center">
                                            <span className="text-tm-gray-700 mr-3">Customer chat</span>
                                        </div>
                                        <span className="text-tm-gray-600">{this.props.translate("text.customerChatVisible")}</span>
                                    </React.Fragment>
                                )}

                                {!!this.props.customerChatName && (
                                    <React.Fragment>
                                        <div className="text-2xl mt-1 flex items-center">
                                            <span className="text-tm-gray-700 mr-3">Load chat</span>
                                        </div>
                                        <span className="text-tm-gray-600">Hi {this.props.customerChatName}, thank you for reaching out to us. Our agent will respond as soon as possible. </span>
                                        <button
                                            onClick={this.props.onClearNameClick}
                                            className="btn btn-text px-0 text-xs py-0.5 h-auto"
                                        >
                                            Not {this.props.customerChatName}? Click here
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            {/*<button type="button"*/}
                            {/*        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-tm-gray-500 hover:bg-light focus:outline-none">*/}
                            {/*    <MagnifyingGlassIcon className="w-6 h-6"/>*/}
                            {/*</button>*/}

                            {/*{!this.isExternalUser && (*/}
                            {/*    <button*/}
                            {/*        type="button"*/}
                            {/*        className={classNames(this.state.notifyMe ? 'bg-primary text-white' : 'hover:bg-light text-tm-gray-500', 'inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out focus:outline-none')}*/}
                            {/*        onClick={() => this.onToggleNotify()}*/}
                            {/*    >*/}
                            {/*        <BellIcon className="w-6 h-6"/>*/}
                            {/*    </button>*/}
                            {/*)}*/}
                        </div>
                    </div>

                    <div
                        className={
                            classNames(
                                "flex flex-col",
                                this.props.chatHeightClass ?? "h-[calc(100vh-340px)]"
                            )
                        }
                    >
                        <div
                            id="messages"
                            className="flex flex-col space-y-4 p-3 overflow-y-auto grow"
                            ref={this.scrollRef}
                        >
                            {messages}
                        </div>

                        <div className="border-t-2 border-tm-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                            <div className="relative flex space-x-2">
                                <FieldTextarea
                                    innerRef={this.chatBoxRef}
                                    rows={this.state.chatBoxHeight > 48 ? 3 : 1}
                                    onChange={this.handleInputChange}
                                    placeholder="Write something"
                                    addClass="border w-full focus:ring-2 focus:ring-primary focus:ring-offset-inverse focus:ring-offset-0 focus:border-transparent focus:placeholder-tm-gray-400 text-tm-gray-600 placeholder-tm-gray-400 bg-inverse px-1 border border-tm-gray-200 rounded-card py-0 resize-none whitespace-pre-wrap"
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            this.state.dirty ? this.onMessageSend() : null
                                        }
                                    }}
                                    {...this.state.fields.Message}
                                />

                                <div className="hidden sm:flex">
                                    <button
                                        type="button"
                                        disabled={!this.state.dirty}
                                        className={classNames(!this.state.dirty ? 'bg-tm-gray-200' : 'bg-primary hover:bg-primary-tint', 'focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary inline-flex items-center justify-center rounded-card h-12 w-12 transition duration-500 ease-in-out text-white focus:outline-none')}
                                        onClick={() => this.state.dirty ? this.onMessageSend() : null}
                                    >
                                        <PaperAirplaneIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </React.Fragment>
        )
    }
}

