import React from "react";
import ModalTabbedBody from "./modal-tabbed-body";
import Modal from "../index";

export default function ModalTabbed({
                                        handleInputChangeExternal,
                                        onClose,
                                        onSubmit,
                                        selects,
                                        show,
                                        sidebarWidth,
                                        tabFields,
                                        tabsData,
                                        title,
                                        translate,
                                        validateFields,
                                        validateOnlyCurrentTab,
                                        widthClass
                                    }) {
    return (
        <Modal
            show={show}
            widthClass={widthClass ?? 'max-w-5xl w-screen'}
            onClose={onClose}
            translate={translate}
        >
            <ModalTabbedBody
                title={title}
                onClose={onClose}
                onSubmit={onSubmit}
                selects={selects}
                tabFields={tabFields}
                tabsData={tabsData}
                validateFields={validateFields}
                validateOnlyCurrentTab={validateOnlyCurrentTab}
                handleInputChangeExternal={handleInputChangeExternal}
                sidebarWidth={sidebarWidth}
                translate={translate}
            />
        </Modal>
    )
}
