import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {download} from '../../../../data/actions/download'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    resourceIsCreated,
    resourceIsUpdated,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../../common/util/util-helpers'
import {currentDate, toFrontDate} from '../../../../common/util/util-dates'
import {cloneDeep} from '../../../../common/util/util-vanilla'
import {showGlobalModal} from '../../../../data/actions/ui'
import {ArchiveBoxIcon, ArrowUturnLeftIcon, CreditCardIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import TableOptionsDialog from "../../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../common/components/resource-table";
import TableSettingsPopOver from "../../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import Button from '../../../../common/components/button'

import TableReminder from "../../../../common/components/resource-table/table-components/table-reminder";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import {
    DEFAULT_DATABASE_DATE_FORMAT,
    RECURRING_TYPE,
    RECURRING_TYPE_UNSCHEDULED
} from "../../../../common/util/util-consts";
import {BuildingOfficeIcon} from "@heroicons/react/20/solid";
import UserIcon from "@heroicons/react/20/solid/UserIcon";
import moment from "moment";
import RecurringIncomeModal from "./recurring-income-modal";

export default class IncomeRecurringTab extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_income_recurring'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            createUpdateDialog: false,
            confirmModalOpen: false,
            deleteDialog: false,
            isTableFullScreen: false,

            isRecurringModalOpen: false,

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            selectedItem: undefined,
            isCreatingFromTemplate: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
        this.renderCells = {
            Reminder: (item) => {
                const startDate = item.LastCreatedDate ? item.LastCreatedDate : item.StartDate
                if (item.RecurringType !== RECURRING_TYPE_UNSCHEDULED && startDate && item.Cron) {
                    const nextDate = this.getNextJobDayFromCron(item.Cron, startDate);
                    const today = moment();
                    let reminderType = 'danger';
                    if (moment(nextDate).isAfter(today, 'day')) {
                        reminderType = 'ok';
                    } else if (moment(nextDate).isSame(today, 'day')) {
                        reminderType = 'warning';
                    }

                    return <TableReminder
                        onClick={() => this.handleCreateFromTemplateClick(item)}
                        type={reminderType}
                        content={toFrontDate(nextDate)}
                        item={item}
                    />
                } else {
                    return "Unscheduled";
                }
            },
            RecurringType: (item) => RECURRING_TYPE[item.RecurringType],
            ChargeTo: (item) => item.Organization
                ? <span className="flex items-center gap-2"><BuildingOfficeIcon
                    className="w-5 h-5 text-green-500"/> {item.Organization}</span>
                :
                <span className="flex items-center gap-2"><UserIcon className="w-5 h-5 text-yellow-500"/> {item.Contact}</span>,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        // Close recurring form modal on successful save
        if (resourceIsCreated(this?.props?.resource, prevProps.resource)) {
            this.handleToggleCreateModal();
        }

        if (resourceIsUpdated(this?.props?.resource, prevProps.resource)) {
            this.handleToggleEditModal();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'Recurring_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = ''
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }
    handleCreateUpdateResource = (item = null, prefilled = '') => {
        this.setState({prefilled: prefilled}, () => {
            this.props.dispatch(showGlobalModal('createTaskDialog', item))
        })
    }

    handleToggleEditModal = (selectedItem = null) => {
        this.setState({
            selectedItem: selectedItem,
            isRecurringModalOpen: !this.state.isRecurringModalOpen,
            isCreatingFromTemplate: false
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            isRecurringModalOpen: !this.state.isRecurringModalOpen,
            selectedItem: undefined,
            isCreatingFromTemplate: false
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        const queryFields = Object.assign({}, this.state.queryFilterFields);

        let query = {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(queryFields),

            searchFields: JSON.stringify({
                ContactID: queryFields.ContactID?.value?.value,
                OrganizationID: queryFields.OrganizationID?.value?.value
            })
        }

        delete query.ContactID;
        delete query.OrganizationID;

        return query;
    }

    getFields(item = {}) {
        const fieldTemplates = {
            'TemplateName': new Field('TemplateName', '', [''], false, 'text'),
            'RecurringType': new Field('RecurringType', '', [''], false, 'select'),
            'Reminder': new Field('Reminder', '', [''], false, 'custom'),
            'RefNumber': new Field('RefNumber', '', [''], false, 'text'),
            'ChargeTo': new Field('ChargeTo', '', [''], false, 'custom'),
            'Amount': new Field('Amount', '', [''], false, 'money',),
            'Office': new Field('Office', '', [''], false, 'text'),
            'ContactGroup': new Field('ContactGroup', '', [''], false, 'text'),
            'InternalNotes': new Field('InternalNotes', '', ['empty'], false, 'textarea'),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {}, {isClearable: true}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),
        }
    }

    getResource = () => {
        return Resources.IncomeRecurring
    }

    handleToggleDeleteDialog = (submit, item, restore = false) => {
        this.setState({
            confirmText: !restore ? this.props.translate('message.confirm_delete_generic', [item?.RecurringPaymentsID]) : this.props.translate('message.confirm_restore_generic') + ` Income: ${item?.RecurringPaymentsID}`,
            deleteDialog: submit
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.TemplateName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            RecurringPaymentsID: item.RecurringInvoiceID,
                            id: item.RecurringInvoiceID
                        }),
                        errorMessage: true,
                        successMessage: this.props.translate(`text.recurring`) + ' ' + [item.RecurringInvoiceID],
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_contact')} ${item.RecurringPaymentsID}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.RecurringPaymentsID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Contact ${item.RecurringPaymentsID} restored.`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }


    handleCreateFromTemplateClick = (item) => {
        this.setState({
            selectedItem: item,
            isRecurringModalOpen: !this.state.isRecurringModalOpen,
            isCreatingFromTemplate: true
        })
    }


    getNextJobDayFromCron = (cron, lastJobDate) => { // or start date
        const cronSplit = cron.split(" ");
        const today = moment();

        if (cronSplit[3].includes("/")) {
            const isLastDayOfTheMonth = cronSplit[2] === '28-31';
            const everyXMonths = cronSplit[3].split("/")[1];
            let dayInMonth;
            const currentMonthDay = moment().date();

            if (isLastDayOfTheMonth) {
                dayInMonth = 28;
            } else {
                dayInMonth = Number(cronSplit[2]);
            }

            if (!moment(lastJobDate).isAfter(today, 'day') && dayInMonth < currentMonthDay) {
                if (isLastDayOfTheMonth) {
                    return moment(lastJobDate).add(everyXMonths, 'months').endOf('month').format(DEFAULT_DATABASE_DATE_FORMAT)
                } else {
                    return moment(lastJobDate).add(everyXMonths, 'months').date(dayInMonth).format(DEFAULT_DATABASE_DATE_FORMAT);
                }
            }

            if (isLastDayOfTheMonth) {
                return moment(lastJobDate).endOf('month').format(DEFAULT_DATABASE_DATE_FORMAT);
            } else {
                return moment(lastJobDate).date(dayInMonth).format(DEFAULT_DATABASE_DATE_FORMAT);
            }

        } else if (["mon", "tue", "wed", "thu", "fri", "sat", "sun"].includes(cronSplit[4])) {
            const dayInWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"].findIndex(it => it === cronSplit[4]);
            const dayInWeekToday = moment().day();
            const startOfWeek = moment(lastJobDate).startOf('isoWeek');

            if (!moment(lastJobDate).isAfter(today, 'day') || dayInWeekToday >= dayInWeek) {
                return startOfWeek.add(1, 'week').add(dayInWeek, 'days').format(DEFAULT_DATABASE_DATE_FORMAT);
            } else {
                return startOfWeek.add(dayInWeek, 'days').format(DEFAULT_DATABASE_DATE_FORMAT);
            }
        } else {
            if (moment(lastJobDate).isSame(today, 'day')) {
                return moment(lastJobDate).add(1, 'day').format(DEFAULT_DATABASE_DATE_FORMAT);
            }
        }

        return lastJobDate;
    }


    /** Render
     ================================================================= */
    render() {
        const {translate, resource, dialogResource} = this.props

        const isLoading = !!resource.isLoading
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        <Button
                            hasPerm={checkPerm(Resources.IncomeRecurring, CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}
                    />

                    <ResourceTable
                        tableKey={'RecurringPaymentsID'}
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        renderCells={this.renderCells}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        onEdit={this.handleToggleEditModal}
                        onRowClick={this.handleToggleEditModal}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.archiveResource,
                                icon: ArchiveBoxIcon, // make this a function
                                visible: (it) => (checkPerm(this.getResource(), DELETE_PERM) && !it.ArchivedDate),
                                label: false, // make this a function
                                title: translate('btn.archive'),
                                order: 30
                            },
                            {
                                action: this.restoreFromArchive,
                                icon: ArrowUturnLeftIcon, // make this a function
                                visible: (it) => (checkPerm(this.getResource(), UPDATE_PERM) && !!it.ArchivedDate),
                                label: false, // make this a function
                                title: translate('btn.restore'),
                                order: 40
                            },
                            {
                                action: this.handleCreateFromTemplateClick,
                                icon: CreditCardIcon,
                                hasPerm: checkPerm(Resources.IncomeRecurringCreateFromTemplate, CREATE_PERM),
                                title: translate('btn.create_invoice_from_template'),
                                order: 20
                            },
                        ]}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <RecurringIncomeModal
                    show={this.state.isRecurringModalOpen}
                    selectedItem={this.state?.selectedItem ? dialogResource.data ?? {} : undefined}
                    isCreatingFromTemplate={this.state.isCreatingFromTemplate}
                    itemList={this.state?.selectedItem ? dialogResource?.data?.Lines ?? [] : []}
                    isLoading={resource.isLoading || dialogResource.isLoading}
                    fetchOnMount={() => {
                        if (this.state.selectedItem) {
                            this.props.dispatch(getDialogResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.IncomeRecurringInfo,
                                query: {
                                    RecurringInvoiceID: this.state?.selectedItem?.RecurringInvoiceID
                                }
                            }))
                        }
                    }}
                    onSubmitCreate={(params) => {
                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: Resources.IncomeRecurring,
                            piggyResource: this.getResource(),
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: this.props.translate("message.recurring_template_create_success"),
                        }));
                    }}
                    onSubmitEdit={(params) => {
                        if (this.state.isCreatingFromTemplate) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.IncomeRecurringCreateFromTemplate,
                                piggyResource: this.getResource(),
                                query: this.getQuery(),
                                errorMessage: true,
                                successMessage: this.props.translate("message.income_created_from_template_success"),
                            }));
                        } else {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.IncomeRecurring,
                                piggyResource: this.getResource(),
                                query: this.getQuery(),
                                errorMessage: true,
                                successMessage: this.props.translate("message.recurring_template_edit_success"),
                            }));
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            isRecurringModalOpen: false,
                            selectedItem: undefined
                        })
                    }}
                    translate={translate}
                />

                {this.state.deleteDialog && (
                    <ModalConfirm
                        {...this.props}
                        text={this.state.text}
                        name={this.state.confirmText}
                        submit={this.state.deleteDialog}
                        cancel={this.handleToggleDeleteDialog}
                        ui={{confirmDialogHideAnimation: this.state.confirmDialogHideAnimation}}
                    />)
                }
                <ModalConfirm
                    title={translate('text.confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

            </React.Fragment>
        )
    }
}
