import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    checkPerm,
    classNames,
    cnv,
    ConstantTranslate,
    getDriverBadgeClass,
    getFullAddressName,
    getHighlightedFullAddressName,
    getLookup,
    getMeasurementUnitName,
    getProp,
    getWeightUnitName,
    groupListBySCAC,
    handleMaskedFocus,
    openInNewTab,
    resourceIsLoaded,
    resourceIsUpdated,
} from '../../../common/util/util-helpers'
import {hideConfirmDialog, pushNotification, showGlobalModal, showModal} from '../../../data/actions/ui'
import moment from "moment-timezone";
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import InfoAccess from './load-sections/load-info-access'
import {
    createResource,
    deleteResourceTempData,
    getResource,
    resetResource,
    updateResource
} from '../../../data/actions/resource'
import {getSecondResource, resetSecondResource} from '../../../data/actions/secondResource'
import LoadInfoRead from './load-info-read'
import Tippy from '@tippyjs/react'
import {updateHelpResource} from '../../../data/actions/helpResource'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import ExclamationSolidIcon from '@heroicons/react/20/solid/ExclamationTriangleIcon'
import FireIcon from '@heroicons/react/20/solid/FireIcon'
import NoSymbolIcon from '@heroicons/react/24/outline/NoSymbolIcon'
import {
    ArrowDownTrayIcon,
    BanknotesIcon,
    Bars4Icon,
    ExclamationCircleIcon as ExclamationCircleOutlineIcon,
    MagnifyingGlassIcon,
    MapIcon,
    PresentationChartBarIcon,
    RectangleStackIcon,
} from '@heroicons/react/24/outline'
import {createDialogResource, updateDialogResource} from '../../../data/actions/dialogResource'
import TruckIcon from '@heroicons/react/24/outline/TruckIcon'
import TruckSolidIcon from '@heroicons/react/20/solid/TruckIcon'
import {
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DRIVER_STATUS_AVAILABLE,
    DRIVER_STATUS_BOOKED,
    DRIVER_STATUS_ON_LOAD,
    DRIVER_STATUS_TYPE_TIPPY_TEXT,
    DRIVER_STATUS_TYPES,
    FIELD_MASK_PHONE,
    LOAD_PRICING_TYPES,
    LOAD_STATUS_CANCELED,
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_NEW,
    LOAD_STATUS_POSSIBLE,
    LOAD_STATUS_READY,
    LOAD_STATUS_RESERVED,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_STOP_BY,
    TRUCK_STATUS_ON_LOAD,
    UPDATE_PERM,
    WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY
} from '../../../common/util/util-consts'
import {cloneDeep, dirtyClone, scrollErrorIntoView} from '../../../common/util/util-vanilla'
import {numberWithCommas, numberWithCommasToBack} from '../../../util/util-formaters'
import CommodityForm from '../templates/dialogs/commodity-form'
import LoadInfoMap from "./load-sections/load-info-map";
import LoadInfoBasic from "./load-sections/load-info-basic";
import LoadCustomerSettingsDialog from "./load-sections/dialog/load-customer-settings-dialog";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon, PencilSquareIcon} from "@heroicons/react/20/solid";
import LoadInfoAssets from "./load-sections/load-info-assets";
import {Link} from "react-router-dom";
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import ArrowTopRightOnSquareIcon from "@heroicons/react/24/outline/ArrowTopRightOnSquareIcon";
import LoadInfoLocations from "./load-sections/load-info-locations";
import SwitchDispatchInfo from "./load-sections/dialog/load-switch-dispatch-info";
import ShareSolidIcon from "@heroicons/react/20/solid/ShareIcon";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import LoadCompleteStopDialog from "./load-sections/dialog/load-complete-stop-dialog";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import MinusIcon from "@heroicons/react/24/outline/MinusIcon";
import LoadInfoCommodities from "./load-sections/load-info-commodities";
import LoadInfoAccessorials from "./load-sections/load-info-accessorials";
import axios from "axios";
import {
    clearLocationFields,
    convertMeasurements,
    generateStops,
    getCombinedStopObject,
    getCommodityFields,
    getContactDialogFields,
    getLoadSelects,
    getStopByFields,
    getStopFields,
    mergeStops,
    processStopInputChange
} from "./load-utils";
import {cancelLoad} from "../../../package/realtime/actions/realtime";
import {
    getCurrentTimeSeconds,
    getUserTimeFormat,
    guessDateFormat,
    timePickerValueToServerTime,
    toFrontDateTimeFromUTC,
    toFrontDateTimeOCR
} from '../../../common/util/util-dates'
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import ContactSelectButton from "./load-sections/load-components/contact-select-button";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import AtSymbolIcon from "@heroicons/react/24/outline/AtSymbolIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import TrucksTableBadge from "../../fleet/trucks-view/trucks-table-badge";
import GetDispatchManagerFields from "../dispatch-manager-view/dispatch-manager-fields";
import LoadCarrierShareContent from "./load-sections/load-carrier-share-content";
import LoadCustomerShareContent from "./load-sections/load-customer-share-content";
import {getHistoryResource} from "../../../data/actions/historyResource";
import LoadOCRDialog from "./load-sections/load-ocr-dialog";
import GroupsViewDialog from "../../my-company/groups-view/groups-view-dialog";
import Env from '../../../util/env'
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import StopsShortDisplay from "./load-sections/stops-short-display";
import ChevronUpIcon from "@heroicons/react/24/outline/ChevronUpIcon";
import RectangleGroupIcon from '@heroicons/react/24/outline/RectangleGroupIcon'
import LoadInfoPricing from './load-sections/load-info-pricing';
import LoadInfoStopUpdateReminder from './load-sections/load-info-stop-update-reminder';
import LoadCompleteStopByDialog from "./load-sections/dialog/load-complete-stop-by-dialog";
import {getInfoResource} from "../../../data/actions/infoResource";
import LoadInfoDocuments from "./load-sections/load-info-documents";
import ConfirmLoadStatusUpdateModal from "./load-sections/confirm-load-status-update-modal";
import {getDialogInfoResource} from "../../../data/actions/dialogInfoResource";
import {getJobStatus} from "./load-sections/load-ocr-dialog/ocr-util";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import ContactForm from "../../../common/components/forms/contact-form";
import Modal from "../../../common/components/modal";
import {excludeFields, fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import CreateLocationDialog from "../../../common/components/modal/createLocationDialog";
import DragAndDrop from "../../../common/components/modal/truckDialogs/createTruckLocationDialog/DragAndDrop";
import LocationInfo from "../../../common/components/modal/modal-global/global-location-info/location-info";
import ModalTabbed from "../../../common/components/modal/modal-tabbed";
import {LoaderSmall} from "../../../common/components/loader";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTableDialogWithFilters from "../../../common/components/modal/resource-table-dialog-with-filters";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FormEmailUrl from "../../../common/components/forms/form-email-url"
import {getJWT, getUser} from "../../../common/util/util-auth";
import FieldOptions from "../../../common/components/fields/field-options";
import Tooltip from "../../../common/components/tooltip";
import CellButtonWithCopy from "../../../common/components/resource-table/table-components/cell-button-copy";
import TextCopy from "../../../common/components/resource-table/table-components/text-copy"
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import Badge from "../../../common/components/badge"
import ProgressArrows from "../../../common/components/progress-arrows";
import InfoParagraph from "../../../common/components/info-paragraph";
import ButtonIcon from "../../../common/components/button/button-icon";
import {checkFeatureFlag, Flags} from "../../../common/components/feature-flags"
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import DropZoneAlt from "../../../common/components/dropzone/drop-zone-alt"
import Button from "../../../common/components/button";
import ModalHeader from "../../../common/components/modal/modal-header";
import CreateSuccessModal from "../../../common/components/modal/create-success-modal"
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Card from "../../../common/components/card";
import ButtonsGroup from "../../../common/components/buttons/buttons-group";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import Cron from "../../../common/components/cron";
import FieldDate from "../../../common/components/fields/field-date";
import FieldText from "../../../common/components/fields/field-text";
import FieldTextarea from "../../../common/components/fields/field-textarea";
import ResourceTable from "../../../common/components/resource-table";
import FormSubtitle from "../../../common/components/forms/form-subtitle"
import ContactSelector from "../../../common/components/modal/contact-selector"
import StateMileageDialog from "./load-sections/StateMileageDialog"
import {DRIVER_STATUS_ON_VACATION} from "../../../util/util-constants";
import LoadSteps from "./load-steps";

export default class LoadInfoTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataOCR: {},
            fields: this.getFields(),
            stops: [],
            stopBys: [],
            stopsCombined: [],
            reminderFields: this.getReminderFields(),
            selectedLocation: 0, // rename to stop after refactor

            createLoadDocuments: [],
            customerFields: {},
            carrierFields: {},
            truckFields: {},
            trailerFields: {},
            driverFields: {},
            coDriverFields: {},
            accessorialFields: {},
            locationFields: [],
            createdLocationFields: [],
            CustomerContactsList: {},
            CustomerLoadSetting: {},
            tempFields: {},
            showDialog: false,
            showSwitchDispatchDialog: false,
            dialogHideAnimation: false,
            editItemIndex: null,
            editMode: false,
            selectedField: "",
            confirmText: '',
            draggedStopIndex: undefined,
            draggedLocationIndex: null,
            draggedHasChanges: false,
            draggedHasIncludedInMileageChanges: false,
            hasIncludedInMileageResetMessage: false,
            hasChangeStopOrderWarning: false,
            validationMessage: false,
            createOptionAutofill: {},
            selectedRepeatOption: 'none',
            isRedirectDialogOpen: false,
            lineOfBusiness: 'brokerage',
            confirmModalAction: false,
            isLineOfBusinessChoiceVisible: false,
            loadStatusID: LOAD_STATUS_POSSIBLE,
            screenBreakpoint: undefined,
            showURLDialog: false,
            isTonuDialogVisible: false,
            isTailgateDialogVisible: false,
            isRemoveTailgateDialogOpen: false,
            selectedItem: undefined,
            selectedStop: undefined,
            StopByItem: undefined,
            selectedUnit: null,
            isAccessorialDialogOpen: false,
            AccessorialData: [],
            isAdjustmentDialogOpen: false,
            AdjustmentData: [],
            isCommodityDialogOpen: false,
            CommodityData: [],
            SkipCustomerDefaults: 1,
            isLocationDialogOpen: false,
            isLocationContactsDialogOpen: false,
            isDriverSelectDialogOpen: false,
            isTruckSelectDialogOpen: false,
            isSelectLocationDialogOpen: false,
            isPingUpdateModalOpen: false,
            isTrailerSelectDialogOpen: false,
            isCarrierSelectDialogOpen: false,
            isDispatchTeamDialogOpen: false,
            isPrimaryCustomerContactDialogOpen: false,
            isCreateContactDialogOpen: false,
            isEmailExistError: false,
            isBookedByDialogOpen: false,
            isChoseDispatchContactDialogOpen: false,
            isShareCarrierDialogOpen: false,
            isShareCustomerDialogOpen: false,
            isCoveredByDialogOpen: false,
            isOcrDialogMinimized: false,
            doesStopsNeedsUpdate: false,
            createdLoadID: undefined,
            submittedContact: {},
            currentCreatedStopOrder: -2,
            hasUpdatedStopTypeWarning: false,
            postUpdatePingLocation: {},
            showConfirmDialogForStatusUpdate: null,
            CustomerCreditLimit: undefined,
            CustomerAvailableCredit: undefined,
            CustomerAllowCreditLimitOverride: undefined,
            loadStepsCompleteStopState: {},
            areStepsActive: false,
            linkOptions: [
                {
                    name: 'AllowShare',
                    description: 'Activate link and show updates to customer',
                    value: false
                },
                {
                    name: 'AllowChat',
                    description: 'Enable chat on shared link page',
                    value: false
                },
                {
                    name: 'AllowTracks',
                    description: 'Enable map on shared link page',
                    value: false
                },
                {
                    name: 'AllowAssets',
                    description: 'Allow assets on shared link page',
                    value: false
                }
            ],

            carrierLinkOptions: [
                {
                    name: 'CarrierAllowShare',
                    description: 'Activate this link',
                    value: false
                },
                {
                    name: 'CarrierAllowEdit',
                    description: 'Enable chat on shared link page',
                    value: false
                },
                {
                    name: 'CarrierAllowLocation',
                    description: 'Enable locations on shared link page',
                    value: false
                },
                {
                    name: 'CarrierAllowNote',
                    description: 'Enable note on shared link page',
                    value: false
                },
                {
                    name: 'CarrierAllowUpload',
                    description: 'Enable uploading documents on shared link page',
                    value: false
                }
            ],
            docContainerHeight: 0,
            linkOptionStateName: undefined,
            splitDispatchBtnDisabled: true,
            isCompleteStopModalOpen: false,
            isCompleteStopByModalOpen: false,
            isUpdateStopModalOpen: false,
            isCustomerSettingsModalOpen: false,
            isSelectLocationModalOpen: false,
            isCreateCarrierDriverDialogOpen: false,
            isDriverNameTxtDialogOpen: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},

            createdLocationData: {},
            isAxiosRequesting: false,
            updateUnit: 1,
            DriverNameTxtKey: 1,
            expandedSections: this.getSectionSettings(),
            isOCRDialogOpen: false,
            hashUUID: '',
            assignees: [],
            pingZone: '',
            loadInfoLocationsKey: 1,
            duplicateCustomerReferenceNumberLoad: {
                LoadID: 0,
                LoadNumber: 0,
                previousCustomerReferenceNumberValue: ""
            },

            isJobsListingLoading: false,
            jobListingData: []
        }

        this.selects = getLoadSelects();

        this.fieldsRef = {
            'customer': null,
            'carrier': null,
            'driver': null,
            'coDriver': null,
            'truck': null,
            'trailer': null,
            'location': null,
        }
        this.locationsRefs = {}
        this.isEditMode = this.props.match.params.id
        this.splitDispatchDialogRef = React.createRef()

        this.MeasureUnitID = LocalStorage.get('user')?.Contact.MeasureUnitID ?? 1
        this.MeasureUnit = LocalStorage.get('user')?.Contact.MeasureUnit ?? 'in'
        this.defaultWeightUnit = LocalStorage.get('user')?.Contact?.WeightUnit ?? 'lb'

        this.isMetric = ['cm', 'm'].includes(this.MeasureUnit)
        this.standardLengthMeasure = this.isMetric ? 'm' : 'ft'
        this.standardWeightMeasure = this.isMetric ? 'kg' : 'lb'
        this.dispatchedAssets = [];

        this.LoadInfoDocumentsSection = React.createRef();
        this.LoadInfoBasicSection = React.createRef();
        this.LoadInfoPricingSection = React.createRef();
        this.LoadInfoAssetsSection = React.createRef();
        this.LoadInfoLocationsSection = React.createRef();
        this.LoadInfoStopUpdateReminderSection = React.createRef();
        this.LoadInfoCommoditiesSection = React.createRef();
        this.LoadInfoMapSection = React.createRef();
        this.LoadInfoAccessorialsSection = React.createRef();

        this.countries = getLookup('Country', 'CountryID', 'CountryName');
        this.states = getLookup('State', 'StateID', 'StateAbbreviation');
        this.statesFullName = getLookup('State', 'StateID', 'State');

        this.contactTableOptions = {
            style: {
                stripedRows: true,
                horizontalLines: false,
                floatingActions: false,
            },
        }

        this.successModalFocusRef = React.createRef();
        this.dispatchManagerInitialFocusRef = React.createRef();

        this.loadStatuses = getLookup('LoadStatus', 'LoadStatusID', 'LoadStatus');

        this.loadSettings = {}

        this.isCreateMode = this.props?.match?.path === '/loads/create';
        this.dispatchSettings = {};
        this.refCustomerRefChangeTimeout;

        this.customerShareOptionsTimeout = undefined;
        this.carrierShareOptionsTimeout = undefined;

        this.completeStopFieldsList = [
            "ActualArrivalDate", "ActualArrivalDateTime", "ActualDepartureDate", "ActualDepartureDateTime", "NotOnTime",
            "IsStopCompleted", "PalletsLoaded", "PalletsUnLoaded", "SealNo", "BOLNumber", "SignedByName", "SignedByPhone"
        ];

        this.StepsAddStopsRef = React.createRef();
        this.SubmitButtonRef = React.createRef();

        this.possibleStatusRef = React.createRef();
        this.readyStatusRef = React.createRef();
        this.coveredStatusRef = React.createRef();
        this.dispatchedStatusRef = React.createRef();
        this.inTransitStatusRef = React.createRef();
        this.deliveredStatusRef = React.createRef();

        this.fieldsRefs = {
            DriverID: React.createRef(),
            TruckID: React.createRef(),
            CarrierID: React.createRef(),
            CustomerReferenceNumber: React.createRef()
        }

        this.stopFieldsRefs = {
            StopID: React.createRef(),
            StopDate: React.createRef()
        }

        this.completeStopButtonRef = {};
        this.completeStopFormRef = React.createRef();
        this.completeStopSubmitButtonRef = React.createRef();
        this.isStopCompletedCheckboxRef = React.createRef();
        this.suggestNextActionButtonRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.handleCheckIfRedirected()
        this.fetchData()
        this.updateRequiredFields()

        if (this.isCreateMode) {
            this.fetchLoadSettings();
        }

        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.watchWindowSize);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLocationDialogOpen && !this.state.isLocationDialogOpen && this.state.isSelectLocationDialogOpen) {
            this.setState({
                isSelectLocationDialogOpen: false
            }, () => this.setState({
                    isSelectLocationDialogOpen: true
                }
            ))
        }

        if (!!this.props.notifications?.data?.notifications?.list?.length &&
            !this.isEditMode &&
            (this.props.lastNotification?.['ID'] !== prevProps.lastNotification?.['ID'])) {
            this.readNotificationData(this.props.lastNotification)
            const {uuid = "", currentStatus = ""} = this.readNotificationData(this.props.lastNotification)
            this.handleOCRStatusChange(uuid, currentStatus)
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            let fields = this.getFields()

            let isBroker = getProp(
                this.props.resource.data, 'load/info.IsBroker',
                LocalStorage.get('user')?.Contact?.LineOfBusinessPrimary === 1 ? 1 : 0
            );

            let lineOfBusiness = null
            if (isBroker === 0) {
                lineOfBusiness = 'carrier'
            } else {
                lineOfBusiness = 'brokerage'
            }

            const linkOptions = this.state.linkOptions.map(it => {
                it.value = getProp(this.props.resource.data, 'load/info.' + it.name, false)
                return it
            })

            const carrierLinkOptions = this.state.carrierLinkOptions.map(it => {
                it.value = getProp(this.props.resource.data, 'load/info.' + it.name, false)
                return it
            })

            let stopData = getProp(this.props.resource, 'data.load/multistops', []);

            if (this.props.isCopyMode) {
                stopData = stopData.map(it => {
                    //delete it.LoadStopID;
                    delete it.IsStopCompleted;
                    delete it.ActualArrivalDateTime;
                    delete it.ActualDepartureDateTime;
                    delete it.StopDate;
                    delete it.StopEndDate;
                    delete it.AptReferenceNumber;
                    delete it.ReferenceNumber;
                    delete it.SealNo;
                    delete it.SignedByName;
                    delete it.SignedByPhone;
                    delete it.BOLNumber;
                    delete it.NotOnTime;
                    delete it.PalletsExchanged;
                    delete it.PalletsLoaded;
                    delete it.PalletsUnLoaded;
                    return it;
                })
            }

            let stops = generateStops(stopData, this.props.translate);
            stops = this.addStopFieldsActions(stops);

            stops = stops.map(stop => this.extendStop(stop));

            let stopBys = this.generateStopBys();
            let CommodityData = getProp(this.props.resource, 'data.' + Resources.LoadCommodities, []);

            let mergedStops = [];

            if (stops.length) {
                mergedStops = mergeStops(
                    getProp(this.props.resource, 'data.load/multistops', []),
                    getProp(this.props.resource, 'data.load/stopby', [])
                );

                // Last selected stop stays selected
                if (this.state.stopsCombined.length) {
                    let selectedAndCompletedStopIndex = -1;
                    mergedStops.map((it, index) => {
                        it.IsSelected = !!this.state.stopsCombined?.[index]?.IsSelected;
                        if (it.IsSelected && it.IsStopCompleted) {
                            selectedAndCompletedStopIndex = index;
                        }
                        return it;
                    });

                    if (selectedAndCompletedStopIndex > -1 && mergedStops[selectedAndCompletedStopIndex + 1]) {
                        mergedStops[selectedAndCompletedStopIndex].IsSelected = false;
                        mergedStops[selectedAndCompletedStopIndex + 1].IsSelected = true;
                    }
                }
            }

            this.dispatchedAssets = getProp(this.props.resource.data, 'load/info.isInDispatch', []);
            this.isInactiveTruck = getProp(this.props.resource.data, 'load/info.isInactiveTruck', null);
            this.isInactiveTrailer = getProp(this.props.resource.data, 'load/info.isInactiveTrailer', null);
            this.isCarrierBlacklisted = getProp(this.props.resource.data, 'load/info.isCarrierBlacklisted', null);

            this.dispatchedAssets = getProp(this.props.resource.data, 'load/info.isInDispatch', []);
            this.loadSettings = getProp(this.props.resource.data, 'settings', {});

            this.setState({
                fields: fields,
                reminderFields: this.getReminderFields(this.props.isCopyMode ? {} : getProp(this.props.resource.data, 'load/info', {})),
                CommodityData: CommodityData,
                loadStatusID: this.props.isCopyMode ? 0 : getProp(this.props.resource.data, 'load/info.LoadStatusID', 0),
                initialLoadStatusID: this.props.isCopyMode ? 0 : getProp(this.props.resource.data, 'load/info.LoadStatusID', 0),
                lineOfBusiness: lineOfBusiness,
                CustomerID: getProp(this.props.resource, 'data.load/info.CustomerID', 0),
                CustomerLoadSetting: this.getCustomerLoadSetting(getProp(this.props.resource.data, 'load/info.CustomerLoadSettings.Tasks', {})),
                isLoadInvoiced: getProp(this.props.resource.data, 'load/info.IsInvoiced', false),
                linkOptions, carrierLinkOptions,
                stops: stops,
                stopBys: stopBys,
                stopsCombined: [...mergedStops],
                dispatchSwitchData: cloneDeep(getProp(this.props.resource, 'data.load/dispatch', [])),
                doesStopsNeedsUpdate: false,
                CustomerContactsList: getProp(this.props.resource.data, 'load/info.CustomerContactsList', []).reduce((memo, it) => {
                    memo[it.ContactID] = it;
                    return memo;
                }, {}),
                EmptyMiles: getProp(this.props.resource, 'data.' + Resources.LoadInfo + '.EmptyMiles', undefined),
                TotalMiles: getProp(this.props.resource, 'data.' + Resources.LoadInfo + '.TotalMiles', undefined),
                TotalMilesNoStopBy: getProp(this.props.resource, 'data.' + Resources.LoadInfo + '.TotalMilesNoStopBy', undefined),
                hasUpdatedStopTypeWarning: false,
                draggedHasIncludedInMileageChanges: false,
                hasIncludedInMileageResetMessage: false,
                hasChangeStopOrderWarning: false
            }, () => {
                this.updateRequiredFields();
            })
        }

        if (prevProps.secondResource.isLoading && !this.props.secondResource.isLoading) {
            // pre-populate settings data and then fetch load data
            let isLineOfBusinessChoiceVisible = false

            const LineOfBusinessPrimary = getProp(this.props, 'secondResource.data.LineOfBusinessPrimary', [])
            const LineOfBusiness = getProp(this.props, 'secondResource.data.LineOfBusiness', [])

            const lineOfBusinessName = LineOfBusinessPrimary === 1 ? 'brokerage' : 'carrier'

            if (LineOfBusiness.length > 1) {
                isLineOfBusinessChoiceVisible = true
            }

            let stops = this.state.stops;
            let stopBys = this.state.stopBys;
            let stopsCombined = this.state.stopsCombined;
            let CommodityData = this.state.CommodityData;

            if (!this.props.match.params.id) {
                stops = generateStops(getProp(this.props.resource, 'data.load/multistops', []), this.props.translate);
                stops = this.addStopFieldsActions(stops);

                stopsCombined = mergeStops(
                    getProp(this.props.resource, 'data.load/multistops', []),
                    getProp(this.props.resource, 'data.load/stopby', [])
                );
            }

            if (this.props.resource.temp) {
                stops = generateStops(this.props.resource.temp.stops, this.props.translate);
                stops = this.addStopFieldsActions(stops);

                stopBys = this.generateStopBys(this.props.resource.temp.stopBys);
                stopsCombined = mergeStops(cloneDeep(this.props.resource.temp).stops, cloneDeep(this.props.resource.temp).stopBys);
                CommodityData = this.props.resource.temp.commodityData
            }

            this.setState({
                lineOfBusiness: lineOfBusinessName,
                isLineOfBusinessChoiceVisible: isLineOfBusinessChoiceVisible,
                stops,
                stopBys,
                stopsCombined,
                CommodityData
            }, () => {
                if (this.props.match.params.id) {
                    this.fetchLoadData()
                }

                if (this.props.resource.temp) {
                    this.props.setDirty();
                    this.props.dispatch(deleteResourceTempData()); // data from load-preview-modal
                }
            })
        }

        if (prevProps.ui.confirmDialog && !this.props.ui.confirmDialog) {
            if (this.state.focusNext) {
                this.locationsRefs[this.state.focusNext].focus()
                this.setState({
                    focusNext: undefined
                })
            }
        }

        if (this.props.resource !== prevProps.resource && !!this.props.resource.create && !!this.props.resource.showRedirectDialog) {
            this.toggleRedirectDialog()
        }

        // Update created StopID
        if (!prevProps.dialogResource?.create && !!this.props.dialogResource?.create
            && (this.props.dialogResource.resource === Resources.LocationsQuick || this.props.dialogResource.resource === Resources.Locations)
        ) {
            let stopsCombinedClone = cloneDeep(this.state.stopsCombined)

            let stopsFieldsUpdate = stopsCombinedClone.map((it) => {
                if (it.IsSelected.value) {
                    it.StopID.value.value = this.props.dialogResource.create.id;
                    it.LocationData.value.LocationID = this.props.dialogResource.create.id
                }
                return it;
            })

            this.setState({
                stopsCombined: stopsFieldsUpdate
            })
        }

        if (
            !!this.props.dialogResource?.create?.id
            && this.props.dialogResource.create.id !== prevProps.dialogResource?.create?.id
            && (this.props.dialogResource.resource === Resources.LocationsQuick || this.props.dialogResource.resource === Resources.Locations)
        ) {
            // Create new location
            const newDropDownOption = {
                value: this.props.dialogResource.create.id,
                label: this.state.createdLocationData.LocationName,
                metadata: this.state.createdLocationData
            }

            this.handleStopInputChange("StopID", newDropDownOption);
        }

        if (
            !prevProps.dialogResource.errorMessage
            && this.props.dialogResource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS'
        ) {
            this.setIsEmailExistError(true);
        }

        if (
            !!this.props.dialogResource?.create?.id
            && this.props.dialogResource.create.id !== prevProps.dialogResource?.create?.id
            && this.props.dialogResource.resource === Resources.CustomersCommissions
            && !this.isEditMode
        ) {
            let fieldsUpdate = this.state.fields;

            fieldsUpdate.BookedByID.value = {
                label: this.state.submittedContact.LastName + " " + this.state.submittedContact.FirstName,
                value: this.props.dialogResource.create.id,
                metadata: Object.assign(this.state.submittedContact)
            }

            this.setState({
                isCreateContactDialogOpen: false,
                isBookedByDialogOpen: false,
                fields: fieldsUpdate
            })
        }

        if (
            !!this.props.dialogResource?.create?.id
            && this.props.dialogResource.create.id !== prevProps.dialogResource?.create?.id
            && this.props.dialogResource.resource === Resources.CarrierContacts
        ) {
            let fieldsUpdate = this.state.fields;

            fieldsUpdate.DispatchContactID.value = {
                label: this.state.submittedContact.LastName + " " + this.state.submittedContact.FirstName,
                value: this.props.dialogResource.create.id,
                metadata: Object.assign(this.state.submittedContact)
            };

            this.setState({
                isChoseDispatchContactDialogOpen: false,
                isCreateContactDialogOpen: false,
                fields: fieldsUpdate
            });
        }

        if (
            !!this.props.dialogResource?.create?.id
            && this.props.dialogResource.create.id !== prevProps.dialogResource?.create?.id
            && this.props.dialogResource.resource === Resources.CarrierDrivers
        ) {
            let fieldsUpdate = this.state.fields;

            if (this.state.selectedItem?.isCoDriver) {
                fieldsUpdate.CoDriverNameTxt.value = {
                    label: this.state?.selectedItem?.FirstName + " " + this.state?.selectedItem?.LastName,
                    value: this.props.dialogResource.create.id
                }

                fieldsUpdate.CoDriverCellTxt.value = this.state?.selectedItem?.PhoneNumber;
            } else {
                fieldsUpdate.DriverNameTxt.value = {
                    label: this.state?.selectedItem?.FirstName + " " + this.state?.selectedItem?.LastName,
                    value: this.props.dialogResource.create.id
                }
                fieldsUpdate.DriverCellTxt.value = this.state?.selectedItem?.PhoneNumber;
            }

            fieldsUpdate.DriverNameTxt.props.key = Number(this.state.DriverNameTxtKey) + 1;
            fieldsUpdate.CoDriverNameTxt.props.key = Number(this.state.DriverNameTxtKey) + 1;

            this.setState({
                fields: fieldsUpdate
            })

            this.toggleCreateCarrierDriverDialog();
        }

        if (this.state.lineOfBusiness === 'brokerage'
            && !!this.state.fields.CustomerID?.value?.value
            && prevState.fields.CustomerID?.value?.value !== this.state.fields.CustomerID?.value?.value
        ) {
            this.setState({
                DriverNameTxtKey: Number(this.state.DriverNameTxtKey) + 1
            })
        }

        if (
            resourceIsUpdated(this.props.helpResource, prevProps.helpResource)
            && this.props.helpResource.resource === Resources.LoadMark
        ) {
            let fields = this.state.fields;
            fields.Marked.value = fields.Marked?.value ? 0 : 1;
            this.setState({
                fields
            })
        }

        if (resourceIsLoaded(this.props.info, prevProps.info) && this.props.info.resource === Resources.DispatchSettings) {
            this.dispatchSettings = this.props.info.data;
        }

        if (resourceIsLoaded(this.props.dialogInfoResource, prevProps.dialogInfoResource) && this.props.dialogInfoResource?.resource === Resources.OrganizationsQuick) {
            this.setState({
                CustomerCreditLimit: !this.isEditMode && this.props.dialogInfoResource?.data?.list?.[0]?.CreditLimit,
                CustomerAvailableCredit: !this.isEditMode && this.props.dialogInfoResource?.data?.list?.[0]?.AvailableCredit,
                CustomerAllowCreditLimitOverride: !this.isEditMode && this.props.dialogInfoResource?.data?.list?.[0]?.AllowCreditLimitOverride
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.watchWindowSize);
        this.props.dispatch(resetResource());
        this.props.dispatch(resetSecondResource());
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.Company
        }))
    }

    fetchLoadData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadInfo,
            query: {
                id: this.props.match.params.id
            }
        }))
    }

    fetchLoadSettings = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.DispatchSettings,
        }))
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location)
        const hash = query.get('hash')
        if (hash) {
            this.setState({
                hashUUID: hash.slice(1),
                isOCRDialogOpen: true
            })
        }
    }

    submit = () => {
        if (!(this.props.isStateDirty || this.props.isCopyMode)) return

        let fields = this.state.fields;
        const loadStatus = getLookup('LoadStatus', 'LoadStatusID', 'LoadStatus')
        const loadStatusLabel = loadStatus[this.state.loadStatusID]

        if (this.props.lineOfBusiness === 'carrier') {
            FieldsManager.updateField(fields, 'CarrierID', -1)
        }

        let stops = cloneDeep(this.state.stops);
        let stopBys = cloneDeep(this.state.stopBys);


        if (stops.length) {
            // Update StopOrder for stops and stop bys
            let stopShouldHaveTrailer = !!this.state.fields.TrailerID?.value?.value;

            this.state.stopsCombined.forEach((it, i) => {
                if (it.StopType === STOP_TYPE_STOP_BY) {
                    stopBys[it.index].StopOrder.value = i + 1;
                    stopBys[it.index].IncludeInMileage.value = it.IncludeInMileage === 0 ? 0 : 1;

                    // Prevent hidden required fields
                    if (stopShouldHaveTrailer && !stopBys[it.index]?.IsDroppingTrailer?.value) {
                        stopBys[it.index].PickingTrailerID.value = "";
                        stopBys[it.index].PickingTrailerID.validate = [''];
                        stopShouldHaveTrailer = true;
                    } else {
                        stopShouldHaveTrailer = false;
                    }
                } else {

                    stops[it.index].StopOrder.value = i + 1;
                    stops[it.index].IncludeInMileage.value = it.IncludeInMileage === 0 ? 0 : 1;

                    // Prevent hidden required fields
                    if (stopShouldHaveTrailer && !stops[it.index]?.IsDroppingTrailer?.value) {
                        stops[it.index].PickingTrailerID.value = "";
                        stops[it.index].PickingTrailerID.validate = [''];
                        stopShouldHaveTrailer = true;
                    } else {
                        stopShouldHaveTrailer = false;
                    }
                }
            });
        }

        this.setState({
            fields: FieldsManager.validateFields(fields),
            reminderFields: FieldsManager.validateFields(this.state.reminderFields),
            stops: stops.map((fields) => FieldsManager.validateFields(fields)),
            stopBys: stopBys.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && FieldsManager.checkFieldsForErrors(this.state.reminderFields) && this.checkLocationsForError() && this.checkCommoditiesForErrors()) {

                let locationsClone = stops;
                let commodities = this.state.CommodityData.map(it => {
                    it.LoadCommodityPickupOrder = stops.find(stop => Number(stop.LoadStopID.value) === Number(it.LoadCommodityPickupID))?.StopOrder?.value;
                    it.LoadCommodityDestinationOrder = stops.find(stop => Number(stop.LoadStopID.value) === Number(it.LoadCommodityDestinationID))?.StopOrder?.value;
                    it.Temp = it.Temp ?? "";
                    delete it.LoadCommodityPickup
                    delete it.LoadCommodityDestination
                    delete it.ArchivedDate
                    delete it.CreateUpdateDate
                    delete it.LoadCommodityDestinationLatitude
                    delete it.LoadCommodityDestinationLongitude
                    delete it.LoadCommodityPickupLatitude
                    delete it.LoadCommodityPickupLongitude
                    delete it.LoadCommodityID
                    delete it.LoadCommodityPickupID
                    delete it.LoadCommodityDestinationID
                    delete it.LoadID
                    delete it.UpdatedByContactID
                    delete it.WeightConversion
                    delete it.LengthConverted
                    delete it.WidthConverted
                    delete it.HeightConverted
                    delete it.WeightConverted
                    delete it.LengthConversion
                    delete it.WidthConversion
                    delete it.HeightConversion
                    delete it.Template

                    return it
                })

                let locations = locationsClone.map((location) => {
                    location.StopTime.value = location.StopTime.value === "" || timePickerValueToServerTime(location.StopTime.value) == 'Invalid date' ? null : timePickerValueToServerTime(location.StopTime.value)
                    location.StopEndTime.value = location.StopEndTime.value === "" || timePickerValueToServerTime(location.StopEndTime.value) == 'Invalid date' ? null : timePickerValueToServerTime(location.StopEndTime.value)

                    if (!location.ReferenceNumber.value) {
                        location.ReferenceNumber.value = [];
                    }
                    location.ReferenceNumber.value = location.ReferenceNumber.value.map(it => it.value).join(",");

                    if (!location.AptReferenceNumber.value) {
                        location.AptReferenceNumber.value = [];
                    }
                    location.AptReferenceNumber.value = location.AptReferenceNumber.value.map(it => it.value).join(",");

                    delete location.IsSelected;
                    delete location.LocationData;

                    location.IsDroppingTrailer.value = location.IsDroppingTrailer.value ? 1 : 0;
                    location.EstimatedRetrievalDate.value = location.IsDroppingTrailer.value ? location.EstimatedRetrievalDate.value : '';

                    location.DriverID.value = location.IsSwitchDispatch ? location.DriverID.value : '';
                    location.CoDriverID.value = location.IsSwitchDispatch.value ? location.CoDriverID.value : '';
                    location.TruckID.value = location.IsSwitchDispatch.value ? location.TruckID.value : '';
                    location.TrailerID.value = location.IsSwitchDispatch.value ? location.TrailerID.value : '';

                    return location;
                })

                const params = Object.assign({},
                    Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                    FieldsManager.getFieldKeyValues(this.state.reminderFields),
                    {
                        LoadID: this.props.match.params.id,
                        BookedByID: this.state.fields.BookedByID?.value?.value,
                        //CoveredByID: this.state.fields.CoveredByID?.value?.value,
                        CoBrokerID: this.state.fields.CoBrokerID?.value?.value,
                        CustomerContactsList: Object.keys(this.state.CustomerContactsList),
                        Commodities: commodities,
                        Multistop: locations.map((fields) => Object.assign(FieldsManager.getFieldKeyValues(fields), {
                            StopDate: this.removeTimeFromDate(fields.StopDate.value),
                            StopEndDate: this.removeTimeFromDate(fields.StopEndDate.value),
                            StopID: fields.StopID.value.value,

                            Stop: fields.StopID?.value?.value === -1 && fields?.NewLocationData?.value
                                ? FieldsManager.getFieldKeyValues(fields.NewLocationData.value)
                                : null,

                            LoadStopID: fields.LoadStopID.value < 0 ? "" : fields.LoadStopID.value
                        })),
                        StopBy: stopBys.map((fields) => {
                            if (fields.ActualArrivalDate.value.split(" ").length > 1) {
                                fields.ActualArrivalDate.value = fields.ActualArrivalDate.value.split(" ")[0];
                            }

                            if (fields.ActualDepartureDate.value.split(" ").length > 1) {
                                fields.ActualDepartureDate.value = fields.ActualDepartureDate.value.split(" ")[0];
                            }

                            fields.ActualArrivalDateTime.value = moment(fields.ActualArrivalDate.value, guessDateFormat(fields.ActualArrivalDate.value)).format("YYYY-MM-DD") + " " + timePickerValueToServerTime(fields.ActualArrivalDateTime.value);
                            fields.ActualDepartureDateTime.value = moment(fields.ActualDepartureDate.value, guessDateFormat(fields.ActualDepartureDate.value)).format("YYYY-MM-DD") + " " + timePickerValueToServerTime(fields.ActualDepartureDateTime.value);

                            delete fields.ActualArrivalDate;
                            delete fields.ActualDepartureDate;

                            // Getting key values
                            fields = FieldsManager.getFieldKeyValues(fields);
                            fields.IsDroppingTrailer = fields.IsDroppingTrailer ? 1 : 0;
                            fields.EstimatedRetrievalDate = fields.IsDroppingTrailer ? fields.EstimatedRetrievalDate : '';

                            fields.DriverID = fields.IsSwitchDispatch ? fields.DriverID : '';
                            fields.CoDriverID = fields.IsSwitchDispatch ? fields.CoDriverID : '';
                            fields.TruckID = fields.IsSwitchDispatch ? fields.TruckID : '';
                            fields.TrailerID = fields.IsSwitchDispatch ? fields.TrailerID : '';

                            return fields;
                        }),
                        CarrierID: this.state.fields.CarrierID?.value?.value,
                        CoDriverID: this.state.fields.CoDriverID.value?.value,
                        CustomerID: this.state.fields.CustomerID.value?.value,
                        DriverID: this.state.fields.DriverID.value?.value,
                        TruckID: this.state.fields.TruckID.value?.value,
                        TrailerID: this.state.fields.TrailerID.value?.value,
                        SecondTrailerID: this.state.fields.SecondTrailerID.value?.value,
                        ThirdTrailerID: this.state.fields.ThirdTrailerID.value?.value,
                        LoadTypeID: this.state.fields.LoadTypeID.value,
                        LoadSubTypeID: this.state.fields.LoadSubTypeID.value,
                        DriverNameTxt: this.state.fields.DriverNameTxt.value?.label,
                        DriverCellTxt: !!this.state.fields?.DriverCellTxt?.value && this.state.fields.DriverCellTxt.value.replace(/\D/g, ""),
                        CoDriverCellTxt: !!this.state.fields?.CoDriverCellTxt?.value && this.state.fields.CoDriverCellTxt.value.replace(/\D/g, ""),
                        CoDriverNameTxt: this.state.fields.CoDriverNameTxt.value?.label,
                        TruckTxt: this.state.fields.TruckTxt.value,
                        TrailerTxt: this.state.fields.TrailerTxt.value,
                        TrailerTypeTxt: this.state.fields.TrailerTypeTxt.value,
                        DispatchContactID: this.state.fields.DispatchContactID?.value?.value,
                        CustomerReferenceNumber: this.state.fields.CustomerReferenceNumber.value,
                        IsBroker: (this.state.lineOfBusiness === 'brokerage' ? 1 : 0),
                        OfficeID: this.state.fields.OfficeID.value.value,
                        ContactGroupID: this.state.fields.ContactGroupID.value?.value,
                        Repeat: this.state.fields.Repeat.value ? 1 : 0,
                        ExternalNotesDriverCarrier: this.state.lineOfBusiness === 'carrier' ? this.state.fields.ExternalDriverNotes.value : this.state.fields.ExternalCarrierNotes.value,
                        ExternalNotesCustomer: this.state.fields.ExternalNotesCustomer.value,

                        warnings: JSON.stringify({
                            inactiveDriverID: this.getAssetWarnings()?.inactiveDriverData?.DriverID,
                            inactiveCoDriverID: this.getAssetWarnings()?.inactiveCoDriverData?.DriverID
                        })
                    },
                    (this.state.fields.TruckID.value?.value === -1) ? {
                        Truck: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.truckFields), {
                            OfficeID: this.state.truckFields.OfficeID?.value?.value,
                            TrailerID: this.state.truckFields.TrailerID?.value?.value,
                        })
                    } : {},
                    (this.state.fields.TrailerID.value?.value === -1) ? {
                        Trailer: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.trailerFields), {
                            OfficeID: this.state.trailerFields.OfficeID?.value?.value
                        })
                    } : {},
                    (this.props.match.params.id && !this.props.isCopyMode) ? {
                        LoadStatusID: this.state.loadStatusID,
                        LoadStatus: loadStatusLabel || this.state.loadStatusLabel
                    } : {},
                    (this.state.fields.CustomerID.value?.value === -1) ? {Customer: FieldsManager.getFieldKeyValues(this.state.customerFields)} : {},
                    (this.state.fields.DriverID.value?.value === -1) ? {Driver: FieldsManager.getFieldKeyValues(this.state.driverFields)} : {},
                    (this.state.fields.CoDriverID.value?.value === -1) ? {CoDriver: FieldsManager.getFieldKeyValues(this.state.coDriverFields)} : {},
                    (this.state.fields.CarrierID?.value?.value === -1) ? {Carrier: dirtyClone(FieldsManager.getFieldKeyValues(this.state.carrierFields))} : {},
                );

                if (this.props.match.params.id && !this.props.isCopyMode) {
                    delete params.BookedByID; // This is only required for Create action
                    delete params.CoveredByID;

                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.LoadInfo,
                        piggyResource: Resources.LoadInfo,
                        query: {
                            id: this.props.match.params.id,
                        },
                        errorMessage: true,
                        successMessage: 'Load Updated'
                    }))
                } else {
                    params.Accesorials = getProp(this.state, 'AccessorialData', []).filter(it => it.checked).map(it => {
                        return Object.assign({}, {
                            'AccessorialID': it.AccessorialID,
                            'Amount': it.Amount,
                            'LoadAccessorialNotes': it.LoadAccessorialNotes,
                            'LoadAccessorialUnits': it.LoadAccessorialUnits,
                        })
                    })

                    params.SkipCustomerDefaults = 1 // Always skip, for now

                    let fileParams = [];
                    let filesToUpload = [];
                    if (this.state.createLoadDocuments.length) {
                        filesToUpload = this.state.createLoadDocuments;

                        fileParams = this.state.createLoadDocuments?.map((it) => {
                            const values = FieldsManager.getFieldKeyValues(it.fields ?? {});
                            return {
                                IsSendWithInvoice: values.IsSendWithInvoice ? 1 : 0,
                                IsVisibleForCustomer: values.IsVisibleForCustomer ? 1 : 0,
                                Description: values.Description,
                                ExpiryDate: values.ExpiryDate,
                                DocumentTypeID: values.DocumentTypeID,
                            }
                        });
                    }

                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        piggyResource: Resources.Loads,
                        resource: Resources.Load,
                        showRedirectDialog: true,
                        query: this.getQuery(),
                        file: filesToUpload,
                        fileParams: fileParams.length > 1 ? fileParams : fileParams[0],
                        fileResource: Resources.LoadDocuments,
                        errorMessage: true
                    }))
                }

                this.props.dirtyStateToggle()
            } else {
                this.setState({
                    validationMessage: true,
                }, () => {
                    if (!FieldsManager.checkFieldsForErrors(this.state.fields)) {
                        scrollErrorIntoView(this.state.fields);
                    } else if (!FieldsManager.checkFieldsForErrors(this.state.reminderFields)) {
                        scrollErrorIntoView(this.state.reminderFields);
                    }
                })
            }
        })
    }

    submitLoadStatus = () => {
        const loadStatus = getLookup('LoadStatus', 'LoadStatusID', 'LoadStatus')
        const loadStatusLabel = loadStatus[this.state.loadStatusID]

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                id: this.props.match.params.id,
                LoadStatusID: this.state.loadStatusID,
                LoadStatus: loadStatusLabel || this.state.loadStatusLabel,
            },
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.LoadCancel,
            piggyResource: Resources.LoadInfo,
            errorMessage: true, successMessage: `Load status updated`
        }))
    }

    submitContactForm = (params, file) => {
        params.id = this.state.fields.CustomerID?.value?.value;
        params.ExcludeOrganizationID = this.state.fields.CustomerID?.value?.value;

        this.props.dispatch(createDialogResource({
            user: getUser(),
            params: params,
            errorMessage: true,
            successMessage: this.props.translate('message.ContactCreatedSuccessfully'),
            query: LocalStorage.get("contactFormQuery") ?? {},
            resource: Resources.CustomersCommissions,
            piggyResource: Resources.Contacts,
            file: !!file && [file],
            fileResource: Resources.ContactImage,
        }));

        this.setState({
            submittedContact: params
        })
    }

    submitDispatcherContactForm = (params, file) => {
        params.id = this.state.fields.CarrierID?.value?.metadata?.OrganizationID;
        params.ContactID = -1;

        this.props.dispatch(createDialogResource({
            user: getUser(),
            params: params,
            errorMessage: true,
            successMessage: this.props.translate('message.ContactCreatedSuccessfully'),
            query: LocalStorage.get("contactFormQuery") ?? {},
            resource: Resources.CarrierContacts,
            piggyResource: Resources.Contacts,
            file: !!file && [file],
            fileResource: Resources.ContactImage,
        }));

        this.setState({
            submittedContact: params
        })
    }

    submitOCRData = () => {
        if (Object.keys(this.state.dataOCR)) {
            let data = this.state.dataOCR;

            let page = new Array(this.state.dataOCR.documentPages).fill(undefined).map(() => {
                return {"element": []};
            });

            page = Object.keys(data.pairs).reduce((memo, dbGuid) => {
                memo[data.pairs[dbGuid].specific.pageIndex].element.push(data.pairs[dbGuid]);

                return memo;
            }, page);

            const params = {
                "document": {
                    "page": page
                },
                "property": {
                    "DPI": data.snapSpecific.DPI,
                    "specific": data.snapSpecific
                }
            };

            axios.post(
                Env.getOcrApiUrl('ocr/template', {}),
                params,
                {
                    headers: {
                        Authorization: `Bearer ${getJWT().access_token}`,
                    }
                }
            ).then((response) => {
                console.log(response)
            }).catch((error) => {
                    console.log('error', error);
                }
            );

            this.setState({
                dataOCR: {}
            });
        }
    }

    handleSubmitCarrierShareClick = () => {
        this.props.dispatch(updateDialogResource({
            user: getUser(),
            params: {
                'CarrierAllowShare': this.state.carrierLinkOptions[0].value ? 1 : 0,
                'CarrierAllowEdit': this.state.carrierLinkOptions[1].value ? 1 : 0,
                'CarrierAllowLocation': this.state.carrierLinkOptions[2].value ? 1 : 0,
                'CarrierAllowNote': this.state.carrierLinkOptions[3].value ? 1 : 0,
                'CarrierAllowUpload': this.state.carrierLinkOptions[4].value ? 1 : 0,
                'LoadID': this.props.match.params.id
            },
            errorMessage: true,
            successMessage: this.props.translate('message.carrier_share_options_updated'),
            resource: Resources.LoadShareCarrierSettings,
        }));
    }

    handleSubmitCustomerShareClick = () => {
        this.props.dispatch(updateDialogResource({
            user: getUser(),
            params: {
                'AllowShare': this.state.linkOptions[0].value ? 1 : 0,
                'AllowChat': this.state.linkOptions[1].value ? 1 : 0,
                'AllowTracks': this.state.linkOptions[2].value ? 1 : 0,
                'AllowAssets': this.state.linkOptions[3].value ? 1 : 0,
                'LoadID': this.props.match.params.id
            },
            errorMessage: true,
            successMessage: false,
            resource: Resources.LoadShareSettings,
        }));
    }

    /** UI Events
     ================================================================= */
    handleCommodityFormSubmitClick = (params, fields, selects) => {
        if (params) {

            params = Object.assign({}, params, {
                LoadCommodityPickup: selects.LoadCommodityPickupID && selects.LoadCommodityPickupID[params.LoadCommodityPickupID],
                LoadCommodityDestination: selects.LoadCommodityPickupID && selects?.LoadCommodityDestinationID?.[params.LoadCommodityDestinationID],

                PickupAddressName: selects.LoadCommodityPickup && selects.LoadCommodityPickup[params.LoadCommodityPickupID],
                DestinationAddressName: selects.LoadCommodityPickup && selects?.LoadCommodityDestination?.[params.LoadCommodityDestinationID],

                CommodityID: params.CommodityTemplates
            })

            params.saveCommodity = !params.CommodityID && !!params.updateCommodity ? 1 : 0;
            params.updateCommodity = !!params.CommodityID && !!params.updateCommodity ? 1 : 0;

            let CommodityData = this.state.CommodityData

            if (!!this.state.selectedItemIndex || this.state.selectedItemIndex === 0) {
                CommodityData[this.state.selectedItemIndex] = params
            } else {
                CommodityData.push(params)
            }

            this.setState({
                CommodityData
            }, () => {
                this.toggleCommodityFormModal()
                this.props.setDirty()
            })
        }
    }

    handleCompleteStopByClick = (stopBy, i) => {
        let stopData = FieldsManager.getFieldKeyValues(stopBy);
        let stopsCombinedClone = cloneDeep(this.state.stopsCombined);

        if (i !== undefined) {
            let stopIndex = 0;
            stopsCombinedClone.map((it, updateIndex) => {
                it.IsSelected = updateIndex === i;
                stopIndex = it.index;

                return it
            })

            stopData = this.state.stopBys[stopIndex];
        }

        this.setState({
            selectedStop: stopData,
            stopsCombined: stopsCombinedClone,
            isCompleteStopByModalOpen: true
        });
    }

    handleCompleteStopClick = (stop, i) => {
        let stopData = FieldsManager.getFieldKeyValues(stop);
        let stopsCombinedClone = cloneDeep(this.state.stopsCombined);

        if (i !== undefined) {
            let stopIndex = 0;
            stopsCombinedClone.map((it, updateIndex) => {
                it.IsSelected = updateIndex === i;
                stopIndex = it.index;

                return it
            })

            stopData = this.state.stops[stopIndex];
        }

        this.setState({
            selectedStop: stopData,
            stopsCombined: stopsCombinedClone,
            isCompleteStopModalOpen: true
        },);
    }

    handleCloseCompleteStopModal = () => {
        this.setState({
            isCompleteStopModalOpen: false
        })
    }

    handleCloseCompleteStopByModal = () => {
        this.setState({
            isCompleteStopByModalOpen: false
        })
    }

    handleUpdateStopClick = (stop) => {
        let stopData = FieldsManager.getFieldKeyValues(stop)

        this.setState({
            selectedStop: stopData,
            postUpdatePingLocation: {},
            isUpdateStopModalOpen: true
        })
    }

    handleCloseUpdateStopModal = () => {
        this.setState({
            isUpdateStopModalOpen: false
        })
    }

    handlePingUpdateClick = () => {
        this.setState({
            isPingUpdateModalOpen: true,
            postUpdatePingLocation: {}
        })
    }

    handleClosePingUpdateModalClick = () => {
        this.setState({
            isPingUpdateModalOpen: false,
        })
    }

    submitPingUpdateLocation = (locationData, tabsData) => {
        const selectedTab = tabsData.find(it => it.isCurrent);

        if (selectedTab.name === "select_location") {
            this.setState({
                isAxiosRequesting: true,
                isPingUpdateModalOpen: false,
            }, () => {
                axios.get(Env.getApiUrl('api/load/stops/eta', {
                    startLat: locationData.Latitude?.value,
                    startLon: locationData.Longitude?.value,
                    destLat: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Latitude,
                    destLon: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Longitude
                }), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    this.setState({
                        postUpdatePingLocation: {
                            address: getFullAddressName(locationData.LocationID.value.metadata),
                            LocationID: locationData.LocationID.value.value,
                            lat: locationData.Latitude?.value,
                            lng: locationData.Longitude?.value,
                            eta: response.data.data.last_detected_address + " - " +
                                "ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                        },
                        isAxiosRequesting: false
                    });
                });
            });
        }

        if (selectedTab.name === "enter_manually") {
            const GooglePlacesString = [locationData.AddressName.value, locationData.CityName.value, this.states[locationData.StateID.value], locationData.PostalCode.value, this.countries[locationData.CountryID.value]].filter(it => !!it).join(", ");

            this.setState({
                isAxiosRequesting: true,
                isPingUpdateModalOpen: false
            }, () => {
                axios.get(Env.getApiUrl('api/load/stops/eta', {
                    startLat: locationData.Latitude?.value,
                    startLon: locationData.Longitude?.value,
                    destLat: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Latitude,
                    destLon: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Longitude
                }), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    this.setState({
                        postUpdatePingLocation: {
                            address: GooglePlacesString,
                            lat: locationData.Latitude?.value,
                            lng: locationData.Longitude?.value,
                            eta: response.data.data.last_detected_address + " - " +
                                "ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                        },
                        isAxiosRequesting: false
                    });
                });
            });
        }

        if (selectedTab.name === "select_on_map") {
            const lat = locationData.Latitude.value;
            const lng = locationData.Longitude.value;

            this.setState({
                isAxiosRequesting: true,
                isPingUpdateModalOpen: false
            }, () => {
                axios.get(Env.getApiUrl('api/user/geocode', {Latitude: lat, Longitude: lng}), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    let address = response.data.data;

                    axios.get(Env.getApiUrl('api/load/stops/eta', {
                        startLat: lat,
                        startLon: lng,
                        destLat: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Latitude,
                        destLon: this.state.stopsCombined.find(it => !!it.IsSelected).metadata?.Longitude
                    }), {
                        headers: {
                            'Authorization': 'Bearer ' + getJWT().access_token
                        }
                    }).then((response) => {
                        this.setState({
                            postUpdatePingLocation: {
                                address: address?.FormatedAddress,
                                lat: lat,
                                lng: lng,
                                eta: response.data?.data?.last_detected_address + " - " +
                                    " ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                            },
                            isAxiosRequesting: false
                        });
                    });
                });
            })
        }

        const latVal = locationData.Latitude?.value ? locationData.Latitude?.value : locationData.LocationID?.value?.metadata?.Latitude;
        const longVal = locationData.Longitude?.value ? locationData.Longitude?.value : locationData.LocationID?.value?.metadata?.Longitude;

        if (latVal && longVal) {
            axios.get(Env.getApiUrl('api/user/timezone', {
                Latitude: latVal,
                Longitude: longVal,
                Timestamp: getCurrentTimeSeconds()
            }), {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }).then((response) => {
                const zone = response.status === 200 ? response.data.data.timeZoneId : null
                this.setState({pingZone: zone})
            })
        }
    }

    openJobsListingModal = () => {
        this.setState({
            isJobsListingModalOpen: true,
            isJobsListingLoading: true
        }, async () => {
            try {
                const jobsListingResponse = await this.getUserJobsListing()
                const jobListingTableData = []

                if (!jobsListingResponse) {
                    this.setState({
                        isJobsListingLoading: false,
                        jobListingData: []
                    })
                    return
                }

                for (const [key, value] of Object.entries(jobsListingResponse)) {
                    const item = {
                        Document: value['pdf-user'],
                        Status: getJobStatus(value['state']),
                        Started: value['stamp'],
                        Completed: value['done'],
                        UUID: key
                    }
                    jobListingTableData.push(item)
                }

                this.setState({
                    jobListingData: jobListingTableData,
                })
            } catch (err) {
                this.setState({
                    jobListingData: []
                })
            } finally {
                this.setState({
                    isJobsListingLoading: false
                })
            }
        })
    }

    getUserJobsListing = async () => {
        const response = await axios.get(
            Env.getOcrApiUrl('ocr/manager/situation'),
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
            }
        )

        return response.data
    }

    toggleNeedsAttention = (value) => {
        this.props.dispatch(updateHelpResource({
            user: LocalStorage.get('user'),
            params: {
                Marked: value ? 1 : 0,
                IDs: [this.props.match.params.id]
            },
            resource: Resources.LoadMark,
            query: {
                id: this.props.match.params.id,
            },
            errorMessage: true, successMessage: this.props.translate(`text.load_${value ? 'marked' : 'unmarked'}`)
        }))
    }

    handleInputChange = (name, value, refIndex = null) => {
        let shouldFetchAvailableCredit = false;
        let fields = this.state.fields
        let CustomerLoadSetting = this.state.CustomerLoadSetting;

        let CustomerCreditLimit = this.state.CustomerCreditLimit;
        let CustomerAvailableCredit = this.state.CustomerAvailableCredit;
        let CustomerAllowCreditLimitOverride = this.state.CustomerAllowCreditLimitOverride;

        fields = FieldsManager.updateField(fields, name, value);

        let CustomerContactsList = this.state.CustomerContactsList;

        if (refIndex) {
            if (name === 'OfficeID' && this.props.lineOfBusiness === 'carrier') {
                refIndex = 'driver'
            }

            this.fieldsRef[refIndex] && this.fieldsRef[refIndex].focus()
        }

        if ('TrailerID' === name) {
            fields.TrailerType.type = value?.value ? "text" : "hidden";
            fields = FieldsManager.updateField(fields, 'TrailerType', getProp(value?.metadata, 'TrailerType', this.props.translate('text.not_specified')))
        }

        if ('SecondTrailerID' === name) {
            fields.SecondTrailerType.type = value?.value ? "text" : "hidden";
            fields = FieldsManager.updateField(fields, 'SecondTrailerType', getProp(value?.metadata, 'TrailerType', this.props.translate('text.not_specified')))
        }

        if ('ThirdTrailerID' === name) {
            fields.ThirdTrailerType.type = value?.value ? "text" : "hidden";
            fields = FieldsManager.updateField(fields, 'ThirdTrailerType', getProp(value?.metadata, 'TrailerType', this.props.translate('text.not_specified')))
        }

        if ("CustomerID" === name && value) {
            CustomerContactsList = {};
            CustomerLoadSetting = this.getCustomerLoadSetting(value?.metadata?.CustomerLoadSetting);
            fields.ContactGroupID.value = {
                value: value?.metadata?.ContactGroupID,
                label: value?.metadata?.ContactGroup
            }
            if (value?.metadata?.OfficeID) {
                fields.OfficeID.value = {
                    value: value?.metadata?.OfficeID,
                    label: value?.metadata?.Office
                }
            }

            if (value?.metadata?.CreditLimit > 1 && this.isCreateMode) {
                shouldFetchAvailableCredit = true;
            } else {
                CustomerCreditLimit = undefined;
                CustomerAvailableCredit = undefined;
                CustomerAllowCreditLimitOverride = undefined;
            }
        }

        if ("CarrierID" === name) {
            fields.DriverNameTxt.value = "";
            fields.DriverNameTxt.type = value ? "creatable-select-search" : "text"
            fields.DriverNameTxt.disabled = !value
            fields.DriverNameTxt.props.key = Number(fields.DriverNameTxt.props.key) + 1;
            fields.DriverNameTxt.metadata.fieldOptions = value
                ? () => (
                    <FieldOptions
                        options={[
                            {
                                icon: PlusIcon,
                                onClick: () => this.toggleCreateCarrierDriverDialog(),
                                isVisible: true
                            }]}
                    />
                )
                : null;

            fields.CoDriverNameTxt.value = "";
            fields.CoDriverNameTxt.type = value ? "creatable-select-search" : "text"
            fields.CoDriverNameTxt.disabled = !value
            fields.CoDriverNameTxt.props.key = Number(fields.DriverNameTxt.props.key) + 1;
            fields.CoDriverNameTxt.metadata.fieldOptions = value
                ? () => (
                    <FieldOptions
                        options={[
                            {
                                icon: PlusIcon,
                                onClick: () => this.toggleCreateCarrierDriverDialog("", true),
                                isVisible: true
                            }]}
                    />
                )
                : null;
        }

        if ("DriverNameTxt" === name) {
            fields.DriverCellTxt.value = value?.metadata?.PhoneNumber ?? "";
        }

        if ("CoDriverNameTxt" === name) {
            fields.CoDriverCellTxt.value = value?.metadata?.PhoneNumber ?? "";
        }
        if ("PendingTasks" === name) {
            fields.TaskInterval.validate = value ? ['empty'] : ['']
            fields.TaskName.validate = value ? ['empty'] : ['']
            fields.TaskGroupID.validate = value ? ['empty'] : ['']
        }

        fields[name].errorMessage = "";

        if ("CustomerReferenceNumber" === name) {
            clearTimeout(this.refCustomerRefChangeTimeout);

            this.refCustomerRefChangeTimeout = setTimeout(() => {
                this.checkForCustomerReferenceDuplicate();
            }, 3000);
        }

        this.setState({
            validationMessage: false,
            fields: fields,
            CustomerContactsList: CustomerContactsList,
            CustomerLoadSetting: CustomerLoadSetting,
            CustomerCreditLimit: CustomerCreditLimit,
            CustomerAvailableCredit: CustomerAvailableCredit,
            CustomerAllowCreditLimitOverride: CustomerAllowCreditLimitOverride,
        }, () => {
            this.props.isStateDirty ? null : this.props.setDirty();

            if (shouldFetchAvailableCredit && value?.metadata?.OrganizationID) {
                this.props.dispatch(getDialogInfoResource({
                    user: LocalStorage.get('user'),
                    query: {
                        OrganizationID: value.metadata.OrganizationID
                    },
                    resource: Resources.OrganizationsQuick
                }))
            }
        })
    }

    handleInputCommodityStopChange = (name, value, label, commodityIndex) => {

        const CommodityData = cloneDeep(this.state.CommodityData);
        CommodityData[commodityIndex][name] = value;
        CommodityData[commodityIndex][name.replace("ID", "")] = label;
        CommodityData[commodityIndex][name + "Error"] = "";

        let selectedStop = this.state.stops.find(it => it.LoadStopID.value === Number(value));
        selectedStop = selectedStop?.LocationData?.value;

        if (name === 'LoadCommodityPickupID') {
            CommodityData[commodityIndex].LoadCommodityPickupLatitude = selectedStop?.Latitude;
            CommodityData[commodityIndex].LoadCommodityPickupLongitude = selectedStop?.Longitude;
            CommodityData[commodityIndex].LoadCommodityPickupIDError = "";
        }

        if (name === 'LoadCommodityDestinationID') {
            CommodityData[commodityIndex].LoadCommodityDestinationLatitude = selectedStop?.Latitude;
            CommodityData[commodityIndex].LoadCommodityDestinationLongitude = selectedStop?.Longitude;
            CommodityData[commodityIndex].LoadCommodityDestinationIDError = "";
        }

        this.setState({
            CommodityData: CommodityData
        }, () => this.props.isStateDirty ? null : this.props.setDirty());
    }

    handleStopByInputChange = (name, value) => {
        let stopBys = cloneDeep(this.state.stopBys);
        let stopsCombined = this.state.stopsCombined;

        let stop = stopsCombined.find((it) => {
            return !!it.IsSelected
        });

        const selectedStopIndex = stop.index;

        stop = stopBys[selectedStopIndex];
        stop[name].errorMessage = "";

        if ("IsSwitchDispatch" === name) {
            stop.DriverID.value = "";
            stop.CoDriverID.value = "";
            stop.TruckID.value = "";
            stop.TrailerID.value = "";
        }

        if ("IsSwitchDispatch" === name) {
            stop.IsDroppingTrailer.value = "";
            stop.DriverID.validate = !value ? [] : ['empty'];
            stop.TruckID.validate = !value ? [] : ['empty'];
        }

        if ("IsDroppingTrailer" === name) {
            stop.IsSwitchDispatch.value = "";
            stop.PickingTrailerID.value = "";
            stop.PickingTrailerID.errorMessage = "";
            stop.PickingTrailerID.validate = [];
            stop.PickingTrailerID.errorMessage = "";
        }

        if ("IsPickingTrailer" === name) {
            stop.PickingTrailerID.validate = !value ? [] : ['empty'];
            stop.PickingTrailerID.errorMessage = "";
            stop.PickingTrailerID.value = "";
        }

        stopBys[selectedStopIndex] = FieldsManager.updateField(stop, name, value);

        this.setState({
            stopBys: stopBys,
            stopsCombined: stopsCombined
        }, () => this.props.isStateDirty ? null : this.props.setDirty());
    }

    handleStopInputChange = (name, value) => {
        let stopsClone = cloneDeep(this.state.stops);
        let stopsCombinedClone = this.state.stopsCombined;
        let commodityData;
        let hasUpdatedStopTypeWarning = false;
        let doesStopsNeedsUpdate = this.state.doesStopsNeedsUpdate;

        const selectedStop = this.state.stopsCombined.find(it => it.IsSelected);

        if (name === "StopID") {
            stopsClone[selectedStop.index].RequiresAppointment.value = value?.metadata?.RequiresAppointment ? 1 : 0;
        }

        if (name === "IsPickingTrailer") {
            stopsClone[selectedStop.index].PickingTrailerID.value = "";
            stopsClone[selectedStop.index].PickingTrailerID.ErrorMessage = "";
        }

        const {
            stops,
            stopsCombined
        } = processStopInputChange(name, value, stopsClone, stopsCombinedClone, !this.props.match.params.id)

        if (name === "StopID" && !!this.isEditMode) {
            // Show only if it has more than two stops on initial load
            if (getProp(this.props.resource, 'data.load/multistops', []).length > 1) {
                doesStopsNeedsUpdate = true;
            }
        }

        if ("StopType" === name) {
            commodityData = this.state.CommodityData.map(it => {
                if (it.LoadCommodityDestinationID == selectedStop?.LoadStopID?.value) {
                    it.LoadCommodityDestination = "";
                    it.LoadCommodityDestinationID = "";
                    it.LoadCommodityDestinationLatitude = "";
                    it.LoadCommodityDestinationLongitude = "";
                    it.LoadCommodityDestinationIDError = "";
                    it.DestinationCityName = "";
                    it.DestinationAddressName = "";
                    hasUpdatedStopTypeWarning = true;
                }

                if (it.LoadCommodityPickupID == selectedStop?.LoadStopID?.value) {
                    it.LoadCommodityPickup = "";
                    it.LoadCommodityPickupID = "";
                    it.LoadCommodityPickupLatitude = "";
                    it.LoadCommodityPickupLongitude = "";
                    it.LoadCommodityPickupIDError = "";
                    it.PickupCityName = "";
                    it.PickupAddressName = "";
                    hasUpdatedStopTypeWarning = true;
                }

                return it;
            })
        }

        this.setState({
            stops: stops,
            stopsCombined: stopsCombined,
            doesStopsNeedsUpdate: doesStopsNeedsUpdate,
            isSelectLocationDialogOpen: false,
            CommodityData: !commodityData ? this.state.CommodityData : commodityData,
            hasUpdatedStopTypeWarning
        }, () => this.props.isStateDirty ? null : this.props.setDirty());
    }

    handleStopAppointmentChange = (name, value, combinedIndex) => {
        let stops = this.state.stops;
        let stopsCombined = this.state.stopsCombined;

        let selectedStop = stopsCombined[combinedIndex];

        const selectedStopIndex = selectedStop.index;

        let stop = stops[selectedStopIndex];

        stop = FieldsManager.updateField(stop, name, value);
        stop.AptReferenceNumber.type = value ? "creatable" : "hidden";

        stops[selectedStopIndex] = stop;

        this.setState({
            stops: stops
        }, () => {
            !this.props.isStateDirty && this.props.setDirty();

            if (!stopsCombined[combinedIndex].IsSelected) {
                this.handleStopClick(combinedIndex)
            }
        });
    }

    handleReminderInputChange = (name, value) => {
        let fieldsClone = Object.assign({}, this.state.reminderFields);
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);

        if ('IsStopUpdateReminderOn' === name) {
            fieldsClone.StopUpdateReminderInternal.validate = value ? ['empty'] : [''];
        }

        this.setState({
            reminderFields: fieldsClone
        }, () => this.props.setDirty());
    }

    clearField = (fieldName) => {
        let fieldsUpdate = this.state.fields;

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, fieldName, "");
        fieldsUpdate[fieldName].type = "hidden";

        this.setState({
            fields: fieldsUpdate,
        }, () => this.props.setDirty());
    }

    handleStopClick = (selectedStopIndex) => {
        let stopsCombined = [...this.state.stopsCombined];

        stopsCombined.map((it, i) => {
            it.IsSelected = selectedStopIndex === i ? true : "";

            return it;
        })

        this.setState({
            stopsCombined: stopsCombined
        }, () => {
            this.focusFirstStopInput();
        })
    }

    handleCreateLoadStop = (name, value) => {
        let stops = cloneDeep(this.state.stops);
        let stopsCombined = this.state.stopsCombined;

        let stop = stopsCombined.find((stop) => {
            return !!stop.IsSelected;
        });

        let selectedIdx = stop.index;

        stop = stops[selectedIdx];

        stop.StopID.value = {"value": -1, "label": value};

        stops[selectedIdx] = stop;

        this.setState({
            stops
        }, () => this.toggleLocationDialog({LocationID: -1, LocationName: value}));
    }

    handleSelectStopClick = () => {
        this.setState({
            isSelectLocationDialogOpen: !this.state.isSelectLocationDialogOpen,
        }, () => {
            if (
                !this.state.isSelectLocationDialogOpen
                && this.state.stopsCombined[this.state.stopsCombined.length - 1]?.addressName === ""
                && this.state.stopsCombined.length > 2
            ) {
                this.handleDeleteLocationClick(this.state.stopsCombined.length - 1)
            }
        })
    }

    handleSwitchDispatchInputChange = (fields, name, value) => {
        fields.Notes.disabled = false;

        if ('Notes' !== name) {
            this.setState({
                splitDispatchBtnDisabled: false
            });
        }

        return FieldsManager.updateField(fields, name, value)
    }

    hideConfirmDialog = () => {
        this.props.dispatch(hideConfirmDialog())
    }

    setLocations = (locations) => {
        this.setState({
            locationFields: locations
        })
    }

    handleStopDragStart = (i) => {
        this.setState({
            draggedStopIndex: i,
            draggedHasChanges: false
        })
    }

    handleStopDragEnd = () => {
        const draggedHasIncludedInMileageChanges = this.state.draggedHasIncludedInMileageChanges;
        const draggedHasChanges = this.state.draggedHasChanges;
        this.setState({
            draggedStopIndex: undefined,
            draggedHasChanges: false
        }, () => this.handleDragLocationChange(draggedHasChanges, draggedHasIncludedInMileageChanges))
    }

    handleStopDragEnter = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        let doesStopsNeedsUpdate = this.state.doesStopsNeedsUpdate;

        let draggedColumn = stopsCombined.splice(this.state.draggedStopIndex, 1);
        stopsCombined.splice(index, 0, draggedColumn[0]);

        if (getProp(this.props.resource, 'data.load/multistops', []).length > 1) {
            doesStopsNeedsUpdate = true;
        }

        let draggedHasIncludedInMileageChanges = false;
        stopsCombined.map(it => { // Reset IncludedInMileage for all stops
            if (it.IncludeInMileage === 0) {
                draggedHasIncludedInMileageChanges = true;
            }

            it.IncludeInMileage = 1
            return it;
        });

        this.setState({
            stopsCombined: stopsCombined,
            draggedStopIndex: index,
            doesStopsNeedsUpdate: doesStopsNeedsUpdate,
            draggedHasChanges: true,
            draggedHasIncludedInMileageChanges
        }, () => this.props.setDirty());
    }

    handleDragLocationChange = (hasChanges, hasIncludedInMileageChanges) => {
        if (!hasChanges) {
            return false
        }

        let hasIncludedInMileageResetMessage = this.state.hasIncludedInMileageResetMessage;

        if (hasIncludedInMileageChanges) {
            hasIncludedInMileageResetMessage = true;
        }

        let stops = cloneDeep(this.state.stops);
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let CommodityData = this.state.CommodityData;

        let lastStopIndex = stops.length - 1;

        let stopIndex = 0;
        stopsCombined = stopsCombined.map((it) => {
            if (it.StopType) {
                if (stopIndex === 0) {
                    it.StopType = STOP_TYPE_PICKUP;
                    stops[it.index].StopType.value = STOP_TYPE_PICKUP;
                }
                if (stopIndex === lastStopIndex) {
                    it.StopType = STOP_TYPE_DELIVERY;
                    stops[it.index].StopType.value = STOP_TYPE_DELIVERY;
                }

                stopIndex++;
            }

            return it;
        });

        CommodityData = CommodityData.map(it => {
            it.LoadCommodityDestination = "";
            it.LoadCommodityDestinationID = "";
            it.LoadCommodityDestinationLatitude = "";
            it.LoadCommodityDestinationLongitude = "";
            it.LoadCommodityDestinationIDError = "";

            it.LoadCommodityPickup = "";
            it.LoadCommodityPickupID = "";
            it.LoadCommodityPickupLatitude = "";
            it.LoadCommodityPickupLongitude = "";
            it.LoadCommodityPickupIDError = "";
            return it;
        });

        this.setState({
            hasChangeStopOrderWarning: true,
            CommodityData,
            hasIncludedInMileageResetMessage,
            stops,
            stopsCombined
        })
    }

    handleAddLocationClick = () => {
        let stops = cloneDeep(this.state.stops);
        let currentCreatedStopOrder = this.state.currentCreatedStopOrder - 1;
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        stopsCombined.map(it => {
            it.IsSelected = "";
            return it
        });

        stops.push(this.getStopFields({
            StopType: STOP_TYPE_DELIVERY,
            LoadStopID: currentCreatedStopOrder
        }, this.props.translate));

        stops = this.addStopFieldsActions(stops);

        stopsCombined.push(getCombinedStopObject({
            StopType: STOP_TYPE_DELIVERY,
            IsSelected: true,
        }, undefined, this.state.stops, this.state.stopBys));

        this.setState({
            stops,
            stopsCombined,
            currentCreatedStopOrder: currentCreatedStopOrder,
            isSelectLocationDialogOpen: true
        }, () => {
            this.updateRequiredFields();
        });
    }

    handleAddStopByClick = () => {
        let stopBys = cloneDeep(this.state.stopBys);
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        stopsCombined.map(it => {
            it.IsSelected = "";
            return it
        })

        stopBys.push(getStopByFields({}, this.props.translate));
        stopsCombined.push(getCombinedStopObject({
            StopType: STOP_TYPE_STOP_BY,
            IsSelected: true
        }, undefined, this.state.stops, this.state.stopBys));

        this.setState({
            stopBys,
            stopsCombined,
            StopByItem: undefined
        }, () => this.toggleSelectLocationDialog(undefined, true));
    }

    handleDeleteLocationClick = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stops = cloneDeep(this.state.stops);

        const removedStop = stopsCombined.splice(index, 1)[0];
        stops.splice(removedStop.index, 1);

        let commodityData = this.state.CommodityData;

        let commodityPickupIndex = -1;
        commodityData.find((it, commodityIndex) => {
            if (Number(removedStop?.metadata?.LoadStopID) === Number(it.LoadCommodityPickupID)) {
                commodityPickupIndex = commodityIndex;
                return true;
            }

            return false;
        });

        if (commodityPickupIndex > -1) {
            commodityData[commodityPickupIndex].LoadCommodityPickup = "";
            commodityData[commodityPickupIndex].LoadCommodityPickupID = "";
            commodityData[commodityPickupIndex].LoadCommodityPickupLatitude = "";
            commodityData[commodityPickupIndex].LoadCommodityPickupLongitude = "";
            commodityData[commodityPickupIndex].LoadCommodityPickupIDError = "";
        }

        let commodityDeliveryIndex = -1;

        commodityData.find((it, commodityIndex) => {
            if (Number(removedStop?.metadata?.LoadStopID) === Number(it.LoadCommodityDestinationID)) {
                commodityDeliveryIndex = commodityIndex;
                return true;
            }

            return false;
        });

        if (commodityDeliveryIndex > -1) {
            commodityData[commodityDeliveryIndex].LoadCommodityDestination = "";
            commodityData[commodityDeliveryIndex].LoadCommodityDestinationID = "";
            commodityData[commodityDeliveryIndex].LoadCommodityDestinationLatitude = "";
            commodityData[commodityDeliveryIndex].LoadCommodityDestinationLongitude = "";
            commodityData[commodityDeliveryIndex].LoadCommodityDestinationIDError = "";
        }

        if (index === stopsCombined.length) {
            stopsCombined[index - 1].IsSelected = true;
        } else {
            stopsCombined[index].IsSelected = true;
        }

        let newIndex = 0;
        stopsCombined.map((it) => {
            if (it.StopType !== STOP_TYPE_STOP_BY) {
                it.index = newIndex;
                newIndex = newIndex + 1;
            }

            return it;
        });

        this.setState({
            areStepsActive: false,
            stops: stops,
            stopsCombined: stopsCombined,
            CommodityData: commodityData
        }, () => this.props.setDirty())
    }

    handleDeleteStopByClick = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stopBys = cloneDeep(this.state.stopBys);

        const removeStopBy = stopsCombined.splice(index, 1)[0];

        stopBys.splice(removeStopBy.index, 1);

        if (index === stopsCombined.length) {
            stopsCombined[index - 1].IsSelected = true;
        } else {
            stopsCombined[index].IsSelected = true;
        }

        let newIndex = 0;
        stopsCombined.map((it) => {
            if (it.StopType === STOP_TYPE_STOP_BY) {
                it.index = newIndex;
                newIndex = newIndex + 1;
            }

            return it;
        });

        this.setState({
            stopBys: stopBys,
            stopsCombined: stopsCombined
        }, () => this.props.setDirty())
    }

    handleCustomerReferenceNumberBlur = () => {
        if (this.isCreateMode) {
            this.checkForCustomerReferenceDuplicate();
        }
    }

    toggleSelectLocationDialog = (StopByItem) => {
        let StopByItemUpdate = StopByItem ?? this.state.StopByItem;

        if (StopByItem) {
            StopByItemUpdate = FieldsManager.getFieldKeyValues(StopByItem);
            StopByItemUpdate.Location = StopByItem.LocationID?.value?.label
            StopByItemUpdate.Country = StopByItem.CountryID?.value?.label
        }

        this.setState({
            isSelectLocationModalOpen: !this.state.isSelectLocationModalOpen,
            StopByItem: StopByItemUpdate
        })
    }

    closeSelectLocationDialog = () => {
        let deleteStopByIndex = -1;
        let deleteStopsCombinedIndex = undefined;
        let stopsCombined = this.state.stopsCombined;
        let stopBys = this.state.stopBys;

        let selectedCombinedStop = stopsCombined.find((it, i) => {
            deleteStopsCombinedIndex = i
            return it.IsSelected;
        })

        let selectedStopBy = stopBys[selectedCombinedStop.index];
        // Check if location is selected by selecting 1.LocationID, 2.manually or by 3.clicking on a map
        if (!!selectedStopBy && !selectedStopBy.LocationID.value && !selectedStopBy.AddressName.value && !selectedStopBy.Latitude.value) {
            deleteStopByIndex = selectedCombinedStop.index
        }

        if (deleteStopByIndex > -1) {
            stopBys.splice(deleteStopByIndex, 1);
            stopsCombined.splice(deleteStopsCombinedIndex, 1);

            stopsCombined[stopsCombined.length - 1].IsSelected = true;

            this.setState({
                stopBys: stopBys,
                stopsCombined: stopsCombined,
                isSelectLocationModalOpen: false,
            })
        } else {
            this.setState({
                isSelectLocationModalOpen: false
            })
        }
    }

    toggleCustomerSettingsDialog = () => {
        this.setState({
            isCustomerSettingsModalOpen: !this.state.isCustomerSettingsModalOpen
        })
    }

    handleShowCustomerDetailsClick = () => {
        let customerData = this.state.fields.CustomerID.value.metadata;
        customerData.CustomerID = this.state.fields.CustomerID.value.value;

        this.props.dispatch(showModal('ViewCustomerCard', customerData))
    }

    // Rename to Note to Field
    handleAddNoteClick = (noteFieldName, type = "textarea") => {
        let fieldsUpdate = this.state.fields;

        fieldsUpdate[noteFieldName].type = type;

        if ("CoBrokerID" === noteFieldName) {
            fieldsUpdate["IsCoBroker"].value = 1;
        }

        this.setState({
            fields: fieldsUpdate
        });
    }

    handleDeleteAccessorialClick = (it, i) => {
        this.setState({
            confirmModalAction: () => this.deleteAccessorial(i),
            confirmText: 'Are you sure you want to delete `' + it.Accessorial + '`?',
            confirmBtnLabel: this.props.translate('text.Confirm')
        })
    }

    handleSelectLocationClick = (fields, tabs) => {
        const selectedTab = tabs.find(it => it.isCurrent);
        let stopBys = this.state.stopBys
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stopsCombinedSelectedIndex = 0;

        let selectedStopByIndex = stopsCombined.find((it, i) => {
            stopsCombinedSelectedIndex = i;
            return it.IsSelected;
        }).index;

        let fieldUpdate = stopBys[selectedStopByIndex];

        fieldUpdate = clearLocationFields(fieldUpdate);

        if (selectedTab.name === "select_location") {
            fieldUpdate.LocationID.value = fields.LocationID.value;
            stopsCombined[stopsCombinedSelectedIndex].metadata = fieldUpdate.LocationID.value.metadata;
        }

        if (selectedTab.name === "enter_manually") {
            //const GooglePlacesString = [fields.AddressName.value, fields.CityName.value, this.states[fields.StateID.value], fields.PostalCode.value, this.countries[fields.CountryID.value]].filter(it => !!it).join(", ");
            fieldUpdate.AddressName.value = fields.AddressName.value;
            fieldUpdate.CityName.value = fields.CityName.value;
            fieldUpdate.StateID.value = fields.StateID.value;
            fieldUpdate.PostalCode.value = fields.PostalCode.value;
            fieldUpdate.CountryID.value = fields.CountryID.value;

            // stopsCombined[stopsCombinedSelectedIndex].StopName = GooglePlacesString;
            // stopsCombined[stopsCombinedSelectedIndex].addressName = "";

            stopsCombined[stopsCombinedSelectedIndex].metadata = FieldsManager.getFieldKeyValues(fieldUpdate);
        }

        if (selectedTab.name === "select_on_map") {
            const lat = fields.Latitude.value;
            const lng = fields.Longitude.value;

            this.setState({
                isAxiosRequesting: true
            }, () => {
                axios.get(Env.getApiUrl('api/user/geocode', {Latitude: lat, Longitude: lng}), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    let address = response.data.data;

                    stopsCombined[stopsCombinedSelectedIndex].StopName = address['FormatedAddress'];
                    stopsCombined[stopsCombinedSelectedIndex].addressName = "";
                    stopsCombined[stopsCombinedSelectedIndex].metadata.AddressName = address['FormatedAddress'];

                    fieldUpdate.Latitude.value = lat;
                    fieldUpdate.Longitude.value = lng;
                    stopBys[selectedStopByIndex] = fieldUpdate;

                    stopsCombined[stopsCombinedSelectedIndex].hasLocation = true;

                    this.setState({
                        stopBys,
                        stopsCombined,
                        isSelectLocationModalOpen: false,
                        isAxiosRequesting: false

                    }, () => this.props.setDirty());

                });
            })


        } else {
            stopBys[selectedStopByIndex] = fieldUpdate;
            stopsCombined[stopsCombinedSelectedIndex].hasLocation = true;

            this.setState({
                stopBys,
                stopsCombined,
                isSelectLocationModalOpen: false,
                doesStopsNeedsUpdate: true

            }, () => this.props.setDirty());
        }
    }

    handleToggleContactsModal = () => {
        this.setState({
            addAssigneeModal: !this.state.addAssigneeModal
        })
    }

    handleBadgeCloseClick = (id, state) => {
        this.setState({[state]: this.state[state].filter((contact) => id !== contact.ContactID)})
    }

    deleteAccessorial = (i) => {
        const AccessorialData = cloneDeep(this.state.AccessorialData)
        AccessorialData.splice(i, 1)

        this.setState({
            AccessorialData,
            confirmModalAction: false,
        })
    }

    toggleHistoryDialog = () => {
        this.setState({
            viewHistoryDialog: !this.state.viewHistoryDialog
        })
    }

    openScanDialog = () => {
        if (this.state.isOcrDialogMinimized) {
            this.setState({
                isOcrDialogMinimized: false
            });
        } else {
            this.setState({
                isOCRDialogOpen: true
            });
        }
    }

    closeScanDialog = () => {
        this.setState({
            isOCRDialogOpen: false,
            isOcrDialogMinimized: false,
            OCRDropzoneDocument: null,
            hashUUID: null
        });
    }

    handleOpenOCRTableAction = (item) => {
        this.setState({
            isJobsListingModalOpen: false,
            hashUUID: item.UUID
        }, () => this.openScanDialog())
    }

    openDriverNameTxtListDialog = (fieldName) => {
        this.setState({
            isDriverNameTxtDialogOpen: true,
            selectedField: fieldName
        });
    }

    closeDriverNameTxtListDialog = () => {
        this.setState({
            isDriverNameTxtDialogOpen: false,
        })
    }

    removeContentFragmentsFromTemplate = (pair) => {
        for (const key in pair.specific) {
            if (key.startsWith('content') || key.startsWith('fragment')) {
                delete pair.specific[key]
            }
        }

        return pair
    }

    handleSaveOCRClick = (data, pairs, snapSpecific, documentPages, createdLocations, createLoadAfterSubmission) => {
        let stopData = Object.values(getProp(data, 'load/multistops', {}));
        stopData = stopData.filter((stop) => !!stop['StopID'])

        // Fill in created locations
        let loadStopID = -1000;
        stopData = stopData.map((stop, index) => {
            if (stop.StopID === "-1" && Object.keys(createdLocations).length) {
                stop.StopID = createdLocations[stop.Stop];
            }

            if (stop.StopTime && stop.StopTime.indexOf('m') !== -1) {
                stop.StopTime = moment(stop.StopTime, "hh:mma").format("HH:mm")
            }

            if (stop.StopEndTime && stop.StopEndTime.indexOf('m') !== -1) {
                stop.StopEndTime = moment(stop.StopEndTime, "hh:mma").format("HH:mm")
            }

            stop.CountryName = "USA"
            stop.LocationData = Object.assign({}, stop);
            stop.LoadStopID = loadStopID;
            loadStopID = loadStopID - 1;

            if (!stop.StopType) {
                stop.StopType = STOP_TYPE_PICKUP
                if (stopData.length - 1 == index) {
                    stop.StopType = STOP_TYPE_DELIVERY
                }
            }

            return stop;
        })

        if (stopData.length === 1) {
            stopData.push({
                "StopType": 5,
                "CountryName": "USA",
                "LoadStopID": -1001
            })
        }

        let emptyCommodity = { // temp variable, this will be removed
            "CommodityDescription": "",
            "CommodityID": "",
            "CommodityReferenceNumber": "",
            "CommodityTemplates": "",
            "CommonChemical": "",
            "CountPallets": "",
            "CountPieces": "",
            "EmergencyContact": "",
            "EmergencyContactName": "",
            "Exch": 0,
            "HAWB": "",
            "Hazmat": 0,
            "HazmatClassID": "",
            "HazmatCode": "",
            "Height": "",
            "IsLTL": "",
            "Length": "",
            "MAWB": "",
            "MeasureUnitID": 1,
            "PackingGroupID": "",
            "ProductCode": "",
            "saveCommodity": 0,
            "Stackable": 0,
            "Temp": "",
            "Units": "",
            "UnitTypeID": "",
            "updateCommodity": 0,
            "Weight": "",
            "WeightUnit": 1,
            "Width": ""
        }

        let CommodityData = Object.values(data["load/commodities"]).map((it) => {
            it = Object.assign({}, emptyCommodity, it);
            it.ProductName = it.ProductName ? it.ProductName : 'FAK';
            if (it.Weight) {
                it.Weight = parseFloat(it.Weight.replace(/,/g, ''))
            }

            if (it.UnloadFromLocation) {
                const pickupData = stopData[it.UnloadFromLocation]
                it.CommodityPickupLoadStopID = pickupData?.["LoadStopID"]
                it.CommodityPickupLocationName = pickupData?.["LocationName"]
            }

            const destinationData = stopData[it.ConnectedLocationIndex]
            it.CommodityDestinationLoadStopID = destinationData?.["LoadStopID"]
            it.CommodityDestinationLocationName = destinationData?.["LocationName"]

            if (it["CommodityPickupLoadStopID"]) {
                it["LoadCommodityPickup"] = it["CommodityPickupLocationName"]
                it["LoadCommodityPickupID"] = it["CommodityPickupLoadStopID"]
            }

            if (it["CommodityDestinationLoadStopID"]) {
                it["LoadCommodityDestination"] = it["CommodityDestinationLocationName"]
                it["LoadCommodityDestinationID"] = it["CommodityDestinationLoadStopID"]
            }

            return it;
        });

        data = Object.assign({}, data);

        delete data["load/multistops"];
        delete data["load/commodities"];

        let dataUpdate = data;

        const fieldsUpdate = this.getFields(null, dataUpdate);

        if (data['CustomerIDMetadata']) {
            fieldsUpdate['CustomerID'].value.metadata = data['CustomerIDMetadata']
            fieldsUpdate['ContactGroupID'].value = {
                value: data['CustomerIDMetadata'].ContactGroupID,
                label: data['CustomerIDMetadata'].ContactGroup
            }
            this.handleInputChange('CustomerID', fieldsUpdate['CustomerID'].value)
        }

        let stops = generateStops(stopData, this.props.translate);
        stops = this.addStopFieldsActions(stops);

        // let stopBys = this.generateStopBys();
        let mergedStops = [];

        if (stops.length) {
            mergedStops = mergeStops(
                stopData,
                []
            );
        }

        Object.values(pairs).forEach((pair) => this.removeContentFragmentsFromTemplate(pair))

        this.props.setDirty();
        this.setState({
            fields: fieldsUpdate,
            stops,
            stopsCombined: mergedStops,
            CommodityData,
            dataOCR: {
                pairs,
                snapSpecific,
                documentPages
            },
            loadInfoLocationsKey: this.state.loadInfoLocationsKey + 1,
            isOCRDialogOpen: false,
        }, () => {
            this.submitOCRData();
            this.updateRequiredFields();
            this.checkForCustomerReferenceDuplicate();
            if (createLoadAfterSubmission) {
                this.submit()
            }
        });
    }

    toggleDriverSelectDialog = (selectedField) => {
        this.setState({
            isDriverSelectDialogOpen: !this.state.isDriverSelectDialogOpen,
            selectedField: selectedField
        })
    }

    toggleTruckSelectDialog = () => {
        this.setState({
            isTruckSelectDialogOpen: !this.state.isTruckSelectDialogOpen
        })
    }

    toggleTrailerSelectDialog = (selectedField) => {
        this.setState({
            isTrailerSelectDialogOpen: !this.state.isTrailerSelectDialogOpen,
            selectedField: selectedField
        })
    }

    toggleCarrierSelectDialog = () => {
        this.setState({
            isCarrierSelectDialogOpen: !this.state.isCarrierSelectDialogOpen,
        })
    }

    toggleContactsDialog = (item) => {
        this.setState({
            isLocationContactsDialogOpen: !this.state.isLocationContactsDialogOpen,
            selectedItem: item
        })
    }

    toggleLinkOption = (option) => {
        let linkOptions = this.state.linkOptions.map(it => {
            if (it.name === option) {
                it.value = !it.value
            }
            return it
        })

        this.setState({
            linkOptions
        }, () => {
            clearTimeout(this.customerShareOptionsTimeout);
            this.customerShareOptionsTimeout = setTimeout(this.handleSubmitCustomerShareClick, 1000);
            this.props.setDirty()
        })
    }

    toggleLinkAllOptions = (value) => {
        let linkOptions = this.state.linkOptions.map(it => {
            it.value = value
            return it
        })

        this.setState({
            linkOptions
        }, () => {
            this.props.setDirty();
            this.handleSubmitCustomerShareClick();
        })
    }

    toggleCarrierLinkOption = (option) => {
        let carrierLinkOptions = this.state.carrierLinkOptions.map(it => {
            if (it.name === option) {
                it.value = !it.value
            }
            return it
        })

        this.setState({
            carrierLinkOptions
        }, () => {
            clearTimeout(this.carrierShareOptionsTimeout);
            this.carrierShareOptionsTimeout = setTimeout(this.handleSubmitCarrierShareClick, 1000);
            this.props.setDirty();
        })
    }

    toggleCarrierLinkAllOptions = (value) => {
        let carrierLinkOptions = this.state.carrierLinkOptions.map(it => {
            it.value = value
            return it
        })

        this.setState({
            carrierLinkOptions
        }, () => {
            this.props.setDirty();
            this.handleSubmitCarrierShareClick();
        })
    }

    toggleSendURLDialog = (link, linkOptionStateName) => {
        this.setState({
            showURLDialog: link,
            linkOptionStateName: linkOptionStateName
        })
    }

    toggleSwitchDispatchDialog = () => {
        if (this.state.initialLoadStatusID >= LOAD_STATUS_DISPATCHED) {
            this.setState({
                showSwitchDispatchDialog: !this.state.showSwitchDispatchDialog,
                splitDispatchBtnDisabled: true,
                splitDispatchCanSubmit: false,
                selectedUnit: null
            })
        }
    }

    toggleLocationDialog = (item, stop) => {

        if (stop?.StopID?.value?.value ?? item?.StopID) {
            item.LocationID = stop?.StopID?.value?.value ?? item?.StopID;
        }

        this.setState({
            selectedLocation: item,
            isLocationDialogOpen: !this.state.isLocationDialogOpen
        })
    }

    toggleSplitDispatchInfoModal = () => {
        this.setState({
            isSplitDispatchInfoModalOpen: !this.state.isSplitDispatchInfoModalOpen
        })
    }

    toggleWorkingHoursModal = () => {
        const selectedLocation = this.state.stopsCombined.find(it => !!it.IsSelected);
        const isStopBy = selectedLocation.StopType === STOP_TYPE_STOP_BY;
        const stopData = isStopBy ? this.state.stopBys[selectedLocation.index] : this.state.stops[selectedLocation.index];

        this.props.dispatch(showGlobalModal('workingHoursModal', Object.assign({}, {
            locationName: stopData?.StopID?.value?.label,
            locationData: stopData?.LocationData.value
        })));
    }

    toggleAccessorialFormModal = (selectedItem = null, selectedItemIndex = null) => {

        if (!!this.state.isAccessorialDialogOpen || !!selectedItem?.target) {
            selectedItem = null
            selectedItemIndex = null
        }

        this.setState({
            isAccessorialDialogOpen: !this.state.isAccessorialDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: selectedItemIndex
        })
    }

    toggleCommodityFormModal = (selectedItem = null, selectedItemIndex = null) => {
        if (!!this.state.isCommodityDialogOpen || !!selectedItem?.target) {
            selectedItem = null
            selectedItemIndex = null
        }

        this.setState({
            isCommodityDialogOpen: !this.state.isCommodityDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: selectedItemIndex
        })
    }

    handleDeleteCommodityClick = (it, i) => {
        this.setState({
            confirmModalAction: () => this.deleteCommodity(i),
            confirmText: this.props.translate('text.delete_commodity', [it.ProductName]),
            confirmBtnLabel: this.props.translate('text.Confirm'),
            confirmCancelBtnLabel: this.props.translate("text.cancel"),
            confirmBody: undefined
        })
    }

    deleteCommodity = (i) => {
        const CommodityData = cloneDeep(this.state.CommodityData)

        CommodityData.splice(i, 1)

        this.setState({
            CommodityData,
            confirmModalAction: false
        }, this.props.setDirty)
    }

    updateCommodityOrder = (modifiedOrderData) => {
        this.setState({
            CommodityData: modifiedOrderData
        }, this.props.setDirty);
    }

    applyCustomerSettings = (customerSettings) => {
        let AccessorialData = cloneDeep(this.state.AccessorialData)
        let AdjustmentData = cloneDeep(this.state.AdjustmentData)
        const AccessorialCustomerSettingsData = customerSettings.Accessorial.filter(it => it.checked).map(it => {
            it.LoadAccessorialUnits = it.CustomerAccessorialUnits
            it.Amount = it.CustomerAccessorialAmount
            it.LoadAccessorialNotes = it.CustomerAccessorialNotes
            it.PayDriverAmount = it.PayDriverAmount ?? 0
            it.TotalAmount = it.CustomerAccessorialUnits * it.CustomerAccessorialAmount
            return it
        })

        const AdjustmentCustomerSettingsData = customerSettings.Adjustment.filter(it => it.checked).map(it => {
            it.TypeName = it.Type ? 'Expense' : 'Charge'
            return it
        })

        let message = this.props.translate('text.accessorial_data_added')

        this.setState({
            AccessorialData: AccessorialCustomerSettingsData.concat(AccessorialData),
            AdjustmentData: AdjustmentCustomerSettingsData.concat(AdjustmentData),
            SkipCustomerDefaults: customerSettings.SkipCustomerDefaults
        }, () => this.props.dispatch(pushNotification({
            title: message
        })))
    }

    toggleUnitSelectionDialog = () => {
        this.setState({
            isUnitSelectionDialogOpen: !this.state.isUnitSelectionDialogOpen
        })
    }

    applySelectedLocationUnit = (it, stopIndex, isStopOff) => {
        let stateName = isStopOff ? "stopBys" : 'stops';
        let fieldsState = cloneDeep(this.state[stateName]);
        let fields = fieldsState[stopIndex];

        const DriverID = it.DriverID ? {
            value: it.DriverID,
            label: it.Driver,
            metadata: {ContactID: it.DriverContactID}
        } : null;
        const CoDriverID = it.CoDriverID ? {
            value: it.CoDriverID,
            label: it.CoDriver,
            metadata: {ContactID: it.CoDriverContactID}
        } : null;
        const TrailerID = it.TrailerID ? {value: it.TrailerID, label: it.Trailer} : null;
        const TruckID = it.TruckID ? {value: it.TruckID, label: it.Truck} : null;

        fields = FieldsManager.updateField(fields, 'DriverID', DriverID)
        fields = FieldsManager.updateField(fields, 'CoDriverID', CoDriverID)
        fields = FieldsManager.updateField(fields, 'TrailerID', TrailerID)
        fields = FieldsManager.updateField(fields, 'TruckID', TruckID)

        fieldsState[stopIndex] = fields;

        this.setState({
            [stateName]: fieldsState
        }, () => {
            this.props.setDirty()
        })
    }

    applySelectedUnit = (it) => {
        let fields = cloneDeep(this.state.fields)

        const DriverID = it.DriverID ? {
            value: it.DriverID,
            label: it.Driver,
            metadata: {ContactID: it.DriverContactID}
        } : null;
        const CoDriverID = it.CoDriverID ? {
            value: it.CoDriverID,
            label: it.CoDriver,
            metadata: {ContactID: it.CoDriverContactID}
        } : null;
        const TrailerID = it.TrailerID ? {value: it.TrailerID, label: it.Trailer} : null;
        const TruckID = it.TruckID ? {value: it.TruckID, label: it.Truck} : null;

        fields = FieldsManager.updateField(fields, 'DriverID', DriverID)
        fields = FieldsManager.updateField(fields, 'CoDriverID', CoDriverID)
        fields = FieldsManager.updateField(fields, 'TrailerID', TrailerID)
        fields = FieldsManager.updateField(fields, 'TruckID', TruckID)

        this.setState({fields}, () => {
            this.toggleUnitSelectionDialog()
            this.props.setDirty()
        })
    }

    handleLocationEnterPress = () => {
        this.focusFirstStopInput()
    }

    handleChoseBookedByClick = () => {
        if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED && !this.state.fields?.BookedByID?.value?.value) {
            return null;
        } else if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED) {
            this.props.dispatch(showModal('ViewContactCard', {ContactID: this.state.fields.BookedByID.value.value}))
        } else {
            this.setState({
                isBookedByDialogOpen: !this.state.isBookedByDialogOpen
            })
        }
    }

    handleCreateContactClick = () => {
        this.setState({
            isCreateContactDialogOpen: !this.state.isCreateContactDialogOpen
        })
    }

    setIsEmailExistError = (bool) => {
        this.setState({
            isEmailExistError: bool
        })
    }

    handleChoseCoveredByClick = () => {
        if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED && !this.state.fields?.CoveredByID?.value?.value) {
            return null;
        } else if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED) {
            this.props.dispatch(showModal('ViewContactCard', {ContactID: this.state.fields.CoveredByID.value.value}))
        } else {
            this.setState({
                isCoveredByDialogOpen: !this.state.isCoveredByDialogOpen
            })
        }
    }

    handleChoseDispatchContactClick = () => {
        if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED && !this.state.fields?.DispatchContactID?.value?.value) {
            return null;
        } else if (this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED) {
            this.props.dispatch(showModal('ViewContactCard', {ContactID: this.state.fields.DispatchContactID.value.value}))
        } else {
            this.setState({
                isChoseDispatchContactDialogOpen: !this.state.isChoseDispatchContactDialogOpen
            })
        }
    }

    handleViewDispatchTeamClick = () => {
        this.setState({
            isDispatchTeamDialogOpen: !this.state.isDispatchTeamDialogOpen
        })
    }

    handleViewPrimaryCustomerContactClick = () => {
        this.setState({
            isPrimaryCustomerContactDialogOpen: !this.state.isPrimaryCustomerContactDialogOpen
        })
    }

    updateCustomerContactsList = (item) => {
        let CustomerContactsListClone = this.state.CustomerContactsList;

        const existingItem = !!CustomerContactsListClone[item.ContactID];

        if (existingItem) {
            delete CustomerContactsListClone[item.ContactID]
        } else {
            CustomerContactsListClone[item.ContactID] = item;
        }

        this.setState({
            CustomerContactsList: CustomerContactsListClone
        }, () => this.props.setDirty())
    }
    onUpdateContactList = (list) => {
        this.setState({
            CustomerContactsList: cloneDeep(list),
        }, () => this.props.setDirty());

        this.handleViewPrimaryCustomerContactClick(); // Close dialog
    }

    clearCustomerContactsList = () => {
        this.setState({
            CustomerContactsList: {}
        }, () => this.props.setDirty())
    }

    undoCustomerContactsList = (items) => {
        this.setState({
            CustomerContactsList: items
        })
    }

    /** Helpers
     ================================================================= */
    readNotificationData = (notification) => {
        if (notification?.Metadata) {
            let metadataType = typeof (notification.Metadata)
            let data;
            switch (metadataType) {
                case "object": {
                    data = notification.Metadata
                    break
                }
                case "string": {
                    data = JSON.parse(notification.Metadata)
                    break
                }
            }
            return {
                uuid: data.UID,
                currentStatus: data['New state']
            }
        } else {
            return null
        }
    }

    handleOCRStatusChange = async (uuid, status) => {
        if (!!uuid && status === 'Done') {
            this.setState({
                hashUUID: uuid
            }, () => this.openScanDialog())
        }
    }

    renderTimeDiff = (timestampStart, timestampEnd) => {
        if (timestampEnd === "1970-(01)January-01 00:00:00") return ""

        const startDate = moment(timestampStart, "YYYY-(MM)MMM-DD HH:mm:ss");
        const doneDate = moment(timestampEnd, "YYYY-(MM)MMM-DD HH:mm:ss");

        const diffSeconds = doneDate.diff(startDate, 'seconds');

        const minutes = Math.floor(diffSeconds / 60);
        const seconds = diffSeconds % 60;

        let result;
        if (minutes > 0) {
            result = `${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${seconds > 1 ? 's' : ''}`;
        } else {
            result = `${seconds} second${seconds > 1 ? 's' : ''}`;
        }

        return `(${result})`
    }

    checkForCustomerReferenceDuplicate = () => {
        const fieldsClone = this.state.fields;

        if (!this.dispatchSettings.PreventDuplicateRefNumber
            || this.state.duplicateCustomerReferenceNumberLoad.previousCustomerReferenceNumberValue === this.state.fields.CustomerReferenceNumber.value
            || fieldsClone.CustomerReferenceNumber.metadata.isLoading
        ) {
            return null;
        }

        if (!this.state.fields.CustomerReferenceNumber.value) {
            fieldsClone.CustomerReferenceNumber.metadata.htmlAfterField = null;

            this.setState({
                fields: fieldsClone,
                duplicateCustomerReferenceNumberLoad: {
                    LoadID: 0,
                    LoadNumber: 0,
                    previousCustomerReferenceNumberValue: ""
                }
            })
            return null;
        }

        fieldsClone.CustomerReferenceNumber.metadata.htmlAfterField = () => {
            return <div className="btn btn-input hover:cursor-default hover:bg-field">
                <LoaderSmall disableContainer={true}/>
            </div>
        }

        fieldsClone.CustomerReferenceNumber.metadata.isLoading = true;

        this.setState({
            fields: fieldsClone
        }, () => {

            axios.get(
                Env.getApiUrl("api/" + Resources.Loads, {
                    CustomerReferenceNumber: this.state.fields.CustomerReferenceNumber.value
                }),
                {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }
            )
                .then((response) => {

                    const fieldsClone = this.state.fields;
                    const duplicate = (response?.data?.data?.list ?? []).find(it => it.CustomerReferenceNumber === this.state.fields.CustomerReferenceNumber.value);

                    let duplicateCustomerReferenceNumberLoad = {};

                    if (duplicate) {
                        duplicateCustomerReferenceNumberLoad = {
                            LoadID: duplicate.LoadID,
                            LoadNumber: duplicate.LoadNumber,
                            previousCustomerReferenceNumberValue: this.state.fields.CustomerReferenceNumber.value
                        };

                        fieldsClone.CustomerReferenceNumber.metadata.htmlAfterField = () => {
                            return (
                                <Tooltip
                                    content={this.props.translate(
                                        "text.CustomerReferenceNumberDuplicate",
                                        [duplicate.LoadNumber]
                                    )}
                                >
                                    <button
                                        onClick={() => openInNewTab('/loads/info/' + duplicate.LoadID)}
                                        className="btn btn-input hover:cursor-default hover:bg-field"
                                    >
                                        <ExclamationCircleIcon className="w-5 h-5 text-yellow-600"/>
                                    </button>
                                </Tooltip>
                            )
                        }
                    } else {
                        duplicateCustomerReferenceNumberLoad = {
                            LoadID: 0,
                            LoadNumber: 0,
                            previousCustomerReferenceNumberValue: this.state.fields.CustomerReferenceNumber.value
                        };

                        fieldsClone.CustomerReferenceNumber.metadata.htmlAfterField = () => {
                            return (
                                <Tooltip content={this.props.translate("text.CustomerReferenceNumberUnique")}>
                                    <div className="btn btn-input hover:cursor-default hover:bg-field">
                                        <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                    </div>
                                </Tooltip>
                            )
                        }
                    }

                    fieldsClone.CustomerReferenceNumber.metadata.isLoading = false;

                    this.setState({
                        fields: fieldsClone,
                        duplicateCustomerReferenceNumberLoad: duplicateCustomerReferenceNumberLoad
                    });
                }).catch((error) => {
                    console.log('error', error);
                }
            );
        })
    }

    getTotalMiles = () => {
        const international = getProp(LocalStorage.get("lookup"), 'LoadType', []).find(it => it.LoadType === "International");
        const isLoadInternational = (!!international?.LoadTypeID && !!FieldsManager.getFieldKeyValues(this.state.fields)?.['LoadTypeID'])
            && international.LoadTypeID === FieldsManager.getFieldKeyValues(this.state.fields)['LoadTypeID'];

        if (isLoadInternational) {
            return null
        }

        return this.state.TotalMiles;
    }
    getStopFields = (stopData, translate) => {
        let stop = getStopFields(stopData, translate, []);

        return this.extendStop(stop);
    }

    extendStop = (stop) => {
        stop.StopEndDate.props.onFocus = () => {
            let stopIndex = this.state.stopsCombined.find(it => it.IsSelected)?.index;
            let stops = this.state.stops;

            if (stops[stopIndex].StopDate.value) {
                stops[stopIndex].StopEndDate.value = stops[stopIndex].StopDate.value;
            }

            this.setState({
                stops: stops
            });
        }

        return stop;
    }

    focusFirstStopInput = () => {
        const firstInput = this.LoadInfoLocationsSection.current.querySelector('input');

        if (firstInput) {
            firstInput.focus();
        }
    }

    handleMinimizeDialogClick = () => {
        this.setState({
            isOcrDialogMinimized: !this.state.isOcrDialogMinimized
        });
    }

    addStopFieldsActions = (stops) => {
        return stops.map(stop => {
            stop.StopID.props = Object.assign(stop.StopID.props ?? {}, {onCreateOption: (name, value) => this.handleCreateLoadStop(name, value)});
            stop.StopID.type = 'creatable-select-search';
            stop.StopID.metadata = Object.assign(stop.StopID.metadata ?? {}, {
                addFieldContainerClass: "pr-11 relative",
                htmlAfterField: (it) => {
                    return <button
                        onClick={() => this.handleSelectStopClick()}
                        disabled={it?.disabled}
                        className="btn btn-input absolute top-0 right-0"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
                }
            });
            return stop;
        });
    }

    getCustomerPrimaryContact = (contacts = {}) => {
        const hasContacts = !!Object.keys(contacts)?.length;

        return (
            <div>
                <div>
                    <p className="text-sm font-semibold text-tm-gray-600 mt-4 mb-2">{this.props.translate("text.customer_contacts")}</p>
                    <Tippy
                        content={this.props.translate("text.select_customer_first")}
                        disabled={!!this.state.fields.CustomerID?.value?.value}
                    >
                        <span>
                            <button
                                className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                disabled={!this.state.fields.CustomerID?.value?.value}
                                onClick={this.handleViewPrimaryCustomerContactClick}
                            >
                                {hasContacts && (
                                    <React.Fragment>
                                        <PencilIcon className="w-5 h-5"/>
                                        {this.props.translate("btn.update_customer_contacts")}
                                    </React.Fragment>
                                )}

                                {!hasContacts && (
                                    <React.Fragment>
                                        <PlusCircleIcon className="w-5 h-5"/>
                                        {this.props.translate("btn.add_customer_contacts")}
                                    </React.Fragment>
                                )}
                            </button>
                        </span>
                    </Tippy>
                </div>

                <div className="space-y-2 my-2">
                    {Object.values(contacts).map(contact =>
                        <ContactSelectButton
                            key={contact.ContactID}
                            id={contact.ContactID}
                            name={contact.LastName + " " + contact.FirstName}
                            hasImagePath={!!contact.ImagePath}
                            onChoseContactClick={() => this.props.dispatch(
                                showModal('ViewContactCard', {"ContactID": contact.ContactID}))
                            }
                            onClearContactClick={() => this.updateCustomerContactsList(contact)}
                            onAvatarClick={() => this.props.dispatch(
                                showModal('ViewContactCard', {ContactID: contact.ContactID}))
                            }
                            isLoading={!!this.props.resource.isLoading || !!this.props.secondResource.isLoading || this.props.dialogResource.isLoading}
                            translate={this.props.translate}
                        />
                    )}
                </div>

                {hasContacts && Object.keys(contacts).length > 1 && (
                    <React.Fragment>
                        <button
                            className="flex btn-text gap-2 hover:text-red-600"
                            onClick={this.clearCustomerContactsList}
                        >
                            <XCircleIcon className="w-5 h-5 text-red-600"/>
                            Clear all customer contacts
                        </button>
                    </React.Fragment>
                )}
            </div>
        )
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    getFields = (includeFields = null, data) => {
        let info = data ? data : getProp(this.props.resource, 'data.load/info', {})
        const isCopyMode = !!this.props.isCopyMode

        let myArr = [];
        for (let i = 5; i <= 300; i += 5) {
            myArr.push(i)
        }
        const requiredObject = myArr.reduce((obj, val) => {
            obj[val] = val;
            return obj;
        }, {});

        const isDispatched = info.LoadStatusID >= LOAD_STATUS_DISPATCHED && !this.props.isCopyMode;
        const isTailgate = getProp(this.props.resource, 'data.load/info.ParentLoadID', 0);

        const defaultOffice = LocalStorage.get('user')?.Office;

        const driverNameTxt = getProp(info, 'DriverNameTxt', '');
        const coDriverNameTxt = getProp(info, 'CoDriverNameTxt', '');
        const carrierID = getProp(info, 'CarrierID', '');

        const fieldTemplates = {
            BookedByID: new Field('BookedByID', {value: info.BookedByID, label: info.BookedBy}, [""], false),
            CoveredByID: new Field('CoveredByID', {value: info.CoveredByID, label: info.CoveredBy}, [""], false),
            LoadTypeID: new Field(
                'LoadTypeID',
                getProp(info, 'LoadTypeID', ''),
                [''],
                false,
                'select',
                {addContainerClass: "col-span-6 3xl:col-span-6"}
            ),
            LoadSubTypeID: new Field('LoadSubTypeID', getProp(info, 'LoadSubTypeID', ''), [''], false, 'select', {addContainerClass: "col-span-6 3xl:col-span-6"}),
            OfficeID: new Field(
                'OfficeID',
                {
                    value: getProp(info, 'OfficeID', getProp(defaultOffice, 'OfficeID', '')),
                    label: getProp(info, 'OfficeName', getProp(defaultOffice, 'OfficeName',
                        this.props.translate('text.no_selection')))
                },
                ['empty_select_search'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6 3xl:col-span-6"
                }),
            ContactGroupID: new Field(
                'ContactGroupID',
                this.getID() ? this.getLoadContactGroup() : null,
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6 3xl:col-span-6",
                    label: "LoadGroupID",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }),
            CustomerID: new Field('CustomerID', {
                value: getProp(info, 'CustomerID', ''),
                label: getProp(info, 'Customer', ''),
                metadata: info?.CustomerInfo ?? {}
            }, [''], false, 'select-search', {
                addContainerClass: "col-start-1 col-span-8",
                fieldOptions: (it) => (
                    <FieldOptions
                        options={[
                            {
                                buttonLabel: this.props.translate('btn.add_customer_defaults'),
                                onClick: this.toggleCustomerSettingsDialog,
                                isVisible: !this.isEditMode && !!this.state && !!this.state.fields.CustomerID.value?.label,
                                addClass: "w-auto"
                            },
                            {
                                icon: InformationCircleIcon,
                                toolTip: this.props.translate("text.view_customer_info"),
                                onClick: this.handleShowCustomerDetailsClick,
                                isVisible: !!this.state.fields.CustomerID.value?.label
                            },
                            {
                                icon: ArrowTopRightOnSquareIcon,
                                toolTip: this.props.translate("text.open_customer_in_new_tab"),
                                onClick: () => openInNewTab("/customers/info/" + it?.value?.metadata?.OrganizationID + "/" + it?.value?.value),
                                isVisible: !!it?.value?.label
                            }
                        ]}
                    />
                )
            }),
            CustomerReferenceNumber: new Field(
                'CustomerReferenceNumber',
                isCopyMode ? null : getProp(info, 'CustomerReferenceNumber', ''),
                [''],
                false,
                "text",
                {
                    addContainerClass: "col-span-4",
                },
                {
                    onBlur: () => this.handleCustomerReferenceNumberBlur()
                }
            ),
            Price: new Field('Price', getProp(info, 'Price', ''), [''], false, 'money', {addContainerClass: "col-span-full sm:col-span-5"}),
            PriceTypeID: new Field('PriceTypeID', 1, [''], true, 'select', {addContainerClass: "col-span-5"}),
            ExternalNotesCustomer: new Field('ExternalNotesCustomer', getProp(info, 'ExternalNotesCustomer', ''), [''], false, getProp(info, 'ExternalNotesCustomer', null) ? "textarea" : "hidden", {
                addContainerClass: "col-start-1 col-span-full",
                htmlAfter: () => <InfoBar type="warning" addClass="col-span-full font-bold">
                    {this.props.translate("text.external_customer_notes_message")}
                </InfoBar>,
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("ExternalNotesCustomer")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),
            InternalNotes: new Field('InternalNotes', getProp(info, 'InternalNotes', ''), [''], false, getProp(info, 'InternalNotes', null) ? "textarea" : "hidden", {
                addContainerClass: "col-start-1 col-span-full",
                htmlAfter: () => <InfoBar type="info" addClass="col-span-full">
                    {this.props.translate("text.internal_notes_message")}
                </InfoBar>,
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("InternalNotes")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),
            DriverID: new Field('DriverID', info.DriverID ? {
                value: getProp(info, 'DriverID', ''),
                label: getProp(info, 'Driver', ''),
                metadata: {
                    ContactID: getProp(info, 'DriverContactID', ''),
                    DriverID: getProp(info, 'DriverContactID', ''),
                    Email: getProp(info, 'DriverEmail', ''),
                    DriverPositionType: getProp(info, 'DriverPositionType', ''),
                    PayTo: getProp(info, 'PayTo', '')
                }
            } : undefined, [''], isDispatched || isTailgate, 'select-search', {
                addContainerClass: "2xl:col-span-6 col-span-full",
                fieldOptions: (it) => {
                    let driverInDispatch = this.getDispatchedDriverInfo(it?.value?.value);
                    const inactiveDriverData = getProp(this.props.resource, 'data.load/info.isInactiveDriver', [])[0] || (it?.value?.metadata?.StatusID > DRIVER_STATUS_ON_VACATION && it?.value?.metadata);

                    const LoadID = driverInDispatch?.LoadID
                        || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && it?.value?.metadata?.OnLoadID)
                    const LoadNumber = driverInDispatch?.LoadNumber
                    const DriverID = it?.value?.value;
                    const isOnTimeOff = !!inactiveDriverData && inactiveDriverData?.StatusID === DRIVER_STATUS_ON_VACATION;

                    return (
                        <FieldOptions
                            addClass="pr-11"
                            options={[
                                {
                                    icon: isOnTimeOff ? ExclamationCircleOutlineIcon : ExclamationTriangleIcon,
                                    addClass: !!inactiveDriverData
                                        && inactiveDriverData?.StatusID === DRIVER_STATUS_ON_VACATION
                                        ? "text-yellow-600 hover:text-yellow-800 relative"
                                        : "text-red-600 hover:text-red-800 relative top-px",
                                    onClick: () => openInNewTab("/drivers/info/" + inactiveDriverData?.DriverID),
                                    toolTip: isOnTimeOff
                                        ? this.props.translate("text.driver_time_off", [inactiveDriverData?.Driver])
                                        : this.props.translate("text.is_not_active_driver", [inactiveDriverData?.Driver]),
                                    isVisible: !!inactiveDriverData
                                        && inactiveDriverData?.StatusID >= DRIVER_STATUS_ON_VACATION
                                        && it?.value?.value === inactiveDriverData?.DriverID
                                },
                                {
                                    icon: ExclamationCircleOutlineIcon,
                                    addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                    onClick: () => openInNewTab("/loads/info/" + LoadID),
                                    toolTip: this.props.translate("text_driver_already_dispatched", [LoadNumber ?? LoadID]),
                                    isVisible: !!LoadID
                                },
                                {
                                    icon: UserGroupIcon,
                                    onClick: () => this.handleViewDispatchTeamClick(it),
                                    toolTip: this.props.translate("text.view_dispatch_team"),
                                    isVisible: getProp(it, "value.metadata.ContactID", null)
                                },
                                {
                                    icon: InformationCircleIcon,
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    onClick: () => this.props.dispatch(
                                        showGlobalModal(
                                            'ViewDriverCard',
                                            DriverID
                                        )
                                    ),
                                    isVisible: getProp(it, "value.metadata.DriverID", null)
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    onClick: () => openInNewTab("/drivers/info/" + DriverID),
                                    isVisible: !!DriverID
                                }
                            ]}
                        />
                    )
                },
                htmlAfterField: (it) => {
                    return <button
                        disabled={it.disabled}
                        onClick={() => this.toggleDriverSelectDialog("DriverID")}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
                },
                htmlAfter: (it) => {
                    const Email = it.value?.metadata?.Email;
                    const DriverPositionType = it.value?.metadata?.DriverPositionType;
                    const PayTo = it.value?.metadata?.PayTo;
                    return (Email || DriverPositionType || PayTo) && (
                        <div className="2xl:col-start-1 2xl:col-span-6 col-span-full gap-4">
                            <div className="flex gap-2 py-2 px-px rounded-md bg-sky-600/10 text-tm-gray-900">
                                <div className="w-full">
                                    {Email && (
                                        <div className="px-3 group">
                                            <CellButtonWithCopy copyText={Email}>
                                                <div className="flex w-full">
                                                    <div className="w-20 mr-2 text-left">Email:</div>
                                                    <span className="font-bold">{Email}</span>
                                                </div>
                                            </CellButtonWithCopy>
                                        </div>
                                    )}

                                    {DriverPositionType && (
                                        <div className="px-3 group">
                                            <CellButtonWithCopy copyText={DriverPositionType}>
                                                <div className="flex w-full">
                                                    <div className="w-20 mr-2 text-left">Position type:</div>
                                                    <span className="font-bold">{DriverPositionType}</span>
                                                </div>
                                            </CellButtonWithCopy>
                                        </div>
                                    )}

                                    {PayTo && (
                                        <div className="px-3 group">
                                            <CellButtonWithCopy copyText={PayTo}>
                                                <div className="flex w-full">
                                                    <div className="w-20 mr-2 text-left">PayTo:</div>
                                                    <span className="font-bold">{PayTo}</span>
                                                </div>
                                            </CellButtonWithCopy>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>)
                }
            }, {isClearable: true}),

            CoDriverID: new Field('CoDriverID', info.CoDriverID ? {
                value: getProp(info, 'CoDriverID', ''),
                label: getProp(info, 'CoDriver', ''),
                metadata: {
                    ContactID: getProp(info, 'CoDriverContactID', ''),
                    CoDriverID: getProp(info, 'CoDriverID', ''),
                },
            } : undefined, [''], isDispatched || isTailgate, 'select-search', {
                addContainerClass: "2xl:col-span-6 col-span-full 2xl:col-start-1",
                fieldOptions: (it) => {
                    let driverInDispatch = this.getDispatchedDriverInfo(it?.value?.value);
                    const LoadID = driverInDispatch?.LoadID
                        || (it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && it?.value?.metadata?.OnLoadID && it.value.metadata.OnLoadID)
                    const DriverID = it?.value?.value;
                    const LoadNumber = driverInDispatch?.LoadNumber

                    const inactiveCoDriverData = getProp(this.props.resource, 'data.load/info.isInactiveDriver', [])
                        .find(it => it.DriverID === this.state.fields.CoDriverID?.value?.value);
                    const isOnTimeOff = !!inactiveCoDriverData && inactiveCoDriverData?.StatusID === DRIVER_STATUS_ON_VACATION;

                    return (
                        <FieldOptions
                            addClass="pr-11"
                            options={[
                                {
                                    icon: isOnTimeOff ? ExclamationCircleOutlineIcon : ExclamationTriangleIcon,
                                    addClass: !!inactiveCoDriverData
                                    && inactiveCoDriverData?.StatusID === DRIVER_STATUS_ON_VACATION
                                        ? "text-yellow-600 hover:text-yellow-800 relative"
                                        : "text-red-600 hover:text-red-800 relative top-px",
                                    onClick: () => openInNewTab("/drivers/info/" + inactiveCoDriverData?.DriverID),
                                    toolTip: isOnTimeOff
                                        ? this.props.translate("text.co_driver_time_off", [inactiveCoDriverData?.Driver])
                                        : this.props.translate("text.co_driver_is_not_active_driver", [inactiveCoDriverData?.Driver]),
                                    isVisible: !!inactiveCoDriverData
                                        && inactiveCoDriverData?.StatusID >= DRIVER_STATUS_ON_VACATION
                                        && it?.value?.value === inactiveCoDriverData?.DriverID
                                },
                                {
                                    icon: ExclamationCircleOutlineIcon,
                                    addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                    onClick: () => openInNewTab("/loads/info/" + LoadID),
                                    toolTip: this.props.translate("text_driver_already_dispatched", [LoadNumber]),
                                    isVisible: !!LoadID
                                },
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showGlobalModal('ViewDriverCard', DriverID)),
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    isVisible: !!it?.value?.metadata?.DriverID
                                },
                                {
                                    icon: InformationCircleIcon,
                                    toolTip: this.props.translate("text.view_contact_info"),
                                    onClick: () => this.props.dispatch(
                                        showModal(
                                            'ViewContactCard',
                                            {ContactID: getProp(it, "value.ContactID", null)}
                                        )
                                    ),
                                    isVisible: getProp(it, "value.ContactID", null)
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    onClick: () => openInNewTab("/drivers/info/" + DriverID),
                                    isVisible: !!DriverID
                                }
                            ]}
                        />
                    )
                },
                htmlAfterField: (it) => {
                    return <button
                        disabled={it.disabled}
                        onClick={() => this.toggleDriverSelectDialog("CoDriverID")}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
                }
            }, {isClearable: true}),
            TruckID: new Field('TruckID', info.TruckID ? {
                value: getProp(info, 'TruckID', ''),
                label: getProp(info, 'Truck', '')
            } : undefined, [''], isDispatched || isTailgate, 'select-search', {
                addContainerClass: "col-span-6 col-start-1",
                fieldOptions: (it) => {
                    const truckInDispatch = this.dispatchedAssets
                        .find(it =>
                            it.TruckID === this.state.fields.TruckID?.value?.value
                        );
                    const notPermittedTruck = getProp(this.props.resource, 'data.load/info.isNotPermittedTruck', [])[0];
                    const LoadID = truckInDispatch?.LoadID
                        || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && it?.value?.metadata?.OnLoadID);
                    const LoadNumber = truckInDispatch?.LoadNumber;

                    return (
                        <div className="space-x-1 h-5 relative bottom-1">
                            <FieldOptions
                                addClass="pr-11"
                                options={[
                                    {
                                        icon: ExclamationCircleOutlineIcon,
                                        addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                        onClick: () => openInNewTab("/loads/info/" + LoadID),
                                        toolTip: it?.value?.metadata?.StatusID === 3
                                            ? LoadNumber
                                                ? this.props.translate("text.truck_already_dispatched", [LoadNumber])
                                                : this.props.translate("text.truck_already_dispatched_some")
                                            : this.props.translate("text.truck_already_booked", [LoadNumber]),
                                        isVisible: !!LoadID
                                    },
                                    {
                                        icon: ExclamationTriangleIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trucks/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.truck_inactive"),
                                        isVisible: !!it?.value?.value && this.isInactiveTruck?.TruckID === it?.value?.value
                                    },
                                    {
                                        icon: NoSymbolIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trucks/info/' + notPermittedTruck?.Truck?.TruckID),
                                        toolTip: notPermittedTruck?.Message,
                                        isVisible: !!notPermittedTruck && it?.value?.value === notPermittedTruck?.Truck?.TruckID
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.props.dispatch(showGlobalModal('ViewTruckCard', it?.value?.value)),
                                        toolTip: this.props.translate("text.view_truck_info"),
                                        isVisible: !!it?.value?.value
                                    },
                                    {
                                        icon: ArrowTopRightOnSquareIcon,
                                        onClick: () => openInNewTab('/trucks/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.view_truck_info"),
                                        isVisible: !!it?.value?.value
                                    }
                                ]}
                            />
                        </div>
                    )
                },
                htmlAfterField: (it) => {
                    return <button
                        disabled={it.disabled}
                        onClick={() => this.toggleTruckSelectDialog()}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
                }
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', info.TrailerID ? {
                value: getProp(info, 'TrailerID', ''),
                label: getProp(info, 'Trailer', '')
            } : undefined, [''], isDispatched || isTailgate, 'select-search', {
                addContainerClass: "col-start-1 col-span-6",
                fieldOptions: (it) => {
                    const trailerInDispatch = this.getInDispatchTrailerInfo(it?.value?.value);
                    const LoadID = trailerInDispatch?.LoadID
                        || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && it?.value?.metadata?.OnLoadID);
                    const LoadNumber = trailerInDispatch?.LoadNumber || it?.value?.metadata?.LoadNumber
                    return (
                        <React.Fragment>
                            <FieldOptions
                                addClass="pr-11"
                                options={[
                                    {
                                        icon: ExclamationCircleOutlineIcon,
                                        addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                        onClick: () => openInNewTab("/loads/info/" + LoadID),
                                        toolTip: LoadNumber
                                            ? this.props.translate("text.trailer_already_dispatched", [LoadNumber])
                                            : this.props.translate("text.trailer_already_dispatched_some"),
                                        isVisible: trailerInDispatch?.LoadID
                                            || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD)
                                    },
                                    {
                                        icon: ExclamationTriangleIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trailers/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.trailer_inactive"),
                                        isVisible: !!it?.value?.value && this.isInactiveTrailer?.TrailerID === it?.value?.value
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.props.dispatch(showGlobalModal('ViewTrailerCard', it?.value?.value)),
                                        toolTip: this.props.translate("text.view_trailer_info"),
                                        isVisible: !!it?.value?.value
                                    },
                                    {
                                        icon: ArrowTopRightOnSquareIcon,
                                        onClick: () => openInNewTab('/trailers/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.view_trailer_info"),
                                        isVisible: it?.value?.value
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )
                },
                htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={() => this.toggleTrailerSelectDialog("TrailerID")}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
            }, {isClearable: true}),
            TrailerType: new Field('TrailerType', getProp(info, 'TrailerType', this.props.translate('text.not_specified')), [''], true, getProp(info, 'TrailerID', null) ? 'text' : 'hidden', {addContainerClass: "col-span-6"}),
            SecondTrailerID: new Field('SecondTrailerID', info.SecondTrailerID ? {
                value: getProp(info, 'SecondTrailerID', ''),
                label: getProp(info, 'SecondTrailer', '')
            } : null, [''], isDispatched || isTailgate, getProp(info, 'SecondTrailerID', null) ? 'select-search' : 'hidden', {
                addContainerClass: "col-start-1 col-span-6",
                fieldOptions: (it) => (
                    <React.Fragment>
                        <FieldOptions
                            addClass="pr-11"
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showGlobalModal('ViewTrailerCard', it?.value?.value)),
                                    toolTip: this.props.translate("text.view_trailer_info"),
                                    isVisible: !!it?.value?.value
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    onClick: () => openInNewTab('/trailers/info/' + it.value.value),
                                    toolTip: this.props.translate("text.view_trailer_info"),
                                    isVisible: it?.value?.value
                                },
                                {
                                    icon: XMarkIcon,
                                    onClick: () => {
                                        this.clearField("SecondTrailerID");
                                        this.clearField("SecondTrailerType");
                                    },
                                    toolTip: this.props.translate("text.remove_second_trailer"),
                                    isVisible: this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED
                                }
                            ]}
                        />
                    </React.Fragment>
                ),

                htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={() => this.toggleTrailerSelectDialog("SecondTrailerID")}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
            }, {isClearable: true}),
            SecondTrailerType: new Field('SecondTrailerTypeID', getProp(info, 'SecondTrailerType', this.props.translate('text.not_specified')), [''], true, getProp(info, 'SecondTrailerID', null) ? 'text' : 'hidden', {addContainerClass: "col-span-6"}),

            ThirdTrailerID: new Field('ThirdTrailerID', info.ThirdTrailerID ? {
                value: getProp(info, 'ThirdTrailerID', ''),
                label: getProp(info, 'ThirdTrailer', '')
            } : null, [''], isDispatched || isTailgate, getProp(info, 'ThirdTrailerID', null) ? 'select-search' : 'hidden', {
                addContainerClass: "col-start-1 col-span-6",
                fieldOptions: (it) => (
                    <React.Fragment>
                        <FieldOptions
                            addClass="pr-11"
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showGlobalModal('ViewTrailerCard', it?.value?.value)),
                                    toolTip: this.props.translate("text.view_trailer_info"),
                                    isVisible: !!it?.value?.value
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    onClick: () => openInNewTab('/trailers/info/' + it.value.value),
                                    toolTip: this.props.translate("text.view_trailer_info"),
                                    isVisible: it?.value?.value
                                },
                                {
                                    icon: XMarkIcon,
                                    onClick: () => {
                                        this.clearField("ThirdTrailerID");
                                        this.clearField("ThirdTrailerType");
                                    },
                                    toolTip: this.props.translate("text.remove_third_trailer"),
                                    isVisible: this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED
                                }
                            ]}
                        />
                    </React.Fragment>
                ),

                htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={() => this.toggleTrailerSelectDialog("ThirdTrailerID")}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
            }, {isClearable: true}),
            ThirdTrailerType: new Field('ThirdTrailerTypeID', getProp(info, 'ThirdTrailerType', this.props.translate('text.not_specified')), [''], true, getProp(info, 'ThirdTrailerID', null) ? 'text' : 'hidden', {addContainerClass: "col-span-6"}),

            ExternalNotes: new Field('ExternalNotes', getProp(info, 'ExternalNotes', ''), ['']),
            CoBrokerID: new Field('CoBrokerID', {
                value: getProp(info, 'CoBrokerID', ''),
                label: getProp(info, 'CoBroker', '')
            }, [''], false, getProp(info, 'IsCoBroker', 0) ? "select-search" : "hidden", {
                addContainerClass: "col-start-1 col-span-8",
                fieldOptions: () => this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED ? (
                    <React.Fragment>
                        <div className="flex gap-x-3 h-5 items-center">
                            <button
                                onClick={() => {
                                    this.clearField("CoBrokerID");
                                    this.handleInputChange("IsCoBroker", 0)
                                }}
                                className="inline-block field-btn animate-button-fade-in"
                            >
                                <XMarkIcon className="w-5 h-5 text-primary"/>
                            </button>
                        </div>
                    </React.Fragment>
                ) : null
            }),
            IsCoBroker: new Field('IsCoBroker', getProp(info, 'IsCoBroker', 0), [''], false, "hidden"),
            CarrierID: new Field('CarrierID', info.CarrierID ? {
                value: getProp(info, 'CarrierID', ''),
                label: getProp(info, 'Carrier', ''),
                metadata: {
                    OrganizationID: getProp(info, 'CarrierOrganizationID', ''),
                    CarrierID: getProp(info, 'CarrierID', ''),
                    MCNumber: getProp(info, 'MCNumber', undefined) ?? getProp(info, 'CarrierMCNumber', "")
                }
            } : undefined, [''], false, 'select-search', {
                addContainerClass: "col-start-1 col-span-8",
                htmlBefore: (it) => {
                    return it?.value?.metadata?.MCNumber
                        ? <div className='col-span-full'>
                            <InfoBar>
                                {this.props.translate("text.mc_number")}: {it.value.metadata.MCNumber}
                            </InfoBar>
                        </div>
                        : null
                },
                fieldOptions: (it) => {
                    const isCarrierBlacklisted = !!it?.value?.value
                        && (this.isCarrierBlacklisted?.CarrierID === it?.value?.value || !!it.value?.metadata?.Blacklisted);

                    return (
                        <div className="gap-x-3 h-5 relative bottom-0.5 flex pr-11">
                            <button
                                className="btn-field animate-button-fade-in"
                                onClick={this.toggleShareCarrierDialog}
                            >
                                <ShareSolidIcon/>
                            </button>

                            {isCarrierBlacklisted && (
                                <Tippy
                                    content={
                                        this.props.translate(
                                            "text.carrier_is_blacklisted",
                                            [it.value?.metadata?.BlacklistUpdatedByContact ?? this.isCarrierBlacklisted.BlacklistUpdatedByContact]
                                        )
                                    }
                                >
                                    <Link
                                        className="btn-field animate-button-fade-in text-yellow-600 hover:text-yellow-800 relative"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={'/carriers/info/' + it.value.metadata.OrganizationID + "/" + it.value.value}
                                    >
                                        <ExclamationCircleIcon className={"text-red-600"}/>
                                    </Link>
                                </Tippy>
                            )}

                            {!!it.value?.value && (
                                <Tooltip
                                    content={this.props.translate("text.show_carrier_info")}
                                >
                                    <button
                                        className="btn-field animate-button-fade-in"
                                        onClick={() => this.props.dispatch(showModal('ViewCarrierCard', it?.value?.metadata))}
                                    >
                                        <InformationCircleIcon/>
                                    </button>
                                </Tooltip>
                            )}

                            {!!it.value?.value && (
                                <Tooltip
                                    content={this.props.translate("text.view_carrier_info")}
                                >
                                    <Link
                                        className="btn-field animate-button-fade-in"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={'/carriers/info/' + it.value.metadata.OrganizationID + "/" + it.value.value}
                                    >
                                        <ArrowTopRightOnSquareIcon/>
                                    </Link>
                                </Tooltip>
                            )}

                        </div>
                    )
                },
                htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={this.toggleCarrierSelectDialog}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>

            }, {isClearable: true}),
            CarrierReferenceNumber: new Field('CarrierReferenceNumber', isCopyMode ? null : getProp(info, 'CarrierReferenceNumber', ''), [''], false, "text", {addContainerClass: "col-span-4"}),

            DriverNameTxt: new Field('DriverNameTxt', driverNameTxt ? {
                    label: driverNameTxt,
                    value: -1
                } : "", [''], !carrierID, carrierID ? "creatable-select-search" : "text", {
                    addContainerClass: "col-span-6",
                    fieldOptions: () => (
                        <React.Fragment>
                            {getProp(info, 'CarrierID', '') && (
                                <FieldOptions
                                    className="pr-10"
                                    options={[
                                        {
                                            icon: PencilAltOutlineIcon,
                                            toolTip: this.props.translate("text.create_driver_for_carrier", [this.state.fields.CarrierID?.value?.label]),
                                            onClick: () => this.toggleCreateCarrierDriverDialog(),
                                            isVisible: this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED
                                        }]}
                                />
                            )}
                        </React.Fragment>
                    ),
                    htmlAfterField: (it) =>
                        <button
                            disabled={it.disabled}
                            onClick={() => this.openDriverNameTxtListDialog('DriverNameTxt')}
                            className="btn btn-input"
                        >
                            {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                        </button>
                }, {
                    isClearable: true,
                    key: this?.state?.DriverNameTxtKey ?? 0,
                    onCreateOption: (name, value) => this.toggleCreateCarrierDriverDialog(value),
                }
            ),
            DriverCellTxt: new Field('DriverCellTxt', getProp(info, 'DriverCellTxt', ''), [''], false, "mask", {addContainerClass: "col-span-6"}, {
                mask: FIELD_MASK_PHONE,
                showMask: true,
                onFocus: handleMaskedFocus
            }),
            CoDriverNameTxt: new Field('CoDriverNameTxt', coDriverNameTxt ? {
                    label: coDriverNameTxt,
                    value: -1
                } : "", [''], !carrierID, carrierID ? "creatable-select-search" : "text", {
                    addContainerClass: "col-span-6",
                    fieldOptions: () => (
                        <React.Fragment>
                            {getProp(info, 'CarrierID', '') && (
                                <FieldOptions
                                    className="pr-10"
                                    options={[
                                        {
                                            icon: PencilAltOutlineIcon,
                                            toolTip: this.props.translate("text.create_driver_for_carrier", [this.state.fields.CarrierID?.value?.label]),
                                            onClick: () => this.toggleCreateCarrierDriverDialog("", true),
                                            isVisible: this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED
                                        }]}
                                />
                            )}
                        </React.Fragment>
                    ),
                    htmlAfterField: (it) =>
                        <button
                            disabled={it.disabled}
                            onClick={() => this.openDriverNameTxtListDialog('CoDriverNameTxt')}
                            className="btn btn-input"
                        >
                            {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                        </button>
                }, {
                    isClearable: true,
                    key: this?.state?.DriverNameTxtKey ?? 0,
                    onCreateOption: (name, value) => this.toggleCreateCarrierDriverDialog(value, true),
                }
            ),
            CoDriverCellTxt: new Field('CoDriverCellTxt', getProp(info, 'CoDriverCellTxt', ''), [''], false, "mask", {addContainerClass: "col-span-6"}, {
                mask: FIELD_MASK_PHONE,
                showMask: true,
                onFocus: handleMaskedFocus
            }),

            TruckTxt: new Field('TruckTxt', getProp(info, 'TruckTxt', ''), [''], false, "text", {addContainerClass: "col-span-6"}),

            TrailerTxt: new Field('TrailerTxt', getProp(info, 'TrailerTxt', ''), [''], false, "text", {addContainerClass: "col-start-1 col-span-6"}),
            TrailerTypeTxt: new Field('TrailerTypeTxt', getProp(info, 'TrailerTypeTxt', ''), [''], false, "text", {addContainerClass: "col-span-6"}),

            DispatchContactID: new Field('DispatchContactID', {
                value: info.DispatchContactID,
                label: info.DispatchContact
            }, [""], false),

            DispatchContactTxt: new Field('DispatchContactTxt', info.DispatchContactTxt ?? "", [""], false, 'text'),

            DispatchContactFieldType: new Field('DispatchContactFieldType', info.DispatchContactID ? "2" : "1", [""], false),

            ExternalDriverNotes: new Field('ExternalDriverNotes', getProp(info, 'ExternalNotesDriverCarrier', ''), [''], false, getProp(info, 'ExternalNotesDriverCarrier', null) ? "textarea" : "hidden", {
                addContainerClass: "col-span-full",
                htmlAfter: () => <InfoBar type="warning" addClass="col-span-full font-bold">
                    {this.props.translate("text.external_driver_notes_message")}
                </InfoBar>,
                fieldOptions: () => (
                    <div className="space-x-3 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("ExternalDriverNotes")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),
            ExternalCarrierNotes: new Field('ExternalCarrierNotes', getProp(info, 'ExternalNotesDriverCarrier', ''), [''], false, getProp(info, 'ExternalNotesDriverCarrier', null) ? "textarea" : "hidden", {
                addContainerClass: "col-span-full",
                htmlAfter: () => <InfoBar type="warning" addClass="col-span-full font-bold">
                    {this.props.translate("text.external_carrier_notes_message")}
                </InfoBar>,
                fieldOptions: () => (
                    <div className="space-x-3 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("ExternalCarrierNotes")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),

            Marked: new Field('Marked', getProp(info, 'Marked', ''), [], false, 'checkbox'),
            Cron: new Field('Cron', '', []),
            EndDate: new Field('EndDate', '', []),
            EndAfter: new Field('EndAfter', '', ['integer_not_require']),
            Notes: new Field('Notes', '', ['']),
            IsSwitchDispatch: new Field('IsSwitchDispatch', '', ['']),

            Repeat: new Field('Repeat', getProp(info, 'Repeat', ''), []),
            PendingTasks: new Field('PendingTasks', '', [''], false, 'hidden'),
            TaskInterval: new Field('TaskInterval', '', [''], false, 'select', {}, {values: requiredObject}),
            TaskName: new Field('TaskName', '', [''], false, 'text', {addContainerClass: 'mt-3'}),
            TaskDescription: new Field('TaskDescription', '', [''], false, 'textarea', {addContainerClass: 'mt-3'}),
            TaskGroupID: new Field('TaskGroupID', '', [''], false, 'select-search', {
                addContainerClass: 'mt-3',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showGlobalModal('BoardInfoInfoDialog', it?.value?.value)),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),
        }

        if (includeFields) {
            Object.keys(fieldTemplates).forEach(key => {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key]
                }
            })
        }

        return fieldTemplates
    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({},
            this.getFields(),
            getContactDialogFields(this.props.translate),
            this.getStopFields({}, this.props.translate),
            getStopByFields({}, this.props.translate),
            getCommodityFields(),
        )

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        return Object.assign({
            AllowTracks: 'checkbox',
            AllowChat: 'checkbox',
            AllowShare: 'checkbox',
            CarrierAllowNote: 'checkbox',
            CarrierAllowUpload: 'checkbox',
            CarrierAllowEdit: 'checkbox',
            CarrierAllowShare: 'checkbox',
            CarrierAllowLocation: 'checkbox',
            CalculationPrice: 'money',
            PickupDate: 'datetime',
            PickupEndDate: 'datetime',
            DestinationDate: 'datetime',
            DestinationEndDate: 'datetime',
        }, fieldsCombined);
    }

    getContactDialogFields = () => ({
        FirstName: new Field('FirstName', '', [''], false, 'select-search'),
        LastName: new Field('LastName', '', [''], false, 'select-search'),
        Email: new Field("Email", '', [], false, "text", {
            render: (it) => it.Email ?
                <TextCopy
                    text={it.Email}
                    copyText={this.props.translate("text.copy_to_clipboard")}
                    copiedText={this.props.translate("text.copied")}
                /> :
                null
        }),
        Phone: new Field("Phone", '', [], false, "text", {
            render: (it) => it.Phone ?
                <TextCopy
                    text={it.Phone}
                    copyText={this.props.translate("text.copy_to_clipboard")}
                    copiedText={this.props.translate("text.copied")}
                /> :
                null
        }),
        DepartmentID: new Field('DepartmentID', '', [''], false, 'select-search'),
        Notes: new Field("Notes", '', [], false, "textarea", {limitWidth: true})
    });

    getSwitchDispatchFields = (item) => {
        return {
            DriverID: new Field('DriverID', item?.DriverID ?? this.state.fields.DriverID.value, [''], false, 'select-search', {
                addContainerClass: 'col-span-full sm:col-span-3',
                fieldOptions: (it) => {
                    let driverInDispatch = this.getDispatchedDriverInfo(it?.value?.value);
                    const LoadID = driverInDispatch?.LoadID
                        || (it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && it?.value?.metadata?.OnLoadID)
                    const DriverID = it?.value?.value;
                    const LoadNumber = driverInDispatch?.LoadNumber

                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: ExclamationCircleOutlineIcon,
                                    addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                    onClick: () => openInNewTab("/loads/info/" + LoadID),
                                    toolTip: this.props.translate("text_driver_already_dispatched", [LoadNumber]),
                                    isVisible: !!LoadID
                                },
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: it.value.metadata.ContactID})),
                                    toolTip: this.props.translate("text.view_contact_info"),
                                    isVisible: !!it?.value?.metadata?.ContactID
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    onClick: () => openInNewTab("/drivers/info/" + DriverID),
                                    isVisible: !!DriverID
                                }
                            ]}
                        />
                    )
                }
            }),
            CoDriverID: new Field('CoDriverID', item?.CoDriverID ?? this.state.fields.CoDriverID.value, [''], false, 'select-search', {
                addContainerClass: 'col-span-full sm:col-span-3',
                fieldOptions: (it) => {
                    let driverInDispatch = this.getDispatchedDriverInfo(it?.value?.value);
                    const LoadID = driverInDispatch?.LoadID
                        || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD)
                    const DriverID = it?.value?.value;
                    const LoadNumber = driverInDispatch?.LoadNumber

                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: ExclamationCircleOutlineIcon,
                                    addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                    onClick: () => openInNewTab("/loads/info/" + LoadID),
                                    toolTip: this.props.translate("text_driver_already_dispatched", [LoadNumber]),
                                    isVisible: !!LoadID
                                },
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: it.value.metadata.ContactID})),
                                    toolTip: this.props.translate("text.view_contact_info"),
                                    isVisible: !!it?.value?.metadata?.ContactID
                                },
                                {
                                    icon: ArrowTopRightOnSquareIcon,
                                    toolTip: this.props.translate("text.view_driver_info"),
                                    onClick: () => openInNewTab("/drivers/info/" + DriverID),
                                    isVisible: !!DriverID
                                }
                            ]}
                        />
                    )
                },
            }),
            TruckID: new Field('TruckID', item?.TruckID ?? this.state.fields.TruckID.value, [''], false, 'select-search', {
                addContainerClass: 'col-span-full sm:col-span-3',
                fieldOptions: (it) => {
                    const truckInDispatch = this.dispatchedAssets
                        .find(it =>
                            it.TruckID === this.state.fields.TruckID?.value?.value
                        );
                    const notPermittedTruck = getProp(this.props.resource, 'data.load/info.isNotPermittedTruck', [])[0];
                    const LoadID = truckInDispatch?.LoadID
                        || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD);

                    return (
                        <div className="space-x-1 h-5 relative bottom-1">
                            <FieldOptions
                                options={[
                                    {
                                        icon: ExclamationCircleOutlineIcon,
                                        addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                        onClick: () => openInNewTab("/loads/info/" + LoadID),
                                        toolTip: it?.value?.metadata?.StatusID === 3 ? this.props.translate("text.truck_already_dispatched", [LoadID]) : this.props.translate("text.truck_already_booked", [LoadID]),
                                        isVisible: !!LoadID
                                    },
                                    {
                                        icon: ExclamationTriangleIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trucks/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.truck_inactive"),
                                        isVisible: !!it?.value?.value && this.isInactiveTruck?.TruckID === it?.value?.value
                                    },
                                    {
                                        icon: NoSymbolIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trucks/info/' + notPermittedTruck?.Truck?.TruckID),
                                        toolTip: notPermittedTruck?.Message,
                                        isVisible: !!notPermittedTruck && it?.value?.value === notPermittedTruck?.Truck?.TruckID
                                    },
                                    {
                                        icon: ArrowTopRightOnSquareIcon,
                                        onClick: () => openInNewTab('/trucks/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.view_truck_info"),
                                        isVisible: !!it?.value?.value
                                    }
                                ]}
                            />
                        </div>
                    )
                },
            }),
            TrailerID: new Field('TrailerID', item?.TrailerID ?? this.state.fields.TrailerID.value, [''], false, 'select-search', {
                addContainerClass: 'col-span-full sm:col-span-3',
                fieldOptions: (it) => {
                    const trailerInDispatch = this.getInDispatchTrailerInfo(it?.value?.value);

                    return (
                        <React.Fragment>
                            <FieldOptions
                                options={[
                                    {
                                        icon: ExclamationCircleOutlineIcon,
                                        addClass: "text-yellow-600 hover:text-yellow-800 relative",
                                        onClick: () => openInNewTab('/trailers/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.trailer_already_dispatched", [trailerInDispatch?.LoadID || it?.value?.metadata?.OnLoadID]),
                                        isVisible: trailerInDispatch?.LoadID
                                            || (it?.value?.metadata?.OnLoadID && it?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD)
                                    },
                                    {
                                        icon: ExclamationSolidIcon,
                                        addClass: "text-red-600 hover:text-red-800 relative",
                                        onClick: () => openInNewTab('/trailers/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.trailer_inactive"),
                                        isVisible: !!it?.value?.value && this.isInactiveTrailer?.TrailerID === it?.value?.value
                                    },
                                    {
                                        icon: ArrowTopRightOnSquareIcon,
                                        onClick: () => openInNewTab('/trailers/info/' + it?.value?.value),
                                        toolTip: this.props.translate("text.view_trailer_info"),
                                        isVisible: it?.value?.value
                                    }
                                ]}
                            />
                        </React.Fragment>
                    )
                },
            }),
            Notes: new Field('Notes', item?.Notes ?? this.state.fields.Notes.value, [''], !item, 'textarea', {addContainerClass: 'col-span-full'})
        }
    }

    getDriverFields = () => ({
        Driver: new Field('Driver', '', ['empty'], false, 'text', {}),
        LastActive: new Field('LastActive', '', [''], false, 'date'),
        City: new Field('City', '', ['empty'], false, "text", {omitSort: true}),
        State: new Field('State', '', ['empty']),
        StatusID: new Field('StatusID', '', [''], false, 'custom', {
            render: (item) => (
                <Tippy
                    content={<span>{this.props.translate(DRIVER_STATUS_TYPE_TIPPY_TEXT[item.StatusID])}</span>}
                >
                    <div>
                        <Badge
                            onClick={(e) => {
                                if (item.StatusID === DRIVER_STATUS_BOOKED || item.StatusID === DRIVER_STATUS_ON_LOAD) {
                                    e.stopPropagation();
                                    window.open(`${window.location.origin}/loads/info/${item.OnLoadID}`, '_blank');
                                }
                            }}
                            type={getDriverBadgeClass(item.StatusID)}>
                            <div className="flex">
                                {DRIVER_STATUS_TYPES[item.StatusID]}
                                {(item.StatusID === DRIVER_STATUS_BOOKED || item.StatusID === DRIVER_STATUS_ON_LOAD) && (
                                    <ArrowTopRightOnSquareIcon
                                        className={'w-5 h-5 ml-4'}
                                    />
                                )}
                            </div>
                        </Badge>
                    </div>
                </Tippy>
            )
        })
    });

    getTruckFields = () => ({
        TruckNumber: new Field('TruckNumber', '', ['empty']),
        TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], false, 'select'),
        LastActive: new Field('LastActive', '', [''], false, 'date'),
        City: new Field('City', '', ['empty'], false, "text", {omitSort: true}),
        State: new Field('State', '', ['empty']),
        StatusID: new Field('StatusID', '', [''], false, 'custom', {
            render: (item) => (
                <TrucksTableBadge
                    item={item}
                    translate={this.props.translate}
                />
            )
        })
    });

    getTrailerFields = () => ({
        TrailerNumber: new Field('TrailerNumber', '', ['empty']),
        TrailerOwnershipTypeID: new Field('TrailerOwnershipTypeID', '', [''], false, 'select'),
        LastActive: new Field('LastActive', '', [''], false, 'date'),
        TrailerLength: new Field('TrailerLength', '', ['float_or_empty'], false, 'float'),
        TrailerHeight: new Field('TrailerHeight', '', ['float_or_empty'], false, 'float'),
        City: new Field('City', '', ['empty'], false, "text", {omitSort: true}),
        State: new Field('State', '', ['empty']),
        StatusID: new Field('StatusID', '', [''], false, 'custom', {
            render: (item) => (
                <TrucksTableBadge
                    item={item}
                    translate={this.props.translate}
                />
            )
        })
    });

    getCarrierFields = () => ({
        LegalName: new Field('LegalName', '', ['empty'], false, 'custom', {
            render: (item) => {
                let disabled = !item.Blacklisted && !item.Preferred
                return (
                    <>
                        <Tippy
                            disabled={disabled}
                            content={
                                <span>
                                        {item.Blacklisted ? `Blacklisted by: ${item.BlacklistUpdatedByContact} - ${toFrontDateTimeFromUTC(item.BlacklistUpdateDate)}` : ''}
                                    {item.Preferred ? `Preferred by: ${item.PreferredUpdatedByContact} - ${toFrontDateTimeFromUTC(item.PreferredUpdateDate)}` : ''}
                                    </span>
                            }
                        >
                            <span
                                className={`${disabled ? 'text-primary cursor-pointer font-semibold' : ''} ${item.Blacklisted ? 'color-red' : ''} ${item.Preferred ? 'color-green' : ''} cursor-pointer`}
                            >
                                {`${item.LegalName}`}
                            </span>
                        </Tippy>
                    </>
                )
            }
        }),
        MCNumber: new Field('MCNumber', '', ['empty']),
        USDOTNumber: new Field('USDOTNumber', '', ['empty'])
    })

    getDriverTxtFields = () => {
        return {
            FirstName: new Field('FirstName', '', [''], false, 'text', {}),
            LastName: new Field('LastName', '', [''], false, 'text', {}),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'text', {}),
            Preferred: new Field('Preferred', '', [''], false, 'checkbox', {}),
            IsBlacklisted: new Field('IsBlacklisted', '', [''], false, 'checkbox', {
                render: (it) => it.IsBlacklisted
                    ? <CheckCircleIcon className="w-5 h-5 text-red-600"/>
                    : <XMarkIcon className="w-5 h-5 text-tm-gray-400"/>
            }),
            Notes: new Field('Notes', '', [''], false, 'text', {})
        }
    }

    getAssetSelects = () => ({
        StatusID: {
            [DRIVER_STATUS_AVAILABLE]: "Available",
            [DRIVER_STATUS_BOOKED]: "Booked",
            [DRIVER_STATUS_ON_LOAD]: "On Load"
        },
        CountryID: getLookup('Country', 'CountryID', 'CountryName'),
        StateID: getLookup('State', 'StateID', 'State')
    })

    getAssetFilters = () => (Object.assign({
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }),
            StatusID: new Field('StatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true}),
        },
        getUser("Office.CountryName") === "USA" ? {
            StateID: new Field('StateID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true})
        } : {}
    ));

    getReminderFields = (item) => {
        let fieldTemplates = {
            IsStopUpdateReminderOn: new Field('IsStopUpdateReminderOn', '', [''], false, 'switch', {
                addContainerClass: "px-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl",
                htmlAfter: (it) =>
                    <FieldSwitchLabel
                        onClick={() => this.handleReminderInputChange("IsStopUpdateReminderOn", !it.value)}
                        label={this.props.translate("field.IsStopUpdateReminderOn")}
                        note={this.props.translate("text.StopUpdateReminderInfo")}
                    />
            }),
            StopUpdateReminderInternal: new Field('StopUpdateReminderInternal', '', [item?.IsStopUpdateReminderOn ? 'empty' : ''], false, 'integer', {addContainerClass: "col-span-5"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    cancel = () => {
        if (!this.props.isStateDirty) {
            return
        }

        this.props.dispatch(cancelLoad({
            LoadID: this.getID()
        }));

        this.props.remountInfoTab()
    }

    getQuery = () => {
        return {
            id: this.props.match.params.id,
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            query: '',
        }
    }

    setNewItemState = (itemName, fields) => {
        this.setState({
            [itemName + 'Fields']: fields,
            createOptionAutofill: {}
        })
    }

    guessNextStopType = (locationFields, index) => {
        return (index == (locationFields.length - 1)) ? STOP_TYPE_DELIVERY : STOP_TYPE_PICKUP
    }

    toggleLineOfBusiness = (name, type) => {
        if (this.state.initialLoadStatusID >= LOAD_STATUS_DISPATCHED) return

        this.setState({
            lineOfBusiness: type
        }, () => {
            this.props.setDirty();
            this.updateRequiredFields()
        })
    }

    toggleRedirectDialog = () => {
        this.setState({
            isRedirectDialogOpen: !this.state.isRedirectDialogOpen,
            createdLoadID: this.props?.resource?.create?.id
        })
    }

    toggleShareCarrierDialog = () => {
        this.setState({
            isShareCarrierDialogOpen: !this.state.isShareCarrierDialogOpen
        })
    }

    toggleShareCustomerDialog = () => {
        this.setState({
            isShareCustomerDialogOpen: !this.state.isShareCustomerDialogOpen
        })
    }

    removeTimeFromDate = (date) => {
        return date.split(' ')[0]
    }

    checkCommoditiesForErrors = () => {
        let hasNoErrors = true;

        if (!this.state.stops.length) {
            return hasNoErrors;
        }

        let commodityData = this.state.CommodityData;

        commodityData = commodityData.map(it => {
            if (!it.LoadCommodityPickupID) {
                it.LoadCommodityPickupIDError = true;
                hasNoErrors = false;
            }

            if (!it.LoadCommodityDestinationID) {
                it.LoadCommodityDestinationIDError = true;
                hasNoErrors = false;
            }

            return it;
        });

        this.setState({
            CommodityData: commodityData
        });

        if (!hasNoErrors && !!this.LoadInfoCommoditiesSection.current) {
            this.LoadInfoCommoditiesSection.current.scrollIntoView({block: "center", behavior: "smooth"});
        }

        return hasNoErrors;
    }

    checkLocationsForError = () => {
        let stops = this.state.stops;
        let firstStopIndex = undefined;
        let firstStop = undefined;
        let lastStopIndex = 1;

        let lastStop = {};
        this.state.stopsCombined.forEach((it) => {
            if (it.StopType !== STOP_TYPE_STOP_BY) {
                if (firstStop === undefined && firstStopIndex === undefined) {
                    firstStopIndex = it.index;
                    firstStop = stops[it.index];
                }

                lastStopIndex = it.index;
                lastStop = stops[it.index];
            }
        });

        let locationsCheck = this.state.stops.map((fields) => FieldsManager.checkFieldsForErrors(fields))
        let stopBysCheck = this.state.stopBys.map((fields) => {
            let validateList = ['ActualArrivalDate', 'ActualArrivalDateTime', 'ActualDepartureDate', 'ActualDepartureDateTime'];
            if (fields.IsPickingTrailer.value) {
                validateList.push('PickingTrailerID')
            }
            return FieldsManager.checkFieldsForErrors(
                fields, validateList
            )
        });
        let areStopTypesValid = true;

        if (
            stops.length >= 2
            && (
                Number(firstStop.StopType.value) !== STOP_TYPE_PICKUP
                || Number(lastStop.StopType.value) !== STOP_TYPE_DELIVERY
            )
        ) {
            areStopTypesValid = false;
            stops[firstStopIndex].StopType.errorMessage = Number(firstStop.StopType?.value) !== STOP_TYPE_PICKUP ? "text.stop_should_be_pickup" : "";
            stops[lastStopIndex].StopType.errorMessage = Number(lastStop.StopType?.value) !== STOP_TYPE_DELIVERY ? "text.stop_should_be_delivery" : "";
            this.setState({
                stops
            })
        }

        const areLocationsValid = !locationsCheck.includes(false) && !stopBysCheck.includes(false) && areStopTypesValid;

        if (!areLocationsValid && !!this?.LoadInfoLocationsSection?.current) {
            this.LoadInfoLocationsSection.current.scrollIntoView({block: "start", behavior: "smooth"});
        }

        return areLocationsValid;
    }

    toggleExpandSection = (sectionName) => {
        let expandedSections = Object.values(this.state.expandedSections);

        expandedSections.map(section => {
            if (sectionName === section.name) {
                section.expanded = !section.expanded;
            }
            return section;
        });

        expandedSections = expandedSections.reduce((memo, section) => {
            memo[section.name] = section;
            return memo;
        }, {});

        this.setState({
            expandedSections
        }, () => this.persistSectionSettings())
    }

    handleCancelLoadClick = () => {
        if (this.props.isStateDirty) {
            return null
        }

        this.setState({
            confirmModalAction: this.cancelLoad,
            confirmText: this.props.translate('message.confirm_cancel_load'),
            confirmBtnLabel: this.props.translate('btn.cancel_load_confirm'),
            confirmBody: undefined,
            confirmCancelBtnLabel: this.props.translate('btn.no')
        })
    }

    cancelLoad = () => {
        this.setState({
            loadStatusID: LOAD_STATUS_CANCELED,
            loadStatusLabel: 'Canceled',
            confirmText: null,
            confirmBody: undefined,
            confirmModalAction: null
        }, () => this.submitLoadStatus())
    }

    toggleTonuDialog = () => {
        this.setState({
            isTonuDialogVisible: !this.state.isTonuDialogVisible
        })
    }

    toggleTailgateDialog = () => {
        this.setState({
            isTailgateDialogVisible: !this.state.isTailgateDialogVisible
        })
    }

    toggleRemoveTailgateDialog = () => {
        this.setState({
            isRemoveTailgateDialogOpen: !this.state.isRemoveTailgateDialogOpen
        })
    }

    toggleCreateCarrierDriverDialog = (name = "", isCoDriver = false) => {
        let fullName = {};

        if (name) {
            fullName = {
                firstName: name.substring(0, name.indexOf(' ')),
                lastName: name.substring(name.indexOf(' ') + 1)
            }
        }

        this.setState({
            isCreateCarrierDriverDialogOpen: !this.state.isCreateCarrierDriverDialogOpen,
            selectedItem: {firstName: fullName?.firstName, lastName: fullName?.lastName, isCoDriver}
        });
    }

    updateRequiredFields = (submitAfter) => {
        let fields = Object.assign({}, this.state.fields)
        let locationFields = cloneDeep(this.state.stops)

        if (this.state.loadStatusID === LOAD_STATUS_CANCELED || this.state.loadStatusID === LOAD_STATUS_NEW || this.state.loadStatusID >= LOAD_STATUS_POSSIBLE) {
            fields = FieldsManager.requireField(fields, 'LoadTypeID')
            fields = FieldsManager.requireField(fields, 'CustomerID')
        }

        locationFields.map(it => {
            it.StopDate.validate = Number(this.state.loadStatusID) >= LOAD_STATUS_READY ? ['empty'] : [''];
            //it.StopTime.validate = Number(this.state.loadStatusID) >= LOAD_STATUS_READY ? ['empty'] : [''];
            it.IsStopCompleted.validate = Number(this.state.loadStatusID) === LOAD_STATUS_DELIVERED ? ['empty'] : [];
            return it;
        });

        if (this.state.loadStatusID > LOAD_STATUS_READY && this.state.lineOfBusiness === 'carrier') {
            fields = FieldsManager.requireField(fields, 'DriverID')
            fields = FieldsManager.requireField(fields, 'TruckID')
        } else {
            fields = FieldsManager.doNotRequireField(fields, 'DriverID')
            fields = FieldsManager.doNotRequireField(fields, 'TruckID')
        }

        if (this.state.loadStatusID > LOAD_STATUS_READY && this.state.lineOfBusiness === 'brokerage') {
            fields = FieldsManager.requireField(fields, 'CarrierID')
        } else {
            fields = FieldsManager.doNotRequireField(fields, 'CarrierID')
        }

        if (this.state.loadStatusID >= LOAD_STATUS_DISPATCHED && !!this.loadSettings.RequireCustomerRefDispatch) {
            fields = FieldsManager.requireField(fields, 'CustomerReferenceNumber');
        } else {
            fields = FieldsManager.doNotRequireField(fields, 'CustomerReferenceNumber')
        }

        this.setState({
            fields: fields,
            stops: locationFields,
        }, () => {
            if (submitAfter) {
                this.submit();
            }
        })
    }


    setLoadStatusID = (id) => {
        if (id >= LOAD_STATUS_DISPATCHED && this.state.initialLoadStatusID < id) {
            this.setState({
                showConfirmDialogForStatusUpdate: id
            });
        } else {
            this.updateStatusID(id);
        }

        if (id !== LOAD_STATUS_POSSIBLE && this.state.stops.length === 0) {
            this.handleAddStopsClick();
        }
    }

    updateStatusID = (id, submitAfter) => {
        this.setState({
            loadStatusID: id,
            confirmModalAction: undefined
        }, () => {
            this.props.setDirty()
            this.updateRequiredFields(submitAfter)
        })
    }

    getID = () => {
        return this.props.match.params.id
    }

    getLoadContactGroup = () => {
        const info = getProp(this.props.resource, 'data.load/info', {})

        return {
            label: getProp(info, 'ContactGroup', ''),
            value: getProp(info, 'ContactGroupID', '')
        }
    }


    getAccessorialFields = (item = {}) => {
        const fieldTemplates = {
            AccessorialID: new Field('AccessorialID', '', ['empty_select_search'], false, 'select-search'),
            Accessorial: new Field('Accessorial', '', [''], false, 'text'),
            LoadAccessorialUnits: new Field('LoadAccessorialUnits', '', ['float_or_empty'], false, "float"),
            Amount: new Field('Amount', '', ['float_or_empty'], false, 'float'),
            TotalAmount: new Field('TotalAmount', '', ['float_or_empty'], false, 'float'),
            LoadAccessorialNotes: new Field('LoadAccessorialNotes', '', [''], false, 'textarea')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    toggleUnitSwitchDialog = () => {
        this.setState({
            isUnitSwitchDialogOpen: !this.state.isUnitSwitchDialogOpen
        })
    }

    applySelectedUnitSwitch = (it) => {
        const DriverID = it.DriverID ? {
            value: it.DriverID,
            label: it.Driver,
            metadata: {ContactID: it.DriverContactID}
        } : null;
        const CoDriverID = it.CoDriverID ? {
            value: it.CoDriverID,
            label: it.CoDriverID,
            metadata: {ContactID: it.CoDriverContactID}
        } : null;
        const TrailerID = it.TrailerID ? {value: it.TrailerID, label: it.Trailer} : null;
        const TruckID = it.TruckID ? {value: it.TruckID, label: it.TruckID} : null;

        this.setState({
                updateUnit: this.state.updateUnit + 1,
                splitDispatchBtnDisabled: false,
                selectedUnit: {
                    DriverID: DriverID,
                    CoDriverID: CoDriverID,
                    TrailerID: TrailerID,
                    TruckID: TruckID
                },
                splitDispatchCanSubmit: true
            }, () => {
                this.toggleUnitSwitchDialog()
            }
        )
    }

    updateLocationsState = (params) => {
        let stops = cloneDeep(this.state.stops)

        let stopsFieldsUpdate = stops.map((it, i) => {
            const LocationID = it.LocationData.value.StopID ?? it.LocationData.value.LocationID;
            if (LocationID === params.LocationID) {
                it.StopType.value = this.guessNextStopType(stops, i)

                it.LocationData.value = params
                it.StopID.value.label = params.LocationName
                it.StopID.value.metadata = params
            }
            return it
        })

        this.setState({
            stops: stopsFieldsUpdate
        })
    }

    createLocationsState = (params) => {
        params.State = this.statesFullName[params.StateID];

        if (params.State) {
            params.State = params.State.split(" (")[0];
        }

        let stopsClone = cloneDeep(this.state.stops);
        let stopsCombinedClone = cloneDeep(this.state.stopsCombined)

        stopsClone.map((it, i) => {
            if (i === it.index) {
                stopsClone[i].StopType.value = this.guessNextStopType(stopsClone, i)

                stopsClone[i].LocationData.value = params;
                stopsClone[i].StopID.value.label = params.LocationName;
                stopsClone[i].StopID.value.metadata = params;
                stopsClone[i].StopID.value.metadata = params;
                stopsClone[i].NewLocationData = {value: params}
            }
            return it;
        });

        stopsCombinedClone.map((it) => {
            if (it.IsSelected) {
                it.IsSelected = true;
                it.IsStopCompleted = false;
                it.StopName = params.LocationName;
                it.StopType = stopsClone[it.index].StopType.value;
                it.addressName = getHighlightedFullAddressName(params);
            }
            return it;
        });

        this.setState({
            stops: stopsClone,
            stopsCombined: stopsCombinedClone,
            createdLocationData: params
        })
    }

    watchWindowSize = () => {
        const smallScreenBreakpoint = 768
        const xlScreenBreakpoint = 1200

        if (window.innerWidth < smallScreenBreakpoint) {
            if (this.state.screenBreakpoint !== 'small') {
                this.setState({
                    screenBreakpoint: 'small'
                })

            }
        }

        if (window.innerWidth > smallScreenBreakpoint && window.innerWidth <= xlScreenBreakpoint) {
            if (this.state.screenBreakpoint !== 'large') {
                this.setState({
                    screenBreakpoint: 'large'
                })

            }
        }

        if (window.innerWidth > xlScreenBreakpoint) {
            if (this.state.screenBreakpoint !== 'xl') {
                this.setState({
                    screenBreakpoint: 'xl'
                })

            }
        }
    }

    handleAddStopsClick = () => {
        let stops = [];
        const stopsCombined = mergeStops(
            [],
            []
        );

        stops.push(
            this.getStopFields({
                StopType: STOP_TYPE_PICKUP,
                LoadStopID: -1
            }, this.props.translate),
            this.getStopFields({
                StopType: STOP_TYPE_DELIVERY,
                LoadStopID: -2
            }, this.props.translate)
        );

        stops = this.addStopFieldsActions(stops);

        this.setState({
            stops,
            stopsCombined,
            currentCreatedStopOrder: -2
        }, () => {
            this.updateRequiredFields();
        });
    }

    handleRemoveStopsClick = () => {
        this.setState({
            stops: [],
            stopBys: [],
            stopsCombined: [],
            EmptyMiles: undefined,
            TotalMiles: undefined,
            TotalMilesNoStopBy: undefined
        }, () => this.props.setDirty());
    }

    generateStopBys = (data) => {
        let stopBysData;
        if (data) {
            stopBysData = data;
        } else {
            stopBysData = getProp(this.props.resource, 'data.load/stopby', []);
        }
        let stopBys = []

        if (stopBysData.length) {
            stopBys = stopBysData.reduce((memo, it) => {
                memo.push(getStopByFields(it, this.props.translate));
                return memo;
            }, [])
        }

        return stopBys;
    }

    getDispatchedDriverInfo = (DriverID) => {
        if (!DriverID) return;

        return !!this.dispatchedAssets && this.dispatchedAssets.find(it => it.DriverID === DriverID || it.CoDriverID === DriverID);
    }

    getInDispatchTrailerInfo = (TrailerID) => {
        return !!this.dispatchedAssets && this.dispatchedAssets.find(it => it.TrailerID === TrailerID && TrailerID !== undefined);
    }

    handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "start", behavior: "smooth"})
    }

    handleSetCreateLoadDocuments = (files) => {
        this.setState({
            createLoadDocuments: files.concat(this.state.createLoadDocuments)
        })
    }

    handleRemoveDocument = (i) => {
        const createLoadDocuments = [...this.state.createLoadDocuments];

        createLoadDocuments.splice(i, 1);

        this.setState({
            createLoadDocuments: createLoadDocuments
        });
    }

    persistSectionSettings = () => {
        if (this.props.match.params.id) {
            LocalStorage.set("load_section_settings", this.state.expandedSections);
        }
    }

    getSectionSettings = () => {
        const defaultData = {
            LoadInfoDocuments: {
                name: "LoadInfoDocuments",
                expanded: true,
                visible: this.isCreateMode
            },
            LoadInfoBasic: {
                name: "LoadInfoBasic",
                expanded: true,
                visible: true
            },
            LoadInfoPricing: {
                name: "LoadInfoPricing",
                expanded: true,
                visible: !this.props.match.params.id && !this.props.isCopyMode
            },
            LoadInfoAssets: {
                name: "LoadInfoAssets",
                expanded: true,
                visible: true
            },
            LoadInfoLocations: {
                name: "LoadInfoLocations",
                expanded: true,
                visible: true
            },
            LoadInfoReminder: {
                name: "LoadInfoReminder",
                expanded: true,
                visible: true
            },
            LoadInfoCommodities: {
                name: "LoadInfoCommodities",
                expanded: true,
                visible: true
            },
            LoadInfoMap: {
                name: "LoadInfoMap",
                expanded: true,
                visible: !!this.props.match.params.id
            },
            LoadInfoAccessorials: {
                name: "LoadInfoAccessorials",
                expanded: true,
                visible: !this.props.match.params.id
            }
        }
        const LoadSectionSetting = this.props.match.params.id ? LocalStorage.get("load_section_settings") : null;

        if (LoadSectionSetting) {
            return Object.values(defaultData).reduce((memo, it) => {
                memo[it.name] = Object.assign(it, LoadSectionSetting[it.name]);
                return memo;
            }, {});
        }
        return defaultData
    }

    getBookedByQuery = () => {
        if (this.state.fields.CustomerID?.value?.value && this.state.lineOfBusiness === 'brokerage') {
            return {SelectCommisionID: this.state.fields.CustomerID.value.value}
        }

        if (this.state.lineOfBusiness !== 'brokerage') {
            return {
                SelectDriverOrID: this.state.fields.DriverID?.value?.value,
                SelectCommisionOrID: this.state.fields.CustomerID?.value?.value
            }
        }

        return null
    }

    getCoveredByQuery = () => {
        if (this.state.fields.DriverID?.value?.value && this.state.lineOfBusiness !== 'brokerage') {
            return {SelectDriverID: this.state.fields.DriverID.value.value}
        }

        if (this.state.lineOfBusiness === 'brokerage') {
            return {
                IsDispatch: 1,
                OfficeID: this.state.fields.OfficeID?.value?.value
            }
        }

        return null
    }

    getBookedByMessage = () => {
        let messages = [];
        if (this.state.lineOfBusiness !== 'brokerage') {
            if (this.state.fields.DriverID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_driver_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/drivers/info/"
                        + this.state.fields.DriverID?.value?.value
                        + "?tab=drivers/contacts"
                    )
                });
            }

            if (this.state.fields.CustomerID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_customer_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/customers/info/"
                        + this.state.fields.CustomerID?.value?.metadata?.OrganizationID
                        + "/"
                        + this.state.fields.CustomerID?.value?.value
                        + "?tab=customers/comissions&customer=" + this.state.fields.CustomerID?.value?.label
                    )
                });
            }
        }

        if (this.state.lineOfBusiness === 'brokerage') {
            if (this.state.fields.CustomerID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_customer_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/customers/info/"
                        + this.state.fields.CustomerID?.value?.metadata?.OrganizationID
                        + "/"
                        + this.state.fields.CustomerID?.value?.value
                        + "?tab=customers/comissions&customer=" + this.state.fields.CustomerID?.value?.label
                    )
                });
            }
        }

        return messages;
    }

    getCoveredByMessage = () => {
        let messages = [];
        if (this.state.lineOfBusiness !== 'brokerage') {
            if (this.state.fields.DriverID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.covered_by_carrier_driver_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/drivers/info/"
                        + this.state.fields.DriverID?.value?.value
                        + "?tab=drivers/contacts"
                    )
                });
            }
        }

        return messages
    }

    getChoseDispatchContactMessage = () => {
        let messages = [];

        if (this.state.fields.CarrierID?.value?.value) {
            messages.push({
                content:
                    <div className="flex w-full">
                        {this.props.translate("text.dispatch_contact_message")}

                        <div className="ml-auto text-sky-600">
                            <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                        </div>
                    </div>
                ,
                handler: () => openInNewTab("/carriers/info/"
                    + this.state.fields.CarrierID?.value?.metadata?.OrganizationID
                    + "/"
                    + this.state.fields.CarrierID.value.value
                    + "?tab=carriers/contacts"
                )
            });
        }
        return messages;
    }

    getInfoWarnings = (isPageLoading) => {
        if (isPageLoading) {
            return {}
        }

        const isCustomerBlacklisted = !!this.state.fields.CustomerID?.value?.metadata?.CreditHold;
        const duplicateRefNumber = getProp(this.props.resource, 'data.load/info.isDuplicateRefNumber', null)
        const isOverCreditLimit = this.state.CustomerCreditLimit && this.state.CustomerAvailableCredit < numberWithCommasToBack(this.state.fields['Price'].value);
        const allowOverLimit = this.state.CustomerAllowCreditLimitOverride;

        return {
            isCustomerBlacklisted,
            duplicateRefNumber,
            isOverCreditLimit,
            allowOverLimit,
            OrganizationID: this.state.fields?.CustomerID?.value?.metadata?.OrganizationID,
            CustomerID: this.state.fields?.CustomerID?.value?.value,
            DuplicateLoadID: duplicateRefNumber,
            hasWarning: isCustomerBlacklisted || duplicateRefNumber || isOverCreditLimit
        }
    }


    getAssetWarnings = () => {
        const hasDriverWarning = !!this.state.fields.DriverID?.value?.value
            && (
                this.state.fields.DriverID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
                || this.dispatchedAssets
                    .find(it =>
                        it.DriverID === this.state.fields.DriverID?.value?.value ||
                        it.CoDriverID === this.state.fields.DriverID?.value?.value
                    )
            );

        const hasCoDriverWarning = !!this.state.fields.CoDriverID?.value?.value
            && (
                this.state.fields.CoDriverID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
                || !!this.dispatchedAssets
                    .find(it =>
                        it.DriverID === this.state.fields.CoDriverID?.value?.value ||
                        it.CoDriverID === this.state.fields.CoDriverID?.value?.value
                    ))

        const hasTruckWarning = this.state.fields.TruckID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
            || !!this.dispatchedAssets
                .find(it =>
                    it.TruckID === this.state.fields.TruckID?.value?.value
                )

        const notPermittedTruck = getProp(this.props.resource, 'data.load/info.isNotPermittedTruck', []);

        const inactiveDriverData = getProp(this.props.resource, 'data.load/info.isInactiveDriver', [])
                .find(it => it.DriverID === this.state.fields.DriverID?.value?.value)
            || (
                this.state.fields.DriverID.value?.metadata?.StatusID >= DRIVER_STATUS_ON_VACATION
                && this.state.fields.DriverID.value?.metadata
            )

        const inactiveCoDriverData = getProp(this.props.resource, 'data.load/info.isInactiveDriver', [])
            .find(it => it.DriverID === this.state.fields.CoDriverID?.value?.value)
            || (
                this.state.fields.CoDriverID.value?.metadata?.StatusID >= DRIVER_STATUS_ON_VACATION
                && this.state.fields.CoDriverID.value?.metadata
            )

        return {
            hasDriverWarning,
            driverOnLoadID: (
                !!this.state.fields.DriverID?.value?.value
                && this.state.fields.DriverID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
                && this.state.fields.DriverID?.value?.metadata?.OnLoadID
            ) || (this.dispatchedAssets
                    .find(it =>
                        it.DriverID && it.DriverID === this.state.fields.DriverID?.value?.value ||
                        it.CoDriverID && it.CoDriverID === this.state.fields.DriverID?.value?.value
                    )?.LoadID
            ),
            driverOnLoadLoadNumber: this.dispatchedAssets
                .find(it =>
                    it.DriverID && it.DriverID === this.state.fields.DriverID?.value?.value ||
                    it.CoDriverID && it.CoDriverID === this.state.fields.DriverID?.value?.value
                )?.LoadNumber,
            hasCoDriverWarning,
            coDriverOnLoadID: (
                !!this.state.fields.CoDriverID?.value?.value
                && this.state.fields.CoDriverID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
                && this.state.fields.CoDriverID?.value?.metadata?.OnLoadID
            ) || (this.dispatchedAssets
                    .find(it =>
                        it.DriverID && it.DriverID === this.state.fields.CoDriverID?.value?.value ||
                        it.CoDriverID && it.CoDriverID === this.state.fields.CoDriverID?.value?.value
                    )?.LoadID
            ),
            coDriverOnLoadLoadNumber: this.dispatchedAssets
                .find(it =>
                    it.DriverID && it.DriverID === this.state.fields.CoDriverID?.value?.value ||
                    it.CoDriverID && it.CoDriverID === this.state.fields.CoDriverID?.value?.value
                )?.LoadNumber,
            hasTruckWarning,
            truckOnLoadID: (
                this.state.fields.TruckID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD
                && this.state.fields.TruckID?.value?.metadata?.OnLoadID
            ) || (this.dispatchedAssets
                    .find(it =>
                        it.TruckID && it.TruckID === this.state.fields.TruckID?.value?.value
                    )?.LoadID
            ),
            truckOnLoadLoadNumber: this.dispatchedAssets
                .find(it =>
                    this.state.fields.TruckID?.value?.value && it.TruckID === this.state.fields.TruckID?.value?.value
                )?.LoadNumber,
            trailerOnLoadID: (this.state.fields.TrailerID?.value?.metadata?.StatusID === TRUCK_STATUS_ON_LOAD && this.state.fields.TrailerID?.value?.metadata?.OnLoadID)
                || (this.getInDispatchTrailerInfo(this.state.fields.TrailerID?.value?.value)?.LoadID),
            trailerOnLoadLoadNumber: this.getInDispatchTrailerInfo(this.state.fields.TrailerID?.value?.value)?.LoadNumber,
            truckStatusID: this.state.fields.TruckID?.value?.metadata?.StatusID || getProp(this.props.resource.data, 'load/info.isInactiveTruck', false)?.StatusID,
            trailerStatusID: this.state.fields.Trailer?.value?.metadata?.StatusID || getProp(this.props.resource.data, 'load/info.isInactiveTrailer', false)?.StatusID,
            hasAssetsWarnings: hasDriverWarning || hasCoDriverWarning || hasTruckWarning,
            inactiveDriverData: inactiveDriverData,
            hasInactiveDriver: !!inactiveDriverData,
            inactiveCoDriverData: inactiveCoDriverData,
            hasInactiveCoDriver: !!inactiveCoDriverData,
            isInactiveTruck:
                getProp(this.props.resource.data, 'load/info.isInactiveTruck', false)?.TruckID !== undefined
                && getProp(this.props.resource.data, 'load/info.isInactiveTruck', false)?.TruckID
                === this.state.fields.TruckID?.value?.value,
            isInactiveTrailer:
                getProp(this.props.resource.data, 'load/info.isInactiveTrailer', false)?.TrailerID !== undefined
                && getProp(this.props.resource.data, 'load/info.isInactiveTrailer', false)?.TrailerID
                === this.state.fields.TrailerID?.value?.value,
            inactiveTrailerID: getProp(this.props.resource.data, 'load/info.isInactiveTrailer', false)?.TrailerID,
            inactiveTrailer: getProp(this.props.resource.data, 'load/info.isInactiveTrailer', false)?.Trailer,
            isCarrierBlacklisted:
                (
                    !!this.isCarrierBlacklisted?.CarrierID
                    && this.isCarrierBlacklisted?.CarrierID === this.state.fields.CarrierID?.value?.value
                )
                || !!this.state.fields.CarrierID?.value?.metadata?.Blacklisted,
            blackListedCarrier: Object.assign(
                {},
                {CarrierID: this.state.fields.CarrierID?.value?.value},
                this.isCarrierBlacklisted,
                {OrganizationID: this.state.fields.CustomerID?.value?.metadata?.OrganizationID},
                {
                    BlacklistUpdatedByContact: this.isCarrierBlacklisted?.BlacklistUpdatedByContact
                        ? this.isCarrierBlacklisted.BlacklistUpdatedByContact
                        : this.state.fields.CarrierID?.value?.metadata?.BlacklistUpdatedByContact
                }
            ),
            inactiveTruckID: getProp(this.props.resource.data, 'load/info.isInactiveTruck', false)?.TruckID,
            inactiveTruck: getProp(this.props.resource.data, 'load/info.isInactiveTruck', false)?.Truck,
            notPermittedTruck: notPermittedTruck,
            isNotPermittedTruck: !!notPermittedTruck
                .find(it => it?.Truck?.TruckID === this.state.fields.TruckID?.value?.value)
        };
    }

    getCustomerLoadSetting = (CustomerLoadSetting) => {
        const statusMap = Object.keys(this.loadStatuses).reduce((memo, it) => {
            memo[it] = this.loadStatuses[it];
            return memo;
        }, {});

        return Object.values(CustomerLoadSetting).reduce((memo, it) => {
            it.Type = statusMap[it.Type] ?? 0;
            memo[it.Type] = it;
            return memo;
        }, {})
    }

    toggleIncludeInMileage = (index) => {
        const stopsCombined = this.state.stopsCombined;

        let message = stopsCombined[index].IncludeInMileage === 0
            ? this.props.translate("text.include_mileage_between", [stopsCombined[index - 1]?.metadata?.LocationName ?? ("STOP " + index), stopsCombined[index]?.metadata?.LocationName ?? ("STOP " + (index + 1))])
            : this.props.translate("text.exclude_mileage_between", [stopsCombined[index - 1]?.metadata?.LocationName ?? ("STOP " + index), stopsCombined[index]?.metadata?.LocationName ?? ("STOP " + (index + 1))])

        this.setState({
            confirmBody: message,// stopsCombined[index - 1].StopName, stopsCombined[index].StopName,
            confirmModalAction: () => this.confirmIncludeInMileage(index),
            confirmBtnLabel: this.props.translate("btn.confirm"),
            confirmCancelBtnLabel: this.props.translate("btn.cancel"),
            hasIncludedInMileageResetMessage: false
        });
    }

    confirmIncludeInMileage = (index) => {
        const stopsCombined = this.state.stopsCombined;

        stopsCombined[index].IncludeInMileage = stopsCombined[index].IncludeInMileage === 0 ? 1 : 0;
        this.setState({
            stopsCombined,
            doesStopsNeedsUpdate: true,
            confirmModalAction: undefined
        }, () => this.props.setDirty());
    }

    getCommodityVolume = (commodity) => {
        const selectedMeasureUnit = getMeasurementUnitName(commodity.MeasureUnitID);
        const width = cnv(commodity.Width, selectedMeasureUnit, this.standardLengthMeasure)
        const height = cnv(commodity.Height, selectedMeasureUnit, this.standardLengthMeasure)
        const length = cnv(commodity.Length, selectedMeasureUnit, this.standardLengthMeasure)

        return width * height * length;
    }

    render() {
        const {translate, resource, secondResource, isLayoutScrolled, scrollLayoutOnTop} = this.props;
        const isLoadInternational = getProp(this.props.resource, 'data.load/info.LoadType', "") === "International";
        const isEditMode = this.props.match.params.id
        const UseMultiSCAC = getProp(this.props, 'secondResource.data.UseMultiSCAC', '');
        const IsTonu = getProp(this.props.resource, 'data.load/info.IsTonu', 0);
        const tailgateParentID = getProp(this.props.resource, 'data.load/info.ParentLoadID', 0);
        // const tailgateParentNumber = getProp(this.props.resource, 'data.load/info.ParentLoadNumber', 0);
        const tailgated = getProp(this.props.resource.data, 'load/info.tailgated', []);

        const isEmptyMilesWarning = getProp(this.props.resource.data, 'load/info.isEmptyMilesWarning', 0);

        const isPageLoading = !!this.props.resource.isLoading || !!this.props.secondResource.isLoading || this.props.dialogResource.isLoading;

        const inactiveDriverData = getProp(this.props.resource, 'data.load/info.isInactiveDriver', [])[0];

        const isDifferentStatusSelected = this.state.loadStatusID != this.state.initialLoadStatusID && !this.props.resource.isLoading

        const isDispatched = this.state.initialLoadStatusID >= LOAD_STATUS_DISPATCHED
        const isDelivered = this.state.initialLoadStatusID == LOAD_STATUS_DELIVERED

        let commodityUnitType = undefined;

        const totalCommodityWeight = numberWithCommas(this.state.CommodityData.reduce((memo, it) => {
            if (!commodityUnitType) {
                commodityUnitType = it.UnitType
            }

            if (!!commodityUnitType && commodityUnitType !== it.UnitType) {
                commodityUnitType = translate("text.unit_s");
            }

            if (!it.Weight) {
                return memo
            }

            memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), this.defaultWeightUnit))
            return memo
        }, 0))

        if (!commodityUnitType) {
            commodityUnitType = translate("text.unit_s");
        }

        let totalCommodityPieces = 0;
        let totalCommodityVolume = 0;
        const totalCommodityPallets = this.state.CommodityData.reduce((memo, it) => {
            totalCommodityVolume = totalCommodityVolume + (this.getCommodityVolume(it) * it.CountPallets);
            totalCommodityPieces += it.CountPieces;
            memo = memo + Number(it.CountPallets)
            return memo
        }, 0);

        const basicInfoFieldsList = ["LoadTypeID", "LoadSubTypeID", "CustomerID", "CustomerReferenceNumber", "OfficeID", "ContactGroupID", "InternalNotes", "ExternalNotesCustomer"];

        const basicInfoFieldsListClone = includeFields(this.state.fields, basicInfoFieldsList);

        Object.keys(this.fieldsRefs).forEach(fieldName => {
            basicInfoFieldsListClone[fieldName].props.innerRef = this.fieldsRefs[fieldName];
        })

        const basicInfoFields = fieldsToHtml(Object.values(basicInfoFieldsListClone), translate, this.handleInputChange, this.selects);

        const hasErrorsBasicInfoFields = basicInfoFieldsList.find(it => !!this.state.fields[it].errorMessage);

        const selects = Object.assign({}, this.selects, {
            DriverNameTxt: {
                api: 'api/' + Resources.CarrierDrivers,
                query: {id: this.state.fields.CarrierID?.value?.value},
                searchMap: (it) => ({
                    label: `${it.FirstName} ${it.LastName}`,
                    value: it.CarrierDriverID,
                    metadata: it
                })
            },
            CoDriverNameTxt: {
                api: 'api/' + Resources.CarrierDrivers,
                query: {id: this.state.fields.CarrierID?.value?.value},
                searchMap: (it) => ({
                    label: `${it.FirstName} ${it.LastName}`,
                    value: it.CarrierDriverID,
                    metadata: it
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                    NotSoldRetired: 1
                }),
                customizeList: (list) => {
                    if (UseMultiSCAC === 1) {
                        return groupListBySCAC(list, 'Truck')
                    } else {
                        return list
                            .map(it => ({
                                label: (it.SCAC ? it.SCAC + " " : "") + it['Truck' + "Number"],
                                value: it['Truck' + "ID"],
                                metadata: it
                            }))
                    }

                }
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                    NotSoldRetired: 1
                }),
                customizeList: (list) => {
                    if (UseMultiSCAC === 1) {
                        return groupListBySCAC(list, 'Trailer')
                    } else {
                        return list
                            .map(it => ({
                                label: (it.SCAC ? it.SCAC + " " : "") + it['Trailer' + "Number"],
                                value: it['Trailer' + "ID"],
                                metadata: it
                            }))
                    }
                }
            }
        })

        const assetFieldsList = this.state.lineOfBusiness === 'carrier' ?
            ["DriverID", "CoDriverID", "TruckID", "TrailerID", "TrailerType", "SecondTrailerID", "SecondTrailerType", "ThirdTrailerID", "ThirdTrailerType", "ExternalDriverNotes"] :
            ["CoBrokerID", "CarrierID", "CarrierReferenceNumber", "DriverNameTxt", "DriverCellTxt", "CoDriverNameTxt", "CoDriverCellTxt", "TruckTxt", "TrailerTxt", "TrailerTypeTxt", "ExternalCarrierNotes"]

        const assetFieldsClone = includeFields(this.state.fields, assetFieldsList);

        Object.keys(this.fieldsRefs).forEach(fieldName => {
            assetFieldsClone[fieldName].props.innerRef = this.fieldsRefs[fieldName];
        })

        const assetFields = fieldsToHtml(Object.values(assetFieldsClone), translate, this.handleInputChange, selects);

        const infoWarnings = this.getInfoWarnings(isPageLoading);
        const assetWarnings = this.getAssetWarnings();

        const hasErrorsAssetFields = assetFieldsList.find(it => !!this.state.fields?.[it]?.errorMessage);

        let hasErrorsStopFields = !!this.state.stopsCombined.find(it => {
                return it.StopType !== STOP_TYPE_STOP_BY ?
                    !!this.state.stops[it.index]?.StopID?.errorMessage :
                    !FieldsManager.checkFieldsForErrors(this.state.stopBys[it.index], ['ActualArrivalDate', 'ActualArrivalDateTime', 'ActualDepartureDate', 'ActualDepartureDateTime'])
            }
        );

        let selectedCombinedLocationIndex = 0;
        let selectedLocation = this.state.stopsCombined.find((it, i) => {
            selectedCombinedLocationIndex = i;
            return !!it.IsSelected;
        });

        if (selectedLocation) {
            const isStopBy = selectedLocation.StopType === STOP_TYPE_STOP_BY;
            selectedLocation = isStopBy ? this.state.stopBys[selectedLocation.index] : this.state.stops[selectedLocation.index];
        }

        const hasCompletedStops = this.state.stopsCombined.some(it => !!it.IsStopCompleted)
        const areAllStopsCompleted = !!this.state.stopsCombined[0] && this.state.stopsCombined.every(it => it.IsStopCompleted);

        return (
            <div
                className="grid gap-4 grid-cols-2"
            >
                {this.props.isEditMode && (
                    <div className="col-span-2 isolate z-30">
                        <div className="3xl:max-w-7xl mx-auto">
                            <ProgressArrows
                                locked={!!this.state.isLoadInvoiced && !!IsTonu && (this.state.loadStatusID === LOAD_STATUS_DELIVERED)}
                                LoadStatusID={this.state.loadStatusID}
                                initialLoadStatusID={this.state.initialLoadStatusID}
                                onItemClick={this.setLoadStatusID}
                                translate={translate}
                                possibleStatusRef={this.possibleStatusRef}
                                readyStatusRef={this.readyStatusRef}
                                coveredStatusRef={this.coveredStatusRef}
                                dispatchedStatusRef={this.dispatchedStatusRef}
                                inTransitStatusRef={this.inTransitStatusRef}
                                deliveredStatusRef={this.deliveredStatusRef}
                            />
                        </div>
                    </div>
                )}

                <div className="relative z-20 col-span-2 w-full 3xl:max-w-7xl mx-auto grid grid-cols-12 gap-4">
                    {!this.props.isCopyMode && !!isEditMode && (
                        <div className="col-span-12 lg:col-span-8">
                            <div className="flex justify-center w-full flex-col gap-4">
                                {!!tailgateParentID && (
                                    <InfoBar
                                        onClick={() => openInNewTab("/loads/info/" + tailgateParentID)}
                                        Icon={TruckSolidIcon}
                                    >
                                        {/*This load marked as tailgate of load {tailgateParentNumber}*/}
                                        This load is marked as a tailgate of other load. Click to view that load.
                                    </InfoBar>
                                )}

                                {!!tailgated.length && (
                                    <InfoBar
                                        Icon={TruckSolidIcon}
                                    >
                                        This load is part of tailgate ({tailgated.map(it => (
                                        <button
                                            onClick={() => openInNewTab("/loads/info/" + it.LoadID)}
                                            key={it.LoadNumber}
                                            className="btn btn-text p-0 h-auto text-xs"
                                        >
                                            {it.LoadNumber}
                                        </button>
                                    ))})
                                    </InfoBar>
                                )}

                                {isDifferentStatusSelected && !isPageLoading && !(hasCompletedStops && this.state.loadStatusID < LOAD_STATUS_DISPATCHED) && (
                                    <InfoParagraph>
                                        {translate('info.load_status_is_changed')}
                                    </InfoParagraph>
                                )}

                                {hasCompletedStops && this.state.loadStatusID < LOAD_STATUS_DISPATCHED && (
                                    <InfoParagraph type='danger'>
                                        {translate('info.completed_stops_cannot_select')}
                                    </InfoParagraph>
                                )}

                                {!!IsTonu && (
                                    <InfoParagraph type="danger">
                                        {translate('info.load_is_tonu')}
                                    </InfoParagraph>
                                )}

                                {/*{!!this.state.isLoadInvoiced && (*/}
                                {/*    <InfoParagraph>*/}
                                {/*        {translate('info.load_is_invoiced')}*/}
                                {/*    </InfoParagraph>*/}
                                {/*)}*/}

                                {!!this.props.isEditMode && this.state.initialLoadStatusID === LOAD_STATUS_CANCELED && (
                                    <InfoParagraph type="danger">
                                        {translate('info.load_is_canceled')}
                                    </InfoParagraph>
                                )}
                            </div>
                        </div>
                    )}


                    {!isEditMode && (
                        <div className="col-span-8 flex items-center gap-4">
                            <ContactSelectButton
                                disabled={isDelivered}
                                id={this.state.fields?.BookedByID?.value?.value}
                                name={this.state.fields?.BookedByID?.value?.label}
                                hasImagePath={!!this.state.fields?.BookedByID?.value?.value}
                                text={translate("text.booked_by")}
                                onChoseContactClick={this.handleChoseBookedByClick}
                                onClearContactClick={() => this.handleInputChange("BookedByID", "")}
                                onAvatarClick={() => this.props.dispatch(
                                    showModal(
                                        'ViewContactCard',
                                        {ContactID: this.state.fields?.BookedByID?.value?.value})
                                )}
                                isLoading={isPageLoading}
                                translate={this.props.translate}
                            />
                        </div>
                    )}


                    {!this.props.isCopyMode && !!isEditMode && (
                        <div className={"relative col-span-full lg:col-span-4"}>

                            {!!resource.isLoading && (
                                <LoaderSmall disableContainer addClass={"absolute right-0 mt-2 mr-2"}/>
                            )}

                            <div
                                className={classNames("flex grow justify-end items-center",
                                    !!resource.isLoading && "invisible"
                                )}>

                                <InfoAccess
                                    LoadID={this.getID()}
                                    allowCustomerShare={this.state.linkOptions?.[0]?.value}
                                    className="ml-3 inline-block z-10 relative"
                                    translate={translate}
                                    resource={resource}
                                    toggleLinkOption={this.toggleLinkOption}
                                    toggleLinkAllOptions={this.toggleLinkAllOptions}
                                    linkOptions={this.state.linkOptions}
                                    onSendURLClick={this.toggleSendURLDialog}
                                    toggleHistoryDialog={this.toggleHistoryDialog}
                                    colorTags={this.props.colorTags}
                                    selectedColorTabs={this.props.loadColorTags}
                                    onTagLoad={this.props.onTagLoad}
                                    toggleTagManagerModal={this.props.toggleTagManagerModal}
                                    tagPopoverButtonRef={this.props.tagPopoverButtonRef}
                                    toggleShareCustomerDialog={this.toggleShareCustomerDialog}
                                    MarkLoad={
                                        <div className="relative overflow-hidden rounded-btn">
                                            <ButtonIcon
                                                tooltip={translate('text.needsAttention')}
                                                hasPerm={checkPerm(Resources.LoadMark, UPDATE_PERM)}
                                                type="button"
                                                onClick={() => this.toggleNeedsAttention(!this.state.fields.Marked.value)}
                                                className={classNames(
                                                    'btn btn-header z-10 m-0',
                                                    this.state.fields.Marked.value ? "text-red-500 bg-transparent border-red-600" : undefined)
                                                }
                                            >
                                                <FireIcon className="h-5 w-5"/>
                                            </ButtonIcon>

                                            {!!this.state.fields.Marked.value && (
                                                <span
                                                    className="-inset-px animate-ping absolute inline-flex rounded-full border-8 border-red-600 opacity-75 z-0"/>
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    )}

                    {!isEditMode && (
                        <div className={"relative col-span-full lg:col-span-4"}>

                            {!!resource.isLoading && (
                                <LoaderSmall disableContainer addClass={"absolute right-0 mt-2 mr-2"}/>
                            )}

                            <div
                                className={classNames("flex grow justify-end items-center",
                                    !!resource.isLoading && "invisible"
                                )}>
                                <Flags
                                    authorizedFlags={['OCRScanON']}
                                    renderOn={() => (
                                        <div className={"flex"}>
                                            <div className={"grow pr-4"}>
                                                <DropZoneAlt
                                                    className={"border-2 border-dashed rounded-card"}
                                                    maxFilesAccepted={1}
                                                    accept={"application/pdf"}
                                                    translate={translate}
                                                    onAcceptFiles={(file) => this.setState({
                                                        OCRDropzoneDocument: file,
                                                        isOCRDialogOpen: true
                                                    })}
                                                    content={(isDragAccept, isDragReject) => {
                                                        return (
                                                            <React.Fragment>
                                                                <div className={classNames(
                                                                    isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                                    isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                                    !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                                    "pb-4 w-full h-full relative rounded-card"
                                                                )}>
                                                                    <div className={"px-4 mt-4"}>
                                                                        Autofill load from uploaded document
                                                                    </div>
                                                                </div>

                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            </div>

                                            <Tippy
                                                content={translate("text.fill_data_from_document")}
                                                delay={[400, 0]}
                                                trigger={"mouseenter"}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={this.openScanDialog}
                                                    className={classNames('btn btn-header relative z-10 m-0')}
                                                >
                                                    <RectangleGroupIcon className="h-5 w-5"/>
                                                </button>
                                            </Tippy>

                                            <div className={"pl-2"}>
                                                <Tippy
                                                    content={translate("text.view_background_jobs")}
                                                    delay={[400, 0]}
                                                    trigger={"mouseenter"}
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={this.openJobsListingModal}
                                                        className={classNames('btn btn-header relative z-10 m-0')}
                                                    >
                                                        <RectangleStackIcon className="h-5 w-5"/>
                                                    </button>
                                                </Tippy>
                                            </div>


                                        </div>
                                    )}
                                    renderOff={() => null}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="col-span-2">
                    {this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED && (
                        <div className="flex max-w-full 3xl:max-w-7xl 3xl:mx-auto relative">
                            <div
                                className="hidden w-36 h-full absolute xl:block -left-4 3xl:left-auto 3xl:right-full -translate-x-4 3xl:-translate-x-4 ml-8">
                                <div className="sticky h-10 top-16">
                                    {isLayoutScrolled && (
                                        <div className="flex space-x-2">
                                            <GoBackButton
                                                translate={this.props.translate}
                                                history={this.props.history}
                                                path={'/loads'}
                                            />

                                            <button
                                                onClick={scrollLayoutOnTop}
                                                className="btn btn-text hover:bg-primary-transparent hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                            >
                                                {this.props.LoadNumber ?? translate("text.scroll_to_top")}
                                            </button>
                                        </div>
                                    )}

                                    {[
                                        {
                                            name: "LoadInfoDocuments",
                                            visible: this.state.expandedSections.LoadInfoDocuments.visible,
                                            hasError: false,
                                            hasWarning: false,
                                            ref: this.LoadInfoBasicSection
                                        },
                                        {
                                            name: "LoadInfoBasic",
                                            visible: this.state.expandedSections.LoadInfoBasic.visible,
                                            hasError: hasErrorsBasicInfoFields,
                                            hasWarning: infoWarnings?.hasWarning,
                                            ref: this.LoadInfoBasicSection
                                        }, {
                                            name: "LoadInfoPricing",
                                            visible: this.state.expandedSections.LoadInfoPricing.visible,
                                            hasError: false,
                                            ref: this.LoadInfoPricingSection
                                        }, {
                                            name: "LoadInfoAssets",
                                            visible: this.state.expandedSections.LoadInfoAssets.visible,
                                            hasError: hasErrorsAssetFields
                                                || assetWarnings.hasInactiveDriver
                                                || assetWarnings.hasInactiveCoDriver
                                                || !!assetWarnings?.isInactiveTruck
                                                || !!assetWarnings?.isInactiveTrailer
                                                || assetWarnings?.isNotPermittedTruck,
                                            hasWarning: assetWarnings.hasAssetsWarnings
                                                || assetWarnings?.isCarrierBlacklisted
                                                || !!assetWarnings?.trailerOnLoadID,
                                            ref: this.LoadInfoAssetsSection
                                        },
                                        {
                                            name: "LoadInfoLocations",
                                            visible: this.state.expandedSections.LoadInfoAssets.visible,
                                            hasError: hasErrorsStopFields,
                                            ref: this.LoadInfoLocationsSection
                                        },
                                        {
                                            name: "LoadInfoReminder",
                                            visible: this.state.expandedSections.LoadInfoReminder.visible,
                                            hasError: hasErrorsStopFields,
                                            ref: this.LoadInfoStopUpdateReminderSection
                                        },
                                        {
                                            name: "LoadInfoCommodities",
                                            visible: this.state.expandedSections.LoadInfoCommodities.visible,
                                            ref: this.LoadInfoCommoditiesSection
                                        },
                                        {
                                            name: "LoadInfoMap",
                                            visible: this.state.expandedSections.LoadInfoMap.visible,
                                            ref: this.LoadInfoMapSection
                                        }, {
                                            name: "LoadInfoAccessorials",
                                            visible: this.state.expandedSections.LoadInfoAccessorials.visible,
                                            ref: this.LoadInfoAccessorialsSection
                                        }

                                    ].filter(it => it.visible).map(it => (
                                        <div
                                            key={it.name}
                                            className="group flex items-center lg:text-sm lg:leading-6 text-primary-shade"
                                        >
                                            <button
                                                className="btn-icon m-0 p-1 rounded-button hover:bg-tm-gray-200"
                                                onClick={() => this.toggleExpandSection(it.name)}
                                            >
                                                {!this.state.expandedSections[it.name].expanded && (
                                                    <PlusIcon className="w-5 h-5 text-green-600"/>
                                                )}

                                                {this.state.expandedSections[it.name].expanded && (
                                                    <MinusIcon className="w-5 h-5"/>
                                                )}
                                            </button>

                                            <button
                                                className="pl-2 rounded-l-btn font-medium focus-visible:underline w-full text-left hover:text-tm-gray-900 py-2 relative hover:bg-primary-transparent"
                                                onClick={() => this.handleScrollToSectionClick(it.ref)}
                                            >
                                                {translate("text." + it.name)}

                                                {it.hasError && (
                                                    <ExclamationCircleIcon
                                                        className="w-5 h-5 absolute right-1.5 top-1/2 -translate-y-1/2 text-red-600"/>
                                                )}

                                                {it.hasWarning && !it.hasError && (
                                                    <ExclamationCircleIcon
                                                        className="w-5 h-5 absolute right-0.5 top-1/2 -translate-y-1/2 text-yellow-600"/>
                                                )}
                                            </button>
                                        </div>
                                    ))}

                                    {this.props.isEditMode && checkFeatureFlag('IsLoadHelperON') && (
                                        <LoadSteps
                                            areStepsActive={this.state.areStepsActive}
                                            setAreStepsActive={(bool) => this.setState({
                                                areStepsActive: bool
                                            })}
                                            loadStatus={this.state.loadStatusID}
                                            loadSettings={this.loadSettings}
                                            initialLoadStatus={this.state.initialLoadStatusID}
                                            stepsAddStopsRef={this.StepsAddStopsRef}
                                            submitButtonRef={this.SubmitButtonRef}
                                            stopsCombined={this.state.stopsCombined}
                                            isCarrier={this.state.lineOfBusiness === 'carrier'}
                                            stops={this.state.stops}
                                            onStopClick={this.handleStopClick}
                                            possibleStatusRef={this.possibleStatusRef}
                                            readyStatusRef={this.readyStatusRef}
                                            coveredStatusRef={this.coveredStatusRef}
                                            dispatchedStatusRef={this.dispatchedStatusRef}
                                            inTransitStatusRef={this.inTransitStatusRef}
                                            deliveredStatusRef={this.deliveredStatusRef}
                                            completeStopButtonRef={this.completeStopButtonRef}
                                            fields={this.state.fields}
                                            fieldsRefs={this.fieldsRefs}
                                            isCompleteStopModalOpen={this.state.isCompleteStopModalOpen}
                                            completeStopFormRef={this.completeStopFormRef}
                                            isStopCompletedCheckboxRef={this.isStopCompletedCheckboxRef}
                                            loadStepsCompleteStopState={this.state.loadStepsCompleteStopState}
                                            completeStopSubmitButtonRef={this.completeStopSubmitButtonRef}
                                            suggestNextActionButtonRef={this.suggestNextActionButtonRef}
                                            stopFieldsRefs={this.stopFieldsRefs}
                                        />
                                    )}
                                </div>
                            </div>

                            <Card
                                addClass="w-full xl:max-w-[calc(100%-144px)] ml-auto 3xl:max-w-full max-w-full"
                                bodyClass=""
                            >
                                {this.isCreateMode && checkPerm(Resources.LoadDocuments, CREATE_PERM) && (
                                    <LoadInfoDocuments
                                        innerRef={this.LoadInfoDocumentsSection}
                                        createLoadDocuments={this.state.createLoadDocuments}
                                        OCRDropzoneDocument={this.state.OCRDropzoneDocument}
                                        handleRemoveDocument={this.handleRemoveDocument}
                                        onSetCreateLoadDocuments={this.handleSetCreateLoadDocuments}
                                        toggleExpandSection={this.toggleExpandSection}
                                        isSectionExpanded={this.state.expandedSections.LoadInfoDocuments.expanded}
                                        updateDocumentState={(createLoadDocuments, OCRDropzoneDocument, isOCRDialogOpen) => {
                                            this.setState({
                                                createLoadDocuments, OCRDropzoneDocument, isOCRDialogOpen
                                            });
                                        }}
                                        onOCRDocumentClick={(fileIndex) => {
                                            this.setState({
                                                OCRDropzoneDocument: [this.state.createLoadDocuments[fileIndex]],
                                                isOCRDialogOpen: true
                                            })
                                        }}
                                        translate={translate}
                                    />
                                )}

                                <LoadInfoBasic
                                    Price={this.state.fields?.['Price']?.value}
                                    innerRef={this.LoadInfoBasicSection}
                                    fields={basicInfoFields}
                                    hasErrors={hasErrorsBasicInfoFields}
                                    CustomerLoadSetting={this.state.CustomerLoadSetting}
                                    loadStatusID={this.state.loadStatusID}
                                    loadStatus={this.loadStatuses[this.state.loadStatusID] ?? ""}
                                    onAddNoteClick={this.handleAddNoteClick}
                                    areInternalNotesVisible={this.state.fields.InternalNotes.type !== 'hidden'}
                                    areCustomerNotesVisible={this.state.fields.ExternalNotesCustomer.type !== 'hidden'}
                                    isSectionExpanded={this.state.expandedSections.LoadInfoBasic.expanded}
                                    toggleExpandSection={this.toggleExpandSection}
                                    getCustomerPrimaryContact={this.getCustomerPrimaryContact}
                                    customerContactsList={this.state.CustomerContactsList}
                                    duplicateCustomerReferenceNumberLoad={this.state.duplicateCustomerReferenceNumberLoad}
                                    hideWarningMessages={this.state.isRedirectDialogOpen || resource.isLoading}
                                    warnings={infoWarnings}
                                    isLoading={isPageLoading}
                                    isEditMode={isEditMode}
                                    translate={translate}
                                />

                                {!!(!isEditMode && !this.props.isCopyMode) && (
                                    <LoadInfoPricing
                                        innerRef={this.LoadInfoPricingSection}
                                        fields={{
                                            Price: this.state.fields['Price'],
                                            PriceTypeID: this.state.fields['PriceTypeID']
                                        }}
                                        onInputChange={this.handleInputChange}
                                        isSectionExpanded={this.state.expandedSections.LoadInfoPricing.expanded}
                                        toggleExpandSection={this.toggleExpandSection}
                                        selects={
                                            {PriceTypeID: ConstantTranslate(LOAD_PRICING_TYPES, this.props.translate)}
                                        }
                                        translate={translate}
                                    />
                                )}

                                <LoadInfoAssets
                                    innerRef={this.LoadInfoAssetsSection}
                                    fields={assetFields}
                                    hasErrors={hasErrorsAssetFields}
                                    warnings={assetWarnings}
                                    fieldsData={FieldsManager.getFieldKeyValues(this.state.fields)}
                                    lineOfBusiness={this.state.lineOfBusiness}
                                    lineOfBusinessSwitchDisabled={this.state.initialLoadStatusID >= LOAD_STATUS_DISPATCHED}
                                    isLineOfBusinessChoiceVisible={this.state.isLineOfBusinessChoiceVisible}
                                    toggleLineOfBusiness={this.toggleLineOfBusiness}
                                    resource={resource}
                                    isDispatched={isDispatched}
                                    //isSwitchDispatchVisible={isDispatched && !isDelivered && this.state.lineOfBusiness === 'carrier'}
                                    isSwitchDispatchVisible={false} // Switch dispatch is now in stops
                                    toggleSwitchDispatchDialog={this.toggleSwitchDispatchDialog}
                                    onAddNoteClick={this.handleAddNoteClick}
                                    toggleSplitDispatchInfoModal={this.toggleSplitDispatchInfoModal}
                                    toggleUnitSelectionDialog={this.toggleUnitSelectionDialog}
                                    areDriverNotesVisible={this.state.fields.ExternalDriverNotes.type === 'hidden'}
                                    areCarrierNotesVisible={this.state.fields.ExternalCarrierNotes.type !== 'hidden' && !tailgateParentID}
                                    isSecondTrailerVisible={this.state.fields.SecondTrailerID.type !== 'hidden' && !tailgateParentID}
                                    isThirdTrailerVisible={this.state.fields.ThirdTrailerID.type !== 'hidden' && !tailgateParentID}
                                    canAddSecondTrailer={this.state.fields.TrailerID?.value?.value && this.state.initialLoadStatusID < LOAD_STATUS_DISPATCHED}
                                    canAddThirdTrailer={this.state.fields.SecondTrailerID?.value?.value && this.state.initialLoadStatusID < LOAD_STATUS_DISPATCHED}
                                    isSectionExpanded={this.state.expandedSections.LoadInfoAssets.expanded}
                                    toggleExpandSection={this.toggleExpandSection}
                                    inactiveDriverData={inactiveDriverData || assetWarnings?.inactiveDriverData}
                                    isCoBrokerVisible={!!this.state.fields?.IsCoBroker?.value}
                                    isLoading={isPageLoading}
                                    tailgateParentID={tailgateParentID}
                                    translate={translate}
                                    htmlAfter={
                                        <React.Fragment>
                                            {this.state.lineOfBusiness === 'brokerage' && (
                                                <div className="mt-4">
                                                    <FormSubtitle
                                                        subtitle={this.props.translate('text.dispatcher_contact')}
                                                    />

                                                    <div
                                                        className="flex gap-x-5 items-center"
                                                    >
                                                        {(!this.state.fields?.DispatchContactTxt?.value && !this.state.fields?.DispatchContactID?.value?.value) && (
                                                            <ButtonsGroup
                                                                data={{
                                                                    1: this.props.translate('field.type'),
                                                                    2: this.props.translate('field.chose')
                                                                }}
                                                                onChange={this.handleInputChange}
                                                                {...this.state.fields.DispatchContactFieldType}
                                                            />
                                                        )}

                                                        {Number(this.state.fields.DispatchContactFieldType.value) === 1 && (
                                                            <div
                                                                className="relative grow"
                                                            >
                                                                {this.state.fields?.DispatchContactTxt?.value && (
                                                                    <button
                                                                        className="btn btn-close absolute top-1.5 right-2"
                                                                        onClick={() => this.handleInputChange("DispatchContactTxt", "")}
                                                                    >
                                                                        <XMarkIcon className="w-5 h-5"/>
                                                                    </button>
                                                                )}

                                                                <input
                                                                    type="text"
                                                                    placeholder={"e.g. John Doe"}
                                                                    className="form-control w-full"
                                                                    value={this.state.fields?.DispatchContactTxt?.value}
                                                                    onChange={e => this.handleInputChange("DispatchContactTxt", e.target.value)}
                                                                />
                                                            </div>
                                                        )}

                                                        {Number(this.state.fields.DispatchContactFieldType.value) === 2 && (
                                                            <ContactSelectButton
                                                                isDisabled={isDelivered || !this.state.fields.CarrierID?.value?.value}
                                                                disabledContent={
                                                                    <InfoBar
                                                                        addClass="pr-4"
                                                                        message={translate("text.select_carrier_first")}
                                                                    />
                                                                }
                                                                id={this.state.fields?.DispatchContactID?.value?.value}
                                                                name={this.state.fields?.DispatchContactID?.value?.label}
                                                                onChoseContactClick={this.handleChoseDispatchContactClick}
                                                                onClearContactClick={() => this.handleInputChange("DispatchContactID", "")}
                                                                hasImagePath={!!this.state.fields?.DispatchContactID?.value?.value}
                                                                onAvatarClick={
                                                                    () => this.props.dispatch(
                                                                        showModal(
                                                                            'ViewContactCard',
                                                                            {ContactID: this.state.fields?.DispatchContactID?.value?.value}
                                                                        )
                                                                    )
                                                                }
                                                                isLoading={isPageLoading}
                                                                translate={this.props.translate}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    }
                                />

                                <LoadInfoLocations
                                    dropLoadParams={{
                                        IsDropped: this.props.IsDropped,
                                        initialLoadStatusID: this.state.initialLoadStatusID,
                                        LoadID: this.props.match.params.id,
                                        DriverID: this.state.fields.DriverID.value,
                                        CoDriverID: this.state.fields.CoDriverID.value,
                                        TruckID: this.state.fields.TruckID.value,
                                        TrailerID: this.state.fields.TrailerID.value,
                                        getQuery: this.getQuery,
                                    }}
                                    loadInfoLocationsKey={this.state.loadInfoLocationsKey}
                                    innerRef={this.LoadInfoLocationsSection}
                                    fieldSelects={this.selects}
                                    areLocationAssetsVisible={this.state.lineOfBusiness !== 'brokerage'}
                                    hasTrailer={!!this.state.fields.TrailerID?.value?.value}
                                    applySelectedLocationUnit={this.applySelectedLocationUnit}
                                    onStopByInputChange={this.handleStopByInputChange}
                                    onStopInputChange={this.handleStopInputChange}
                                    stops={this.state.stops}
                                    stopBys={this.state.stopBys}
                                    stopsCombined={this.state.stopsCombined}
                                    isEditMode={isEditMode}
                                    onLocationClick={this.handleStopClick}
                                    onStopDragStart={this.handleStopDragStart}
                                    onStopDragEnd={this.handleStopDragEnd}
                                    onStopDragEnter={this.handleStopDragEnter}
                                    draggedStopIndex={this.state.draggedStopIndex}
                                    onAddLocationClick={this.handleAddLocationClick}
                                    onAddStopByClick={this.handleAddStopByClick}
                                    onAddStopsClick={this.handleAddStopsClick}
                                    onRemoveStopsClick={this.handleRemoveStopsClick}
                                    onDeleteLocationClick={this.handleDeleteLocationClick}
                                    onSelectLocationClick={this.toggleSelectLocationDialog}
                                    onDeleteStopByClick={this.handleDeleteStopByClick}
                                    toggleContactsDialog={this.toggleContactsDialog}
                                    dispatch={this.props.dispatch}
                                    EmptyMiles={this.state.EmptyMiles}
                                    TotalMiles={this.getTotalMiles()}
                                    TotalMilesNoStopBy={this.state.TotalMilesNoStopBy}
                                    needsUpdate={this.state.doesStopsNeedsUpdate}
                                    onCompleteStopClick={this.handleCompleteStopClick}
                                    onCompleteStopByClick={this.handleCompleteStopByClick}
                                    toggleLocationDialog={this.toggleLocationDialog}
                                    onStopAppointmentChange={this.handleStopAppointmentChange}
                                    onUpdateStopClick={this.handleUpdateStopClick}
                                    loadStatusID={this.state.loadStatusID}
                                    initialLoadStatusID={this.state.initialLoadStatusID}
                                    isStateDirty={this.props.isStateDirty}
                                    toggleWorkingHoursModal={this.toggleWorkingHoursModal}
                                    isSectionExpanded={this.state.expandedSections.LoadInfoLocations.expanded}
                                    stopUpdates={
                                        this.props.isCopyMode
                                            ? []
                                            : getProp(resource?.data, "load/stopupdates", [])
                                    }
                                    toggleExpandSection={this.toggleExpandSection}
                                    onLocationEnterPress={this.handleLocationEnterPress}
                                    isLoading={isPageLoading}
                                    isLoadInternational={isLoadInternational}
                                    commodityData={this.state.CommodityData}
                                    toggleIncludeInMileage={this.toggleIncludeInMileage}
                                    defaultWeightUnit={this.defaultWeightUnit}
                                    hasUpdatedStopTypeWarning={this.state.hasUpdatedStopTypeWarning}
                                    hasIncludedInMileageResetMessage={this.state.hasIncludedInMileageResetMessage}
                                    hasChangeStopOrderWarning={this.state.hasChangeStopOrderWarning}
                                    tailgateParentID={tailgateParentID}
                                    translate={translate}
                                    isEmptyMilesWarning={isEmptyMilesWarning}
                                    stepsAddStopsRef={this.StepsAddStopsRef}
                                    stopFieldsRefs={this.stopFieldsRefs}
                                    completeStopButtonRef={this.completeStopButtonRef}
                                />

                                <LoadInfoStopUpdateReminder
                                    isSectionExpanded={this.state.expandedSections.LoadInfoReminder.expanded}
                                    innerRef={this.LoadInfoStopUpdateReminderSection}
                                    toggleExpandSection={this.toggleExpandSection}
                                    fields={this.state.reminderFields}
                                    onInputChange={this.handleReminderInputChange}
                                    translate={translate}
                                />

                                {this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED && (
                                    <React.Fragment>
                                        <LoadInfoCommodities
                                            addClass="px-6 border-t border-tm-gray-300 relative"
                                            innerRef={this.LoadInfoCommoditiesSection}
                                            data={convertMeasurements(cloneDeep(this.state.CommodityData)).map((it, i) => {
                                                it.CommodityOrder = i;
                                                it.key = i;
                                                return it;
                                            })}
                                            originalData={this.state.CommodityData}
                                            getCommodityFields={getCommodityFields}
                                            toggleCommodityFormModal={this.toggleCommodityFormModal}
                                            handleDeleteCommodityClick={this.handleDeleteCommodityClick}
                                            updateCommodityOrder={this.updateCommodityOrder}
                                            totalCommodityPieces={totalCommodityPieces}
                                            totalCommodityPallets={totalCommodityPallets}
                                            totalCommodityVolume={totalCommodityVolume}
                                            standardLengthMeasure={this.standardLengthMeasure}
                                            commodityUnitType={commodityUnitType}
                                            totalCommodityWeight={totalCommodityWeight}
                                            defaultWeightUnit={this.defaultWeightUnit}
                                            onPrintBOLClick={this.props.onPrintBOLClick}
                                            stops={this.state.stops}
                                            stopsCombined={this.state.stopsCombined}
                                            onInputChange={this.handleInputCommodityStopChange}
                                            isSectionExpanded={this.state.expandedSections.LoadInfoCommodities.expanded}
                                            toggleExpandSection={this.toggleExpandSection}
                                            isLoading={isPageLoading}
                                            isStateDirty={this.props.isStateDirty}
                                            translate={translate}
                                        />

                                        {!isEditMode && (
                                            <LoadInfoAccessorials
                                                innerRef={this.LoadInfoAccessorialsSection}
                                                data={this.state.AccessorialData}
                                                getFields={this.getAccessorialFields}
                                                toggleAccessorialFormModal={this.toggleAccessorialFormModal}
                                                handleDeleteAccessorialClick={this.handleDeleteAccessorialClick}
                                                isSectionExpanded={this.state.expandedSections.LoadInfoAccessorials.expanded}
                                                toggleExpandSection={this.toggleExpandSection}
                                                isLoading={isPageLoading}
                                                translate={translate}
                                            />
                                        )}
                                    </React.Fragment>
                                )}

                                {this.state.expandedSections.LoadInfoMap.visible && (
                                    <LoadInfoMap
                                        innerRef={this.LoadInfoMapSection}
                                        translate={translate}
                                        stops={getProp(resource, "data.load/multistops", []).concat(getProp(resource, "data.load/stopby", []))}
                                        LoadID={this.props.match.params.id}
                                        isSectionExpanded={this.state.expandedSections.LoadInfoMap?.expanded}
                                        onTabChange={this.props.onTabChange}
                                        toggleExpandSection={this.toggleExpandSection}
                                        loadDispatchData={getProp(resource, "data.load/info", [])}
                                        needsUpdate={this.state.doesStopsNeedsUpdate}
                                        isLoading={isPageLoading}
                                    />
                                )}
                            </Card>
                        </div>
                    )}

                    {!isPageLoading && (this.state.initialLoadStatusID == LOAD_STATUS_DELIVERED) && (
                        <div className="max-w-7xl mx-auto">
                            <LoadInfoRead
                                isLocationAssetsInfoVisible={true}
                                isLoadInternational={isLoadInternational}
                                translate={this.props.translate}
                                fields={this.state.fields}
                                stops={this.state.stops}
                                stopBys={this.state.stopBys}
                                stopsCombined={this.state.stopsCombined}
                                commodityData={this.state.CommodityData}
                                resource={resource}
                                dispatch={this.props.dispatch}
                                CustomerContactsList={getProp(resource, "data.load/info.CustomerContactsList", [])}
                                deliveredLoadInstructions={getProp(this.props.resource.data, 'load/info.CustomerLoadSettings.Tasks.7', {})}
                                convertMeasurements={convertMeasurements}
                                defaultWeightUnit={this.defaultWeightUnit}
                                lineOfBusiness={this.state.lineOfBusiness}
                                onCompleteStopClick={this.handleCompleteStopClick}
                                EmptyMiles={this.state.EmptyMiles}
                                TotalMiles={this.state.TotalMiles}
                                TotalMilesNoStopBy={this.state.TotalMilesNoStopBy}
                                selects={this.selects}
                                isInvoiced={this.state.isLoadInvoiced}
                                getID={this.getID()}
                            />

                            <LoadInfoCommodities
                                addClass="pt-9"
                                disableEdit={true}
                                data={convertMeasurements(cloneDeep(this.state.CommodityData))}
                                getCommodityFields={getCommodityFields}
                                toggleCommodityFormModal={this.toggleCommodityFormModal}
                                totalCommodityPieces={totalCommodityPieces}
                                totalCommodityPallets={totalCommodityPallets}
                                commodityUnitType={commodityUnitType}
                                totalCommodityWeight={totalCommodityWeight}
                                defaultWeightUnit={this.defaultWeightUnit}
                                stops={this.state.stops}
                                isSectionExpanded={true}
                                isDelivered={(this.state.initialLoadStatusID == LOAD_STATUS_DELIVERED)}
                                translate={translate}
                            />
                        </div>
                    )}
                </div>

                <Flags authorizedFlags={['RepeatLoadON']}
                       renderOn={() =>
                           <>
                               {!isEditMode && (
                                   <div
                                       className="flex w-full max-w-full 3xl:max-w-7xl 3xl:mx-auto relative col-span-2 mb-6"
                                   >
                                       <Card addClass="w-full">
                                           <div className="flex items-center justify-between">
                                               <h5 className="text-lg text-tm-gray-700">
                                                   {translate("text.recurring_load")}
                                               </h5>

                                               <div>
                                                   <label
                                                       className="h-9 flex flex-center justify-start px-2 -ml-2 rounded-lg cursor-pointer hover:bg-tm-gray-50"
                                                   >
                                                       <FieldCheckbox
                                                           id="repeateableLoadCreate"
                                                           onChange={this.handleInputChange} {...this.state.fields.Repeat}
                                                       />
                                                       <div
                                                           className="ml-2 flex flex-col text-sm font-semibold select-none"
                                                       >
                                                           {translate('field.repeat')}
                                                       </div>
                                                   </label>
                                               </div>
                                           </div>

                                           {this.state.fields.Repeat && this.state.fields.Repeat.value && (
                                               <Cron
                                                   onChange={(e) => this.handleInputChange(this.state.fields.Cron.name, e)}
                                                   value={this.state.fields.Cron.value.value}
                                                   showResultText={true}
                                                   options={{
                                                       //headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.DAILY]
                                                   }}
                                               />
                                           )}
                                           {this.state.fields.Repeat && this.state.fields.Repeat.value && (
                                               <div className="grid grid-cols-12 gap-4">
                                                   <div className="col-span-6">
                                                       <label
                                                           className="h-9 col-span-6 flex items-center gap-4 hover:bg-tm-gray-100 rounded-btn py-2 px-4 cursor-pointer select-none">
                                                           <input
                                                               type={'radio'}
                                                               value={'none'}
                                                               name={'Repeat'}
                                                               checked={this.state.selectedRepeatOption === 'none'}
                                                               onChange={() => {
                                                                   let fields = this.state.fields
                                                                   fields.EndDate.value = ''
                                                                   fields.EndAfter.value = ''
                                                                   this.setState({
                                                                       fields: fields,
                                                                       selectedRepeatOption: 'none'
                                                                   })
                                                               }}
                                                           />

                                                           <p className="text-tm-gray-500 text-small ">{translate('field.DoNotEndRepeat')}</p>
                                                       </label>
                                                   </div>

                                                   <div className="col-start-1 col-span-6">
                                                       <div
                                                           className="h-9 flex items-center gap-4 hover:bg-tm-gray-100 rounded-btn py-2 px-4 cursor-pointer select-none">

                                                           <input
                                                               type={'radio'}
                                                               value={'EndDate'}
                                                               name={'Repeat'}
                                                               checked={this.state.selectedRepeatOption === 'EndDate'}
                                                               onChange={() => this.setState({
                                                                   selectedRepeatOption: 'EndDate',
                                                                   fields: FieldsManager.updateField(this.state.fields, 'EndAfter', '')
                                                               })}
                                                           />

                                                           <p className="text-tm-gray-500 text-small ">{translate('field.EndRepeatDate')}</p>
                                                       </div>
                                                   </div>

                                                   <div className="col-span-6">
                                                       <label className="form-group has-float-label mb-3">
                                                           <FieldDate
                                                               showTimeSelect={false}
                                                               addClass={'form-control text-center'}
                                                               onChange={this.handleInputChange}
                                                               {...this.state.fields.EndDate}
                                                               disabled={this.state.selectedRepeatOption !== 'EndDate'}
                                                           />
                                                           <span>{translate('field.EndDate')}</span>
                                                       </label>
                                                   </div>

                                                   <div className="col-span-6">
                                                       <div
                                                           className="h-9 flex items-center gap-4 hover:bg-tm-gray-100 rounded-btn py-2 px-4 cursor-pointer select-none">
                                                           <input
                                                               type={'radio'}
                                                               value={'EndAfter'}
                                                               name={'Repeat'}
                                                               checked={this.state.selectedRepeatOption === 'EndAfter'}
                                                               onChange={() => this.setState({
                                                                   selectedRepeatOption: 'EndAfter',
                                                                   fields: FieldsManager.updateField(this.state.fields, 'EndDate', '')
                                                               })}
                                                           />

                                                           <p className="text-tm-gray-500 text-small ">{translate('field.EndAfter')}</p>
                                                       </div>
                                                   </div>

                                                   <div className="col-span-6">
                                                       <label className="form-group has-float-label mb-3">
                                                           <FieldText
                                                               showTimeSelect={false}
                                                               addClass={'form-control text-center'}
                                                               onChange={this.handleInputChange}
                                                               {...this.state.fields.EndAfter}
                                                               disabled={this.state.selectedRepeatOption !== 'EndAfter'}
                                                           />
                                                           <span>{translate('field.EndAfterNumber')}</span>
                                                       </label>
                                                   </div>

                                                   <div className="col-span-full">
                                                       <label className="form-group has-float-label mb-3">
                                                           <FieldTextarea
                                                               addClass={'form-control whitespace-pre-wrap'}
                                                               onChange={this.handleInputChange}
                                                               {...this.state.fields.Notes}
                                                           />
                                                           <span>{translate('field.Notes')}</span>
                                                       </label>
                                                   </div>
                                               </div>

                                           )}
                                       </Card>
                                   </div>
                               )}
                           </>}
                       renderOff={() => null}
                />

                <React.Fragment>
                    {/* Page footer */}
                    <div
                        className={"w-full pl-60 h-16 flex justify-end items-center fixed bottom-0 right-0 px-8 bg-inverse border-tm-gray-200 border-t z-30"}
                    >
                        <div className="mr-auto space-x-3">
                            {!isPageLoading && !!this.props.isEditMode && !IsTonu && !this.props.isCopyMode && (this.state.initialLoadStatusID !== LOAD_STATUS_CANCELED) && (this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED) && (
                                <Tippy content={translate('btn.cancel_load_disabled')}
                                       disabled={!this.props.isStateDirty}>
                                    <div className="inline-block">
                                        <Button
                                            hasPerm={checkPerm(Resources.LoadCancel, UPDATE_PERM)}
                                            disabled={!!this.props.isStateDirty}
                                            className={'btn btn-danger'}
                                            onClick={() => this.handleCancelLoadClick()}
                                        >
                                            <ExclamationTriangleIcon className="-ml-1 mr-2 h-5 w-5"
                                                                     aria-hidden="true"/>

                                            {translate('btn.cancel_load')}
                                        </Button>
                                    </div>
                                </Tippy>
                            )
                            }

                            {
                                !isPageLoading && !!this.props.isEditMode && !IsTonu && !this.props.isCopyMode && (this.state.initialLoadStatusID !== LOAD_STATUS_CANCELED) && (this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED) && (
                                    <Tippy content={translate('text.tonu_load_disabled')}
                                           disabled={!this.props.isStateDirty}>
                                        <div className="inline-block">
                                            <Button
                                                disabled={!!this.props.isStateDirty}
                                                hasPerm={checkPerm(Resources.LoadTonu, CREATE_PERM)}
                                                className={'btn btn-danger-outline items-center'}
                                                onClick={this.toggleTonuDialog}
                                            >
                                                <NoSymbolIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>

                                                {translate('btn.tonu')}
                                            </Button>
                                        </div>
                                    </Tippy>
                                )
                            }

                            {

                                (this.state.lineOfBusiness === 'carrier')
                                &&
                                !isPageLoading && !tailgated.length && !!this.props.isEditMode && !IsTonu && !this.props.isCopyMode && (this.state.initialLoadStatusID !== LOAD_STATUS_CANCELED) && (this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED) && (
                                    <Tippy
                                        content={isDispatched
                                            ? translate('text.tailgate_load_disabled')
                                            : translate('text.tailgate_load_disabled_unsaved')
                                        }
                                        disabled={(!this.props.isStateDirty || isDispatched) && !tailgateParentID}
                                    >
                                        <div className="inline-block">
                                            <button
                                                disabled={this.props.isStateDirty || isDispatched}
                                                className={
                                                    classNames(
                                                        'btn',
                                                        tailgateParentID ? "btn-danger-outline" : "btn-outline"
                                                    )
                                                }
                                                onClick={
                                                    tailgateParentID ? this.toggleRemoveTailgateDialog : this.toggleTailgateDialog
                                                }
                                            >
                                                <TruckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>

                                                {tailgateParentID
                                                    ? translate('btn.remove_tailgate')
                                                    : translate('btn.tailgate')
                                                }
                                            </button>
                                        </div>
                                    </Tippy>
                                )
                            }
                        </div>

                        <div className="flex gap-x-3 items-center">
                            {!!this.props.resource.isLoading && (
                                <LoaderSmall addClass="mr-4"/>
                            )}

                            <div className="flex flex-col gap-0.5">
                                {
                                    !this.props.resource.isLoading
                                    && this.state.lineOfBusiness === 'carrier'
                                    && (assetWarnings.hasInactiveDriver || assetWarnings.hasInactiveCoDriver)
                                    && (assetWarnings?.inactiveDriverData?.StatusID > DRIVER_STATUS_ON_VACATION || assetWarnings?.inactiveCoDriverData?.StatusID > DRIVER_STATUS_ON_VACATION)
                                    && !!getProp(resource.data, 'settings.PreventAssigningInactiveDriver', 0) && (
                                        <span className="bg-red-600/10 text-sm px-2 leading-6 rounded-btn">Cannot save load with inactive driver.</span>
                                    )
                                }

                                {
                                    !this.props.resource.isLoading &&
                                    this.state.lineOfBusiness === 'carrier'
                                    && (assetWarnings.isInactiveTruck || assetWarnings.isInactiveTrailer)
                                    && !!getProp(resource.data, 'settings.PreventAssigningInactiveTruckTrailer', 0) && (
                                        <span className="bg-red-600/10 text-sm px-2 leading-6 rounded-btn">Cannot save load with inactive truck/trailer.</span>
                                    )}

                                {!this.props.resource.isLoading
                                    && this.state.lineOfBusiness === 'brokerage'
                                    && assetWarnings.isCarrierBlacklisted
                                    && !!getProp(resource.data, 'settings.PreventAssigningBlacklistedCarrier', 0) && (
                                    <span className="bg-red-600/10 text-sm px-2 leading-6 rounded-btn">Cannot save load with blacklisted carrier.</span>
                                )}
                            </div>

                            {checkPerm(Resources.LoadInfo, UPDATE_PERM) &&
                                <span>
                                    <button
                                        ref={this.SubmitButtonRef}
                                        data-name="submit-button-shortcut"
                                        className={'btn btn-primary'}
                                        disabled={
                                            !(this.props.isStateDirty || this.props.isCopyMode)
                                            || isPageLoading
                                            || (hasCompletedStops && this.state.loadStatusID < LOAD_STATUS_DISPATCHED)
                                            || infoWarnings?.isCustomerBlacklisted
                                            || (infoWarnings?.isOverCreditLimit && !infoWarnings?.allowOverLimit)
                                            || (
                                                this.state.lineOfBusiness === 'carrier'
                                                && (assetWarnings.hasInactiveDriver || assetWarnings.hasInactiveCoDriver)
                                                && (assetWarnings?.inactiveDriverData?.StatusID > DRIVER_STATUS_ON_VACATION || assetWarnings?.inactiveCoDriverData?.StatusID > DRIVER_STATUS_ON_VACATION)
                                                && !!getProp(resource.data, 'settings.PreventAssigningInactiveDriver', 0)
                                            )
                                            || (
                                                this.state.lineOfBusiness === 'carrier'
                                                && (assetWarnings.isInactiveTruck || assetWarnings.isInactiveTrailer)
                                                && !!getProp(resource.data, 'settings.PreventAssigningInactiveTruckTrailer', 0)
                                            )
                                            || (
                                                this.state.lineOfBusiness === 'brokerage'
                                                && assetWarnings.isCarrierBlacklisted
                                                && !!getProp(resource.data, 'settings.PreventAssigningBlacklistedCarrier', 0)
                                            )
                                        }
                                        onClick={this.submit}
                                    >
                                        {translate('btn.save')}
                                    </button>
                                </span>
                            }

                            <button
                                data-name="cancel-button-shortcut"
                                className={'btn btn-outline'}
                                disabled={!this.props.isStateDirty || IsTonu}
                                onClick={this.cancel}>{translate('btn.cancel_changes')}
                            </button>
                        </div>
                    </div>
                </React.Fragment>

                {/* Dialogs */}
                {/*=================================================================*/}
                <Modal
                    show={this.state.isCustomerSettingsModalOpen}
                    widthClass={'max-w-4xl'}
                    onClose={this.toggleCustomerSettingsDialog}
                    isLoading={false}
                >
                    <LoadCustomerSettingsDialog
                        title={translate('text.customer_settings')}
                        show={this.state.isCustomerSettingsModalOpen}
                        dispatch={this.props.dispatch}
                        OrganizationID={this.state.fields.CustomerID?.value?.metadata?.OrganizationID}
                        SkipCustomerDefaults={this.state.SkipCustomerDefaults}
                        onButtonClick={this.applyCustomerSettings}
                        buttonLabel={translate('btn.apply_selected')}
                        closeButtonLabel={translate('btn.cancel')}
                        onClose={this.toggleCustomerSettingsDialog}
                        dialogResource={this.props.dialogResource}
                        transalte={translate}
                    />
                </Modal>

                {this.props.ui.confirmDialog && (
                    <ModalConfirm
                        text={this.state.text}
                        name={this.state.confirmText}
                        submit={this.state.confirmAction}
                        cancel={this.hideConfirmDialog}
                        {...this.props}
                    />
                )}

                <StateMileageDialog
                    show={this.props.ui.globalDialogs?.StateMileageDialog?.visible}
                    match={this.props.match}
                    translate={translate}
                />

                <Modal
                    show={this.state.isRedirectDialogOpen}
                    widthClass={'max-w-sm w-screen'}
                    onClose={() => window.location.reload()}
                    initialFocusRef={this.successModalFocusRef}
                >
                    <ModalHeader
                        title={translate("text.load_created_successfully")}
                        onClose={() => window.location.reload()}
                    />
                    <CreateSuccessModal
                        translate={translate}
                        buttons={[
                            {
                                label: translate("btn.view_load_pricing"),
                                onClick: () => this.props.history
                                    .push(`/loads/info/${this.state.createdLoadID}?tab=LoadPricing`),
                                iconLeading: BanknotesIcon,
                                ref: this.successModalFocusRef
                            },
                            {
                                label: translate("btn.stay_on_page"),
                                onClick: () => this.props.history.push(`/loads/info/${this.state.createdLoadID}`),
                                iconLeading: PencilIcon,
                            },
                            {
                                label: translate("btn.create_new"),
                                onClick: () => window.location.reload(),
                                iconLeading: PlusIcon,
                            },
                            {
                                label: translate("btn.list"),
                                onClick: () => this.props.history.push("/loads"),
                                iconLeading: Bars4Icon,
                                iconTrailing: ArrowTopRightOnSquareIcon
                            },
                            {
                                label: translate("btn.dashboard"),
                                onClick: () => this.props.history.push("/home"),
                                iconLeading: PresentationChartBarIcon,
                                iconTrailing: ArrowTopRightOnSquareIcon
                            },
                        ]}
                    />
                </Modal>

                {/* History dialog */}
                <Modal
                    show={this.state.viewHistoryDialog}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({viewHistoryDialog: false})}
                    translate={translate}
                >
                    {this.state.viewHistoryDialog && (
                        <HistoryModal
                            onClose={() => this.setState({viewHistoryDialog: false})}
                            translate={translate}
                            title={translate("text." + Resources.LoadHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.props.match.params.id
                                    }),
                                    resource: Resources.LoadHistory
                                }))
                            }}
                            data={getProp(this.props.historyResource, "data.list", [])}
                            count={getProp(this.props.historyResource, "data.count", 0)}
                            isLoading={getProp(this.props.historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                {/*Email url dialog*/}
                <Modal
                    show={!!this.state.showURLDialog}
                    title={translate('modal_heading.share_url')}
                    widthClass={'max-w-2xl'}
                    onClose={() => this.toggleSendURLDialog()}
                    hideFooter={true}
                    {...this.props}
                >
                    <FormEmailUrl
                        translate={translate}
                        url={this.state.showURLDialog}
                        toggleLinkOption={this.state.linkOptionStateName === 'carrierLinkOptions' ? this.toggleCarrierLinkOption : this.toggleLinkOption}
                        linkOptions={this.state[this.state.linkOptionStateName]}
                        linkOptionsResource={this.state.linkOptionStateName === 'carrierLinkOptions' ? Resources.LoadShareCarrierSettings : Resources.LoadShareSettings}
                        emailPrefill={this.state.CustomerContactsList}
                        dispatch={this.props.dispatch}
                        id={this.props.match.params.id}
                        infoData={getProp(this.props.resource, 'data.load/info', {})}
                        onClose={() => this.toggleSendURLDialog()}
                        onSubmit={(params) => {
                            if (params) {
                                params['LoadID'] = this.props.match.params.id
                                Object.assign(params, {
                                    Subject: params.subject,
                                    Content: params.content,
                                    Emails: getProp(params, 'emails', []).map(it => it.value).join(',')
                                })

                                let {...updatedParams} = params

                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: updatedParams,
                                    query: {
                                        id: this.props.match.params.id
                                    },
                                    resource: Resources.LoadShareSend,
                                    errorMessage: true, successMessage: `Email with load URL sent`
                                }))

                                this.toggleSendURLDialog()
                            }
                        }}
                        thirdResource={this.props.thirdResource}
                    />
                </Modal>

                <ModalSaveResource
                    {...this.props}
                    title={'Confirm TONU'}
                    htmlBefore={(
                        <React.Fragment>
                            <InfoBar
                                className="flex gap-2 py-2 px-2 rounded-none pr-8"
                                type="danger"
                                Icon={ExclamationSolidIcon}
                            >
                                <div>
                                    <p>{translate('message.confirm_tonu')}</p>
                                    <p>{translate('message.confirm_tonu_warning')}</p>
                                </div>
                            </InfoBar>

                            <InfoBar className="flex gap-2 py-2 px-2 rounded-none pr-8" type="info">
                                <div>
                                    <p>{translate('message.confirm_tonu_info')}</p>
                                </div>
                            </InfoBar>
                        </React.Fragment>
                    )}
                    show={this.state.isTonuDialogVisible}
                    keyid={'AccessorialID'}
                    value={this.state.selectedItem}
                    translate={translate}
                    fields={{
                        EmptyMiles: new Field('EmptyMiles', '', ['float_or_empty'], false, '', {addContainerClass: 'col-span-full'}),
                        LoadedMiles: new Field('LoadedMiles', '', ['float_or_empty'], false, '', {addContainerClass: 'col-span-full'}),
                        TonuRate: new Field('TonuRate', '', ['float_or_empty'], false, '', {addContainerClass: 'col-span-full'}),
                        PayDriver: new Field('PayDriver', '', ['float_or_empty'], false, '', {
                            addContainerClass: 'col-span-full',
                            label: this.state.lineOfBusiness === 'brokerage' ? "PayCarrier" : "PayDriver"
                        }),
                    }}
                    onClose={this.toggleTonuDialog}
                    buttonLabel={translate('btn.confirmTonu')}
                    onSubmit={(params) => {
                        for (const [key, value] of Object.entries(params)) {
                            params[key] = numberWithCommasToBack(value)
                        }

                        params.LoadID = this.getID()

                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: Resources.LoadTonu,
                            piggyResource: Resources.LoadInfo,
                            query: Object.assign({}, this.getQuery(), {id: this.getID()}),
                            errorMessage: true, successMessage: translate('text.tonu_confirmed_successfully')
                        }))

                        this.toggleTonuDialog()
                    }}
                    resourceName={Resources.LoadTonu}
                />

                <ModalSaveResource
                    title={translate("modal_heading.tailgate")}
                    show={this.state.isTailgateDialogVisible}
                    value={this.state.selectedItem}
                    translate={translate}
                    htmlBefore={
                        <div className="p-6 space-y-6">
                            <p>{translate('text.attach_tailgate')}</p>
                            <p>{translate('text.attach_tailgate_note')}</p>
                        </div>
                    }
                    fields={{
                        LoadID: new Field('LoadID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
                    }}
                    metadata={{
                        LoadID: {
                            api: 'api/' + Resources.LoadsQuick,
                            query: {
                                limit: 10,
                                offset: 0,
                                LoadStatusID: LOAD_STATUS_RESERVED
                            },

                            searchMap: (item) => ({
                                value: item.LoadID,
                                label: item.LoadNumber
                            })
                        }
                    }}
                    onClose={this.toggleTailgateDialog}
                    buttonLabel={translate('btn.save')}
                    onSubmit={(params, fields) => {
                        if (Number(params.LoadID) === Number(this.getID())) {
                            return null;
                        }

                        params.ParentLoadID = params.LoadID;
                        params.LoadID = this.getID();

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: params,

                            resource: Resources.LoadTailgate,
                            piggyResource: Resources.LoadInfo,
                            query: Object.assign({}, this.getQuery(), {id: this.getID()}),
                            errorMessage: true,
                            successMessage: translate('text.tailgate_success', [this.props.LoadNumber, fields.LoadID.value.label])
                        }))

                        this.toggleTailgateDialog();
                    }}
                    resourceName={Resources.LoadTailgate}
                />

                <ModalConfirm
                    show={this.state.isRemoveTailgateDialogOpen}
                    type={"warning"}
                    onClose={this.toggleRemoveTailgateDialog}
                    onConfirm={() => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: {
                                LoadID: this.getID(),
                            },
                            resource: Resources.LoadTailgate,
                            piggyResource: Resources.LoadInfo,
                            query: Object.assign({}, this.getQuery(), {id: this.getID()}),
                            errorMessage: true,
                            successMessage: translate('text.tailgate_removed_success')
                        }))

                        this.toggleRemoveTailgateDialog();
                    }
                    }
                    buttonLabel={translate("text.confirm")}
                    closeButtonLabel={translate("text.cancel")}
                    title={translate('modal_heading.confirm_remove_tailgate')}
                    text={translate('text.confirm_remove_tailgate')}
                    translate={translate}
                >
                </ModalConfirm>

                <ModalSaveResource
                    title={translate('text.split_dispatch')}
                    widthClass="max-w-xl"
                    show={this.state.showSwitchDispatchDialog}
                    gridClass="grid gap-4 grid-cols-6 p-6"
                    buttonLabel={translate('btn.confirm_split')}
                    buttonDisabled={this.state.splitDispatchBtnDisabled}
                    handleInputChange={this.handleSwitchDispatchInputChange}
                    onClose={this.toggleSwitchDispatchDialog}
                    fields={this.getSwitchDispatchFields(this.state.selectedUnit)}
                    refreshFields={this.state.updateUnit}
                    onSubmit={(params, splittedFields) => {
                        if (params) {
                            let fields = this.state.fields
                            params.LoadID = this.props.match.params.id

                            fields = FieldsManager.updateField(fields, 'DriverID', splittedFields['DriverID'].value)
                            fields = FieldsManager.updateField(fields, 'CoDriverID', splittedFields['CoDriverID'].value)
                            fields = FieldsManager.updateField(fields, 'TruckID', splittedFields['TruckID'].value)
                            fields = FieldsManager.updateField(fields, 'TrailerID', splittedFields['TrailerID'].value)
                            fields = FieldsManager.updateField(fields, 'Notes', params.Notes)

                            this.setState({
                                fields
                            }, () => {
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.LoadSplitDispatch,
                                    query: {id: this.props.match.params.id},
                                    piggyResource: Resources.LoadInfo,
                                    errorMessage: true, successMessage: translate('text.load_split_successful'),
                                }))
                            })

                            this.toggleSwitchDispatchDialog();
                            this.props.setDirty();
                        }
                    }}
                    metadata={{
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + ' ' + item.LastName,
                                metadata: item
                            })
                        },
                        CoDriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + ' ' + item.LastName,
                                metadata: item
                            })
                        },
                        TruckID: {
                            api: 'api/' + Resources.TrucksQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.TruckID,
                                label: item.SCAC + ' - ' + item.TruckNumber,
                                metadata: item
                            })
                        },
                        TrailerID: {
                            api: 'api/' + Resources.TrailersQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.TrailerID,
                                label: item.SCAC + ' - ' + item.TrailerNumber,
                                metadata: item
                            })
                        }
                    }}
                    htmlBefore={
                        <React.Fragment>
                            <div className="flex mt-6 px-6">
                                <button
                                    onClick={this.toggleUnitSwitchDialog}
                                    className="btn btn-outline justify-center w-full"
                                >
                                    {translate('btn.select_from_existing_unit')}
                                    <TruckIcon className="h-5 w-5 ml-3"/>
                                </button>
                            </div>

                            <ResourceTableDialog
                                show={this.state.isUnitSwitchDialogOpen}
                                dialogResource={this.props.dialogResource}
                                initialFocusRef={this.splitDispatchDialogRef}
                                resource={Resources.Units}
                                title={translate("text.select_from_dispatch_manager")}
                                dispatch={this.props.dispatch}
                                fields={{
                                    DriverID: new Field('DriverID', '', [''], false, 'select-search'),
                                    CoDriverID: new Field('CoDriverID', '', [''], false, 'select-search'),
                                    TruckID: new Field('TruckID', '', [''], false, 'select-search'),
                                    TrailerID: new Field('TrailerID', '', [''], false, 'select-search'),
                                }}
                                fieldsFilter={{
                                    query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}, {innerRef: this.splitDispatchDialogRef}),
                                }}

                                defaultAction={this.applySelectedUnitSwitch}

                                onCustomActions={[
                                    {
                                        tooltipText: () => translate('btn.select_unit'),
                                        action: (it) => this.applySelectedUnitSwitch(it),
                                        icon: ArrowDownTrayIcon
                                    }
                                ]}
                                onClose={this.toggleUnitSwitchDialog}
                                translate={translate}
                            />
                        </React.Fragment>
                    }
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={(this.state.selectedItem ? 'Edit' : 'Add') + ' accessorial'}
                    widthClass="max-w-md"
                    show={this.state.isAccessorialDialogOpen}
                    gridColsClass="grid-cols-1"
                    onClose={this.toggleAccessorialFormModal}
                    fields={excludeFields(this.getAccessorialFields(this.state.selectedItem), ['Accessorial', 'TotalAmount'])}
                    onSubmit={(params) => {
                        if (params) {
                            const AccessorialData = this.state.AccessorialData

                            params.PayDriverAmount = params.PayDriverAmount ?? 0

                            params.TotalAmount = (params.Amount * params.LoadAccessorialUnits) + params.PayDriverAmount

                            params.checked = 1

                            if (!!this.state.selectedItemIndex || this.state.selectedItemIndex === 0) {
                                AccessorialData[this.state.selectedItemIndex] = params
                            } else {
                                AccessorialData.push(params)
                            }

                            this.setState({
                                AccessorialData
                            }, () => this.props.setDirty())

                            this.toggleAccessorialFormModal(this.state.selectedItem)
                        }
                    }}
                    translate={this.props.translate}
                    handleInputChange={(fields, name, value) => {
                        if (name === 'AccessorialID') {
                            FieldsManager.updateField(fields, 'Accessorial', value.label)
                        }

                        if (name === 'IsPayDriver') {
                            if (value) {
                                fields.PayDriverAmount.type = 'float'
                            } else {
                                fields.PayDriverAmount.type = 'hidden'
                                FieldsManager.updateField(fields, 'PayDriverAmount', 0)
                            }
                        }

                        return FieldsManager.updateField(fields, name, value)
                    }}
                    metadata={{
                        AccessorialID: {
                            api: 'api/load/accessorial',
                            query: {
                                limit: 50,
                                offset: 0
                            },
                            searchMap: (item) => ({
                                value: item.AccessorialID,
                                label: item.Accessorial
                            })
                        }
                    }}
                />

                <Modal
                    show={!!this.state.isCommodityDialogOpen}
                    widthClass="max-w-full md:max-w-2xl"
                    onClose={() => this.toggleCommodityFormModal()}
                >
                    <CommodityForm
                        canSaveTemplate={true}
                        title={(this.state.selectedItem ? 'Edit' : 'Add') + ' commodity'}
                        onClose={() => this.toggleCommodityFormModal()}
                        locationFields={this.state.stops}
                        selectedItem={this.state.selectedItem}
                        onSubmit={this.handleCommodityFormSubmitClick}

                        translate={translate}
                    />
                </Modal>

                <ResourceTableDialogWithFilters
                    show={this.state.isUnitSelectionDialogOpen}
                    widthClass={"max-w-[1366px]"}
                    dialogResource={this.props.dialogResource}
                    initialFocusRef={this.dispatchManagerInitialFocusRef}
                    resource={Resources.Units}
                    title={translate("text.select_from_dispatch_manager")}
                    dispatch={this.props.dispatch}
                    fields={GetDispatchManagerFields(translate, this.props.dispatch)}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: "col-span-2",
                            hideLabel: true,
                            labelType: "float"
                        }, {autoFocus: true}),
                        offset: new Field('offset', 0, [''], false, 'hidden'),
                        limit: new Field("limit", 10, [''], false, 'select', {hideLabel: true, labelType: "float"}),
                        sort: new Field('sort', "ASC", [''], false, 'hidden'),
                        sortBy: new Field('sortBy', "UnitID", [''], false, 'hidden'),
                        DriverID: new Field('DriverID', '', [], false, 'select-search', {addContainerClass: "col-span-3 col-start-1"}, {isClearable: true}),
                        TruckID: new Field('TruckID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),

                        DispatchContactID: new Field('DispatchContactID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),
                        StatusID: new Field('StatusID', '', [], false, 'select', {addContainerClass: "col-span-3"}, {isClearable: true}),
                        IsTeamDriver: new Field('IsTeamDriver', '', [], false, 'checkbox', {
                            addContainerClass: "col-span-2",
                            hideLabel: true,
                            labelType: "float"
                        }, {isClearable: true}),
                        StatusMismatch: new Field('StatusMismatch', '', [], false, 'checkbox', {
                            addContainerClass: "col-span-2",
                            hideLabel: true,
                            labelType: "float"
                        }, {isClearable: true})
                    }}
                    defaultAction={this.applySelectedUnit}
                    onCustomActions={[
                        {
                            tooltipText: () => translate('btn.select_unit'),
                            action: (it) => this.applySelectedUnit(it),
                            icon: ArrowDownTrayIcon
                        }
                    ]}
                    onClose={this.state.isUnitSwitchDialogOpen ? this.toggleUnitSwitchDialog : this.toggleUnitSelectionDialog}
                    selects={{
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + ' ' + item.LastName
                            })
                        },
                        CoDriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + ' ' + item.LastName
                            })
                        },
                        TruckID: {
                            api: 'api/' + Resources.TrucksQuick,
                            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                            customizeList: (list) => {
                                return groupListBySCAC(list, 'Truck')
                            }
                        },
                        TrailerID: {
                            api: 'api/' + Resources.TrailersQuick,
                            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                            customizeList: (list) => {
                                return groupListBySCAC(list, 'Trailer')
                            }
                        },
                        DispatchContactID: {
                            api: "api/" + Resources.ContactsQuick,
                            query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsDispatch: 1}),
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName,
                                Contact: item
                            })
                        },
                        StatusID: DRIVER_STATUS_TYPES
                    }}
                    translate={translate}
                />

                <ModalConfirm
                    type={this.state.confirmModalType}
                    onClose={() => this.setState({
                        confirmModalAction: false,
                        confirmModalType: undefined,
                        confirmText: undefined,
                        confirmBody: undefined
                    })}
                    width={"3xl"}

                    onConfirm={this.state.confirmModalAction}
                    buttonLabel={this.state.confirmBtnLabel}
                    closeButtonLabel={this.state.confirmCancelBtnLabel}
                    secondaryButtonLabel={this.state.confirmSecondaryButtonLabel}
                    onSecondaryButtonClick={() => {
                        this.state.confirmModalAction(true);
                    }}
                    show={!!this.state.confirmModalAction}
                    title={translate('modal_heading.confirm')}
                    text={this.state.confirmText}
                    translate={translate}
                >
                    {this.state.confirmBody}
                </ModalConfirm>

                <ConfirmLoadStatusUpdateModal
                    statusUpdateID={this.state.showConfirmDialogForStatusUpdate}
                    areAllStopsCompleted={areAllStopsCompleted}
                    isBroker={this.state.lineOfBusiness === 'brokerage'}
                    isEmptyMilesWarning={isEmptyMilesWarning && this.state.initialLoadStatusID < LOAD_STATUS_DISPATCHED}
                    onConfirm={(statusUpdateID, submitAfter) => this.updateStatusID(statusUpdateID, submitAfter)}
                    onClose={() => this.setState({
                        showConfirmDialogForStatusUpdate: null
                    })}
                    translate={translate}
                />


                <Modal //treba da postoje dva ovakva dialoga, razlika je u show propu, ne brisati
                    show={this.state.isLocationDialogOpen && !this.state.isSelectLocationDialogOpen}
                    widthClass={'max-w-7xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.toggleLocationDialog(false)}
                    translate={translate}
                >
                    <CreateLocationDialog
                        onClose={() => this.toggleLocationDialog(false)}
                        updateItem={this.state.selectedLocation}
                        dispatch={this.props.dispatch}
                        secondResource={secondResource}
                        dialogResource={this.props.dialogResource}
                        contactResource={this.props.contactResource}
                        translate={translate}
                        onSubmit={(params, fields) => {
                            if (!!this.state.selectedLocation?.StopID || !!this.state.selectedLocation?.LocationID) {
                                params.LocationZone = fields.LocationZoneID?.value?.label ?? "";
                                params.LocationID = this.state.selectedLocation.StopID ?? this.state.selectedLocation.LocationID;
                            }

                            if (!!params.LocationID && params.LocationID !== -1) {
                                this.updateLocationsState(params);
                                this.props.dispatch(updateDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.Locations,
                                    errorMessage: true, successMessage: `Location ${params.LocationName} updated`,
                                }))
                            } else {
                                this.createLocationsState(params);
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.Locations,
                                    piggyResource: Resources.Locations,
                                    errorMessage: true, successMessage: 'Location Created Successfully!'
                                }))
                            }
                        }}
                    />
                </Modal>

                <LoadCompleteStopDialog
                    show={this.state.isCompleteStopModalOpen}
                    onClose={this.handleCloseCompleteStopModal}
                    selectedLocation={selectedLocation}
                    stops={this.state.stops}
                    stopsCombined={this.state.stopsCombined}
                    selectedCombinedLocationIndex={selectedCombinedLocationIndex}
                    LoadID={this.props?.match?.params?.id}
                    isLoadDropped={this.props.IsDropped}
                    commodityData={this.state.CommodityData}
                    loadStatusID={this.state.loadStatusID}
                    defaultWeightUnit={this.defaultWeightUnit}
                    commodityUnitType={commodityUnitType}
                    canCompleteStop={this.state.initialLoadStatusID !== LOAD_STATUS_DELIVERED}
                    hideDialogFooter={this.state.initialLoadStatusID === LOAD_STATUS_DELIVERED}
                    onSubmit={(params) => {
                        if (params) {
                            params.ActualArrivalDateTime = params.ActualArrivalDate
                            params.ActualDepartureDateTime = params.ActualDepartureDate
                            params.IsStopCompleted = params.IsStopCompleted ? 1 : 0;

                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.LoadStopComplete,
                                piggyResource: Resources.LoadInfo,
                                query: this.getQuery(),
                                errorMessage: true,
                                successMessage: params.IsStopCompleted ?
                                    translate('message.load_stop_completed') :
                                    translate('message.load_stop_updated')
                            }))

                            this.handleCloseCompleteStopModal();
                            this.props.isStateDirty && this.props.setDirty()
                        }
                    }}
                    submitButtonRef={this.completeStopSubmitButtonRef}
                    completeStopFormRef={this.completeStopFormRef}
                    isStopCompletedCheckboxRef={this.isStopCompletedCheckboxRef}
                    setLoadStepsHelperState={(dialogState) => {
                        this.setState({
                            loadStepsCompleteStopState: dialogState
                        });
                    }}
                    suggestNextActionButtonRef={this.suggestNextActionButtonRef}
                    translate={this.props.translate}
                />

                <LoadCompleteStopByDialog
                    show={this.state.isCompleteStopByModalOpen}
                    onClose={this.handleCloseCompleteStopByModal}
                    selectedCombinedLocationIndex={selectedCombinedLocationIndex}
                    stopsCombined={this.state.stopsCombined}
                    isLoadDropped={this.props.IsDropped}
                    onSubmit={(params) => {
                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({
                                LoadID: this.props.match.params.id,
                            }, params),
                            resource: Resources.LoadStopComplete,
                            piggyResource: Resources.LoadInfo,
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: this.state.stopBys[this.state.selectedLocation]?.IsStopCompleted ?
                                translate('message.load_stop_by_completed') :
                                translate('message.load_stop_by_updated')
                        }))

                        this.handleCloseCompleteStopByModal();

                        if (!this.props.isStateDirty) {
                            this.props.setDirty();
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={translate('text.post_stop_update')}
                    widthClass="max-w-3xl"
                    limitHeight={true}
                    show={this.state.isUpdateStopModalOpen}
                    gridColsClass="grid-cols-3"
                    customButtonsHTML={
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                if (this.state.postUpdatePingLocation.address) {
                                    this.setState({
                                        postUpdatePingLocation: {}
                                    })
                                } else {
                                    this.handlePingUpdateClick();
                                }
                            }}
                        >
                            <MapPinIcon className="w-5 h-5 -ml-1 mr-1"/>

                            {this.state.postUpdatePingLocation.address ? "Remove ping update" : "Add ping update"}
                        </button>
                    }

                    onClose={this.handleCloseUpdateStopModal}
                    fields={{
                        LoadID: new Field('LoadID', this.props?.match?.params?.id, [''], false, 'hidden'),
                        LoadStopID: new Field('LoadStopID', selectedLocation?.LoadStopID?.value, [''], false, 'hidden'),
                        Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'datetime'),
                        DateTime: new Field('DateTime', moment().format(getUserTimeFormat()), ['empty'], false, 'time-custom',),
                        LoadStopStatusID: new Field('LoadStopStatusID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                        IsCustomerVisible: new Field(
                            'IsCustomerVisible',
                            '',
                            [''],
                            false,
                            'checkbox',
                            {
                                addContainerClass: 'col-span-full',
                                labelType: "float",
                                note: translate("field.visible_on_customer_shared_load")
                            }),
                        Notes: new Field('Notes', '', [''], false, 'textarea',
                            {addContainerClass: 'col-span-full'})
                        ,
                    }}
                    onSubmit={(params) => {
                        if (params) {

                            delete params.DateTime

                            if (this.state.postUpdatePingLocation.LocationID) {
                                params.LocationID = this.state.postUpdatePingLocation.LocationID;
                            }

                            if (!!this.state.postUpdatePingLocation.lat && !!this.state.postUpdatePingLocation.lng) {
                                params.Latitude = this.state.postUpdatePingLocation.lat;
                                params.Longitude = this.state.postUpdatePingLocation.lng;
                            }

                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.LoadStopUpdate,
                                piggyResource: Resources.LoadInfo,
                                query: this.getQuery(),
                                errorMessage: true, successMessage: translate('message.load_stop_updated')
                            }))

                            this.handleCloseUpdateStopModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        LoadStopStatusID: getLookup('LoadStopStatus', 'LoadStopStatusID', 'LoadStopStatus')
                    }}
                    htmlAfter={
                        <React.Fragment>
                            {!!this.state.isAxiosRequesting && (
                                <div className="inset-0 absolute flex items-center justify-center">
                                    <div className="text-center">
                                        <LoaderSmall/>
                                    </div>
                                </div>
                            )}

                            <StopsShortDisplay
                                isLoading={this.state.isAxiosRequesting}
                                stopsCombined={this.state.stopsCombined}
                                selectedStopID={selectedLocation?.LoadStopID?.value}
                                postUpdatePingLocation={this.state.postUpdatePingLocation}
                                translate={this.props.translate}
                                pingZone={this.state.pingZone}
                            />

                            <ModalTabbed
                                title={translate(`modal_heading.add_ping_update`)}
                                show={this.state.isPingUpdateModalOpen}
                                onClose={this.handleClosePingUpdateModalClick}
                                onSubmit={this.submitPingUpdateLocation}
                                validateOnlyCurrentTab={true}
                                sidebarWidth={null}
                                tabFields={Object.values(getStopByFields({}, translate)).reduce((memo, it) => {
                                    if ((it.name === 'AddressName') || (it.name === 'PostalCode')) {
                                        it.validate = [''];
                                    }
                                    memo[it.name] = it;
                                    return memo;
                                }, {})}
                                selects={this.selects}
                                tabsData={{
                                    "enter_manually": {
                                        name: 'enter_manually',
                                        icon: PencilSquareIcon,
                                        containerClass: "p-6 w-full max-w-lg mx-auto grid grid-cols-12 gap-4",
                                        fields: ["CountryID", "GooglePlaces", "AddressName", "CityName", "StateID", "PostalCode"],
                                    },
                                    "select_location": {
                                        name: 'select_location',
                                        icon: MagnifyingGlassIcon,
                                        fields: ['LocationID'],
                                        componentAfter: (fields) => {
                                            return (
                                                <React.Fragment>
                                                    {!!fields?.LocationID?.value?.metadata && (
                                                        <div
                                                            className="relative px-3 py-8 text-tm-gray-700 bg-tm-gray-50">
                                                            <div className="max-w-md mx-auto space-y-6">
                                                                <LocationInfo
                                                                    data={fields.LocationID.value.metadata}
                                                                    translate={translate}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )
                                        }
                                    },
                                    "select_on_map": {
                                        name: 'select_on_map',
                                        icon: MapIcon,
                                        fields: ["Latitude", "Longitude"],
                                        component: (fields, handleInputChange, updateFields, setDirty) => {
                                            return (
                                                <React.Fragment>
                                                    <DragAndDrop
                                                        className="w-full h-full relative"
                                                        locations={[]}
                                                        isLoading={this.state.isAxiosRequesting}
                                                        loaderMessage={translate("text.fetching_data_from_google")}
                                                        Latitude={fields.Latitude.value}
                                                        Longitude={fields.Longitude.value}
                                                        addMarker={(data) => {
                                                            setDirty(true);
                                                            fields.Latitude.value = data.Latitude;
                                                            fields.Longitude.value = data.Longitude;
                                                            updateFields(fields);
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    }
                                }}
                                translate={translate}
                            />
                        </React.Fragment>
                    }
                />

                <ModalTabbed
                    title={translate(`modal_heading.select_stop_by_location`)}
                    show={this.state.isSelectLocationModalOpen}
                    onClose={this.closeSelectLocationDialog}
                    onSubmit={this.handleSelectLocationClick}
                    validateOnlyCurrentTab={true}
                    sidebarWidth={null}
                    tabFields={getStopByFields(this.state.StopByItem, translate)}
                    selects={this.selects}
                    tabsData={{
                        "enter_manually": {
                            name: 'enter_manually',
                            icon: PencilSquareIcon,
                            containerClass: "p-6 w-full max-w-lg mx-auto grid grid-cols-12 gap-4",
                            fields: ["CountryID", "GooglePlaces", "AddressName", "CityName", "StateID", "PostalCode"],
                        },
                        "select_location": {
                            name: 'select_location',
                            icon: MagnifyingGlassIcon,
                            fields: ['LocationID'],
                            componentAfter: (fields) => {
                                return (
                                    <React.Fragment>
                                        {!!fields?.LocationID?.value?.metadata && (
                                            <div className="relative px-3 py-8 text-tm-gray-700 bg-tm-gray-50">
                                                <div className="max-w-md mx-auto space-y-6">
                                                    <LocationInfo
                                                        data={fields.LocationID.value.metadata}
                                                        translate={translate}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            }
                        },
                        "select_on_map": {
                            name: 'select_on_map',
                            icon: MapIcon,
                            fields: ["Latitude", "Longitude"],
                            component: (fields, handleInputChange, updateFields, setDirty) => {
                                return (
                                    <React.Fragment>
                                        <DragAndDrop
                                            className="w-full h-full relative"
                                            locations={[]}
                                            isLoading={this.state.isAxiosRequesting}
                                            loaderMessage={translate("text.fetching_data_from_google")}
                                            Latitude={fields.Latitude.value}
                                            Longitude={fields.Longitude.value}
                                            addMarker={(data) => {
                                                setDirty(true);
                                                fields.Latitude.value = data.Latitude;
                                                fields.Longitude.value = data.Longitude;
                                                updateFields(fields);
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            }
                        }
                    }}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isLocationContactsDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.LocationsContacts}
                    title={translate("text.contacts_for_location", [this.state.selectedItem?.StopID?.value?.label])}

                    dispatch={this.props.dispatch}
                    fields={this.getContactDialogFields()}
                    widthClass={"max-w-7xl"}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    defaultQuery={{
                        id: this.state.selectedItem?.StopID?.value?.value,
                        OrganizationID: this.state.selectedItem?.OrganizationID?.value?.value //
                    }}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                    onClose={this.toggleContactsDialog}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isDriverSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Drivers}
                    title={translate("text.select_driver")}
                    dispatch={this.props.dispatch}
                    fields={this.getDriverFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={Object.assign(this.getAssetFilters(), {
                        HasActiveEmployment: new Field('HasActiveEmployment', '1', [''], false, "hidden"),
                    })}
                    sortBy={"FirstName"}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleInputChange(this.state.selectedField, {
                                    label: item.LastName + " " + item.FirstName,
                                    value: item.DriverID,
                                    metadata: item
                                })
                                this.toggleDriverSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    onClose={this.toggleDriverSelectDialog}
                    translate={translate}
                    selects={this.getAssetSelects()}
                />

                <ResourceTableDialog
                    show={this.state.isTruckSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Trucks}
                    title={translate("text.select_truck")}
                    dispatch={this.props.dispatch}
                    fields={this.getTruckFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={
                        Object.assign(this.getAssetFilters(),
                            {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                        )}
                    sortBy={"TruckNumber"}
                    defaultAction={(item) => this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleInputChange("TruckID", {
                                    value: item.TruckID,
                                    label: [item.SCAC, item.TruckNumber].filter(it => !!it).join(" - "),
                                    metadata: item
                                })
                                this.toggleTruckSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    selects={this.getAssetSelects()}
                    onClose={this.toggleTruckSelectDialog}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isTrailerSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Trailers}
                    title={translate("text.select_trailer")}
                    dispatch={this.props.dispatch}
                    fields={this.getTrailerFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={
                        Object.assign(this.getAssetFilters(), {
                            NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")
                        })}
                    sortBy={"TrailerNumber"}
                    defaultAction={(item) => this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleInputChange(this.state.selectedField, {
                                    value: item.TrailerID,
                                    label: [item.SCAC, item.TrailerNumber].filter(it => !!it).join(" - "),
                                    metadata: item
                                })
                                this.toggleTrailerSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    selects={this.getAssetSelects()}
                    onClose={this.toggleTrailerSelectDialog}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isCarrierSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Carriers}
                    title={translate("text.select_carrier")}
                    dispatch={this.props.dispatch}
                    fields={this.getCarrierFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    sortBy={"LegalName"}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewCarrierCard', item))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleInputChange("CarrierID", {
                                    label: item.LegalName,
                                    value: item.CarrierID,
                                    metadata: item
                                })
                                this.toggleCarrierSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    selects={this.getAssetSelects()}
                    onClose={this.toggleCarrierSelectDialog}
                    translate={translate}
                />

                {/* DriverNameTxt list */}
                <ResourceTableDialog
                    show={this.state.isDriverNameTxtDialogOpen}
                    onClose={this.closeDriverNameTxtListDialog}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.CarrierDrivers}
                    defaultQuery={{id: this.state.fields.CarrierID?.value?.value}}
                    title={translate("text.select_driver")}
                    dispatch={this.props.dispatch}
                    fields={this.getDriverTxtFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    sortBy={"FirstName"}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleInputChange(this.state.selectedField, {
                                    label: item.LastName + " " + item.FirstName,
                                    value: item.DriverID,
                                    metadata: item
                                })
                                this.closeDriverNameTxtListDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    translate={translate}
                    selects={this.getAssetSelects()}
                />

                {/* Select stop dialog */}
                <ResourceTableDialog
                    show={this.state.isSelectLocationDialogOpen}
                    hideDialogFooter={true}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.LocationsQuick}
                    title={translate("text.select_stop")}

                    noRecordsText={(currentQuery) => translate("text.no_location_query", [currentQuery])}
                    noRecordsBtnLabel={(currentQuery) => translate("btn.create_location_query", [currentQuery])}
                    noRecordsBtnClick={currentQuery => this.handleCreateLoadStop("StopID", currentQuery)}
                    dispatch={this.props.dispatch}
                    fields={{
                        LocationName: new Field('LocationName', '', ['']),
                        LocationNumber: new Field('LocationNumber', '', ['']),
                        LocationZoneID: new Field('LocationZoneID', '', [''], false, 'select-search'),
                        AddressName: new Field('AddressName', '', ['']),
                        CityName: new Field('CityName', '', ['']),
                        State: new Field('State', '', ['']),
                        PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {mask: FIELD_MASK_PHONE}),
                        CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'date'),
                    }}

                    widthClass={"max-w-7xl"}
                    options={{
                        style: {
                            stripedRows: true,
                            horizontalLines: false,
                            floatingActions: true,
                        },
                    }}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        })
                    }}
                    searchFields={{
                        CityName: new Field('CityName', '', [''], false, 'text', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                        State: new Field('State', '', [''], false, 'select', {
                            addContainerClass: 'col-span-2',
                            labelType: "float"
                        }),
                        PostalCode: new Field('PostalCode', '', [''], false, 'text', {
                            addContainerClass: 'col-span-1',
                            labelType: "float"
                        }),
                    }}

                    tableHeaderRight={
                        <div className="col-start-11 col-span-2 flex justify-end items-center">
                            <Button
                                hasPerm={checkPerm(Resources.Locations, CREATE_PERM)}
                                className="btn btn-primary"
                                onClick={() => this.handleCreateLoadStop("StopID", "")}
                            >
                                {translate("btn.create_location")}
                            </Button>
                        </div>
                    }
                    sortBy={"LocationName"}
                    defaultAction={(item) => {
                        this.handleStopInputChange("StopID", {
                            label: item.LocationName,
                            value: item.LocationID,
                            metadata: item
                        })
                        this.handleSelectStopClick();
                    }}
                    onView={(item) => this.props.dispatch(showModal('ViewLocationCard', item))}
                    onClose={this.handleSelectStopClick}
                    translate={translate}
                    htmlAfter={
                        <Modal //treba da postoje dva ovakva dialoga, razlika je u show propu, ne brisati
                            show={this.state.isLocationDialogOpen && this.state.isSelectLocationDialogOpen}
                            widthClass={'max-w-7xl'}
                            closeButtonLabel={translate('btn.close')}
                            onClose={() => this.toggleLocationDialog(false)}
                            translate={translate}
                        >
                            <CreateLocationDialog
                                onClose={() => this.toggleLocationDialog(false)}
                                updateItem={this.state.selectedLocation}
                                dispatch={this.props.dispatch}
                                secondResource={secondResource}
                                dialogResource={this.props.dialogResource}
                                contactResource={this.props.contactResource}
                                translate={translate}
                                isContactsTabHidden={true}
                                onSubmit={(params, fields) => {
                                    if (!!this.state.selectedLocation?.StopID || !!this.state.selectedLocation?.LocationID) {
                                        params.LocationZone = fields.LocationZoneID?.value?.label ?? "";
                                        params.LocationID = this.state.selectedLocation.StopID ?? this.state.selectedLocation.LocationID;
                                    }

                                    if (!!params.LocationID && params.LocationID !== -1) {
                                        this.updateLocationsState(params);
                                        this.props.dispatch(updateDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: params,
                                            resource: Resources.Locations,
                                            errorMessage: true,
                                            successMessage: `Location ${params.LocationName} updated`,
                                        }))
                                    } else {
                                        this.createLocationsState(params);
                                        this.props.dispatch(createDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: params,
                                            resource: Resources.Locations,
                                            piggyResource: Resources.LocationsQuick,
                                            errorMessage: true, successMessage: 'Location Created Successfully!'
                                        }))
                                    }
                                }}
                            />
                        </Modal>
                    }
                />

                {!isEditMode && (
                    <ResourceTableDialog
                        show={this.state.isBookedByDialogOpen}
                        hideDialogFooter={true}
                        tableHeaderRight={
                            <div className="col-start-11 col-span-2 flex justify-end items-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={this.handleCreateContactClick}
                                >
                                    {translate("btn.create_new_contact")}
                                </button>
                            </div>
                        }
                        dialogResource={this.props.dialogResource}
                        resource={Resources.Contacts}
                        title={translate("text.select_booked_by")}
                        messages={this.getBookedByMessage()}
                        dispatch={this.props.dispatch}
                        fields={excludeFields(this.getContactDialogFields(), ['DepartmentID'])}
                        widthClass={"max-w-7xl"}
                        options={this.contactTableOptions}
                        fieldsFilter={{
                            query: new Field('query', '', [''], false, 'search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }),
                        }}
                        defaultQuery={this.getBookedByQuery()}
                        sortBy={"FirstName"}
                        defaultAction={
                            (item) => {
                                this.handleInputChange("BookedByID", {
                                    label: item.LastName + " " + item.FirstName,
                                    value: item.ContactID
                                })
                                this.handleChoseBookedByClick();
                            }
                        }
                        onView={(item) => this.props.dispatch(
                            showModal('ViewContactCard', {ContactID: item.ContactID})
                        )}
                        onClose={this.handleChoseBookedByClick}
                        translate={translate}
                        // buttonLabel={"Create new contact"}
                        // onButtonClick={() => this.handleCreateContactClick()}
                        storeKey={'contactFormQuery'}
                        htmlAfter={
                            <Modal
                                show={this.state.isCreateContactDialogOpen}
                                widthClass={'max-w-3xl'}
                                onClose={() => this.setState({isCreateContactDialogOpen: false})}
                            >
                                <ContactForm
                                    hasSalesSection={true}
                                    onClose={() => this.setState({isCreateContactDialogOpen: false})}
                                    errorMessages={this.state.isEmailExistError
                                        ? [{field: 'Email', message: 'text.email_exists'}]
                                        : []
                                    }

                                    clearErrorData={() => this.setIsEmailExistError(false)}
                                    onSubmit={this.submitContactForm}
                                    translate={translate}
                                />
                            </Modal>
                        }
                    />
                )}

                <ResourceTableDialog
                    show={this.state.isCoveredByDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Contacts}
                    title={translate("text.select_covered_by")}

                    dispatch={this.props.dispatch}
                    fields={excludeFields(this.getContactDialogFields(), ['DepartmentID'])}

                    widthClass={"max-w-7xl"}

                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}

                    messages={this.getCoveredByMessage()}

                    defaultQuery={this.getCoveredByQuery()}
                    sortBy={"FirstName"}
                    defaultAction={(item) => {
                        this.handleInputChange("CoveredByID", {
                            label: item.LastName + " " + item.FirstName,
                            value: item.ContactID
                        })
                        this.handleChoseCoveredByClick();
                    }}
                    onView={(item) => this.props.dispatch(
                        showModal('ViewContactCard', {ContactID: item.ContactID})
                    )}
                    onClose={this.handleChoseCoveredByClick}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isChoseDispatchContactDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.CarrierContacts}
                    title={translate("text.select_dispatch_contact")}

                    tableHeaderRight={
                        <div className="col-start-11 col-span-2 flex justify-end items-center">
                            <button
                                className="btn btn-primary"
                                onClick={this.handleCreateContactClick}
                            >
                                {translate("btn.create_new_contact")}
                            </button>
                        </div>
                    }

                    dispatch={this.props.dispatch}
                    fields={excludeFields(this.getContactDialogFields(), ['DepartmentID'])}

                    widthClass={"max-w-7xl"}

                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}

                    messages={this.getChoseDispatchContactMessage()}

                    defaultQuery={
                        this.state.fields.CarrierID?.value?.value
                            ? {

                                id: this.state.fields.CarrierID?.value?.metadata?.OrganizationID,
                                DepartmentID: ""
                            }
                            : {}
                    }
                    sortBy={"FirstName"}
                    defaultAction={
                        (item) => {
                            this.handleInputChange("DispatchContactID", {
                                label: item.LastName + " " + item.FirstName,
                                value: item.ContactID
                            })
                            this.handleChoseDispatchContactClick();
                        }
                    }
                    onView={(item) => this.props.dispatch(
                        showModal('ViewContactCard', {ContactID: item.ContactID})
                    )}
                    onClose={this.handleChoseDispatchContactClick}
                    translate={translate}
                    htmlAfter={
                        <Modal
                            show={this.state.isCreateContactDialogOpen}
                            widthClass={'max-w-3xl'}
                            onClose={() => this.setState({isCreateContactDialogOpen: false})}
                        >
                            <ContactForm
                                hasDepartmentSection={true}
                                isOrganizationHiddenTab={true}
                                onClose={() => this.setState({isCreateContactDialogOpen: false})}
                                errorMessages={this.state.isEmailExistError
                                    ? [{field: 'Email', message: 'text.email_exists'}]
                                    : []
                                }
                                clearErrorData={() => this.setIsEmailExistError(false)}
                                onSubmit={this.submitDispatcherContactForm}
                                translate={translate}
                            />
                        </Modal>
                    }
                />

                <ResourceTableDialog
                    show={this.state.isDispatchTeamDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.DriverDispatch}
                    title={translate("text.view_dispatch_team")}
                    dispatch={this.props.dispatch}
                    fields={excludeFields(this.getContactDialogFields(), ['DepartmentID'])}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    defaultQuery={{
                        id: this.state.fields.DriverID?.value?.value
                    }}
                    sortBy={"FirstName"}
                    defaultAction={(item) => this.props.dispatch(
                        showModal('ViewContactCard', {ContactID: item.ContactID}))
                    }
                    onCustomActions={[
                        {
                            action: (item) => {
                                openInNewTab("tel:" + item.Phone.replace(/\D/g, ''))
                            },
                            icon: PhoneIcon,
                            visible: () => true
                        },
                        {
                            action: (item) => {
                                openInNewTab("mailTo:" + item.Email)
                            },
                            icon: AtSymbolIcon,
                            visible: () => true
                        }
                    ]}
                    onClose={this.handleViewDispatchTeamClick}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isSplitDispatchInfoModalOpen}
                    title={translate('text.split_dispatch_history')}
                    widthClass={'max-w-4xl'}
                    onClose={this.toggleSplitDispatchInfoModal}
                    closeButtonLabel={translate('btn.close')}
                    isLoading={false}
                >
                    <SwitchDispatchInfo
                        data={this.state.dispatchSwitchData}
                        translate={translate}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isShareCarrierDialogOpen}
                    title={translate("dialog_heading.carrier_share_link")}
                    widthClass={'max-w-sm'}
                    onClose={this.toggleShareCarrierDialog}
                    closeButtonLabel={translate('btn.close')}
                    isLoading={false}
                >
                    <LoadCarrierShareContent
                        toggleCarrierLinkOption={this.toggleCarrierLinkOption}
                        loadInfoData={getProp(this.props.resource, 'data.load/info', {})}
                        carrierLinkOptions={this.state.carrierLinkOptions}
                        toggleCarrierLinkAllOptions={this.toggleCarrierLinkAllOptions}
                        toggleSendURLDialog={this.toggleSendURLDialog}
                        onClose={this.toggleShareCarrierDialog}
                        isLoading={this.props.dialogResource.isLoading}
                        translate={this.props.translate}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isShareCustomerDialogOpen}
                    title={translate("dialog_heading.customer_share_link")}
                    widthClass={'max-w-sm'}
                    onClose={this.toggleShareCustomerDialog}
                    closeButtonLabel={translate('btn.close')}
                    isLoading={false}
                >
                    <LoadCustomerShareContent
                        toggleCarrierLinkOption={this.toggleLinkAllOptions}
                        loadInfoData={getProp(this.props.resource, 'data.load/info', {})}
                        linkOptions={this.state.linkOptions}
                        toggleLinkOption={this.toggleLinkOption}
                        toggleLinkAllOptions={this.toggleLinkAllOptions}
                        toggleSendURLDialog={this.toggleSendURLDialog}
                        onClose={this.toggleShareCustomerDialog}
                        isLoading={this.props.dialogResource.isLoading}
                        translate={this.props.translate}
                    />
                </ModalDefault>

                <Modal
                    show={this.state.isPrimaryCustomerContactDialogOpen}
                    title={translate('text.view_customer_primary_contact')}
                    widthClass={'max-w-7xl'}
                    closeButtonLabel={translate('btn.close')}
                    isLoading={false}
                    onClose={this.handleViewPrimaryCustomerContactClick}
                >
                    <ContactSelector
                        isCreateContactOrganizationHiddenTab={true}
                        contactList={this.state.CustomerContactsList}
                        fields={this.getContactDialogFields()}
                        onSelectContactClick={this.handleCreateContactClick}
                        onClose={this.handleViewPrimaryCustomerContactClick}
                        onUpdateContactList={this.onUpdateContactList}
                        undoAction={this.undoCustomerContactsList}
                        resourcePath={Resources.CustomerContacts}
                        id={this.state.fields.CustomerID?.value?.metadata?.OrganizationID}
                        ContactOrganizationID={this.state.fields.CustomerID?.value?.value}
                        translate={translate}
                    />
                </Modal>

                <ModalSaveResource
                    show={this.state.isCreateCarrierDriverDialogOpen}
                    title={translate("text.create_driver_for_carrier", [this.state.fields.CarrierID?.value?.label])}
                    canSubmit={!!this.state?.selectedItem?.firstName && !!this.state?.selectedItem?.lastName}
                    isLoading={isPageLoading}
                    fields={{
                        FirstName: new Field('FirstName', this.state?.selectedItem?.firstName ?? '', ['empty'], false, 'text', {addContainerClass: 'col-span-full sm:col-span-3'}),
                        LastName: new Field('LastName', this.state?.selectedItem?.lastName ?? '', [''], false, 'text', {addContainerClass: 'col-span-full sm:col-span-3'}),
                        PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {addContainerClass: 'col-span-full sm:col-span-3'}, {mask: FIELD_MASK_PHONE}),
                        Notes: new Field('Notes', '', [''], false, 'textarea', {
                            limitWidth: true,
                            addContainerClass: 'col-span-full'
                        }),
                        IsBlacklisted: new Field('IsBlacklisted', 0, [''], false, 'hidden', {addContainerClass: 'col-span-3'}),
                        Preferred: new Field('Preferred', 0, [''], false, 'hidden', {addContainerClass: 'col-span-3'}),
                    }}
                    onClose={this.toggleCreateCarrierDriverDialog}
                    buttonLabel={translate('btn.save')}
                    translate={translate}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.state.fields?.CarrierID?.value?.value;
                            params.isCoDriver = this.state?.selectedItem?.isCoDriver;

                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.CarrierDrivers,
                                errorMessage: true,
                                successMessage: "Carrier driver created."
                            }));

                            this.setState(
                                {selectedItem: params},
                                () => this.props.setDirty()
                            );
                        }
                    }}
                />

                {this.state.isOcrDialogMinimized && (
                    <div
                        className="py-2 px-4 mr-6 flex items-center fixed bottom-16 right-0 bg-tm-gray-100 rounded-t-card space-x-2 border border-b-none shadow-card border-tm-gray-200 z-10"
                    >
                        <div className="pr-12">
                            <div className="text-base font-semibold">
                                {translate("dialog_heading.autofill_load")}
                            </div>
                        </div>

                        <button
                            onClick={this.handleMinimizeDialogClick}
                            className="btn btn-close justify-center ml-auto mr-2"
                        >
                            <ChevronUpIcon className="w-5 h-5"/>
                        </button>

                        <button
                            onClick={this.closeScanDialog}
                            className="btn btn-close justify-center ml-auto mr-2"
                        >
                            <XMarkIcon className="w-5 h-5"/>
                        </button>
                    </div>
                )}

                <Modal
                    show={this.state.isOCRDialogOpen}
                    baseClass={"flex items-end justify-center text-center sm:block"}
                    modalClass="text-tm-gray-900 w-full inline-block align-bottom bg-popup rounded-card text-left shadow-xl transform transition-all sm:align-middle"
                    widthClass={'max-w-full'}
                    closeButtonLabel={translate('btn.close')}
                    isLoading={false}
                    onClose={this.closeScanDialog}
                >
                    <LoadOCRDialog
                        onCloseScanDialog={this.closeScanDialog}
                        dialogResource={this.props.dialogResource}
                        notifications={this.props.notifications}
                        onSave={this.handleSaveOCRClick}
                        isDialogMinimized={this.state.isOcrDialogMinimized}
                        onMinimizeDialogClick={this.handleMinimizeDialogClick}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        OCRDropzoneShortcutDocument={this.state.OCRDropzoneDocument}
                        updateCurrentFile={(file) => this.setState({OCRDropzoneDocument: file})}
                        location={this.props.location}
                        hashUUID={this.state.hashUUID}
                    />
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isJobsListingModalOpen}
                    title={translate('text.current_jobs')}
                    widthClass={'max-w-3xl'}
                    onClose={() => this.setState({
                        isJobsListingModalOpen: false
                    })}
                    closeButtonLabel={translate('btn.close')}

                >
                    <ResourceTable
                        data={this.state.jobListingData.slice(-10)}
                        fields={{
                            Document: new Field('Document', '', ['empty'], false, 'text',),
                            Status: new Field('Status', '', [''], false, 'text'),
                            Started: new Field('Started', '', [''], false, 'custom', {
                                render: (item) => toFrontDateTimeOCR(item.Started)
                            }),
                            Completed: new Field('Completed', '', [''], false, 'custom', {
                                render: (item) => (
                                    <div className={"flex flex-col justify-center items-center"}>
                                        <div>{item.Status === 'Done' ? toFrontDateTimeOCR(item.Completed) : null}</div>
                                        <div>{this.renderTimeDiff(item.Started, item.Completed)}</div>
                                    </div>
                                )
                            })
                        }}
                        actions={[
                            {
                                action: this.handleOpenOCRTableAction,
                                icon: RectangleGroupIcon,
                                title: translate('text.open_ocr_with_file'),
                                visible: (item) => item.Status === 'Done',
                            }
                        ]}
                        onRowClick={(item) => item.Status === 'Done' && this.handleOpenOCRTableAction}
                        translate={translate}
                        isLoading={this.state.isJobsListingLoading}
                    />

                    <NoRecordsTable
                        show={!this.state.jobListingData.length && !this.state.isJobsListingLoading}
                        title={'No recent OCR jobs'}
                        className={"pt-12 px-6 pb-6"}
                    />

                </ModalDefault>
            </div>
        )
    }
}
