import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";
import {FieldsManager} from "../../../../data/services/fields";
import {includeFields} from "../../../../common/util/util-fields";
import InvoiceExpenseItemTable from "../../dialogs/invoice-expense-item-table";
import FieldCellTextNew from "../../../../common/components/fields/field-cell-text-new";
import FieldCellMoneyNew from "../../../../common/components/fields/field-cell-money-new";
import FieldCellSelectSearch from "../../../../common/components/fields/field-cell-select-search";
import Resources from "../../../../data/services/resources";
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";

export default function RecurringExpenseItemTable({translate, items, getFields, setItems}) {
    const dispatch = useDispatch();
    const [isChargeToModalOpen, setIsChargeToModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const selects = {
        AccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {},
            searchMap: (item) => ({
                value: item.AccountID,
                label: item.AccountName
            })
        },
        TaxID: {
            api: 'api/' + Resources.AccountingTaxes,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.Tax,
                value: item.TaxID,
                amount: item.Amount
            })
        },
        ChargeToOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.OrganizationID,
                label: item.LegalName,
                Organization: item
            })
        },
        ChargeToContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + ' ' + item.LastName,
                Contact: item
            })
        }
    }

    function handleEditExpenseItem(item, index) {
        const itemsUpdate = [...items];

        itemsUpdate[index] = item;
        setItems(itemsUpdate);
    }

    function addNewExpenseItem(item) {
        const itemsUpdate = [...items];
        itemsUpdate.push(item);
        setItems(itemsUpdate);
    }

    function removeItem(index) {
        const itemsUpdate = [...items];

        itemsUpdate.splice(index, 1);
        setItems(itemsUpdate);
    }

    function handleItemInputChange(name, value, item) {
        const itemsUpdate = [...items];

        itemsUpdate[item.index][name].value = value;
        itemsUpdate[item.index][name].errorMessage = [];

        if ("Amount" === name || "Qty" === name) {
            itemsUpdate[item.index]["TotalAmount"].value
                = Number(itemsUpdate[item.index]["Amount"].value) * Number(itemsUpdate[item.index]["Qty"].value);
        }

        setItems(itemsUpdate);
    }

    function handleChargeInputChange(fields, name, value) {
        fields = FieldsManager.updateField(fields, name, value);

        if ("ChargeTo" === name) {
            fields.ChargeToContactID.type = value === 1 ? 'select-search' : 'hidden';
            fields.ChargeToContactID.value = value === 1 ? fields.ChargeToContactID.value : '';
            fields.ChargeToOrganizationID.type = value === 2 ? 'select-search' : 'hidden';
            fields.ChargeToOrganizationID.value = value === 2 ? fields.ChargeToOrganizationID.value : '';
        }

        return fields;
    }

    const renderCells = {
        Description: (item) => {
            return <FieldCellTextNew
                name="Description"
                value={item?.['Description']}
                onChange={(name, value) => handleItemInputChange(name, value, item)}
                autoFocus={!item.Description}
                errorMessage={items?.[item.index]?.['Description']?.errorMessage}
            />
        },
        AccountID: (item) => <FieldCellSelectSearch
            name="AccountID"
            value={{value: item?.['AccountID'], label: item?.['Account']}}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            placeholder={translate("text.no_account_selected")}
            isClearable={true}
            select={selects["AccountID"]}
            errorMessage={items?.[item.index]?.['AccountID']?.errorMessage}
        />,
        Amount: (item) => <FieldCellMoneyNew
            name="Amount"
            value={item?.['Amount']}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Amount']?.errorMessage}
        />,
        Qty: (item) => <FieldCellTextNew
            align="right"
            type="integer"
            name="Qty"
            value={item?.['Qty']}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Qty']?.errorMessage}
        />,
        TaxID: (item) => <FieldCellSelectSearch
            name="TaxID"
            value={{value: item?.['TaxID'], label: item?.['Tax']}}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            placeholder={translate("text.no_tax_selected")}
            isClearable={true}
            select={selects["TaxID"]}
        />,
        Advanced: (item) => <button
            onClick={() => {
                setIsChargeToModalOpen(true);
                item.Advanced = 1;
                setSelectedItem(item);
            }}
            className="hover:text-primary flex items-center gap-4"
        >
            {item.ChargeToContact || item.ChargeToOrganization || "No item selected"}

            <PencilAltOutlineIcon className="w-5 h-5 text-tm-gray-400"/>
        </button>
    }

    return <>
        <InvoiceExpenseItemTable
            handleSelectFirstElement={() => null}
            getFields={getFields}
            // addBtnRef={this.addBtnRef}
            renderCells={renderCells}
            items={items}
            onRemoveItem={removeItem}
            translate={translate}
            onAddNewItem={addNewExpenseItem}
            onEditItem={handleEditExpenseItem}
            isIncome={false} // true
            dispatch={dispatch}
            hideAddInlineItemButton={true}
        />

        <ModalSaveResource
            title="Who Should Be Charged?"
            widthClass="max-w-md"
            gridColsClass="grid-cols-3"
            show={isChargeToModalOpen}
            onClose={() => setIsChargeToModalOpen(false)}
            fields={includeFields(getFields(selectedItem), ['ChargeTo', 'ChargeToContactID', 'ChargeToOrganizationID'])}
            handleInputChange={handleChargeInputChange}
            onSubmit={(params, fields) => {
                if (params) {
                    const selectedItemUpdate = Object.assign({}, selectedItem);
                    selectedItemUpdate.Advanced = 1;
                    selectedItemUpdate.ChargeTo = fields.ChargeTo.value;
                    selectedItemUpdate.ChargeToContact = fields.ChargeToContactID.value.label;
                    selectedItemUpdate.ChargeToContactID = fields.ChargeToContactID.value.value;
                    selectedItemUpdate.ChargeToOrganization = fields.ChargeToOrganizationID.value.label;
                    selectedItemUpdate.ChargeToOrganizationID = fields.ChargeToOrganizationID.value.value;

                    handleEditExpenseItem(getFields(selectedItemUpdate), selectedItem.index);
                    setIsChargeToModalOpen(false);

                }
            }}
            translate={translate}
            metadata={selects}
        />
    </>
}