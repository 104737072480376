import React, {useState} from 'react'
import {classNames, cnv, getWeightUnitName} from '../../../common/util/util-helpers'
import {Field} from "../../../data/services/fields";
import {convertMeasurements, getCommodityFields, getLoadSelects} from "../../dispatch/load-view/load-utils";
import LoadInfoReadLocations from "../../dispatch/load-view/load-sections/load-info-read-locations";
import LoadInfoCommodities from "../../dispatch/load-view/load-sections/load-info-commodities";
import {numberWithCommas} from "../../../util/util-formaters";
import {LOAD_STATUS_CANCELED, LOAD_STATUS_DELIVERED} from "../../../util/util-constants";
import StarIcon from "@heroicons/react/20/solid/StarIcon";
import {useDispatch} from "react-redux";
import {createPublicResource} from "../../../data/actions/publicResource";
import Resources from "../../../data/services/resources";
import LoadCompleteStopDialog from "../../dispatch/load-view/load-sections/dialog/load-complete-stop-dialog";
import DataCard from "../../../common/components/display-data/data-card";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import ModalDefault from "../../../common/components/modal/modal-default";
import InfoParagraph from "../../../common/components/info-paragraph";
import ProgressArrows from "../../../common/components/progress-arrows";

export default function PublicLoadInfoTab({
                                              translate,
                                              infoData = {},
                                              stops = [],
                                              mergedStops = [],
                                              commoditiesData = [],
                                              token
                                          }) {
    const dispatch = useDispatch();
    const isLoadRated = !!infoData.CustomerRating;

    const [isAddReviewDialogOpen, setIsAddReviewDialogOpen] = useState(false);
    const [CustomerRating, setCustomerRating] = useState(0);
    const [isRatingFinal, setIsRatingFinal] = useState(false);
    const [CustomerReviewNote, setCustomerReviewNote] = useState("");

    const [selectedStop, setSelectedStop] = useState({})
    const [isCompleteStopModalOpen, setIsCompleteStopModalOpen] = useState(false)

    const defaultWeightUnit = 'lb';
    const hasStops = !!stops.length;

    const stepList = [
        {id: '1', name: 'Booked', stepID: 4, status: getStatus(4, infoData.LoadStatusID)},
        {id: '2', name: 'Dispatched', stepID: 5, status: getStatus(5, infoData.LoadStatusID)},
        {id: '3', name: 'In transit', stepID: 6, status: getStatus(6, infoData.LoadStatusID)},
        {id: '4', name: 'Delivered', stepID: 7, status: getStatus(7, infoData.LoadStatusID)},
    ]

    const basicFields = {
        LoadType: new Field('LoadType', "", [''], false, 'select'),
        Customer: new Field('Customer', "", [''], false, 'select-search'),
        CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, "text")
    }

    const carrierAssets = {
        Driver: new Field('Driver', "", [''], false, 'select-search'),
        CoDriver: new Field('CoDriver', "", [''], false, 'select-search'),
        Truck: new Field('Truck', "", [''], false, 'select-search'),
        Trailer: new Field('Trailer', "", [''], false, 'select-search'),
        TrailerType: new Field('TrailerType', "", [''], false, 'text'),
        SecondTrailer: new Field('SecondTrailer', "", [''], false, 'select-search'),
        SecondTrailerType: new Field('SecondTrailerType', "", [''], false, 'text'),
    }

    const brokerageAssets = {
        LineOfBusiness: new Field('LineOfBusiness', "", [''], false, 'text'),
        CoBrokerID: new Field('CoBrokerID', "", [''], false, 'search-select'),
        Carrier: new Field('Carrier', "", [''], false, 'search-select'),
        CarrierReferenceNumber: new Field('CarrierReferenceNumber', "", [''], false, 'text'),
        DriverNameTxt: new Field('DriverNameTxt', "", [''], false, 'text'),
        CoDriverNameTxt: new Field('CoDriverNameTxt', "", [''], false, 'text'),
        TruckTxt: new Field('TruckTxt', "", [''], false, 'text'),
        TrailerTxt: new Field('TrailerTxt', "", [''], false, 'text'),
        TrailerTypeTxt: new Field('TrailerTypeTxt', "", [''], false, 'text'),
        DispatchNameTxt: new Field('DispatchNameTxt', "", [''], false, 'text'),
        DispatchAreaCode: new Field('DispatchAreaCode', "", [''], false, 'text'),
        DispatchPhoneNumber: new Field('DispatchPhoneNumber', "", [''], false, 'text')
    }

    const handleCompleteStopClick = (stop, i) => {
        let stopData = stops[i];

        setSelectedStop(stopData);
        setIsCompleteStopModalOpen(true);
    }

    const noteFields = {
        ExternalNotesCustomer: new Field('ExternalNotesCustomer', "", [''], false, 'textarea'),
    }

    let totalCommodityPieces = 0;

    const totalCommodityPallets = commoditiesData.reduce((memo, it) => {
        totalCommodityPieces += it.CountPieces;
        memo = memo + Number(it.CountPallets)
        return memo
    }, 0);

    let commodityUnitType = undefined;

    const totalCommodityWeight = numberWithCommas(commoditiesData.reduce((memo, it) => {
        if (!commodityUnitType) {
            commodityUnitType = it.UnitType
        }

        if (!!commodityUnitType && commodityUnitType !== it.UnitType) {
            commodityUnitType = translate("text.unit_s");
        }

        if (!it.Weight) {
            return memo
        }

        memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit))
        return memo
    }, 0))

    if (!commodityUnitType) {
        commodityUnitType = translate("text.unit_s");
    }

    const handleAddReviewClick = () => {
        setIsAddReviewDialogOpen(true);
    }

    const handleStarMouseEnter = (it) => {
        if (!isRatingFinal) {
            setCustomerRating(it)
        }
    }

    const handleSubmitReviewClick = () => {
        dispatch(createPublicResource({
            resource: Resources.LoadPublicReview,
            params: {
                token,
                CustomerReviewNote,
                CustomerRating
            },
            piggyResource: Resources.LoadPublic,
            query: {
                token
            }
        }));

        setIsAddReviewDialogOpen(false);
    }

    return (
        <React.Fragment>
            <div className="pt-8 space-y-4">
                <ProgressArrows
                    locked={true}
                    stepList={stepList}
                    LoadStatusID={infoData.LoadStatusID}
                    initialLoadStatusID={infoData.LoadStatusID}
                    translate={translate}
                />

                {infoData.LoadStatusID === LOAD_STATUS_DELIVERED && !isLoadRated && (
                    <InfoParagraph>
                        <div className="flex items-center">
                            <div>
                                <p className="font-bold">Load is delivered</p>
                                <p>Please leave a review.</p>
                            </div>

                            <div className="flex items-center ml-auto">
                                <button
                                    className="btn btn-outline"
                                    onClick={handleAddReviewClick}
                                >
                                    Add review
                                </button>
                            </div>
                        </div>
                    </InfoParagraph>
                )}

                {infoData.LoadStatusID === LOAD_STATUS_DELIVERED && isLoadRated && (
                    <InfoParagraph type="success">
                        <div className="flex items-center">
                            <div>
                                <p className="font-bold">Thank you for your review!</p>
                                <blockquote className="text-sm italic font-semibold text-green-800 word-break">
                                    {infoData.CustomerReviewNote}
                                </blockquote>
                            </div>

                            <div className="flex items-center ml-auto flex-shrink-0">
                                <ReviewStars
                                    inactiveStarBg={"text-green-50"}
                                    onStarMouseEnter={handleStarMouseEnter}
                                    CustomerRating={infoData.CustomerRating}
                                    setCustomerRating={setCustomerRating}
                                    setIsRatingFinal={setIsRatingFinal}
                                />
                            </div>
                        </div>
                    </InfoParagraph>
                )}

                <div className="grid gap-8 max-w-7xl mx-auto grid-cols-1 xl:grid-cols-2 pt-8">
                    <div>
                        <div className="font-bold leading-5 mb-2">{translate('card_header.basic_load_info')}</div>

                        <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                            {Object.values(basicFields).filter(it => it.type !== 'hidden').map((field, i) => {
                                field.type = "text";

                                return (
                                    <DataCard
                                        key={field.name}
                                        displayField={field}
                                        fieldsData={infoData}
                                        className={
                                            classNames(
                                                "px-3 py-1.5 flex items-center px-6",
                                                i < Object.values(basicFields).length - 1
                                                    ? "border-b border-tm-gray-300"
                                                    : undefined
                                            )
                                        }
                                        selects={getLoadSelects()}
                                        translate={translate}
                                    />
                                )
                            })}
                        </div>
                    </div>

                    {!!infoData.AllowAssets && (
                        <div>
                            <div className="font-bold leading-5 mb-2">{translate('text.assets')}</div>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">

                                <AssetsSection
                                    infoData={infoData}
                                    brokerageAssets={brokerageAssets}
                                    carrierAssets={carrierAssets}
                                    translate={translate}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="grid gap-y-4 w-full max-w-7xl mx-auto">
                    {Object.values(noteFields).filter(field => field.type !== 'hidden' && !!infoData?.[field.name]).map((field, i) => {
                        return (
                            <div className="w-full">
                                <div className="font-bold leading-5 mb-2">{translate('field.' + field.name)}</div>

                                <div className="px-3 py-8 bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                    <div className="max-w-5xl mx-auto whitespace-pre-line">
                                        {infoData[field.name]}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {hasStops && (
                    <LoadInfoReadLocations
                        loadedMilesText={translate("text.LoadedMiles")}
                        TotalMiles={infoData.TotalMilesNoStopBy}
                        disableTotalMilesClick={true}
                        stopsCombined={mergedStops}
                        stops={stops}
                        onCompleteStopClick={handleCompleteStopClick}
                        isStopInfoVisible={true}
                        stopBys={[]}
                        translate={translate}
                    />
                )}

                {!!commoditiesData.length && (
                    <LoadInfoCommodities
                        addClass="pt-9"
                        disableEdit={true}
                        data={convertMeasurements(commoditiesData)}
                        getCommodityFields={getCommodityFields}
                        totalCommodityPieces={totalCommodityPieces}
                        totalCommodityPallets={totalCommodityPallets}
                        commodityUnitType={commodityUnitType}
                        totalCommodityWeight={totalCommodityWeight}
                        defaultWeightUnit={defaultWeightUnit}
                        disableDrag={true}
                        isSectionExpanded={true}
                        translate={translate}
                    />
                )}
            </div>

            <ModalDefault
                show={isAddReviewDialogOpen}
                title={translate("modal_heading.load_review")}
                widthClass={"max-w-xl"}
                translate={translate}
                onClose={() => setIsAddReviewDialogOpen(false)}
                closeButtonLabel={translate('btn.not_now')}
                buttonLabel={translate('btn.save_review')}
                buttonDisabled={!(CustomerRating && isRatingFinal)}
                onButtonClick={handleSubmitReviewClick}
            >
                <div className="mx-auto space-y-6 px-4 py-8 max-w-lg">
                    <div className="space-y-1">
                        <p className="text-base text-tm-gray-700 font-semibold">Score</p>

                        <ReviewStars
                            bgClass={"bg-sky-600/5"}
                            onStarMouseEnter={handleStarMouseEnter}
                            CustomerRating={CustomerRating}
                            setCustomerRating={setCustomerRating}
                            setIsRatingFinal={setIsRatingFinal}
                        />
                    </div>

                    <div className="space-y-1">
                        <p className="text-base text-tm-gray-700 font-semibold">Note</p>

                        <textarea
                            className="form-control rounded-xl"
                            value={CustomerReviewNote}
                            onChange={(e) => setCustomerReviewNote(e.target.value)}
                            rows="6"
                        >
                        </textarea>
                    </div>
                </div>
            </ModalDefault>

            <LoadCompleteStopDialog
                hidePlannedInfo={true}
                show={isCompleteStopModalOpen}
                onClose={() => setIsCompleteStopModalOpen(false)}
                selectedLocation={selectedStop}
                stops={stops}
                LoadID={infoData.LoadID}
                commodityData={commoditiesData}

                defaultWeightUnit={defaultWeightUnit}
                commodityUnitType={commodityUnitType}
                canCompleteStop={false}
                hideDialogFooter={true}

                translate={translate}
            />
        </React.Fragment>
    )
}

const getStatus = (stepID, initialLoadStatusID) => {
    switch (true) {
        case (initialLoadStatusID === LOAD_STATUS_CANCELED):
            return "canceled";
        case (initialLoadStatusID === stepID):
            return "current";
        case (initialLoadStatusID >= stepID || stepID <= 4):
            return "complete";
        case initialLoadStatusID < stepID:
            return "upcoming"
    }
}

const ReviewStars = ({
                         onStarMouseEnter,
                         CustomerRating,
                         setCustomerRating,
                         bgClass,
                         inactiveStarBg = "text-tm-gray-300",
                         setIsRatingFinal
                     }) => {
    return (
        <div className={
            classNames(
                "flex py-2 px-6 rounded-full items-center",
                bgClass
            )
        }>
            {new Array(10).fill(1).map((it, i) => {
                return (
                    <button
                        className="relative"
                        onMouseEnter={() => onStarMouseEnter(it + i)}
                        onMouseLeave={() => onStarMouseEnter(0)}
                        onClick={() => {
                            setCustomerRating(it + i);
                            setIsRatingFinal(true);
                        }}
                    >
                        <StarIcon
                            className={
                                classNames(
                                    "w-8 h-8 text-tm-gray-400",
                                )
                            }
                        />
                        <StarIcon
                            className={
                                classNames(
                                    "absolute w-8 h-8 z-1 bottom-px left-px",
                                    i < CustomerRating ? "text-yellow-400" : inactiveStarBg
                                )
                            }
                        />
                    </button>
                )
            })}

            <div className="pl-2 text-base ml-auto">
                {CustomerRating} out of 10
            </div>
        </div>
    )
}

const AssetsSection = ({infoData, brokerageAssets, carrierAssets, translate}) => {
    const fields = Object.values(infoData.IsBroker ? brokerageAssets : carrierAssets).filter(it => it.type !== 'hidden');

    const hasValues = !!fields.find(it => !!infoData[it.name] && infoData[it.name] !== " ");


    if (!hasValues) {
        return <div className="p-1"><InfoBar>No Assets currently on the load!</InfoBar></div>
    }

    return (
        fields.map((field, i) => {
            field.type = "text";

            return (
                <DataCard
                    displayField={field}
                    fieldsData={infoData}
                    className={classNames("px-3 py-1.5 flex items-center px-6", i < Object.values(!!infoData.IsBroker ? brokerageAssets : carrierAssets).length - 1 ? "border-b border-tm-gray-300" : undefined)}
                    selects={getLoadSelects()}
                    translate={translate}
                />
            )
        })
    )
}
