import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import {deleteResource, getResource} from '../../../data/actions/resource'
import {showModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import AddWatchersDialog from './addWatchersDialog'
import {Field} from '../../../data/services/fields'
import {CREATE_PERM, DELETE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalDefault from "../../../common/components/modal/modal-default";
import ContactCard from "../../../common/components/modal/contact-card-dialog/contact-card";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import Button from "../../../common/components/button";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";

export default class WatchersTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            limit: 10,
            offset: 0,
            sort: 'ASC',
            sortBy: '',
            paginationPage: 1,
            userView: false,
            text: 'contact',
            fields: this.getFields(),
            isWatchersDialogOpen: false,
            isConfirmModalOpen: false
        }
    }

    componentDidMount() {
        this.fetchData();

        if (!!this.props?.fetchInfoData && !this.props?.info?.isLoading && this.props?.info?.data?.LoadID !== Number(this.props?.id)) {
            this.props.fetchInfoData();
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.ui.resourceDialog !== this.props.ui.resourceDialog) && !this.props.ui.resourceDialog) {
            this.setState({userView: false})
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            id: this.getID()
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: Resources.LoadWatchers
        }))
    }

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, this.fetchData)
    }

    updateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData())
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            isConfirmModalOpen: !this.state.isConfirmModalOpen,
            selectedItem: item
        })
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
    }

    toggleContactCardDialog = (item = null) => {
        this.setState({
            viewContactCard: !this.state.viewContactCard,
            selectedItem: item
        })
    }

    handleToggleWatchersDialog = () => {
        this.setState({
            isWatchersDialogOpen: !this.state.isWatchersDialogOpen
        })
    }

    getFields = () => {
        return {
            WatcherName: new Field('WatcherName', '', []),
            Email: new Field('Email', '', []),
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, Resources.LoadWatchers + '.list', [])

        const count = getProp(resource.data, Resources.LoadWatchers + '.count', 0)

        return (
            <div>
                <div className="flex items-center mb-4">

                    <h2 className="text-2xl mx-4">{translate('text.Watchers')}</h2>


                    <div className="flex ml-auto">
                        <button className="btn btn-header mr-2" onClick={this.fetchData}>
                            <ArrowPathIcon className="w-5 h-5"/>
                        </button>

                        <Button
                            hasPerm={checkPerm(Resources.LoadWatchers, CREATE_PERM)}
                            className="btn btn-outline"
                            onClick={this.handleToggleWatchersDialog}
                        >
                            {translate('btn.add_watchers')}
                        </Button>
                    </div>

                </div>

                <TableCard>
                    <ResourceTable
                        addClass="rounded-card"

                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        options={{
                            style: {
                                horizontalLines: true,
                                verticalLines: false,
                                floatingActions: true
                            }
                        }}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        paginationButtonLimit={5}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onOffsetChange={this.updateOffset}
                        onSortChange={this.updateSort}

                        onView={this.handleShowDetails}
                        onDelete={checkPerm(Resources.Contacts, DELETE_PERM) ? this.handleToggleConfirmModal : null}
                    />

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={false}
                        title={'No matching records found'}
                    />

                    <TableCardFooter
                        show={count > this.state.limit}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.updateOffset(value, currentPage)
                                    : this.updateSort(name, value)
                            }
                            pageOffset={this.state.offset}
                            pageLimit={this.state.limit}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalDefault
                    show={!!this.state.viewContactCard}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.contact_details')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.toggleContactCardDialog(null)}
                    translate={translate}
                >
                    <ContactCard
                        translate={translate}
                        ContactID={this.state.selectedItem?.ContactID}
                        minHeight={'425px'}
                        {...this.props}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.isConfirmModalOpen}
                    text={this.props.translate('message.are_you_sure_remove_watcher')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                ContactIDs: [this.state.selectedItem.ContactID],
                                LoadID: this.getID(),
                                id: this.getID()
                            },
                            errorMessage: true, successMessage: this.props.translate('message.watcher_deleted'),
                            resource: Resources.LoadWatchers,
                            piggyResource: Resources.LoadWatchers,
                        }))
                        this.handleToggleConfirmModal()
                    }}
                />

                {/* Add watchers Dialog */}
                {this.state.isWatchersDialogOpen && (
                    <AddWatchersDialog
                        /*watchers={data}*/
                        LoadID={this.getID()}
                        onClose={this.handleToggleWatchersDialog}
                        {...this.props}
                    />
                )}

            </div>
        )
    }
}
