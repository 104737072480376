import React from 'react';
import LocalStorage from "../../util/localStorage";
import {
    COUNTRY_ID_CANADA,
    COUNTRY_ID_USA,
    CREATE_PERM,
    DELETE_PERM,
    DISTANCE_UNIT_KILOMETERS_ID,
    DISTANCE_UNIT_MILES_ID,
    DRIVER_STATUS_AVAILABLE,
    DRIVER_STATUS_BOOKED, DRIVER_STATUS_INACTIVE,
    DRIVER_STATUS_ON_LOAD,
    DRIVER_STATUS_ON_VACATION, DRIVER_STATUS_PENDING, DRIVER_STATUS_SUSPENDED, DRIVER_STATUS_TERMINATED,
    MonthsSimple,
    READ_PERM, REGEX_MENTION_IN_STRING, TABLE_OPTIONS_SUF,
    TABLE_SETTINGS_SUF,
    UPDATE_PERM
} from "../../util/util-constants";
import {cloneDeep, genericMoneyFormatter, isObject} from "./util-vanilla";
import {numberWithCommas} from "./util-formaters";
import Tooltip from "../components/tooltip";
import {numberWithCommasToBack} from "../../util/util-formaters";
import {toFrontDate, toFrontDateFromUTC, toFrontDateTimeFromUTC} from "./util-dates";
import moment from "moment-timezone";
import Badge from "../components/badge";
import {closeNotification} from "../../data/actions/ui";
import Resources from "../../data/services/resources";
import convert from 'convert-units';
import {ArrowLeftOnRectangleIcon, BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/20/solid";
import printJS from "print-js";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_PAID,
    INVOICE_STATUS_SENT
} from "./util-consts";
import Tippy from "@tippyjs/react";
import {Field, FieldsManager} from "../../data/services/fields";
import {getUser} from "./util-auth";
import packageJson from "../../../package.json"

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getLookup(name, key = null, value = null) {
    const lookup = LocalStorage.get('lookup', {});
    if (key === null) key = name + "ID"
    if (value === null) value = name

    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getLookupWithFilter(name, key = null, value = null, filterValues = (item) => true) {
    const lookup = LocalStorage.get('lookup', {});
    if (key === null) key = name + "ID"
    if (value === null) value = name

    return lookup[name] && lookup[name].filter(it => filterValues(it)).reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getSubLookupFor(name, value = null) {
    return getProp(LocalStorage.get('lookup', {}), name, [])
        .find(item => item[`${name}ID`] === (+value))
}

export function getTranslatedLookup(name, translate) {
    const lookup = LocalStorage.get('lookup', {});
    const key = name + "ID"
    const value = name

    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = translate('lookup.' + item[value]);
            return memo;
        }, {})
}

export function getLookupsFromArray(arr) {
    return arr.reduce((memo, it) => {
        memo[it] = getLookup(it);
        return memo;
    }, {})
}

export function itemHasPerm(item, perm) {
    const itemPerm = item.perm.toString(2);

    switch (perm) {
        case READ_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 1))
        case CREATE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 2))
        case UPDATE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 3))
        case DELETE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 4))
        default:
    }
}

export function permBtnToggle(item, permName) {
    let mod;
    switch (permName) {
        case "read":
            mod = 1;
            break;
        case "create":
            mod = 2;
            break;
        case "update":
            mod = 4;
            break;
        case "delete":
            mod = 8;
            break;
        default:
            mod = 0;
    }
    if (!item.perm || item.perm > 15) {
        item.perm = 0;
    }

    if (itemHasPerm(item, mod)) {
        item.perm -= mod;
        item[permName] = false;
    } else {
        item.perm += mod;
        item[permName] = true;
    }

    return item;
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export function mileageToErrorMessage(mileage, translate) {
    if (mileage === "-2" && translate) {
        return translate("text.mileage_error_message")
    }

    return mileage;
}

export function returnMileage(value) {
    return numberWithCommas(getProp(LocalStorage.get('user'), 'Contact.DistanceUnitID', 0) === DISTANCE_UNIT_KILOMETERS_ID ? Math.round(value * 1.61) : Math.round(value), 0)
}

export function openInNewTab(url) {
    window.open(url, '_blank', 'noopener, noreferrer');
}

export const getDocumentTypesFor = (typeField) => {
    const lookup = LocalStorage.get('lookup', {});
    const key = "DocumentTypeID"
    const value = "DocumentType";

    return lookup['DocumentType'] && lookup['DocumentType'].filter(it => !!it[typeField]).reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export const updateStateFields = (fields, name, value) => {
    if (name === "CountryID") {
        if ((Number(value) === COUNTRY_ID_USA) || (Number(value) === COUNTRY_ID_CANADA)) {
            fields.StateID.type = 'select'
        } else {
            fields.StateID.type = 'hidden'
            fields.StateID.value = ''
        }
    }

    if (name === "PhysicalCountryID") {
        if ((Number(value) === COUNTRY_ID_USA) || (Number(value) === COUNTRY_ID_CANADA)) {
            fields.PhysicalStateID.type = 'select'
        } else {
            fields.PhysicalStateID.type = 'hidden'
            fields.PhysicalStateID.value = ''
        }
    }

    return fields
}

export function getDefaultUserOfficeValues() {
    const office = getProp(LocalStorage.get('user'), 'Office', {})

    if (!office) {
        return null
    }

    return {
        value: office.OfficeID,
        label: office.OfficeName,
        metadata: office
    }
}

export function getDefaultContactGroup() {
    const defaultContactGroup = getProp(LocalStorage.get('user'), 'ContactGroups.0', {})

    if (!defaultContactGroup.ContactGroupID) {
        return null
    }
    return {
        value: defaultContactGroup.ContactGroupID,
        label: defaultContactGroup.ContactGroupName
    }
}

const getDefaultMeasure = (type) => {
    switch (type) {
        case "length":
            return LocalStorage.get("user")?.Contact?.MeasureUnit;
        case "weight":
            return LocalStorage.get("user")?.Contact?.WeightUnit;
        case "temperature":
            return LocalStorage.get("user")?.Contact?.MeasureTemperature;
        default:
            return null
    }
}

export const getDefaultMeasurementUnitName = () => {
    return LocalStorage.get("user")?.Contact?.MeasureUnit ?? "in";
}

export const getDefaultWeightUnitName = () => {
    return getFormattedMeasure(LocalStorage.get("user")?.Contact?.WeightUnit ?? "lb").key;
}

export const getTempUnitString = (MeasureTemperatureID) => {
    return MeasureTemperatureID === 1 ? "°C" : "°F"
}

export const cnv = (value, from, to = null) => {
    value = numberWithCommasToBack(value);
    value = value ? value : 0;
    const From = getFormattedMeasure(from);

    if (isNaN(value) || !From) {
        return 0;
    }

    if (!to) {
        to = getDefaultMeasure(From.type);

        if (!to) {
            return 0;
        }
    }

    const To = getFormattedMeasure(to);

    return convert(value).from(From.key).to(To.key);
}

export const getPreFillFromQuery = () => {
    let prefilled = (new URL(document.location)).searchParams;
    prefilled = prefilled.get('prefilled');
    window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

    if (prefilled === "undefined" || !prefilled) {
        return "";
    }

    return prefilled;
}

const getFormattedMeasure = (name) => {
    name = name.toLowerCase();

    let key;
    let type = "length";
    switch (name) {
        case "in":
        case "inch":
        case "inches":
            key = "in";
            break;
        case "ft":
        case "foot":
        case "feet":
            key = "ft";
            break;
        case "cm":
        case "centimetre":
        case "centimetres":
            key = "cm";
            break;
        case "m":
        case "meter":
        case "meters":
            key = "m";
            break;
        case "lb":
        case "lbs":
        case "pounds":
        case "libra":
            key = "lb";
            type = "weight";
            break;
        case "kg":
        case "kgs":
        case "kilo":
        case "kilos":
        case "kilogram":
        case "kilogramme":
        case "kilogrammes":
            key = "kg";
            type = "weight";
            break;
        case "c":
        case "°c":
        case "celsius":
            key = "C";
            type = "temperature";
            break;
        case "f":
        case "°f":
        case "fahrenheit":
            key = "F";
            type = "temperature";
            break;
        default:
            key = null;
    }

    return {key, type}
}

export const getMeasurementUnitName = (MeasureUnitID = LocalStorage.get("user")?.Contact?.MeasureUnitID) => {
    const MeasureUnits = getLookup("MeasureUnit", "MeasureUnitID", "MeasureUnit");

    if (!MeasureUnitID || !MeasureUnits) {
        return "in"
    }
    return getFormattedMeasure(MeasureUnits[MeasureUnitID] ?? "in").key;
}

export const updateContractFields = (fields, name, value) => {
    if (name === 'ContractOnFile') {
        fields.ContractStartDate.disabled = !value
        fields.ContractEndDate.disabled = !value
        fields.ContactNumber.disabled = !value

        fields.ContractStartDate.value = ''
        fields.ContractEndDate.value = ''
        fields.ContactNumber.value = ''

        fields.ContractEndDate.props.minDate = ''
        fields.ContractStartDate.props.maxDate = ''
    }
    if (name === "ContractStartDate") {
        if (!fields.ContractEndDate.props) fields.ContractEndDate.props = {}
        fields.ContractEndDate.props.minDate = value
    }
    if (name === "ContractEndDate") {
        if (!fields.ContractStartDate.props) fields.ContractEndDate.props = {}
        fields.ContractStartDate.props.maxDate = value
    }

    return fields
}

export function longTableColumn(value, characters = 50) {
    if (value && value.toString().length > characters) {
        return (
            <Tooltip content={<span
                className={'break-all'}>{value}</span>}><span>{value.slice(0, characters)}...</span></Tooltip>
        )
    } else {
        return value
    }
}

export const handleMaskedFocus = (e) => {
    const element = e.target;
    let firstMaskCharacter = 0;
    for (let i = element.value.length; i > -1; i--) {
        if (!isNaN(element.value[i])) {
            firstMaskCharacter = i + 1; // find index of a last digit character
            break;
        }
    }

    element.setSelectionRange(firstMaskCharacter, firstMaskCharacter + 1);
}

export const splitName = (name) => {
    if (!name) return [];
    const i = name.indexOf(' ');

    if (i > 0) {
        return [name.substring(0, i), name.substring(i + 1)];
    }

    return [name];
}

export const returnTaskStatus = (value) => {
    switch (value) {
        case 1:
            return "text.open";
        case 2:
            return "text.in_progress";
        case 3:
            return "text.completed";
        case 4:
            return "text.finished";
        default:
    }
}

export function concatFullPhoneNumber(phone) {
    const areaCode = phone.AreaCode ? "(" + phone.AreaCode + ")" : "";
    const phoneExtension = phone.PhoneExtension ? "[" + phone.PhoneExtension + "]" : "";
    return [areaCode, phone.PhoneNumber, phoneExtension].join(" ");
}

export const resourceIsCreated = (resource, prevResource, key = "id") => {
    if (prevResource) { // prevResource is for functional components
        if (!!resource?.create?.[key] && resource.create?.[key] !== prevResource?.create?.[key]) {
            return !!resource?.create?.[key];
        }
    } else {
        return !!resource?.create?.id || !!resource?.create?.ids
    }

    return false;
}

export const resourceIsUpdated = (resource, prevResource) => {
    if (prevResource) { // prevResource is for functional components
        if (!!resource?.update && !prevResource?.update) {
            return !!resource?.update;
        }
    } else {
        return !!resource?.update
    }

    return false;
}

export const resourceIsDeleted = (resource, prevResource) => {
    if (prevResource) { // prevResource is for functional components
        if (prevResource.deleteResource === null && resource.deleteResource === true) {
            return !!resource?.deleteResource;
        }
    } else {
        return !!resource?.deleteResource;
    }

    return false;
}

export const resourceIsLoaded = (resource, prevResource) => {
    return !!resource?.data && !resource?.isLoading && prevResource?.isLoading;
}

export const orderFields = (fields, fieldsOrder) => {
    // eslint-disable-next-line no-unused-vars
    const defaultClickAction = Object.values(fields)[0]?.metadata?.onCellClick;

    // Transform to object for selecting visible columns
    const columnVisibilityData = Object.values(fieldsOrder).reduce((memo, it) => {
        memo[it.name] = it.show;
        return memo
    }, {});

    // Clone object
    const filteredFields = Object.assign({}, fields);

    // Remove hidden fields from cloned object
    Object.keys(filteredFields).forEach(field => {
        if (!columnVisibilityData[field]) {
            delete filteredFields[field]
        }
    })

    // Transform to array for sorting
    const fieldsOrderList = fieldsOrder.reduce((memo, it) => {
        memo.push(it.name);
        return memo
    }, []);

    // Sort and return
    return Object.fromEntries(Object.entries(filteredFields).sort((a, b) => fieldsOrderList.indexOf(a[0]) - fieldsOrderList.indexOf(b[0])));
}

export function ConstantTranslate(constant, translate) {
    return Object.keys(constant).reduce((memo, key) => {
        memo[key] = translate("option." + constant[key]);
        return memo;
    }, {});
}

export function getDefaultCountry() {
    const countries = getProp(LocalStorage.get('lookup', {}), "Country", {})
    const defaultOfficeCountry = getProp(LocalStorage.get("user"), "Office.CountryName", "USA")
    return Object.values(countries).find(it => it.CountryName === defaultOfficeCountry);
}

export const getDefaultFieldOrder = (fields, pagePath) => {
    const localStorageKey = pagePath + TABLE_SETTINGS_SUF;

    let settings = LocalStorage.get(localStorageKey);

    if (settings?.length !== Object.keys(fields).length) {
        // reset settings if fields are added or removed from the code
        settings = null
    }

    if (!settings) {
        settings = Object.keys(fields).filter(it => !fields[it]?.metadata?.hideTable).reduce((memo, it) => {
            memo.push({
                name: fields[it].name,
                columnName: fields[it]?.metadata?.columnName,
                show: true,
                subColumn: [],
                inSubColumn: false
            });
            return memo;
        }, []);
    }

    return settings;
}

export function scrollToElementBottom(elementID) {
    let objDiv = document.getElementById(elementID);

    if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight
    }
}

export const renderTaskDueDate = (item) => {
    if (!item.DueDate) {
        return undefined;
    }

    let ExpiryDate = item.DueDate
    let today = moment().format("YYYY-MM-DD HH:mm:ss")
    let x = 1
    let xDaysBefore = moment(ExpiryDate).add(-x, 'days').format("YYYY-MM-DD HH:mm:ss")
    let styles = ''
    if (ExpiryDate) {
        if (xDaysBefore < today && today < ExpiryDate) {
            styles = 'warning'
        }
        if (item.TaskStatusID !== 3 &&
            item.TaskStatusID !== 4 &&
            today > ExpiryDate) {
            styles = 'danger'
        }
    }
    return (
        <>
            <Badge type={styles}>
                {toFrontDateTimeFromUTC(ExpiryDate)}
            </Badge>
        </>
    )
}

export function focusOnElement(firstElementIndex = 1, elementSelector = ".modal-open") {
    // add all the elements inside modal which you want to make focusable
    const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modals = document.querySelectorAll(elementSelector);
    const modal = modals[modals.length - 1]


    if (modal) {
        const firstFocusableElement = modal?.querySelectorAll(focusableElements)[firstElementIndex]; // get first element to be focused inside modal
        const focusableContent = modal.querySelectorAll(focusableElements);

        const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        document.addEventListener('keydown', function (e) {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus(); // add focus for the last focusable element
                    e.preventDefault();
                }
            } else { // if tab key is pressed
                if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                    firstFocusableElement.focus(); // add focus for the first focusable element
                    e.preventDefault();
                }
            }
        });
        firstFocusableElement && firstFocusableElement.focus();
    }
}

export function returnSameKeyValueObject(from, to) {
    let object = {}
    for (let i = from; i <= to; i++) {
        Object.assign(object, {
            [i]: i
        })
    }
    return object
}

export function verifyFilterFields(storageState, pageFields) {
    let fields = storageState?.queryFilterFields;

    if (fields) {
        fields = Object.keys(fields).reduce((memo, name) => {
            if (pageFields[name]) {
                memo[name] = pageFields[name];
                memo[name].value = fields[name].value;
            }

            return memo;
        }, {});

        storageState.queryFilterFields = fields;
    }

    return storageState;
}

export function returnMileageText() {
    // Removed mile/s because it was asked in a ticket, will only not when km are selected
    return getProp(LocalStorage.get('user'), 'Contact.DistanceUnitID', 0) === DISTANCE_UNIT_MILES_ID ? '' : 'km/s'
}

export function generateCustomNotification(notification, dispatch, translate, history) {
    if (!!notification?.notificationMetadata?.ExternalAccessToken && !!notification?.notificationMetadata?.Name && !!notification?.notificationMetadata?.Message) {
        // External message notification
        const onClickEvent = () => openInNewTab('/loads/info/' + (notification.title.split("#").pop()))
        return {
            title: notification.title,
            messageType: "message",
            bottom: {
                buttons: (it) => [
                    {
                        className: "btn-text text-sm",
                        label: translate("btn.open_load_in_new_tab"),
                        onClick: onClickEvent
                    },
                    {
                        className: "btn-text text-sm text-tm-gray-600",
                        label: translate("btn.dismiss"),
                        onClick: () => dispatch(closeNotification(it))
                    }
                ]
            },
            onClickEvent,
            timeout: 8000
        };
    }

    if (!!notification?.notificationMetadata?.event && (notification?.notificationMetadata?.event === "navigate")) {
        const {resource, id} = notification.notificationMetadata
        const onClickEvent = () => openInNewTab(resource + (id ? `/${id}` : ""))
        return {
            title: notification.title,
            messageType: "message",
            bottom: {
                buttons: (it) => [
                    {
                        className: "btn-text text-sm",
                        label: translate("btn.open_in_new_tab"),
                        onClick: onClickEvent
                    },
                    {
                        className: "btn-text text-sm text-tm-gray-600",
                        label: translate("btn.dismiss"),
                        onClick: () => dispatch(closeNotification(it))
                    }
                ]
            },
            onClickEvent,
            timeout: 8000
        };
    }

    if (!!notification?.notificationMetadata?.resource
        &&
        notification?.notificationMetadata?.resource.startsWith("/all-tasks?TaskID=")) {
        const onClickEvent = () => openInNewTab(notification?.notificationMetadata?.resource)
        return {
            title: notification.title,
            messageType: "success",
            bottom: {
                buttons: (it) => [
                    {
                        className: "btn-text text-sm",
                        label: translate("btn.open_task_in_new_tab"),
                        onClick: onClickEvent
                    },
                    {
                        className: "btn-text text-sm text-tm-gray-600",
                        label: translate("btn.dismiss"),
                        onClick: () => dispatch(closeNotification(it))
                    }
                ]
            },
            onClickEvent,
            timeout: false
        };
    }

    switch (notification.title) {
        case "NO_RESPONSE":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "EMAIL_PARAMETER_ALREADY_EXISTS":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "BILL_PAID_FOR_BATCH_ENTRY":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "PAYROLL_ITEM_INVALID_OR_ALREADY_PAYED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "TRUCK_INACTIVE":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "OPERATION_FAILED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "DRIVER_TRUCK_ALREADY_DISPATCH_ANOTHER_LOAD": {
            const onClickEvent = () => openInNewTab("/loads/info/" + notification?.result?.metadata[0]?.LoadID)
            return {
                title: translate("message.error_updating_load"),
                content: `Driver/Truck already dispatched on load ${notification?.result?.metadata[0]?.LoadNumber}.`,
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: `Open load ${notification?.result?.metadata[0]?.LoadNumber}`,
                            onClick: onClickEvent,
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
                onClickEvent
            };
        }
        case "DRIVER_WITH_PHONE_NUMBER_ALREADY_EXISTS":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "DRIVER_INACTIVE": {
            let loadWarnings;
            if (notification?.action?.data?.params?.warnings) {
                loadWarnings = JSON.parse(notification.action.data.params.warnings)
            }
            return {
                title: translate("message.error_updating_load"),
                content: translate("message.assigned_driver_inactive"),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_driver_in_new_tab"),
                            onClick: () => openInNewTab("/drivers/info/" + loadWarnings?.inactiveDriverID),
                            isHidden: !loadWarnings?.inactiveDriverID
                        },
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_co_driver_in_new_tab"),
                            onClick: () => openInNewTab("/drivers/info/" + loadWarnings?.inactiveCoDriverID),
                            isHidden: !loadWarnings?.inactiveCoDriverID
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                }
            };
        }
        case "TWO_MULTISTOPS_REQUIRED":
            return {
                title: translate("message.error_saving_load"),
                content: translate("message.content_two_stops_required"),
                messageType: "error",
            };
        case "TASK_CREATED_SUCCESSFULLY": {
            const onClickEvent = () => openInNewTab("/all-tasks?LinkedTaskID=" + notification?.result?.data?.id)
            return {
                title: translate("message.task_created_successfully", [notification?.result?.data?.id]),
                messageType: "success",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_task_link", [notification?.result?.data?.id]),
                            onClick: onClickEvent
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
                onClickEvent,
                timeout: 8000
            };
        }
        case "TRUCK_DOES_NOT_HAVE_PERMIT": {
            const truckID = notification?.result?.metadata?.[0]?.Truck?.TruckID;

            return {
                title: translate("message.TRUCK_DOES_NOT_HAVE_PERMIT", [notification?.result?.data?.id]),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_truck_new_tab"),
                            onClick: () => openInNewTab("/trucks/info/" + truckID),
                            isHidden: !truckID
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
                timeout: false
            };
        }
        case "ITEM_EXISTS":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "TRAILER_INACTIVE":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "VALUE_ALREADY_EXISTS":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "LOOKUP_DOES_NOT_EXISTS":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "SERVER_ERROR":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };
        case "APPROVE_NOT_ALLOWED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            };

        case "VIN_PARAMETER_ALREADY_EXISTS": {
            const duplicateVINTruckID = notification.result?.metadata?.id

            return {
                title: translate("message." + notification.title),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_truck_new_tab"),
                            onClick: () => openInNewTab("/trucks/info/" + duplicateVINTruckID),
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
            };
        }
        case "TRUCK_NUMBER_PARAMETER_ALREADY_EXISTS": {
            const duplicateTruckID = notification.result?.metadata?.id

            return {
                title: translate("message." + notification.title),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_truck_new_tab"),
                            onClick: () => openInNewTab("/trucks/info/" + duplicateTruckID),
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
            }
        }
        case "TRAILER_NUMBER_PARAMETER_ALREADY_EXISTS": {
            const duplicateTrailerID = notification.result?.metadata?.id

            return {
                title: translate("message." + notification.title),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_trailer_new_tab"),
                            onClick: () => openInNewTab("/trailers/info/" + duplicateTrailerID),
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
            }
        }
        case "TRAILER_VIN_PARAMETER_ALREADY_EXISTS": {
            const duplicateVINTrailerID = notification.result?.metadata?.id

            return {
                title: translate("message." + notification.title),
                messageType: "error",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.open_trailer_new_tab"),
                            onClick: () => openInNewTab("/trailers/info/" + duplicateVINTrailerID),
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ]
                },
            };
        }
        case "ISSUE_NOT_ALLOWED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }

        case "ACTION_NOT_ALLOWED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }
        case "CONTACT_GROUP_NAME_ALREADY_EXIST":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }

        case "CONTACT_EXISTS_IN_BATCH":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }
        case "INVALID_DISPATCH_STATE":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }
        case "MILEAGE_CALC_FAILED":
            return {
                title: translate("message." + notification.title),
                messageType: "error"
            }
        case "JOB_STARTED": {
            return {
                title: translate("message." + notification.title),
                messageType: "success",
            };
        }
        case "JOB_FAILED": {
            return {
                title: translate("message." + notification.title),
                messageType: "error",
            };
        }

        case "Permission update": {
            return {
                title: translate(notification.title),
                messageType: "success",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: <div className={"flex"}>
                                <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-1 -ml-1"/> Re-login
                            </div>,
                            onClick: () => {
                                LocalStorage.set("redirect_path", window.location.pathname);
                                LocalStorage.remove('user');
                                history.push('/logging-in');
                                dispatch(closeNotification(it))
                            }
                        }
                    ]
                },
            };
        }
        case "(AiDone->Done)": {
            const isUserOnLoadCreatePage = window.location.href.includes("/loads/create")
            const metadata = JSON.parse(notification.notificationMetadata)
            const documentUUID = metadata?.UID
            const onClickEvent = () => openInNewTab(`/loads/create#${documentUUID}`)
            return {
                title: "Job complete",
                content: "The document you have submitted to OCR is finished processing.",
                messageType: "success",
                bottom: {
                    buttons: (it) => [
                        {
                            className: "btn-text text-sm",
                            label: translate("btn.navigate_to_ocr"),
                            onClick: onClickEvent,
                            disabled: isUserOnLoadCreatePage
                        },
                        {
                            className: "btn-text text-sm text-tm-gray-600",
                            label: translate("btn.dismiss"),
                            onClick: () => dispatch(closeNotification(it))
                        }
                    ],
                },
                onClickEvent,
                timeout: isUserOnLoadCreatePage ? 3000 : false
            }
        }

        case "(AiFail->Fail)":
        case "(OcrWork->Fail)": {
            const isUserOnLoadCreatePage = window.location.href.includes("/loads/create")
            return {
                title: "Job failed",
                content: "There was an error processing your file",
                messageType: "error",
                timeout: isUserOnLoadCreatePage ? 3000 : false
            }
        }
        default:
            return {
                title: notification.title
            };
    }
}

export function getVacationTemplateLabel(item) {
    const monthLabel = `Restart Month: ${MonthsSimple[item.Months]}`
    const dayLabel = `Days per Year: ${item.Days ? Number(item.Days).toFixed(2) : ''}`

    return monthLabel + ", " + dayLabel;
}

export const focusOnAddedPhone = () => {
    const phoneFields = document.querySelectorAll(".js-phone-type-focus div div div input");
    phoneFields[phoneFields.length - 1].focus()
}

// This function return boolean, if driver have release date or does not have release date - true and driver do not have release date - false
export function checkEmploymentReleaseDate(employmentArray) {
    const employmentArrayLength = employmentArray.length;
    if (employmentArrayLength < 1) {
        return true;
    }
    return toFrontDateFromUTC(employmentArray[employmentArrayLength - 1].ReleaseDate) !== "";
}

export function getReferenceLinks(item) {
    switch (item.ReferenceType) {
        case 'tbl_Load':
            return `/loads/info/${item.ReferenceID}`;
        case 'tbl_Truck':
            return `/trucks/info/${item.ReferenceID}`;
        case 'tbl_Trailer':
            return `/trailers/info/${item.ReferenceID}`;
        case 'tbl_Driver':
            return `/drivers/info/${item.ReferenceID}`;
        case 'tbl_Agent':
            return `/agents/info/${item.ReferenceID}`;
        case 'tbl_Employee':
            return `/employees/info/${item.ReferenceID}`;
        case 'tbl_FixedAsset':
            return `/accounting-assets/${item.ReferenceID}`;
        case 'tbl_MileageTaxEntry':
            return `/fuel-report`;
        case 'tbl_PurchaseOrder':
            return `/purchase-order/${item.ReferenceID}`;
        case 'tbl_FuelPurchaseImportPreview':
            return `/fuel-purchase`;
        case 'tbl_PayrollBatch':
            return `/driver-payroll`;
        default:
    }
}

export function getReferenceResources(item) {
    switch (item.ReferenceType) {
        case 'tbl_Load':
            return Resources.LoadInfo;
        case 'tbl_Truck':
            return Resources.Truck;
        case 'tbl_Trailer':
            return Resources.Trailer;
        case 'tbl_Driver':
            return Resources.DriverInfo;
        case 'tbl_Agent':
            return Resources.AgentInfo;
        case 'tbl_Employee':
            return Resources.EmployeesInfo;
        case 'tbl_FixedAsset':
            return Resources.AssetsAssetsInfo;
        case 'tbl_MileageTaxEntry':
            return Resources.IFTAMileageReports;
        case 'tbl_PurchaseOrder':
            return Resources.AccountingPurchaseOrderInfo;
        case 'tbl_FuelPurchaseImportPreview':
            return Resources.Fuel;
        case 'tbl_PayrollBatch':
            return Resources.PayrollBatches;
        default:
    }
}

export function getDriverBadgeClass(type) {
    switch (type) {
        case DRIVER_STATUS_AVAILABLE:
            return "success"
        case DRIVER_STATUS_BOOKED:
        case DRIVER_STATUS_ON_LOAD:
            return "primary"
        case DRIVER_STATUS_ON_VACATION:
        case DRIVER_STATUS_PENDING:
            return "warning"
        case DRIVER_STATUS_INACTIVE:
        case DRIVER_STATUS_SUSPENDED:
        case DRIVER_STATUS_TERMINATED:
            return "danger"
        default:
            return "danger"
    }
}

function getColumns(fields, translate) {
    return Object.values(fields).filter(field => !field?.metadata?.hideTable).reduce((memo, it, index) => {
        const defaultLabel = fields[it.name]?.metadata?.label ? translate("field." + fields[it.name].metadata.label) : translate("field." + it.name);

        memo[it.name] = {
            name: it.name,
            label: defaultLabel,
            frozen: fields[it.name].metadata?.isFrozen ?? !index,
            type: it.type,
            show: true,
            showMobile: index < 4,
            isMerged: false,
            merged: [],
            minWidth: fields[it.name].metadata?.minWidth
        };
        return memo
    }, {});
}

export function getDefaultTableOptions(fields, tableCustomDefaults, pagePath, translate) {
    const localStorageKey = pagePath + TABLE_OPTIONS_SUF;
    let options = LocalStorage.get(localStorageKey);

    if (!options) {
        options = getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate);
    } else {
        Object.assign(options, {
            icons: {
                IconSortAsc: BarsArrowUpIcon,
                IconSortDesc: BarsArrowDownIcon,
            }
        });
    }

    if (options?.behaviour?.hasVirtualRows === undefined) {
        options.behaviour.hasVirtualRows = true;
    }

    if (options?.style?.isTableLayoutFixed === undefined) {
        if (!options?.style) {
            options.style = {}
        }

        options.style.isTableLayoutFixed = true;
    }

    return options;
}

function mergeColumns(options, tableCustomDefaults) {
    return Object.keys(options["columns"]).reduce((columnsMemo, column) => {
        if (tableCustomDefaults["columns"][column]) {
            columnsMemo[column] = Object.assign({}, options["columns"][column], tableCustomDefaults["columns"][column]);
        } else {
            columnsMemo[column] = options["columns"][column];
        }

        return columnsMemo;
    }, {});
}

export function getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate) {
    let options = {
        behaviour: {
            stickyHeader: true,
            rowSelect: false,
            rowHover: true
        },
        style: {
            stripedRows: false,
            horizontalLines: true,
            verticalLines: false,
            condensed: false,
            floatingActions: true,
            frozenActionColumn: false,
        },
        classes: {
            tdPadding: "h-12 px-3",
            tdWithSubColumnsPadding: "h-16 px-3",
            tdCondensedWithSubColumnsPadding: "h-12 px-3",

            tdPaddingActions: "align-middle pl-3 pr-6",
            verticalLinesClass: "divide-x divide-tm-gray-200",
            horizontalLinesClass: "border-b border-tm-gray-200",
            maxHeightClass: "max-h-[calc(100vh-23rem)]"
        },
        columns: getColumns(fields, translate),
        icons: {
            IconSortAsc: BarsArrowUpIcon,
            IconSortDesc: BarsArrowDownIcon,
        }
    };

    options = Object.keys(options).reduce((memo, option) => {
        if (tableCustomDefaults[option]) {
            if (option === "columns") {
                memo["columns"] = mergeColumns(options, tableCustomDefaults);
            } else {
                memo[option] = Object.assign(options[option], tableCustomDefaults[option]);
            }
        } else {
            memo[option] = options[option];
        }

        return memo;
    }, {})

    return options;
}

export const renderInvoiceStatusBadge = (it) => {
    if (!it.InvoiceStatus) {
        switch (it.InvoiceStatusID) {
            case INVOICE_STATUS_OPEN:
                it.InvoiceStatus = 'Open';
                break;
            case INVOICE_STATUS_SENT:
                it.InvoiceStatus = 'Sent';
                break;
            case INVOICE_STATUS_PAID:
                it.InvoiceStatus = 'Paid';
                break;
        }
    }
    if (
        (it.InvoiceStatus !== 'Paid')
        &&
        it.IsRevised
        &&
        !!it?.InvoiceSentDate
        &&
        !!it?.RevisedDate
        &&
        moment(it.InvoiceSentDate, DEFAULT_DATABASE_DATETIME_FORMAT).isBefore(moment(it.RevisedDate, DEFAULT_DATABASE_DATETIME_FORMAT))
    ) {
        return (
            <div className="inline-block space-y-0.5">
                <Badge type="warning"
                       className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                    Revised
                </Badge>

                <div className="text-red-600 font-bold leading-3">
                    {(it?.RevisedDate ? ' ' + toFrontDate(it.RevisedDate) : '')}
                </div>
            </div>
        )
    } else {
        switch (it.InvoiceStatus) {
            case 'Sent':
                return <div className="inline-block space-y-0.5">
                    {!!it.LastDigitalSendEmail && (
                        <Tippy content={it.LastDigitalSendEmail}>
                            <span className="relative">
                                <Badge type="warning"
                                       className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                                    {it.InvoiceStatus}
                                </Badge>

                                <div className="text-yellow-600 font-bold leading-3">
                                    {(it?.InvoiceSentDate ? ' ' + toFrontDate(it.InvoiceSentDate) : '')}
                                </div>
                            </span>
                        </Tippy>
                    )}
                    {!it.LastDigitalSendEmail && (
                        <>
                            <Badge type="warning"
                                   className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                                {it.InvoiceStatus}
                            </Badge>

                            <div className="text-yellow-600 font-bold leading-3">
                                {(it?.InvoiceSentDate ? ' ' + toFrontDate(it.InvoiceSentDate) : '')}
                            </div>
                        </>
                    )}
                </div>
            case 'Paid':
                return <div className="inline-block space-y-0.5">
                    <Badge type="success"
                           className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                        {it.InvoiceStatus}
                    </Badge>

                    <div className="text-green-600 font-bold leading-3">
                        {(it?.InvoicePaidDate ? ' ' + toFrontDate(it.InvoicePaidDate) : '')}
                    </div>
                </div>
            default:
                return <Badge type="info">{it.InvoiceStatus}</Badge>
        }
    }
}

export const renderExpenseStatusBadge = (it) => {
    switch (+it.ExpenseStatusID) {
        case 3:
            return (
                <div className="inline-block space-y-0.5">
                    <Badge type="success"
                           className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                        {it.ExpenseStatus}
                    </Badge>

                    <div className="text-green-600 font-bold leading-3">
                        {(it?.ExpensePaidDate ? " " + toFrontDate(it.ExpensePaidDate) : "")}
                    </div>
                </div>
            )
        default:
            return (
                <div className="inline-block">
                    <Badge type="info"
                           className="block flex-grow-0 text-center px-3 py-0.5 rounded-btn text-sm font-medium"
                    >{it.ExpenseStatus}</Badge>
                    <div className="text-tm-gray-600 mt-px">
                        {it.IsDelayedPayment ? " (Loan)" : ""}
                    </div>
                </div>
            )
    }
}

export function saveTableColumns(pagePath = "", tableOptions = {}) {
    let tableOptionsUpdate = cloneDeep(tableOptions);

    if (tableOptionsUpdate.icons) {
        delete tableOptionsUpdate.icons;
    }

    LocalStorage.set(pagePath + TABLE_OPTIONS_SUF, tableOptionsUpdate)
}

export const parseHtmlMessageWithMentions = (message) => {
    const matches = message.match(REGEX_MENTION_IN_STRING);

    if (matches?.length) {
        matches.forEach(it => {
            let mention = it.replace('@{{@', "");
            mention = mention.replace('}}', "").split("|");
            const name = mention[0];
            const id = mention[1];
            const htmlMention = `<button data-contact-id="${id}" data-name="${name}" class="mention">${name}</button>`;

            message = message.replace(it, htmlMention)
        })
    }

    return message;
}

export function getErrorNotificationWithExceptions(result, action) {
    if (result?.responseStatus === 403) {
        return {
            title: action.data.errorMessage === true
                ? "You do not have the necessary permissions to perform this action. Please contact your system administrator if you need access."
                : action.data.errorMessage,
                result: result,
            action: action,
            timeout: action.data.timeout ?? true,
            messageType: "error"
        }
    }

    switch (result.data) {
        case "DRIVER_TRUCK_ALREADY_DISPATCH_ANOTHER_LOAD":
            return ({
                title: "Error updating load",
                content: `Driver/Truck already dispatched on load ${result?.metadata[0]?.LoadNumber}.`,
                messageType: "error",
                actions: {
                    hasDismissAction: true,
                    buttons: [
                        {
                            className: "btn-text text-sm",
                            label: `Open load ${result?.metadata[0]?.LoadNumber}`,
                            onClick: () => openInNewTab("/loads/info/" + result?.metadata[0]?.LoadID),
                        }
                    ]
                }
            })
        default:
            return ({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage,
                messageType: "error"
            })
    }
}

export function returnOffsetAndPagination(list, query, paginationPage) {
    const {offset, limit, archived} = query

    return archived ? {
        offset: offset,
        paginationPage: paginationPage
    } : {
        offset: (list.length === 1) ? ((offset - limit) >= 0 ? offset - limit : offset) : offset,
        paginationPage: list.length === 1 ? paginationPage === 1 ? paginationPage : paginationPage - 1 : paginationPage
    }
}

export function groupListBySCAC(list, itemName) {
    let groupedList = {};
    list.sort((a, b) => (a.SCAC > b.SCAC) ? 1 : -1).forEach((it) => {
        if (!groupedList[it.SCAC]) {
            groupedList[it.SCAC] = {
                label: "SCAC: " + it.SCAC,
                options: []
            };
        }

        groupedList[it.SCAC].options.push({
            label: (it.SCAC ? it.SCAC + " " : "") + it[itemName + "Number"],
            value: it[itemName + "ID"],
            metadata: it
        });
    });

    return Object.values(groupedList);
}

export const onDocumentPrint = (url, type) => {
    switch (type) {
        case 'pdf':
            printJS({
                printable: url,
                type: 'pdf'
            })
            break;
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
            printJS({
                printable: url,
                type: 'image'
            })
            break;
        default:
            break;
    }
}

export function updateQuery(queryFields, filterFieldsNames, name, value) {
    let queryClone = cloneDeep(queryFields);

    if ("sortBy" === name) {
        if (value === queryClone.sortBy.value) {
            queryClone.sort.value = queryClone.sort.value === 'ASC' ? 'DESC' : 'ASC'
        }
    }

    if ("ALL_FIELDS" === name) {
        Object.values(queryClone).forEach(field => {
            if (filterFieldsNames.includes(field.name)) {
                FieldsManager.updateField(queryClone, field.name, "")
            }
        })
    } else {
        queryClone = FieldsManager.updateField(queryClone, name, value);
    }

    return queryClone;
}

export function getHeaderFilterValues(filters) {
    return filters ? Object.keys(filters)
        .reduce((memo, it) => {
            memo[it] = filters[it].value ? 1 : 0
            return memo
        }, {}) : {}
}

export const getWeightUnitName = (unitID) => {
    return unitID ? "lb" : "kg"
}

export function getHighlightedFullAddressName(it, locationClass, isNameAndAddressInline) {
    const address = it?.AddressName
    const locationData = [it?.CityName, it?.State, it?.PostalCode, it?.CountryName].filter(it => !!it);

    if (!address && !locationData.length) {
        return "No location selected";
    }

    return (
        <div className="text-sm">
            {!isNameAndAddressInline && (
                <React.Fragment>
                    <div className="text-left">{it.LocationName}</div>
                    <div className="text-tm-gray-700">{address}{address ? "," : ""}</div>
                </React.Fragment>
            )}

            {isNameAndAddressInline && (
                <div className="text-tm-gray-700">{it.LocationName} {address}{address ? "," : ""}</div>
            )}

            <span className={locationClass ?? "font-bold"}>{locationData.join(", ")}</span>
        </div>
    )
}

export function getUserNotification() {
    const notificationSound = getUser('Contact.NotificationSound');
    const isNotificationMuted = getUser('Contact.NotificationSoundMute');
    if (notificationSound && !isNotificationMuted) {
        return notificationSound;
    }

    return 0;
}

export function getDefaultQueryFields(primaryField, translate) {
    return {
        query: new Field('query', '', [''], false, 'search', {
            hideLabel: true,
            hasActiveBadge: true,
            labelType: "float",
            addContainerClass: "col-span-4"
        }, {placeholder: translate("text.search")}),
        sort: new Field('sort', 'ASC', [''], false, 'hidden'),
        sortBy: new Field('sortBy', primaryField, [''], false, 'hidden'),
        offset: new Field('offset', "0", [''], false, 'hidden'),
        limit: new Field('limit', "20", [''], false, 'select', {
            hideLabel: true,
            labelType: "float"
        }, {
            placeholder: "100",
            menuPlacement: "top"
        })
    }
}

export const getAmountText = (item, translate) => {
    if (item.Amount) {
        let value = item.Amount
        if (item.FeeTypeID === 1) {
            value = genericMoneyFormatter(item.Amount)
        } else if (item.FeeTypeID === 2 || item.FeeTypeID === 3 || item.FeeTypeID === 4 || item.FeeTypeID === 5) {
            value = value + '%'
        } else if (item.FeeTypeID === 6) {
            value = translate('text.x_per_mile', [value])
        } else if (item.FeeTypeID === 7) {
            value = translate('text.x_per_mile_and_empty_mile', [item.Amount, item.AmountExtra])
        }
        return (
            <>{value}</>
        )
    }
}

export function getFullAddressName(it) {
    const address = it?.AddressName

    return [address, it.CityName, [it.State, it.PostalCode].filter(it => !!it).join(" "), it.CountryName].filter(it => !!it).join(", ")
}

export function fieldsToInfoHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy
        .filter(
            it => !it?.metadata?.hideDialog && (!selects[it.name]?.visibleFilter || !selects[it.name].visibleFilter(fieldsCpy))
        )
        .reduce((memo, item) => {
            let field;

            let readOnlyFieldGroupClass = item?.metadata?.addContainerClass ?? "sm:col-span-1 relative";
            let readOnlyLabelClass = item?.metadata?.formLabelClass ?? "flex text-sm font-semibold text-tm-gray-700";
            let readOnlyFieldClass = item?.metadata?.formDataClass ?? "mt-1 text-sm font-semibold text-tm-gray-900";

            let notAvailable = translate("text.not_available");

            let itemLabelText = (item?.metadata?.label) ? translate("field." + item?.metadata?.label) : translate("field." + item.name)

            if (item.type === 'render') {
                field = <div className={readOnlyFieldGroupClass}>{item.metadata.render(item)}</div>
            } else if (item.type === 'custom') {
                field = selects[item.name];
            } else if (item.type === 'hidden') {
                field = null;
            } else if (item.type === "select") {
                const value = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""))[item.value];

                field = (
                    <div key={item.name} className={readOnlyFieldGroupClass}>
                        <dt className={readOnlyLabelClass}>{itemLabelText}</dt>
                        <dd className={readOnlyFieldClass}>
                            {value ?? notAvailable}
                        </dd>
                    </div>
                );
            } else if (item.type === "checkbox") {
                field = (
                    <div className={readOnlyFieldGroupClass}>
                        <dt className={readOnlyLabelClass}>
                            {itemLabelText}
                        </dt>
                        <dd className={readOnlyFieldClass}>
                            {item.value ? translate("text.yes") : translate("text.no")}
                        </dd>
                    </div>
                );
            } else {
                field = (
                    <div className={readOnlyFieldGroupClass}>
                        <dt className={readOnlyLabelClass}>
                            {item?.metadata?.beforeLabelHtml}
                            {itemLabelText}
                            {item?.metadata?.afterLabelHtml}
                        </dt>
                        <dd
                            className={classNames(readOnlyFieldClass, item?.props?.onClick ? "text-primary cursor-pointer" : "")}
                            onClick={() => item?.props?.onClick(item)}
                        >
                            {item?.metadata?.beforeValueHtml}
                            {item.value ? item.value : notAvailable}
                            {item?.metadata?.afterValueHtml}
                        </dd>
                    </div>
                );
            }
            memo.push(field);

            return memo;
        }, []);
}

export const tooltipTextareaContent = (textareaContent) => {
    return textareaContent.split('\n').map((it, i) => {
        return <p key={i}>{it}</p>;
    });
}

export function processFloat(value) {
    const processedValue = value.replace(/^\.+|[^0-9.-]|(?<=\..*)\./g, '');

    const firstCharacter = processedValue.charAt(0);

    if (isNaN(firstCharacter)) {
        return Number("0" + processedValue);
    }

    return processedValue;
}

export const getFromLocalStorage = (key, path = '', defaultVal = '') => {
    return getProp(LocalStorage.get(key), path, defaultVal)
}

export const getTemperaturePref = () => {
    return getFromLocalStorage('user', 'Contact.MeasureTemperatureID', 1);
}

export const getBoundsFromStops = (stops) => {
    const boundsPadding = 1.5;
    let lats = [];
    let lngs = [];
    stops.forEach(stop => {
        lats.push(stop.Latitude);
        lngs.push(stop.Longitude);
    });

    return [
        [
            Math.min.apply(Math, lngs) - boundsPadding,
            Math.min.apply(Math, lats) - boundsPadding
        ],
        [
            Math.max.apply(Math, lngs) + boundsPadding,
            Math.max.apply(Math, lats) + boundsPadding
        ]
    ];
}

export function verifyTableOptions(fields, savedOptions, translate) {
    if (!savedOptions?.columns) {
        return undefined;
    }
    
    savedOptions.columns = Object.keys(fields).reduce((memo, field, i) => {
        if (fields?.[field]?.metadata?.hideTable) {
            return memo;
        } else if (savedOptions?.columns[field]) {
            memo[field] = savedOptions?.columns[field];
        } else {
            const defaultLabel = fields[field]?.metadata?.label ? translate("field." + fields[field].metadata.label) : translate("field." + field);

            memo[field] = {
                name: field,
                label: defaultLabel,
                frozen: !i,
                type: fields?.[field]?.type ?? 'text',
                show: false, // added columns are hidden by default
                showMobile: i < 4,
                isMerged: false,
                merged: [],
                minWidth: fields[field].metadata?.minWidth
            };
        }

        return memo;
    }, {});

    return savedOptions;
}

export const TicketTypeColor = (value) => {
    switch (value) {
        case 1:
            return "lightgreen"
        case 2:
            return "green"
        case 3:
            return "orange"
        case 4:
            return "red"
        default:
    }
}
export const returnTicketBugLevel = (value) => {
    switch (value) {
        case 1:
            return "text.Low"
        case 2:
            return "text.Medium"
        case 3:
            return "text.High"
        case 4:
            return "text.Highest"
        default:
    }
}
export const returnTicketType = (value) => {
    switch (value) {
        case 1:
            return "text.Bug"
        case 2:
            return "text.Suggestion"
        default:
    }
}

export function returnTicketingStatus(value) {
    switch (value) {
        case 1:
            return "text.Pending"
        case 2:
            return "text.Open"
        case 3:
            return "text.InProgress"
        case 4:
            return "text.Finished"
        default:
    }
}

export function errorResultToMessage(result) {
    if (result.status === 2) {
        return "You do not have the necessary permissions to perform this action. Please contact your system administrator if you need access.";
    }

    return result?.data;
}

export function JSONParseFix(data) {
    try {
        return JSON.parse(data);
    } catch (e) {
        return data;
    }
}

export function getSystemName() {
    switch (packageJson?.name ?? "") {
        case 'truck-main-frontend':
            return 'main'
        case 'truck-wo-frontend':
            return 'work-orders'
        case 'truck-rental-frontend':
            return 'rental'
        case 'truck-tracking-frontend':
            return 'tracking'
        default:
            return 'tracking'
    }
}