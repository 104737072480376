import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProp} from "../../../../../common/util/util-helpers";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import {hideGlobalModal} from "../../../../../data/actions/ui";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import {Loader} from "../../../../../common/components/loader";
import ResourceTable from "../../../../../common/components/resource-table";
import {Field} from "../../../../../data/services/fields";
import LocalStorage from '../../../../../util/localStorage'
import Resources from '../../../../../data/services/resources'

export default function StateMileageDialog({show, match, translate}) {
    const dispatch = useDispatch();
    const dialogResource = useSelector((state) => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const data = getProp(dialogResource.data, 'load/calculate/state.StateReportLines', []).sort((a, b) => a.Total - b.Total)

    function getFields() {
        return {
            StCntry: new Field('StCntry', "", [""], false),
            Loaded: new Field('Loaded', "", [""], false),
            Empty: new Field('Empty', "", [""], false),
            Free: new Field('Free', "", [""], false),
            Toll: new Field('Toll', "", [""], false),
            Tolls: new Field('Tolls', "", [""], false),
            Total: new Field('Total', "", [""], false),
        }
    }

    function fetchData() {
        dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: Resources.LoadMileage,
            query: getQuery()
        }));
    }

    function getQuery() {
        return {
            id: match.params.id,
            report: 2
        }
    }

    function handleHideModal() {
        dispatch(hideGlobalModal('StateMileageDialog'))
    }

    useEffect(() => {
        if (show) {
            fetchData();
        }

    }, [show]);

    return (
        <ModalDefault
            show={show}
            title={translate("page.heading.state_mileage")}
            widthClass={"max-w-[1400px]"}
            onClose={handleHideModal}
            closeButtonLabel={translate("btn.close")}
            limitHeight={true}
            translate={translate}
        >
            <div className="relative">
                {isLoading && (
                    <Loader/>
                )}

                <ResourceTable
                    data={data}
                    fields={getFields()}
                    translate={translate}
                    options={{
                        style: {floatingActions: true, horizontalLines: true, isTableLayoutFixed: true},
                        behaviour: {hasVirtualRows: true}
                    }}
                    tableID="StateMileageDialogTable"
                    isLoading={isLoading}
                />
            </div>
        </ModalDefault>
    )
}
