import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, resetResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    focusOnAddedPhone,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getPreFillFromQuery,
    getProp
} from '../../../common/util/util-helpers'
import {showGlobalModal} from '../../../data/actions/ui'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import AgentInfoTab from './agentInfoTab'
import {
    CREATE_PERM,
    DEFAULT_CONTACT_INFO_FIELDS,
    DEFAULT_PHONES_FIELDS,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import AgentPayrollTab from "./agentPayrollTab";
import AgentSettlementTab from "./agentSettlementTab";
import {getContactResource} from "../../../data/actions/contactResource";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import EmploymentTab from "../../../common/components/tabs/employment-tab";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import Modal from "../../../common/components/modal";
import VacationTab from "../../../common/components/tabs/vacation-tab";
import {getPhoneFieldsKeyValues} from "../../../common/components/misc/phone-fields";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {
    DEFAULT_EMERGENCY_CONTACT_FIELDS
} from "../../../common/components/contact/contact-fields/emergency-contact-fields";
import NavResponsive from "../../../common/components/nav-responsive";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import Card from "../../../common/components/card";
import {DEFAULT_COMPANY_ROLE_FIELDS} from "../../../common/components/contact/contact-fields/company-role-fields";

class AgentSingleView extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'AgentInfo',
                resource: Resources.AgentInfo,
                current: true,
                visible: checkPerm(Resources.AgentInfo, READ_PERM)
            },
            {
                name: 'AgentsPayroll',
                resource: Resources.AgentsPayroll,
                current: false,
                visible: (checkPerm(Resources.AgentsPayroll, READ_PERM) && !!this.getID())
            },
            {
                name: 'AgentsEmployments',
                resource: Resources.AgentsEmployments,
                current: false,
                visible: (checkPerm(Resources.AgentsEmployments, READ_PERM) && !!this.getID())
            },
            {
                name: 'AgentsSettlements',
                resource: Resources.AgentsSettlements,
                current: false,
                visible: (checkPerm(Resources.AgentsSettlements, READ_PERM) && !!this.getID())
            },
            {
                name: 'AgentsVacation',
                resource: Resources.AgentsVacation,
                current: false,
                visible: checkPerm(Resources.AgentsVacation, READ_PERM)
            },
            {
                name: 'AgentDocuments',
                resource: Resources.AgentDocuments,
                current: false,
                visible: (checkPerm(Resources.AgentDocuments, READ_PERM) && !!this.getID()) || (checkPerm(Resources.AgentDocuments, CREATE_PERM))
            },
        ]

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.AgentInfo,

            // Fields
            fields: this.getFields(),
            phonesFields: this.getPhones(),
            files: [],

            canSubmit: false,
            agentName: '',
            createNewData: {
                [Resources.AgentInfo]: {},
                [Resources.ContactImage]: null,
                [Resources.AgentsVacation]: [],
                [Resources.AgentDocuments]: []
            },

            createFromExistingContact: false,
            resourceTasksModalOpen: false,
            linkedContactData: {},
            contactInfoData: getProp(this.props.contactResource, 'data.list', []),
        }

        this.fileImageObject = null
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        !this.isCreate() && this.fetchData()
        !this.isCreate() && this.fetchTaskResourceCount()
        this.fetchContacts()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        const name = '- ' + getProp(this.props, 'resource.data.FirstName', '') + ' ' + getProp(this.props, 'resource.data.LastName', '')
        if (name !== '-  ' && this.state.agentName !== name) {
            this.setState({
                agentName: '- ' +
                    getProp(this.props, 'resource.data.FirstName', '') +
                    ' ' +
                    getProp(this.props, 'resource.data.LastName', '')
            })
        }

        if (this.props.contactResource !== prevProps.contactResource) {
            this.setState({contactInfoData: getProp(this.props.contactResource, 'data.list', [])})
        }

        if (!this.isCreate() && prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === Resources.AgentInfo) this.fetchData()
        if ((prevProps.resource != this.props.resource) && this.getID()) {
            this.setState({
                phonesFields: this.getPhones(),
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
            if (this.state.selectedTab === Resources.AgentInfo) {
                this.setState({
                    agentName: getProp(this.props.resource, 'data.FirstName', '') + ' ' + getProp(this.props.resource, 'data.LastName', ''),
                    ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
                })
            }
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AgentInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchContacts = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactsQuick,
            query: {}
        }))
    }

    onSubmit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            phonesFields: this.state.phonesFields.map((fields) => FieldsManager.validateFields(fields))
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && this.state.phonesFields.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)), true)) {
                let offices = Array.isArray(this.state.fields.OfficeIDs.value) ? this.state.fields.OfficeIDs.value.map(it => it.value) : []
                const phones = getPhoneFieldsKeyValues(this.state.phonesFields)
                const params = Object.assign({},
                    FieldsManager.getFieldKeyValues(this.state.fields),
                    {
                        Phones: phones,
                        Vacations: this.state.createNewData[Resources.AgentsVacation].map(it => {
                            delete it.VacationStatus
                            return it
                        }),
                        Active: true,
                        OfficeIDs: offices ? offices.join(',') : '',
                    }
                )

                this.setState({canSubmit: false})

                if (this.getID()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, params, {
                            id: this.getID(),
                            OfficeIDs: offices ? offices.join(',') : '',
                        }),
                        query: {
                            id: this.getID()
                        },
                        errorMessage: true,
                        successMessage: `Agent ${this.state.fields.FirstName.value} ${this.state.fields.LastName.value} updated`,
                        resource: Resources.AgentInfo,
                        piggyResource: Resources.AgentInfo,
                        fileParams: {
                            id: getProp(this.props.resource, 'data.ContactID', 0)
                        },
                        file: [this.fileImageObject],
                        fileResource: Resources.ContactImage,
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.Agents,
                        showRedirectDialog: true,
                        errorMessage: true,
                        file: [this.fileImageObject],
                        fileResource: Resources.ContactImage,
                        document: this.state.createNewData[Resources.AgentDocuments],
                        documentResource: Resources.AgentDocuments
                    }))
                }
            }
        })
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.getID()) {
            if (this.state.canSubmit) {
                this.setState({
                    confirmModal: true,
                    reserveTab: resource
                })
            } else {
                this.tabChange(resource)
            }
            return false
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleToggleRedirectDialog = () => {
        // and reset state
        this.fileImageObject = null // reset image

        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.AgentID,
            redirectModalOpen: !this.state.redirectModalOpen,

            // Reset state
            fields: this.getFields(),
            phonesFields: this.getPhones(),
            src: null,
            croppedImageUrl: null,
            files_info: [],
            canSubmit: false
        })
    }

    handleSetLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields)

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        })
    }

    handleSetMailingLocations = (fields) => {
        let updatedFields = Object.assign({}, this.state.fields);

        updatedFields.MailingAddressName.value = fields.AddressName;
        updatedFields.MailingAddressName2.value = fields.AddressName2;
        updatedFields.MailingCityName.value = fields.CityName;
        updatedFields.MailingStateID.value = fields.StateID;
        updatedFields.MailingPostalCode.value = fields.PostalCode;
        //updatedFields.MailingListAddressAutocomplete.value = fields.query

        this.setState({
            fields_info: updatedFields,
            canSubmit: true
        })
    }

    canSwapTabsHandler = (canSubmit = false) => {
        this.setState({canSubmit: canSubmit})
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTasksModalOpen: !this.state.resourceTasksModalOpen
        })
    }

    handleAddPhoneField = () => {
        const newPhones = this.state.phonesFields

        newPhones.push(DEFAULT_PHONES_FIELDS())

        this.setState({
            phonesFields: newPhones
        }, () => focusOnAddedPhone())
    }

    handleRemovePhoneField = (i) => {
        let newPhones = this.state.phonesFields
        newPhones.splice(i, 1)
        this.setState({
            phonesFields: Array.from(newPhones),
            canSubmit: true
        })
    }

    handleItemInputChange = (i, name, value) => {
        let fields = this.state.phonesFields[i]
        const newItems = this.state.phonesFields
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({canSubmit: true, phonesFields: newItems})
    }

    handleInputChange = (name, value) => {
        let fieldsInfo = Object.assign({}, this.state.fields)
        let disableStateID = this.state.disableStateID
        let phonesFields = this.state.phonesFields
        let defaultCountryCode = this.state.defaultCountryCode

        if (name === 'Email' && this.state.emailExist) {
            this.setState({emailExist: false})
        } else if (name === 'Organization') {
            this.getOrganizationOptions('', value)
            fieldsInfo[name].value = value
        }

        if (name === 'Email') {
            let data = this.state.contactInfoData;
            let emailExist = data.filter((it) => it.Email === value)
            if (emailExist.length != 0 && getProp(this.props.resource, 'data.Email', null) != value) {
                fieldsInfo[name].errorMessage = [this.props.translate("text.contact_email_in_system")]
            } else {
                fieldsInfo[name].errorMessage = null
            }
        }

        if (name === 'OfficeID' && this.state.officesCache && this.state.officesCache[value.value] && phonesFields.length > 0) {
            defaultCountryCode = this.state.officesCache[value.value]
            phonesFields[0] = FieldsManager.updateField(phonesFields[0], 'AreaCode', defaultCountryCode)
        }
        if (name === 'AddressName' && !!this.state.sameAsFirstAddress) {
            this.setState({fields: FieldsManager.updateField(fieldsInfo, 'SecondAddress', value)})
        }
        if (name === 'CountryID' && value == 1) {
            disableStateID = true
        } else if (name === 'CountryID' && value != 1) {
            disableStateID = false
            fieldsInfo = FieldsManager.updateField(fieldsInfo, 'StateID', '')
        }

        if (name === 'ContactID') {
            this.setState({linkedContactData: value.metadata})
        }

        this.setState({
            phonesFields,
            defaultCountryCode,
            canSubmit: true,
            fields: FieldsManager.updateField(fieldsInfo, name, value),
            disableStateID: disableStateID
        })
    }

    handleToggleCreateFromExistingContact = () => {
        let fields = this.state.fields
        if (!this.state.createFromExistingContact) {
            fields.FirstName.validate = []
            fields.LastName.validate = []
            fields.Email.validate = []
            fields.ValidationType.validate = []
            fields.Roles.validate = []
            fields.ContactID.validate = ['empty']
            this.setState({createFromExistingContact: !this.state.createFromExistingContact, fields: fields})
        } else {
            fields.ContactID.validate = []
            fields.ContactID.value = '-1'
            fields.FirstName.validate = ['empty']
            fields.LastName.validate = ['empty']
            fields.Email.validate = ['empty']
            fields.ValidationType.validate = ['empty']
            fields.Roles.validate = ['empty']
            this.setState({createFromExistingContact: !this.state.createFromExistingContact, fields: fields})
        }
    }

    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(getProp(this.props.resource, 'data', {})),
            phonesFields: this.getPhones(),
            canSubmit: false
        })
    }

    handleCreateNewVacation = (key, item) => {
        const createNewData = this.state.createNewData
        createNewData[key] = item
        this.setState({
            createNewData: createNewData
        })
    }

    handleUploadDocument = (files) => {
        let createNewData = this.state.createNewData
        createNewData[Resources.AgentDocuments] = createNewData[Resources.AgentDocuments].concat(files)
        this.setState({createNewData: createNewData})
    }

    handleDeleteDocument = (item) => {
        let createNewData = this.state.createNewData
        createNewData[Resources.AgentDocuments] = createNewData[Resources.AgentDocuments].filter(it => it.preview !== item.preview)
        this.setState({createNewData: createNewData})
    }
    /** Fields/Data definitions
     ================================================================= */
    getPhones = () => {
        const phones = getProp(this.props.resource, 'data.Phones', [])
        if (this.getID()) {
            return phones?.map(item => {
                return this.getDefaultPhoneFields(item)
            })
        } else {
            return [this.getDefaultPhoneFields()]
        }
    }

    getDefaultPhoneFields = (item = {}) => {
        return fillFieldsFromData(DEFAULT_PHONES_FIELDS(), item)
    }

    onBlurFetchContacts = () => {
        return new Promise((resolve) => {
            this.fetchContacts();
            setTimeout(() => {
                resolve();
            }, 200);
        });
    }

    getFields = (item = {}) => {
        const name = getPreFillFromQuery()

        let fields = Object.assign({},
            DEFAULT_CONTACT_INFO_FIELDS(),
            DEFAULT_EMERGENCY_CONTACT_FIELDS(),
            DEFAULT_COMPANY_ROLE_FIELDS(),
            {
                ContactID: new Field('ContactID', -1, [''], false, 'select-search'),

                // AGENT FIELDS
                OfficeIDs: new Field('OfficeIDs', this.isCreate() ? [getDefaultUserOfficeValues()] : '', [''], false, 'multi-select-search'),

                CountryID: new Field('CountryID', 1, [''], false, 'select'),
                AddressName: new Field('AddressName', '', ['']),
                AddressName2: new Field('AddressName2', '', ['']),
                CityName: new Field('CityName', '', ['']),
                StateID: new Field('StateID', '', [''], false, 'select'),
                SocialSecurityNumber: new Field('SocialSecurityNumber', '', ['']),
                OfficeID: new Field('OfficeID', !this.getID() ? getDefaultUserOfficeValues() : '', [''], false, 'select-search'),
                EmployeeIDNumber: new Field('EmployeeIDNumber', '', ['']),
                PostalCode: new Field('PostalCode', '', ['']),
                // Mailing Address Info
                MailingSameAsFirstAddress: new Field('MailingSameAsFirstAddress', 1, [], false, 'checkbox', {
                    addContainerClass: "col-span-3"
                }),
                MailingListAddressAutocomplete: new Field('MailingListAddressAutocomplete', '', [], false, 'google-locations', {
                    setLocations: this.handleSetMailingLocations,
                    addContainerClass: "col-span-full"
                }),
                MailingAddressName: new Field('MailingAddressName', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingAddressName2: new Field('MailingAddressName2', '', [''], false, 'text', {
                    addContainerClass: "col-span-3"
                }),
                MailingCityName: new Field('MailingCityName', '', ['']),
                MailingStateID: new Field('MailingStateID', '', [''], false, 'select'),
                MailingPostalCode: new Field('MailingPostalCode', '', ['']),
            })

        fields['FirstName'].value = name ?? ''
        fields['IsSystemUser'].type = 'hidden'
        fields['Roles'].validate = ['']
        fields['Roles'].type = 'hidden'
        fields['ValidationType'].validate = ['']
        fields['ValidationType'].type = 'hidden'
        fields['Title'].metadata.label = 'JobTitle'

        fields.Email = new Field('Email', '', ['empty', 'email'], false, 'text', {
            addContainerClass: "md:col-span-full",
            label: "BusinessEmail",
            htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                <CopyToClipboardButton
                    clipboardText={it.value}
                    addClass="relative -top-2"
                    translate={this.props.translate}
                />
            </div>
        }, {
            onBlur: (e) => {
                e.persist();
                if (e.target.value) {
                    if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(e.target.value)) {
                        this.onBlurFetchContacts().then(() => {
                            this.handleInputChange('Email', e.target?.value);
                        });
                    }
                }
            }
        })

        fields.PrivateEmail = new Field('PrivateEmail', '', [], false, "email", {
            addContainerClass: "md:col-span-6",
            htmlAfterField: (it) => <div className="h-5 relative top-2 flex">
                <CopyToClipboardButton
                    clipboardText={it.value}
                    addClass="relative -top-2"
                    translate={this.props.translate}
                />
            </div>
        })


        if (Object.keys(item).length > 0) {
            fields = fillFieldsFromData(fields, item)
            fields['OfficeIDs'].value = getProp(item, 'Offices', []).map(it => ({value: it.OfficeID, label: it.Office}))
            fields['Groups'].value = getProp(item, 'Groups', []).map(it => ({
                value: it.ContactGroupID,
                label: it.ContactGroupName
            }))
        }

        return fields
    }
    /** Helpers
     ================================================================= */
    isCreate = () => {
        return !this.getID()
    }

    getID = () => {
        return this.props.match.params.id
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const hasFooter = !this.getID() ? true : (this.state.selectedTab === Resources.AgentInfo || this.state.selectedTab === Resources.AgentsSettlements)
        const fullName = getProp(this.props.resource, 'data.FirstName', '') + ' ' + getProp(this.props.resource, 'data.LastName', '')

        return (
            <Layout
                hasFooter={hasFooter}
                {...this.props}
                isDirty={(this.getID() && this.state.canSubmit)}
                customPageTitle={this.getID() ? fullName : 'Agent'}
            >
                <Page>
                    <div className="mb-2 flex justify-between items-center">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center">
                                <GoBackButton
                                    translate={this.props.translate}
                                    history={this.props.history}
                                    path={'/agents'}
                                />
                                <h1 className={'mr-5 text-3xl ml-2'}>
                                    {translate('text.agent')}
                                    <span
                                        className="text-tm-gray-500"> {this.getID() ? this.state.agentName : 'Create new'}
                                        {this.state.ArchivedDate && (
                                            <Tippy content={<span>Archived</span>}>
                                                <i className="iconsminds-delete-file"/>
                                            </Tippy>
                                        )}
                                                </span>
                                </h1>
                            </div>
                        </div>
                        <div>
                            {checkPerm(Resources.Tasks, CREATE_PERM) && !this.isCreate() && (
                                <div className="btn btn-outline mr-3"
                                     onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_agent_task')}
                                </div>
                            )}
                            {checkPerm(Resources.Tasks, READ_PERM) && !this.isCreate() && (
                                <div className="btn btn-outline"
                                     onClick={() => this.handleToggleResourceTasks()}>{translate('btn.show_tasks')}
                                    {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                    {!this.props.taskResource.isCountLoading && `(${TaskCount})`}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.AgentInfo && (
                        <AgentInfoTab
                            onTabChange={this.handleTabChange}
                            canSwapTabsHandler={this.canSwapTabsHandler}
                            setLocations={this.handleSetLocations}
                            isCreate={this.isCreate()}
                            canSubmit={this.state.canSubmit}
                            fields={this.state.fields}
                            linkedContactInfo={this.state.linkedContactData}
                            phonesFields={this.state.phonesFields}
                            handleInputChange={this.handleInputChange}
                            handleItemInputChange={this.handleItemInputChange}
                            removePhoneField={this.handleRemovePhoneField}
                            addPhoneField={this.handleAddPhoneField}
                            cancel={this.handleFormCancel}
                            onSubmit={this.onSubmit}
                            ContactID={getProp(this.props.resource, 'data.ContactID', null)}
                            setImage={(file) => {
                                this.fileImageObject = file
                                this.setState({canSubmit: true})
                            }}
                            createFromExistingContact={this.state.createFromExistingContact}
                            handleToggleCreateFromExistingContact={this.handleToggleCreateFromExistingContact}
                            files={this.state.files}
                            mainID={this.getID()}
                            fileImageObject={this.fileImageObject}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentsPayroll && (
                        <AgentSettlementTab
                            agentID={this.getID()}
                            translate={translate}
                            dispatch={this.props.dispatch}
                            resource={this.props.resource}
                            match={this.props.match}
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentsEmployments && (
                        <EmploymentTab
                            resourceName={Resources.AgentsEmployments}
                            id={this.getID()}
                            primaryKey={'AgentEmploymentID'}
                            translate={translate}
                            dispatch={this.props.dispatch}
                            resource={this.props.resource}
                            pageTitle={translate('page.heading.AgentsEmployment')}
                            hideEmploymentType
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentsSettlements && (
                        <AgentPayrollTab
                            translate={translate}
                            dispatch={this.props.dispatch}
                            id={this.getID()}
                            resource={this.props.resource}
                            secondResource={this.props.secondResource}
                            thirdResource={this.props.thirdResource}
                            setIsStateDirty={(isDirty) => {
                                this.setState({canSubmit: isDirty})
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentsVacation && (
                        <VacationTab
                            id={this.getID()}
                            primaryKey={'AgentVacationID'}
                            resourceName={Resources.AgentsVacation}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            canSwapTabsHandler={this.canSwapTabsHandler}
                            isCreate={this.isCreate()}
                            onCreateNewData={this.handleCreateNewVacation}
                            dialogTitle={translate("text.vacation")}
                            resource={this.isCreate() ? {
                                data: {
                                    list: this.state.createNewData[Resources.AgentsVacation]
                                }
                            } : this.props.resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentDocuments && this.getID() && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'Agent Documents'}

                            sortBy={'Description'}
                            primaryKey={'AgentDocumentID'}

                            download={this.props.download}
                            resourceName={Resources.AgentDocuments}

                            documentUpdateFields={checkPerm(Resources.AgentDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsPersonnelDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}

                            documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsPersonnelDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.AgentDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.AgentDocuments, DELETE_PERM)}
                        />
                    )}

                    {this.state.selectedTab === Resources.AgentDocuments && !this.getID() && (
                        <Card>
                            <DocumentsCreateTab
                                fields={this.state.createNewData[Resources.AgentDocuments]}
                                translate={this.props.translate}

                                dialogTitle={'Agent Documents'}

                                documentTypes={getDocumentTypesFor("IsPersonnelDocument")}
                                handleUploadDocument={this.handleUploadDocument}
                                handleDeleteDocument={this.handleDeleteDocument}
                            />
                        </Card>
                    )}

                    <RedirectDialog
                        show={this.state.redirectModalOpen}
                        title={translate('card_header.agent_created')}
                        onClose={() => {
                            this.props.dispatch(resetResource())
                            this.setState({
                                createdResourceID: null,
                                redirectModalOpen: false
                            })
                        }}

                        createdLabel={translate('btn.agent')}
                        createdLink={`/agents/info/${this.state.createdResourceID}`}

                        newLabel={translate('btn.create_new')}

                        listLabel={translate('btn.list')}
                        listLink="/agents"

                        dashboardLabel={translate('btn.dashboard')}

                        {...this.props}
                    >
                        <p>{translate('message.agent_created')}</p>
                    </RedirectDialog>

                    {!this.getID() && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={checkPerm(Resources.Agents, CREATE_PERM) && this.onSubmit}
                        />
                    )}
                </Page>

                <ModalConfirm
                    type="default"
                    title={translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        canSubmit: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <Modal
                    show={!!this.state.resourceTasksModalOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.agent_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </Modal>
            </Layout>
        )
    }
}

export default connect(state => state)(AgentSingleView)
