import React, {useEffect, useRef, useState} from 'react'
import {classNames, getProp} from '../../../common/util/util-helpers'
import Resources from "../../../data/services/resources";
import PublicLoadInfoTab from "./infoTab";
import {generateStops, mergeStops} from "../../dispatch/load-view/load-utils";
import LoadInfoTracks from "../../dispatch/load-view/load-sections/load-info-tracks";
import {useDispatch, useSelector} from "react-redux";
import {getPublicResource} from "../../../data/actions/publicResource";
import {customerJoinedChat} from "../../../package/realtime/actions/realtime";
import ChatBubbleOvalLeftEllipsisIcon from "@heroicons/react/24/outline/ChatBubbleOvalLeftEllipsisIcon";
import LocalStorage from "../../../util/localStorage";
import LoadExternalChatTab from "../../dispatch/load-view/load-sidebar/load-customer-chat-tab";
import {Field, FieldsManager} from "../../../data/services/fields";
import Env from '../../../util/env'
import { download } from '../../../data/actions/download'
import { EyeIcon } from '@heroicons/react/24/outline'
import PublicStatusUpdatesTab from './public-status-updates-tab';
import ModalDefault from "../../../common/components/modal/modal-default";
import {LoaderLarge} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import NavResponsive from "../../../common/components/nav-responsive";
import FileViewer from "../../../common/components/file-viewer/components";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";

export default function PublicLoad({translate, match}) {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.publicResource);
    const loadPresence = useSelector((state) => state.loadPresence);
    const isLoading = resource.isLoading;

    const isChatEnabled = getProp(resource, 'data.load/info.AllowChat', 0) === 1;

    const mapRef = useRef(null);
    const groupRef = useRef(null);

    const [fields, setFields] = useState(getFields());
    const [customerChatName, setCustomerChatName] = useState(LocalStorage.get("external_load_chat_name", ""));
    const [queryFields, setQueryFields] = useState(getQueryFields());
    const [data, setData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const LoadID = getProp(data, 'load/info.LoadID', '');

    const [tabs, setTabs] = useState(getTabs());

    const multistopsData = getProp(data, 'load/multistops', []);

    let stops = generateStops(multistopsData, translate);

    let mergedStops = [];

    if (stops.length) {
        mergedStops = mergeStops(
            multistopsData,
            []
        );
    }

    const fetchData = () => {
        dispatch(getPublicResource({
            resource: Resources.LoadPublic,
            query: {
                token: match.params.token
            }
        }));
    }

    const handleQueryChange = (name, value) => {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value)
        setQueryFields(queryFieldsUpdate);
    }

    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleInputChange = (name, value) => {
        let fieldsUpdate = Object.assign({}, fields)
        setFields(FieldsManager.updateField(fieldsUpdate, name, value));
    }

    const currentTab = tabs.find(tab => tab.current);

    function getFields() {
        return {
            Name: new Field("Name", '', ['empty'], ''),
            // PhoneNumber: new Field("PhoneNumber", '', [''], ''),
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setTabs(getTabs(data));
    }, [data]);

    useEffect(() => {
        if (currentTab.name === 'map') {
            const PickupLatitude = Number(getProp(data, 'load/info', 0).PickupLatitude);
            const PickupLongitude = Number(getProp(data, 'load/info', 0).PickupLongitude);
            const DestinationLatitude = Number(getProp(data, 'load/info', []).DestinationLatitude);
            const DestinationLongitude = Number(getProp(data, 'load/info', []).DestinationLongitude);

            handleFitBounds(PickupLatitude, PickupLongitude, DestinationLatitude, DestinationLongitude, mapRef, groupRef);
        }
    }, [currentTab]);

    useEffect(() => {
        if (resource.data) {
            setData(resource.data);
        }
    }, [resource])

    useEffect(() => {
        if (isChatEnabled && customerChatName) {
            dispatch(customerJoinedChat({
                ExternalAccessToken: match.params.token,
                Name: customerChatName
            }))
        }
    }, [isChatEnabled, customerChatName]);

    const handleToggleViewModal = (selectedItem = null) => {
        setSelectedItem(selectedItem)
    }

    const downloadDocument = () => {
        dispatch(download({
            user: {},
            resource: Resources.LoadCustomerPublicDocuments,
            query: {
                name: selectedItem.OriginalFilename,
                uuid: selectedItem?.uuid,
                id: LoadID
            }
        }))
    }

    if (!resource.isLoading && resource.data === null) {
        return <p className="text-center p-8 text-xl">The provided link does not have the view option enabled.</p>
    }

    return (
        <div className="p-4 bg-tm-gray-100 min-h-screen">
            <div className={
                classNames(
                    "max-w-8xl mx-auto ml-15 mr-15",
                    isChatEnabled ? "pr-96" : undefined
                )
            }>
                <div className="mb-2">
                    <h1
                        className="mr-5 text-2xl"
                    >
                        {translate('text.Load')} - {getProp(data, 'load/info.LoadNumber', '')}
                    </h1>
                </div>

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {isLoading && (
                    <Card addClass="h-[calc(100vh-12rem)] mt-8">
                        <LoaderLarge/>
                    </Card>
                )}

                {currentTab.name === 'info' && !isLoading && (
                    <PublicLoadInfoTab
                        token={match.params.token}
                        infoData={data[`load/info`]}
                        accessorialsData={data[`load/accessorials`]}
                        commoditiesData={data[`load/commodities`]}
                        stops={stops}
                        mergedStops={mergedStops}
                        translate={translate}
                    />
                )}

                {currentTab.name === 'map' && (
                    <LoadInfoTracks
                        data={data}
                        tracksKey={"load/tracks"}
                        mapRef={mapRef}
                        groupRef={groupRef}
                        onQueryChange={handleQueryChange}
                        queryFields={queryFields}
                        handleFitBounds={handleFitBounds}
                        translate={translate}
                        isPublic={true}
                    />
                )}

                {currentTab.name === 'statusUpdates' && !isLoading && (
                    <PublicStatusUpdatesTab
                        data={getProp(data, 'load/events.list', [])}
                        isLoading={isLoading}
                        translate={translate}
                    />
                )}

                {currentTab.name === 'documents' && !isLoading && (
                    <div className="max-w-xl mx-auto pt-8">
                        <Card>
                            <ul className="pt-8 mx-8" role="list">
                                {getProp(data, 'load/documents.list', []).map((document) => (
                                    <li key={document.DocumentID}>
                                        <div className="flex items-center pb-8 relative">
                                            <div className="flex-shrink-0">
                                                {document.DocumentType}
                                                <button className="btn btn-header ml-auto ml-5" onClick={() => handleToggleViewModal(document)}>
                                                    <EyeIcon className="w-5 h-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Card>
                    </div>
                )}

                <ModalDefault
                    show={!!selectedItem}
                    widthClass={'max-w-7xl'}
                    title={"Document preview"}
                    limitHeight={true}
                    close={handleToggleViewModal}
                    closeButtonLabel={translate('Close')}
                    onClose={handleToggleViewModal}
                    buttonLabel={translate('btn.download')}
                    onButtonClick={downloadDocument}
                >
                    <FileViewer
                        fileType={selectedItem?.OriginalFilename?.split('.').pop()}
                        filePath={Env.getApiUrl('api/' + Resources.LoadCustomerPublicDocuments, Object.assign({}, {
                            DocumentID: selectedItem?.DocumentID,
                            uuid: selectedItem?.uuid,
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </ModalDefault>

                {isChatEnabled && (
                    <div className="w-96 bg-popup h-screen fixed top-0 right-0 z-50">
                        {!!customerChatName && (
                            <LoadExternalChatTab
                                customerChatName={customerChatName}
                                onClearNameClick={() => setCustomerChatName("")}
                                chatHeightClass={"h-[calc(100vh-8rem)]"}
                                resource={resource}
                                loadPresence={loadPresence}
                                dispatch={dispatch}
                                isExternalPage={true}
                                token={match.params.token}
                            />
                        )}

                        {!customerChatName && (
                            <div className="h-full flex items-center justify-center">
                                <div>
                                    <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                        Enter your name and start conversation
                                    </h2>

                                <ChatBubbleOvalLeftEllipsisIcon className="w-10 h-10 text-primary mx-auto"/>
                                    <h2 className="mt-10 text-center text-xl font-semibold leading-9 tracking-tight text-gray-900">
                                        Chat with us. We’re online!
                                    </h2>

                                    <div className="max-w-xs mt-10 mx-auto">
                                        <form
                                            className="space-y-6"
                                            onSubmit={() => {
                                                if (fields.Name.value) {
                                                    setCustomerChatName(fields.Name.value);
                                                    LocalStorage.set('external_load_chat_name', fields.Name.value);
                                                }
                                            }}
                                        >
                                            <FieldsToHtml
                                                fieldsState={fields}
                                                translate={translate}
                                                onInputChange={handleInputChange}
                                            />

                                            <button
                                                disabled={!fields.Name.value}
                                                className="btn btn-primary w-full justify-center"
                                                type="submit"
                                                onClick={() => {
                                                    setCustomerChatName(fields.Name.value);
                                                    LocalStorage.set('external_load_chat_name', fields.Name.value);
                                                }}
                                            >
                                                Start conversation
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div>
    )
}

const getTabs = (data) => {
    const areTracksAllowed = !!getProp(data, 'load/info.AllowTracks', false);
    const hasEvents = !!getProp(data, 'load/events.list', []).length;
    const hasStops = !!getProp(data, 'load/multistops', []).length;
    const hasDocuments = !!getProp(data, 'load/documents.list', []).length;

    return [
        {
            name: 'info',
            resource: Resources.LoadInfo,
            current: true,
            visible: true
        },
        {
            name: 'map',
            resource: "map",
            current: false,
            visible: areTracksAllowed && hasStops
        },
        {
            name: 'statusUpdates',
            resource: "statusUpdates",
            current: false,
            visible: hasEvents
        },
        {
            name: 'documents',
            resource: "documents",
            current: false,
            visible: hasDocuments
        }
    ]
}

const handleFitBounds = (startLatitude, startLongitude, endLatitude, endLongitude, mapRef, groupRef) => {
    if (!isNaN(startLatitude) && !isNaN(startLongitude) && !isNaN(endLatitude) && !isNaN(endLongitude) && mapRef.current) {
        const corner1 = L.latLng(startLatitude, startLongitude)
        const corner2 = L.latLng(endLatitude, endLongitude)
        const bounds = L.latLngBounds(corner1, corner2)
        const map = mapRef.current.leafletElement
        map.fitBounds(bounds)
    } else {
        const map = mapRef?.current?.leafletElement
        const group = groupRef?.current?.leafletElement
        if (map && group) {
            map.fitBounds(group.getBounds())
        }
    }
}

const getQueryFields = () => {
    return {
        offset: new Field('offset', 0, ['']),
        limit: new Field('limit', 10, [''], false, 'select', {labelType: "float", hideLabel: true})
    }
}
