import React, {useEffect, useRef, useState} from "react";
import {PencilIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../../util/util-helpers";
import {numberWithCommasToBack} from "../../../../util/util-formaters";

export default function FieldCellMoney({classFocused, classNotFocused, value = "", name, onBlur}) {
    const decimal = value.toString().split(".")[1] ?? "";

    const inputRef = useRef();
    const prevValue = useRef(value.toString() ?? "");           // Initial and useEffect
    const prevDecimalValue = useRef(decimal);        // Initial and useEffect
    const prevSign = useRef(Number(value) < 0 ? "-" : "+")  // Initial and useEffect

    const [isFocused, setIsFocused] = useState(false);
    const [innerValue, setInnerValue] = useState(value);        // Initial and useEffect
    const [isNegative, setIsNegative] = useState(Number(value) < 0); // Initial and useEffect

    const inputClass = classNames(
        isFocused
            ? classFocused ?? "form-control text-xs text-right pl-2 pr-4"
            : classNotFocused ?? "form-control text-xs text-tm-gray-900 pl-2 pr-8 flex  items-center border-transparent bg-inherit shadow-none hover:bg-tm-gray-100 text-right"
    )

    function handleInputChange(e) {
        const isNegativeCharacterDeleted = numberWithCommasToBack(e.target.value) === -numberWithCommasToBack(innerValue);
        updateValue(
            e.target.value,
            isNegativeCharacterDeleted ? false : isNegative
        );

        if (!!e.target.value && isNegativeCharacterDeleted) {
            setIsNegative(false);
            setTimeout(function () {
                inputRef.current.setSelectionRange(0, 0);
            }, 1);
        }
    }

    function updateValue(originalValue, negative) {
        const position = inputRef?.current?.selectionEnd;
        let adjustPosition = position;

        let val = originalValue.replace(/[^\d.]/g, '');

        let firstDotIndex = val.indexOf('.');

        if (firstDotIndex > -1) {
            val = val.replace(/\./g, (match, index) => index === firstDotIndex ? match : '');
        }

        const splitValue = val.split(".");

        splitValue[0] = Number(splitValue[0]).toLocaleString('en-US');
        splitValue[1] = splitValue[1] ? splitValue[1].substring(0, 2) : "";

        if (splitValue[0] === "0") {
            splitValue[0] = "";
        }

        val = splitValue.filter(it => !!it).join(".")

        if (firstDotIndex > -1 && !splitValue[1].length) {
            val = val + "."
        }

        if (!!prevDecimalValue.current.length && !splitValue[1].length) {
            val = val.replace(/\./g, '')
        }

        prevDecimalValue.current = splitValue[1];

        if ((val.match(/,/g) || []).length > (prevValue.current.match(/,/g) || []).length) {
            adjustPosition = position + 1;
        }

        if (val.length !== 1 && (val.match(/,/g) || []).length < (prevValue.current.match(/,/g) || []).length) {
            adjustPosition = position - 1;
        }

        adjustPosition = adjustPosition < 0 ? 0 : adjustPosition;


        if (isNaN(Number(originalValue.replace(/[.,-]/g, '')))) {
            adjustPosition = position - 1;
        } else {
            if (!!prevValue.current && !!val && prevSign.current === "-" && !negative) {
                adjustPosition = adjustPosition - 1
            }

            if (!!prevValue.current && !!val && prevSign.current === "+" && !!negative) {
                adjustPosition = adjustPosition + 1
            }

            prevValue.current = val;

            // Exceptions
            if (!val) {
                val = "0";
                adjustPosition = adjustPosition + 1;
            }

            if (val && originalValue.length === 1 && negative) {
                adjustPosition = adjustPosition + 1;
            }

            setInnerValue(
                negative ? "-" + val : val
            );

            if (!originalValue) {
                setIsNegative(false);
            }
        }

        setTimeout(function () {
            prevSign.current = negative ? "-" : "+";
            inputRef.current.setSelectionRange(adjustPosition, adjustPosition);
        }, 0);
    }

    function handleFocus() {
        setIsFocused(true);
        updateValue(innerValue, isNegative);
        setTimeout(function () {
            inputRef.current.setSelectionRange(0, inputRef.current.value.length)
        }, 0);
    }

    function handleKeyDown(e) {
        if (e.key === "Backspace" && Math.abs(e.target.value).toString().length === 1) {
            updateValue("", false);
        }

        if (e.key === "-") {
            e.preventDefault();
            setIsNegative(!isNegative);
            updateValue(e.target.value, !isNegative);
        }

        if (e.key === "+") {
            e.preventDefault();
            setIsNegative(false);
            updateValue(e.target.value, false);
        }

        if (e.key === "Enter") {
            if (isFocused) {
                handleBlur();
            } else {
                setIsFocused(true);
            }
        }
    }

    function handleClick() {
        if (!isFocused) {
            setIsFocused(true);
        }
    }

    function handleBlur() {
        setIsFocused(false);
        if (onBlur) {
            let val = numberWithCommasToBack(innerValue);
            val = val === "-0" ? 0 : val;
            onBlur(val);
        }
    }

    useEffect(() => {
        prevValue.current = value.toString();
        prevDecimalValue.current = decimal.toString();
        prevSign.current = Number(value) < 0 ? "-" : "+";

        setInnerValue(value.toString());
        setIsNegative(Number(value) < 0);
    }, [value]);

    return (
        <label
            className="bg-inherit block w-full relative" onClick={(e) => {
            e.stopPropagation();
        }}
        >
            <input
                className={inputClass}
                ref={inputRef}
                type="text"
                name={name ?? ""}
                value={isFocused ? innerValue : numberWithCommasToBack(innerValue ?? 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                onBlur={handleBlur}
            />

            {!isFocused && (
                <PencilIcon className="w-5 h-5 text-tm-gray-400 absolute right-2 top-1/2 -translate-y-1/2"/>
            )}
        </label>
    );
}
