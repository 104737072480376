import React, {Component} from 'react'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getProp} from '../../../common/util/util-helpers'
import {cloneDeep} from "../../../common/util/util-vanilla";
import {
    createContactResource,
    deleteContactResource,
    getContactResource,
    updateContactResource
} from "../../../data/actions/contactResource";
import {DEFAULT_CRUD_STATE} from "../../../util/util-constants";
import ModalDefault from "../../../common/components/modal/modal-default";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";

export default class AddWatchersDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,

            // Fields
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            notificationSettingsFields: this.getNotificationSettingsFields(),

            // Selected watchers
            selectedWatchersToAdd: {},
            selectedWatchersToRemove: {},

            // Selected watchers - Review dialog
            reviewContacts: {},
            selectedReviewContacts: {},

            // Modals
            isAllContactsModalOpen: false,
            isReviewContactsForDeletionDialogOpen: false,
            isNotificationSettingsModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.isNotificationSettingsModalOpen && this.state.isNotificationSettingsModalOpen) {
            this.setState({
                notificationSettingsFields: this.getNotificationSettingsFields(this.state.selectedItem),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadWatchers,
            query: this.getQuery()
        }))
    }

    handleAddWatchers = () => {
        const Contacts = Object.keys(this.state.selectedWatchersToAdd).map(it => {
            return Object.assign({}, {
                ContactID: it,
                LoadWatcherTypeID: 1,
                ReceiveCustomerMessages: 0,
                ReceiveCarrierMessages: 0,
                ReceiveStatusUpdates: 0,
                ReceiveETAUpdates: 0
            })
        })

        this.props.dispatch(createContactResource({
            user: LocalStorage.get('user'),
            params: {
                Contacts: Contacts,
                LoadID: this.props.LoadID,
            },
            query: this.getQuery(),
            resource: Resources.LoadWatchers,
            piggyResource: Resources.LoadWatchers,
            secondPiggyResource: Resources.LoadWatchers,

            errorMessage: true, successMessage: this.props.translate('text.load_watchers_updated')
        }))
        this.handleToggleAllContactsModal()
    }

    handleRemoveWatchers = () => {
        const item = this.state.selectedItem ?? null

        this.props.dispatch(deleteContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadWatchers,
            query: Object.assign({}, this.getQuery(), {
                LoadID: this.props.LoadID,
                ContactIDs: item ? [item.ContactID] : Object.keys(this.state.selectedReviewContacts)
            }),
            piggyResource: Resources.LoadWatchers,
            secondPiggyResource: Resources.LoadWatchers,
            piggyQuery: this.getQuery(),
            secondPiggyQuery: this.getQuery(),

            errorMessage: true, successMessage: this.props.translate('message.watcher_deleted'),
        }))
        this.handleCloseReviewContactsForDeletionDialog()
    }

    handleEditNotificationSettings = () => {
        this.props.dispatch(updateContactResource({
            user: LocalStorage.get('user'),
            params: {
                LoadID: this.props.LoadID,
                ContactID: this.state.selectedItem.ContactID,
                ReceiveStatusUpdates: this.state.notificationSettingsFields.ReceiveStatusUpdates.value ? 1 : 0,
                ReceiveCustomerMessages: this.state.notificationSettingsFields.ReceiveCustomerMessages.value ? 1 : 0,
                ReceiveCarrierMessages: this.state.notificationSettingsFields.ReceiveCarrierMessages.value ? 1 : 0,
                ReceiveETAUpdates: this.state.notificationSettingsFields.ReceiveETAUpdates.value ? 1 : 0
            },
            resource: Resources.LoadWatchers,
            piggyResource: Resources.LoadWatchers,
            piggySecondResource: Resources.LoadWatchers,
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('message.notification_settings_updated')
        }))
        this.handleToggleNotificationSettingsModal()
    }

    /** Field Definitions
     ================================================================= */
    getFields = () => {
        return {
            ContactGroupID: new Field('ContactGroupID', ''),
            LoadWatcherTypeID: new Field('LoadWatcherTypeID', '1'),
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
    }

    getNotificationSettingsFields = (item) => {
        let fieldTemplates = {
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    /** Query Definitions
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {}, {
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.ContactGroupName,
                    value: it.ContactGroupID,
                    metadata: it
                })
            }),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true, labelType: "float"})
        }
    }

    getQuery = () => {
        return {
            id: this.props.LoadID,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    /** UI Events
     ================================================================= */
    handleSelectContact = (row, id) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const selectedWatchersToAdd = cloneDeep(this.state.selectedWatchersToAdd);
        const selectedWatchersToRemove = cloneDeep(this.state.selectedWatchersToRemove);

        if (isAddContacts) {
            if (selectedWatchersToAdd[id]) {
                delete selectedWatchersToAdd[id]
            } else {
                selectedWatchersToAdd[id] = row
            }
        } else {
            if (selectedWatchersToRemove[id]) {
                delete selectedWatchersToRemove[id]
            } else {
                selectedWatchersToRemove[id] = row
            }
        }

        this.setState({
            selectedWatchersToAdd,
            selectedWatchersToRemove
        })
    }

    handleSelectAllContacts = (areAllSelected) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const prop = isAddContacts ?
            getProp(this.props.dialogResource, 'data.list', [])
            :
            getProp(this.props.contactResource, 'data.load/watchers.list', [])

        const selectedWatchersToAdd = cloneDeep(this.state.selectedWatchersToAdd);
        const selectedWatchersToRemove = cloneDeep(this.state.selectedWatchersToRemove);

        prop.map(item => {
            if (!areAllSelected) {
                isAddContacts ?
                    selectedWatchersToAdd[item.ContactID] = item
                    :
                    selectedWatchersToRemove[item.ContactID] = item;
            } else {
                isAddContacts ?
                    delete selectedWatchersToAdd[item.ContactID]
                    :
                    delete selectedWatchersToRemove[item.ContactID]
            }
        });

        this.setState({
            selectedWatchersToAdd,
            selectedWatchersToRemove
        });
    }

    handleSelectReviewContact = (row, id) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        if (selectedReviewContacts[id]) {
            delete selectedReviewContacts[id];
        } else {
            selectedReviewContacts[id] = row;
        }

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleSelectAllReviewContacts = (areAllSelected) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        getProp(this.props.contactResource, 'data.load/watchers.list', []).map(item => {
            if (!areAllSelected) {
                selectedReviewContacts[item.ContactID] = item;
            } else {
                delete selectedReviewContacts[item.ContactID];
            }
        });

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields
        let notificationFields = this.state.notificationSettingsFields

        this.setState({
            fields: FieldsManager.updateField(fields, name, value),
            notificationSettingsFields: FieldsManager.updateField(notificationFields, name, value)
        })
    }

    handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1,
        }, () => {
            this.fetchData();
        })
    }

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData())
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleToggleAllContactsModal = () => {
        this.setState({
            isAllContactsModalOpen: !this.state.isAllContactsModalOpen,
            selectedWatchersToAdd: {},
        })
    }

    handleToggleNotificationSettingsModal = (item = null) => {
        this.setState({
            selectedItem: item,
        }, () => this.setState({
            isNotificationSettingsModalOpen: !this.state.isNotificationSettingsModalOpen
        }))
    }

    handleOpenReviewContactsForDeletionDialog = () => {
        this.setState({
            isReviewContactsForDeletionDialogOpen: true,
            reviewContacts: cloneDeep(this.state.selectedWatchersToRemove),
            selectedReviewContacts: cloneDeep(this.state.selectedWatchersToRemove)
        })
    }

    handleCloseReviewContactsForDeletionDialog = () => {
        this.setState({
            isReviewContactsForDeletionDialogOpen: false,
            reviewContacts: {},
            selectedReviewContacts: {},
            selectedWatchersToRemove: {},
            canSubmit: false
        })
    }

    handleToggleConfirmRemoveContactsModal = (item) => {
        this.setState({
            selectedItem: item
        }, () => this.setState({isRemoveContactsConfirmModalOpen: !this.state.isRemoveContactsConfirmModalOpen}))
    }

    close = () => {
        this.props.onClose();
    }

    render() {
        const {translate, contactResource} = this.props

        const data = getProp(contactResource, 'data.load/watchers.list', [])
        const count = getProp(contactResource, 'data.load/watchers.count', 0)

        const selectedContactsCount = Object.keys(this.state.selectedWatchersToRemove).length
        const notificationSettingsFields = fieldsToHtml(Object.values(Object.assign({}, this.state.notificationSettingsFields)), translate, this.handleInputChange)

        return (
            <React.Fragment>
                <ModalDefault
                    show={true}
                    widthClass={'max-w-5xl'}
                    title={translate("modal_heading.add_watchers")}

                    secondaryButtonLabel={
                        <span className={selectedContactsCount ? "pr-8" : undefined}>
                            {translate('btn.delete_contacts')}
                            {!!selectedContactsCount && (
                                <div
                                    className="absolute right-0 top-0 bottom-0 w-10 bg-inverse border-l border-primary rounded-r-btn flex items-center justify-center">
                                <span className="leading-5 text-xs">
                                    {selectedContactsCount}
                                </span>
                                </div>
                            )}
                        </span>
                    }
                    onSecondaryButtonClick={this.handleOpenReviewContactsForDeletionDialog}
                    secondaryButtonDisabled={!selectedContactsCount}

                    onClose={this.close}
                    closeButtonLabel={translate("btn.cancel")}
                >

                    {contactResource.isLoading && (
                        <div className="p-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    <div className="p-6">
                        <PageHeader
                            className="flex pb-4"
                            title={translate("text.Watchers")}
                            onButtonClick={this.handleToggleAllContactsModal}
                            buttonLabel={translate("btn.add_contacts")}
                        />

                        <TableCard addClass={'rounded-none'}>
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                            />

                            <ResourceTable
                                commonTable={true}

                                data={data}
                                count={count}

                                tableKey={"ContactID"}
                                onSelectRow={this.handleSelectContact}
                                onSelectAllClick={this.handleSelectAllContacts}
                                selectedRows={this.state.selectedWatchersToRemove}

                                onRowClick={(row) => this.handleSelectContact(row, row.ContactID)}
                                onEdit={(item) => this.handleToggleNotificationSettingsModal(item)}
                                onDelete={(item) => this.handleToggleConfirmRemoveContactsModal(item)}

                                translate={translate}

                                fields={{
                                    WatcherName: new Field('WatcherName', '', ['empty']),
                                    Email: new Field('Email', '', ['empty', 'email']),
                                    ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
                                    ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
                                    ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
                                    ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
                                }}

                                isLoading={contactResource.isLoading}

                                limit={this.state.queryFilterFields.limit.value}
                                offset={this.state.offset}
                                page={this.state.paginationPage}
                                paginationButtonLimit={5}
                                onOffsetChange={this.handleUpdateOffset}

                                sort={this.state.sort}
                                sortBy={this.state.sortBy}
                                onSortChange={this.handleUpdateSort}

                                options={{
                                    style: {
                                        floatingActions: true,
                                        stripedRows: true
                                    }
                                }}
                            />

                            <NoRecords
                                addClass={"mt-5"}
                                show={(data.length === 0) && !contactResource.isLoading}
                                title={translate('text.no_records')}
                            />

                            <TableCardFooter
                                show={!(!data.length && !contactResource.isLoading)}
                            >
                                <Pagination
                                    count={count}
                                    isLoading={contactResource.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </div>
                </ModalDefault>

                <ModalDefault
                    show={this.state.isNotificationSettingsModalOpen}
                    widthClass={"max-w-xl"}
                    translate={translate}
                    title={translate("modal_heading.customize_notification_settings") + ' for ' + this.state.selectedItem?.WatcherName}
                    buttonLabel={translate("btn.apply")}
                    onButtonClick={this.handleEditNotificationSettings}
                    buttonDisabled={false}
                    onClose={this.handleToggleNotificationSettingsModal}
                    closeButtonLabel={translate("btn.cancel")}
                >
                    <div className='ml-4'>
                        {notificationSettingsFields}
                    </div>
                </ModalDefault>


                <ModalDefault
                    show={this.state.isReviewContactsForDeletionDialogOpen}
                    widthClass={"max-w-xl"}
                    translate={translate}
                    title={translate("modal_heading.review_selected_contacts")}
                    buttonLabel={translate("btn.delete_selected")}
                    onButtonClick={this.handleToggleConfirmRemoveContactsModal}
                    buttonDisabled={false}
                    onClose={this.handleCloseReviewContactsForDeletionDialog}
                    closeButtonLabel={translate("btn.cancel")}
                >
                    <ResourceTable
                        commonTable={true}
                        data={Object.values(this.state.reviewContacts)}
                        count={count}

                        tableKey={"ContactID"}

                        onRowClick={this.handleSelectReviewContact}
                        onSelectRow={this.handleSelectReviewContact}
                        selectedRows={this.state.selectedReviewContacts}
                        onSelectAllClick={this.handleSelectAllReviewContacts}

                        fields={{
                            WatcherName: new Field('WatcherName', '', ['empty']),
                            Email: new Field('Email', '', ['empty', 'email']),
                        }}

                        options={{
                            style: {
                                floatingActions: false,
                                stripedRows: true
                            }
                        }}

                        translate={translate}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={translate("Confirm")}
                    show={this.state.isRemoveContactsConfirmModalOpen}
                    text={translate("message.are_you_sure_remove_contacts")}
                    onClose={this.handleToggleConfirmRemoveContactsModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('Cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.handleRemoveWatchers();
                        this.handleToggleConfirmRemoveContactsModal()
                    }}
                />

                {/* All contacts */}
                <ResourceTableDialog
                    show={this.state.isAllContactsModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ContactsQuick}
                    title={translate("btn.add_contacts")}
                    dispatch={this.props.dispatch}

                    fields={{
                        FirstName: new Field('FirstName', '', [''], false),
                        LastName: new Field('LastName', '', [''], false),
                        Email: new Field('Email', '', [''], false)
                    }}

                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}),
                    }}

                    defaultQuery={{ExcludeIDs: data.map(it => it.ContactID).join(", ")}}
                    defaultAction={this.handleSelectContact}

                    tableKey={"ContactID"}
                    onSelectRow={this.handleSelectContact}
                    onSelectAllClick={this.handleSelectAllContacts}
                    selectedRows={this.state.selectedWatchersToAdd}

                    buttonLabel={translate('btn.add_selected')}
                    onButtonClick={this.handleAddWatchers}
                    buttonDisabled={!Object.keys(this.state.selectedWatchersToAdd).length}

                    onClose={() => this.setState({isAllContactsModalOpen: false})}
                    translate={translate}
                    commonTable={true}
                />
            </React.Fragment>
        )
    }
}
